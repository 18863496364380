<template>
  <div @click="show_alert = false">
    <!--<b-button size="sm" @click="toggle_alert()">
      SHOW
    </b-button>-->
    <b-alert
      v-model="show_alert"
      class="position-fixed fixed-bottom m-2 rounded-0 toast-alert"
      :variant="variant"
      dismissible>
      {{ text }}
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      show_alert: false,
      text: '',
      timer: 10000,
      variant: 'warning',
    }
  },
  methods: {
    handle_notification(data) {
      if (data.text) this.text = data.text
      if (data.timer) this.timer = data.timer
      if (data.variant) this.variant = data.variant
      this.toggle_alert()
    },
    toggle_alert() {
      this.show_alert = true
      setTimeout(() => {
        this.show_alert = false
      }, this.timer)
    }
  },
}
</script>

<style lang="scss">
.toast-alert {
  cursor: pointer;
  display: inline-block;
  left: auto !important;
  width: auto !important;
  right: 0;
  max-width: 320px;
}
</style>