<template>
  <b-form-select
    v-model="value"
    class="vb-select"
    :aria-invalid="aria_invalid"
    :autofocus="autofocus"
    :disabled="disabled"
    :disabled-field="disabled_field"
    :form="form"
    :html-field="html_field"
    :id="id"
    :label-field="label_field"
    :multiple="multiple"
    :name="name"
    :options="options"
    :options-field="options_field"
    :plain="plain"
    :required="required"
    :select-size="select_size"
    :size="size"
    :state="state"
    :text-field="text_field"
    :value-field="value_field"
    @change="$emit('change', value)"
    @input="$emit('input', value)"
  >
    <slot></slot>
    <slot name="first"></slot>
  </b-form-select>
</template>

<script>

export default {
  name: 'vb-select',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/form-select#comp-ref-b-form-select
      aria_invalid: this.vb_options.aria_invalid !== undefined ? this.vb_options.aria_invalid : false,                        //Optional value to set for the 'aria-invalid' attribute. Supported values are 'true' and 'false'. If not set, the 'state' prop will dictate the value
      autofocus: this.vb_options.autofocus !== undefined ? this.vb_options.autofocus : false,                                 //When set to `true`, attempts to auto-focus the control when it is mounted, or re-activated when in a keep-alive. Does not set the `autofocus` attribute on the control
      //disabled: this.vb_options.disabled !== undefined ? this.vb_options.disabled : false,                                    //When set to `true`, disables the component's functionality and places it in a disabled state
      disabled_field: this.vb_options.disabled_field !== undefined ? this.vb_options.disabled_field : 'disabled',             //When set to `true`, disables the component's functionality and places it in a disabled state
      form: this.vb_options.form !== undefined ? this.vb_options.form : '',                                                   //ID of the form that the form control belongs to. Sets the `form` attribute on the control
      html_field: this.vb_options.html_field !== undefined ? this.vb_options.html_field : 'html',                             //Field name in the `options` array that should be used for the html label instead of text field
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',                                                         //Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
      label_field: this.vb_options.label_field !== undefined ? this.vb_options.label_field : 'label',                         //The key to use from the option object to get the label
      multiple: this.vb_options.multiple !== undefined ? this.vb_options.multiple : false,                                    //When set, allows multiple options to be selected (multi-select)
      name: this.vb_options.name !== undefined ? this.vb_options.name : '',                                                   //Sets the value of the `name` attribute on the form control
      options: this.vb_options.options !== undefined ? this.vb_options.options : [],                                          //Array of items to render in the component
      options_field: this.vb_options.options_field !== undefined ? this.vb_options.options_field : 'options',                 //The key to use from the option object to get the options
      plain: this.vb_options.plain !== undefined ? this.vb_options.plain : false,                                             //Render the form control in plain mode, rather than custom styled mode
      required: this.vb_options.required !== undefined ? this.vb_options.required : false,                                    //Adds the `required` attribute to the form control
      select_size: this.vb_options.select_size !== undefined ? this.vb_options.select_size : 0,                               //When set to a number larger than 0, will set the number of display option rows. Note not all browser will respect this setting
      size: this.vb_options.size !== undefined ? this.vb_options.size : '',                                                   //Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
      state: this.vb_options.state !== undefined ? this.vb_options.state : null,                                              //Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state
      text_field: this.vb_options.text_field !== undefined ? this.vb_options.text_field : 'text',                             //Field name in the `options` array that should be used for the text label
      value: this.vb_options.value !== undefined ? this.vb_options.value : '',                                                //Current value of the select. Should be set to an array when the 'multiple' prop is set
      value_field: this.vb_options.value_field !== undefined ? this.vb_options.value_field : 'value',                         //Field name in the `options` array that should be used for the value
    }
  },

  created() {
  },

  computed: {
    disabled() {                                                                                                               //When set to `true`, disables the component's functionality and places it in a disabled state
      return this.vb_options.disabled !== undefined ? this.vb_options.disabled : false
    }
  },

  mounted() {
    // set value to parent's v-model (if v-model is set)
    if (this.$options._parentVnode.data.model) this.value = this.$options._parentVnode.data.model.value
  }
}
</script>

<style lang="scss">

  .vb-select {

    &--readonly:disabled {
      background: transparent !important;
      box-shadow: none;
      color: $vb-body-color !important;
      opacity: 1;
    }
  }

</style>