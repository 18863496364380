function test_mixin() {
  window.console.log('TEST SITE SPECIFIC MIXIN')
}

function textual_score(score_map = {"10":"Eccezionale", "9":"Eccellente", "8":"Ottimo", "7":"Buono", "6":"Carino"}) {
  const sorted_keys = Object.keys(score_map).sort((a, b) => a - b)
  return function(score) {
    const key = sorted_keys.find((current_key, index) => {
      const next_key = Number(sorted_keys[index + 1])
      return score >= current_key && (score < next_key || !next_key)
    })
    return score_map[key] || 'Mediocre'
  }
}

/*export function get_site_name() {
  return this.get_config().site.payoff + ' ' + this.get_config().site.brand
}*/


export default {
  methods: {
    test_mixin: test_mixin,
    textual_score: textual_score,
    //get_site_name,
  }
}