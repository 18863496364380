<template>

  <b-form-radio
    :class="type === 'light' ? 'vb-radio vb-radio--light' : 'vb-radio'"
    v-model="value"
    :aria-label="aria_label"
    :aria-labelledby="aria_labelledby"
    :autofocus="autofocus"
    :button="button"
    :button-variant="button_variant"
    :checked="value"
    :disabled="disabled"
    :form="form"
    :id="id"
    :inline="inline"
    :name="name"
    :plain="plain"
    :required="required"
    :size="size"
    :state="state"
    @change="$emit('change', value)"
    @input="$emit('input', value)"
  >
    <span
      class="ml-1 d-inline-block align-middle"
      v-html="text"
    />
    <slot></slot>
  </b-form-radio>

</template>

<script>

export default {
  name: 'vb-radio',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // custom options
      text: this.vb_options.text !== undefined ? this.vb_options.text : '',
      type: this.vb_options.type !== undefined ? this.vb_options.type : '',

      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/form-radio#comp-ref-b-form-radio
      aria_label: this.vb_options.aria_label !== undefined ? this.vb_options.aria_label : '',                         //Sets the value of `aria-label` attribute on the rendered element
      aria_labelledby: this.vb_options.aria_labelledby !== undefined ? this.vb_options.aria_labelledby : '',          //The ID of the element that provides a label for this component. Used as the value for the `aria-labelledby` attribute
      autofocus: this.vb_options.autofocus !== undefined ? this.vb_options.autofocus : false,                         //When set to `true`, attempts to auto-focus the control when it is mounted, or re-activated when in a keep-alive. Does not set the `autofocus` attribute on the control button this.vb_options.value !== undefined ? this.vb_options.value : false                         //When set, renders the checkbox with the appearance of a button
      button: this.vb_options.button !== undefined ? this.vb_options.button : false,                                  //When set, renders the checkbox with the appearance of a button
      button_variant: this.vb_options.button_variant !== undefined ? this.vb_options.button_variant : '',             //Applies on of Bootstrap's theme colors when in 'button' mode
      checked: this.vb_options.checked !== undefined ? this.vb_options.checked : null,                                //The current value of the radio(s)
      //disabled: this.vb_options.disabled !== undefined ? this.vb_options.disabled : false,                            //When set to `true`, disables the component's functionality and places it in a disabled state
      form: this.vb_options.form !== undefined ? this.vb_options.form : '',                                           //ID of the form that the form control belongs to. Sets the `form` attribute on the control
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',                                                 //Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
      inline: this.vb_options.inline !== undefined ? this.vb_options.inline : false,                                  //When set, renders the checkbox as an inline element rather than as a 100% width block
      name: this.vb_options.name !== undefined ? this.vb_options.name : '',                                           //Sets the value of the `name` attribute on the form control
      plain: this.vb_options.plain !== undefined ? this.vb_options.plain : false,                                     //Render the form control in plain mode, rather than custom styled mode
      required: this.vb_options.required !== undefined ? this.vb_options.required : false,                            //Adds the `required` attribute to the form control
      size: this.vb_options.size !== undefined ? this.vb_options.size : '',                                           //Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
      state: this.vb_options.state !== undefined ? this.vb_options.state : null,                                      //Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state
      value: this.vb_options.value !== undefined ? this.vb_options.value : true,                                      //Value returned when this radio is checked
    }
  },

  created() {
  },

  computed: {
    disabled() {                                                                                                      //When set to `true`, disables the component's functionality and places it in a disabled state
      return this.vb_options.disabled !== undefined ? this.vb_options.disabled : false
    }
  },

  mounted() {
    // set value to parent's v-model (if v-model is set)
    if (this.$options._parentVnode.data.model) this.value = this.$options._parentVnode.data.model.value
  }
}
</script>

<style lang="scss">

  .vb-radio {

    input:checked~label:after {
      background-color: $info;
      box-shadow: none;
    }

    input:disabled~label:after {
      display: none;
    }

    &--light input:disabled~label:before {
      background: #f9f9f9 !important;
    }

    label {

      &:before {
        $size: 1.25rem;
        width: $size;
        height: $size;
        background: linear-gradient(0deg, rgba(242, 241, 241, 1) 40%, rgba(229, 229, 229, 1) 100%);
        box-shadow: inset 0 -1px 0 rgb(255, 255, 255), inset 0 1px 0 rgb(204, 206, 218, 100%), inset 0 -7px 3px rgb(255 255 255 / 20%) !important;
      }

      &:after {
        $size: .625rem;
        width: $size;
        height: $size;
        top: .569rem;
        left: -1.188rem;
        background-image: none !important;
        border-radius: $custom-radio-indicator-border-radius;
        box-shadow: inset 0 1px 0 #fff, 0 1px 3px rgb(0 0 0 / 20%);
      }
    }

    &--light {

      label {

        &:before {
          box-shadow: none !important;
          background: $white !important;
          border: 1px solid #ececec !important;
        }

        &:after {
          box-shadow: none;
          background-color: #f2f1f1;
        }
      }
    }
  }

</style>