import Vue from 'vue'
import Store from '@/vuex'
import { log } from '@/utilities'
import { round, multiply, divide } from 'mathjs'

function create_new_session() {
  // calculate localstorage occupied space
  let all_strings = '', length, report
  for (const key of Object.keys(window.localStorage)) all_strings += window.localStorage[key]
  length = round(divide(multiply(all_strings.length, 16), multiply(8, 1024)))
  report = 'local storage: ' + (all_strings ? 3 + length + ' KB' : 'empty (0 KB)')
  log('[session.js] - ' + report)
  return btoa((new Date()).getTime())
}

// chiave localstorage per store globale (condiviso tra le sessioni)
export const localstorage_store_key = process.env.VUE_APP_STORE_KEY + '_st'

// chiave localstorage per sessioni
export const localstorage_sessions_key = process.env.VUE_APP_STORE_KEY + '_se'

// chiave di sessione sessionstorage
export const sessionstorage_session_key = process.env.VUE_APP_STORE_KEY + '_se'

export const existing_session_key_query = 'es'

export const new_session_key_query = 'ns'

// gestione sessioni
export function session_handler() {
  // session_handler() ritorna false ho una chiave di sessione in get, nel qual caso NON va riscritto lo store globale in localstorage all'avvio dell'app,
  // altrimenti la nuova scheda che sto aprendo potrebbe cercare il token in localstorage mentre l'app ci sta scrivendo dentro, trovando una stringa vuota;
  // verrebbe così creata una nuova sessione di fatto pubblica (token='') e verrebbero cancellate tutte le sessioni esistenti perché hanno un token diverso da quello attuale

  // recupero eventuale token già esistente in localstorage condiviso
  let url = window.location,
    params = new URLSearchParams(url.search),
    refresh_localstorage_store = true,
    token = '',
    global_localstorage = Vue.$localStorage.get(localstorage_store_key),
    sessionstorage = Vue.$sessionStorage.get(sessionstorage_session_key),
    session_key = params.get('sk'),
    clone_session_key = params.get('ns')

  // la chiave di sessione che trovo in session storage ha la precedenza su quella che ho in get (e comunque dovrebbero sempre coincidere)
  if (sessionstorage) session_key = Vue.$sessionStorage.get(sessionstorage_session_key)

  if (global_localstorage) token = global_localstorage.token

  // il token in get ha la precedenza, ed a questo punto del flusso non è ancora stato letto dal config
  if (params.get('token')) token = params.get('token')

  // cancello dal localstorage tutte le sessioni con eventuale token non valido
  let localstorage_sessions = (Vue.$localStorage.get(localstorage_sessions_key))
  if (localstorage_sessions) {
    let keys = Object.keys(localstorage_sessions)
    keys.forEach(key => {
      if (localstorage_sessions[key] == undefined | localstorage_sessions[key].token == undefined | localstorage_sessions[key].token !== token) delete localstorage_sessions[key]
    })
  } else { localstorage_sessions = {} }

  // chiave di sessione trovata in querystring => clono la sessione in una nuova
  if (clone_session_key) {
    let new_session_key = create_new_session()
    localstorage_sessions = {
      [new_session_key]: localstorage_sessions[clone_session_key],
      ...localstorage_sessions
    }
    log('[sessions.js] - found session ' + clone_session_key + ' in querystring')
    log('[sessions.js] - load session ' + clone_session_key + ' from localstorage')
    log('[sessions.js] - clone old session ' + clone_session_key + ' into new session: ' + new_session_key + ' on top of localstorage session_key stack')
    // pulisco querystring senza refreshare per evitare che con un F5 venga creata una nuova sessione indesiderata
    window.history.replaceState({}, '', (window.location.href).split('?')[0])
    session_key = new_session_key
    refresh_localstorage_store = false
  }

  // se non c'è una chiave di sessione in querystring, cerco di caricare la sessione valida più recente che trovo in localstorage
  if (session_key == undefined) {
    log('[sessions.js] - load last valid session from localstorage: ' + session_key)
    session_key = Object.keys(localstorage_sessions)[0]
  }

  // se non c'è nessuna sessione valida recente creo una nuova chiave di sessione
  if (session_key == undefined) {
    log('[sessions.js] - no valid session found => create new empty session: ' + session_key)
    session_key = create_new_session()
  }

  Vue.$localStorage.set(localstorage_sessions_key, localstorage_sessions)
  Vue.$sessionStorage.set(sessionstorage_session_key, session_key)
  Vue.prototype.$localstorage_session_key = session_key

  return refresh_localstorage_store
}

// caricamento state da localstorage
export function load_state_from_local_storage(submodule = '', state) {
  (localstorage_store_key, localstorage_sessions_key)

  // caso submodule
  if (submodule !== '') {
    if (Store.state[submodule]) {
      let object = Store.state[submodule]
      for (const key in object) {
        if (state.hasOwnProperty.call(object, key)) {
          state[key] = object[key]
        }
      }
    }
    return state
  }

  // caso default state globale
  let local_storage_object = Vue.$localStorage.get(localstorage_store_key)

  if (local_storage_object == undefined) return state
  if (Object.keys(local_storage_object) == 0) return state
  let local_storage_state = {}
  if (submodule) {
    local_storage_state = ((local_storage_object))[submodule]
  }
  else {
    local_storage_state = ((local_storage_object))
  }
  if (!local_storage_state) return state
  Object.keys(local_storage_state).forEach(e => {
    if (local_storage_state[e] && local_storage_state[e] != null) state[e] = local_storage_state[e]
  })

  let local_storage_session_object = {}

  if (Vue.$localStorage.get(localstorage_sessions_key)) {
    local_storage_session_object = {
      ...(Vue.$localStorage.get(localstorage_sessions_key))[Vue.prototype.$localstorage_session_key]
    }
  }

  return {
    ...state,
    ...local_storage_session_object
  }
}