import Store from './store'
import {
  vuex_subscribe_module,
  vuex_store_config
} from './store'

export default Store

export {
  vuex_subscribe_module,
  vuex_store_config
}