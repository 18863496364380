<template>
  <div class="discount-codes">
    <div v-if="loading_dc" class="text-center">
      <loader />
    </div>
    <div class="discount-codes--recap" v-if="!loading_dc">

      <!--<div v-if="codes && codes.length > 0"> se ci sono buoni nello state -->
      <div class="d-flex mb-2">
        <strong v-if="discount_codes.length">Buoni caricati</strong>
        <strong v-else class="logo">Usa un codice di <span class="d-inline-block">{{ get_site_name() }}</span></strong>
      </div>

      <!-- loop codici caricati -->
      <div v-for="(code, index) in discount_codes" v-bind:key="'dc_' + index" class="d-flex discount-codes--list">
        <div class="discount-codes--item" :class="{
            'validCode': code.usable,
            'warningCode': ((!code.usable) && (code.valueUsed > 0)) | (code.usable && !code.divisible && code.valueUsed == 0),
            'invalidCode': (!code.usable) && (code.valueUsed <= 0),
            'fullDot': code.valueUsed == code.valueResidual,
            'halfDot': (code.valueUsed > 0) & (code.valueUsed < code.valueResidual),
            'grayDot': code.valueUsed == 0,
            'redDot': !code.usable
          }">
          <div v-b-toggle="'accordion-dc-' + index">
            <div class="discount-code--title" v-if="!code.divisible && code.status == 'VALID'">
              <div class="d-flex justify-content-between mb-2">
                <!--Buono sconto-->
                <div class="d-flex" v-b-popover.hover.top="'Questo Buono può essere speso solo in modo completo e per una singola prenotazione'">
                  NON frazionabile <div class="icon-small ml-1" v-html="svg_info_circle"></div>
                </div>
                <div v-html="svg_chevron_down" class="svgchevron"></div>
              </div>
            </div>
            <div class="discount-codes--row">
              <div class="copy copyDots">
                <div class="dcCode">{{ code.discountCode }}</div>
              </div>
              <div v-if="code.status != 'EXPIRED'" class="spacer"></div>
              <div v-if="code.status != 'EXPIRED'" class="value">
                <b>{{ round(code.valueUsed, 2) }}€</b>
              </div>
            </div>
          </div>

          <b-collapse :id="'accordion-dc-' + index" accordion="accordion-discount-codes">
            <!--gestione errori / informazioni-->
            <div class="discount-codes--row danger" v-if="code.valueUsed == 0">
              <div class="mt-1 mb-1 copy d-flex justify-content-center align-items-center">
                <div v-html="svg_error" class="icon-small error mr-1"></div>
                <span v-if="code.status == 'NOT FOUND'">
                  Non abbiamo trovato questo buono sconto. Assicurati di averlo
                  digitato correttamente rispettando MAIUSCOLE e minuscole
                </span>
                <span v-else-if="code.status == 'EXPIRED'">
                  Buono sconto scaduto il {{ code.expirationDate }}
                </span>
                <span v-else-if="code.status == 'EMPTY'">
                  Questo buono sconto è già stato interamente utilizzato per
                  un'altra prenotazione
                </span>
                <span v-else-if="code.status == 'DISABLED'">
                  Questo buono sconto non è attivo, contatta l'assistenza
                </span>
                <span v-else-if="!code.divisible && code.valueUsed == 0">
                  Questo buono è di tipo non frazionabile e non può essere speso
                  parzialmente
                </span>
                <span v-else-if="code.status == 'NOT CUMULABLE'">
                  Questo buono sconto non è cumulabile, togli gli altri codici
                  dalla lista per utilizzare questo
                </span>
                <span v-else> Impossibile usare questo buono sconto </span>
              </div>
            </div>

            <div class="discount-codes--row" v-if="code.cumulable == false">
              <div class="copy" v-b-popover.hover.top="'Questo buono non può essere utilizzato congiuntamente ad altri buoni'">
                <div class="icon-small mr-1" v-html="svg_info_circle"></div> <b>Non cumulabile</b>
              </div>
            </div>

            <div class="discount-codes--row">
              <div class="copy" v-b-popover.hover.top="'Valore del Buon all\'emissione'">
                <div class="icon-small mr-1" v-html="svg_info_circle"></div> Valore originario
              </div>
              <div class="spacer"></div>
              <div class="value">{{ round(code.valueNominal, 2) }}€</div>
            </div>

            <div v-if="code.status == 'VALID'" class="discount-codes--row">
              <div class="copy" v-b-popover.hover.top="'In base a possibili utilizzi del Buono sconto per precedenti prenotazioni'">
                <div class="icon-small mr-1" v-html="svg_info_circle"></div> Valore disponibile
              </div>
              <div class="spacer"></div>
              <div class="value">{{ round(code.valueResidual, 2) }}€</div>
            </div>

            <div v-if="code.status == 'VALID'" class="discount-codes--row">
              <div class="copy copyDots"
                v-b-popover.hover.top="
                  'Il valore del Buono sconto ' +
                  (code.valueUsed == code.valueResidual
                    ? 'viene utilizzato in toto per questa prenotazione'
                    : '') +
                  ((code.valueUsed > 0) & (code.valueUsed < code.valueResidual)
                    ? 'viene utilizzato solo parzialmente per questa prenotazione '
                    : '') +
                  (code.valueUsed == 0
                    ? 'non verrà utilizzato per questa prenotazione. Resterà valido per altre prenotazioni'
                    : '')
                ">
                <div class="icon-small mr-1" v-html="svg_info_circle"></div>
                <div><b>Utilizzo per questa prenotazione</b></div>
              </div>
              <div class="spacer"></div>
              <div class="value">
                <b>{{ round(code.valueUsed, 2) }}€</b>
              </div>
            </div>

            <div v-if="code.status == 'VALID'" class="discount-codes--row">
              <div class="copy" v-b-popover.hover.top="'Ti invieremo una email con un riepilogo per un futuro utilizzo del residuo'">
                <div class="icon-small mr-1" v-html="svg_info_circle"></div>
                <div>Valore disponibile dopo la prenotazione</div>
              </div>
              <div class="spacer"></div>
              <div class="value">
                {{ round( subtract(code.valueResidual,  code.valueUsed), 2) }}€
              </div>
            </div>

            <div class="discountCodeRemove" @click="remove_code(code.discountCode)">
              <div v-if="code.status == 'VALID'">Non utilizzare</div>
              <div v-else>Togli dalla lista</div>
              <div v-html="svg_trash" class="svgtrash"></div>
            </div>
          </b-collapse>
        </div>
      </div>

      <!-- inserimento nuovo codice -->
      <!--v-if="!not_cumulable"-->
      <div class="checkout-resume-row d-block paymentPartnerBlock">
        <div class="d-block mb-2" v-if="discount_codes.length"><strong class="logo">Usa un codice sconto</strong></div>
        <div class="dc-item" v-for="(dc_config_item,dc_config_index) in dc_config" v-bind:key="'dc_config_'+dc_config_index">
          <div class="logo" v-if="dc_config_item.brand">
            {{ dc_config_item.brand }}
          </div>
          <validation-observer v-slot="{ invalid }" ref="discount-codes-validation">
            <validation-provider rules="alpha_num|min:16|max:16" name="discount-code" v-slot="{ errors }">
              <div class="new-code" :set_invalid_flag="invalid_flag=(invalid || generic_discount_code == '')">
                <b-input
                  class="new_discount_code invalid"
                  type="text"
                  ref="generic_discount_code"
                  v-model="generic_discount_code"
                  placeholder="es: RFdBkpTTHam8w76" />
                <b-button class="addButton" @click="add_code(generic_discount_code)" :disabled="invalid_flag">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.5" cy="7.5" r="7.5" :fill="invalid_flag ? '#eeeeee' : '#0071BC'"/>
                    <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" :fill="invalid_flag ? '#CCCCCC' : '#E9EBF0'"/>
                  </svg>
                </b-button>
              </div>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Vue from "vue"
import { number, subtract, sum, larger, smallerEq, round } from "mathjs"
//import { dc_check } from "@/api"
import { service_handler } from '@/services'
export default {
  name: "discount-codes",
  components: {},
  props: {
    live_price: [Number, String],
    av_from_state: [Number, String],
    dc_from_state: {
      type: Array,
      default: () => { return [] }
    },
    dc_config: {
      type: Array,
      default: () => {
        return [
          {
            brand: false
          }
        ]
      }
    }
  },
  created() {
    this.round = round //faccio il binding solo se serve a template
    this.subtract = subtract
    this.svg_info_circle = '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.208 0.915039C9.87421 0.915039 7.59279 1.60709 5.65229 2.90369C3.71179 4.20029 2.19936 6.04317 1.30625 8.19934C0.413131 10.3555 0.179454 12.7281 0.63476 15.0171C1.09007 17.3061 2.21391 19.4086 3.86417 21.0588C5.51443 22.7091 7.61699 23.8329 9.90596 24.2882C12.1949 24.7435 14.5675 24.5099 16.7237 23.6168C18.8799 22.7237 20.7228 21.2113 22.0194 19.2708C23.316 17.3303 24.008 15.0488 24.008 12.715C24.008 9.58541 22.7648 6.58402 20.5519 4.37109C18.339 2.15816 15.3376 0.915039 12.208 0.915039ZM11.846 5.89001C12.2475 5.89211 12.6319 6.05224 12.916 6.33582C13.2002 6.6194 13.3611 7.0036 13.364 7.40503C13.3559 7.81487 13.1873 8.20509 12.8945 8.49194C12.6016 8.77879 12.2079 8.93931 11.798 8.93896C11.401 8.92937 11.0233 8.76567 10.7449 8.48254C10.4664 8.19942 10.309 7.8191 10.306 7.422C10.3128 7.01641 10.4774 6.62947 10.765 6.34338C11.0526 6.0573 11.4404 5.89465 11.846 5.89001ZM14.865 19.538H9.55003V17.2729H11.05V12.7H9.55003V10.421H13.35V17.254H14.861L14.865 19.538Z" fill="#333333"/></svg>'
    this.svg_chevron_down = '<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.645 1.35498L9.97501 10.026L1.30399 1.35498" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
    this.svg_trash = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash fa-w-14"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" class=""></path></svg>'
    this.svg_error = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-exclamation-triangle fa-w-18"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" class=""></path></svg>'
  },
  computed: {
    discount_price() {
      return number(this.live_price);
    },
    discount_codes_availability: {
      get() {
        let availability = (larger(number(this.availability), 0))? number(this.availability) : 0
        if (availability <= this.live_price) return availability
        return this.live_price
      },
      set() {},
    },
    not_cumulable() {
      return this.discount_codes.filter(function(d) { return !d.cumulable }).length !== 0
    }
  },
  data() {
    return {
      loading_dc: true,
      discount_codes_amount: 0,
      generic_discount_code: '',
      discount_codes: [],
      availability: 0,
    };
  },
  methods: {
    validate_codes() {
      let existing_codes = ''
      this.loading_dc = true
      if (this.discount_codes.length == 0) {
        this.discount_codes = []
        this.update_totals()
        this.loading_dc = false
        return
      }
      this.discount_codes.forEach((code, index) => {
        existing_codes += code.discountCode
        if (index < this.discount_codes.length - 1) existing_codes += ","
      })
      
      service_handler('dcCheck', {
        discountCodes: existing_codes,
      })
      .then((response) => {
        if (response.data.updateAvailabilityTo !== undefined) this.availability = number(response.data.updateAvailabilityTo)
        this.discount_codes = Object.values(response.data.discountCodes)
        this.update_totals()
        this.loading_dc = false
      })
    },
    update_totals() {
      this.loading_dc = true
      this.discount_codes_amount = 0 // ammontare usato con i codici sconto
      // salvo il prezzo della prenotazione
      // e man a mano che uso codici lo scalo così so quanto mi occorre dai codici
      let temp_checkout_amount = subtract(this.discount_price, this.discount_codes_availability) // prima era availabilityToUse
      this.discount_codes.forEach((code) => {
        if (typeof code.valueNominal == "string") code.valueNominal = number(code.valueNominal)
        if (typeof code.valueResidual == "string") code.valueResidual = number(code.valueResidual)
        code.valueUsed = 0 // serve per l'interfaccia
        if (temp_checkout_amount == 0) return // già raggiunto il pagamento
        if (!code.usable) return
        if (!code.divisible && smallerEq(code.valueResidual, temp_checkout_amount)) {
          // non frazionabile di importo pari o inferiore a quanto resta da pagare
          code.valueUsed = code.valueResidual
          temp_checkout_amount = subtract(temp_checkout_amount, code.valueResidual)
          this.discount_codes_amount = sum(this.discount_codes_amount, code.valueUsed)
          return
        }
        if (code.divisible) {
          if (smallerEq(code.valueResidual, temp_checkout_amount)) {
            // importo minore uguale, uso tutto
            code.valueUsed = code.valueResidual
            temp_checkout_amount = subtract(temp_checkout_amount, code.valueResidual)
            this.discount_codes_amount = sum(this.discount_codes_amount, code.valueUsed)
            return
          } else if (larger(code.valueResidual, temp_checkout_amount)) {
            // importo maggiore, uso quanto basta
            code.valueUsed = temp_checkout_amount
            temp_checkout_amount = subtract(temp_checkout_amount, temp_checkout_amount) // praticamente farà sempre 0, ma così è più coerente
            this.discount_codes_amount = sum(this.discount_codes_amount, code.valueUsed)
            return
          }
        } // if divisible
      }) // each discount codes
      this.discount_codes_amount = round(this.discount_codes_amount, 2)
      this.$emit('change', { name: 'discount-codes-update', payload: { discount_codes_amount: this.discount_codes_amount, discount_codes: this.discount_codes }})
      this.loading_dc = false
    },
    add_code(code)
    {
      if(!this.discount_codes.includes(code))
      {
        this.discount_codes.push({
          discountCode: code
        })
      }
      this.new_discount_code = ''
      this.generic_discount_code = ''
      this.validate_codes()
    },
    remove_code(discount_code_to_remove)
    {
      this.loading_dc = true
      this.discount_codes = this.discount_codes.filter(function(d){
        return d.discountCode !== discount_code_to_remove
      })
      this.new_discount_code = ''
      this.generic_discount_code = ''
      this.validate_codes()
    },    
  },
  mounted() {
    //  DUwb6gXEego0Q320
    this.discount_codes = this.dc_from_state
    this.availability = this.av_from_state
    this.validate_codes()
  },
}
</script>

<style lang='scss'>
.discount-codes {
  svg {
    height:14px;
    width:auto;
  }
  .svgtrash {
    path {
      fill:$danger;
    }
  }
  .icon-small {
    svg {
      min-height:14px !important;
      min-width:14px !important;
      padding-top: 2px;
    }
    path {
      //fill:$cta;
    }
    &.error {
      path {
        fill: $danger;
      }
    }
  }
  .svgchevron {
    path {
      //stroke:$text;//$cta;
    }
    svg {
      height:8px;
    }
  }
  .checkout-resume-block .checkout-resume-row {
    justify-content: flex-start;
    margin-bottom: 1.5rem;
  }
  .new-code {
    display: flex;
    align-items: center;
    .invalid {
      //border-color:magenta !important;
    }
    p {
      display: none;
    }
    .invalid ~ p {
      display: block;
    }
    .invalid ~ button {
      //display:none;
    }

    button {
    }
  }
  .icon {
    width: 26px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    * {
      fill: $black;
    }
  }
  &--list {
  }
  &--item {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    background: rgba($white, 0.2);
    //border:1px solid rgba($white, 0.25);
    padding: 0.5rem 0; // 0.75rem;
    border-radius: $border-radius;
    .spacer,
    .value,
    .copyDots:after,
    .copyDots:before {
      transition: $transition-fade;
    }
    .not-collapsed {
      .spacer,
      .value,
      .copyDots:after,
      .copyDots:before {
        opacity: 0;
      }
    }
    .discountCodeRemove {
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 80%;
      line-height: 1;
      cursor: pointer;
      transition: $transition-base;
      * {
        transition: $transition-base;
      }
      &:hover {
        color: $danger;
        * {
          fill: $danger;
        }
      }
      svg {
        margin-left: 10px;
      }
    }
    .dcResume {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      margin-top: 8px;
      .discount-codes--row {
        //margin-top:8px;
      }
      svg {
        margin-right: 6px;
        * {
          fill: $black;
        }
      }
    }    
  }
  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    cursor: pointer;
    .chevron {
      //max-width: 14px;
      * {
        stroke: $white;
      }
    }
  }
  &--row {
    display: flex;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0.5rem;
    }
    .copy {
      padding-left: 26px;
      display: flex;
      align-items: center;
      line-height: 1;
      position: relative;
      $dot: 16px;
      &.copyDots:before {
        content: "";
        position: absolute;
        width: $dot;
        height: $dot;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.copyDots:after {
        content: "";
        position: absolute;
        width: $dot/2;
        height: $dot;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        border-bottom-right-radius: $dot/2;
        border-top-right-radius: $dot/2;
      }
    }
    .spacer {
      flex: 1;
      border-bottom: 1px solid rgba($black, 0.5);
      margin: 0 6px 3px 6px;
    }
    .value {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      line-height: 1;
      //width: 60px;
      text-align: right;
    }
  }
  .codeInfo {
    flex: 1;
  }
  .codeData {
    display: flex;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
  .dcCode {
    display: inline-block;
    color: $white;
    padding: 3px 8px;
    border-radius: 4px;
    //margin-top:4px;
    //margin-bottom:4px;
  }
  .validCode {
    .dcCode {
      background: $success;
    }
    .resume * {
      color: $success;
    }
    * {
      //fill:$success;
    }
  }
  .invalidCode {
    .dcCode {
      background: $danger;
    }
    .resume * {
      color: $danger;
    }
    * {
      //fill:$danger;
    }
  }
  .warningCode {
    .dcCode {
      background: $gray-600;
    }
    .resume * {
      color: $gray-600;
    }
    * {
      //fill:$danger;
    }
  }
  .fullDot //usato per intero, quindi green per forza
  {
    .copyDots:before {
      background-color: $success;
      border-color: $success;
    }
    .copyDots:after {
      display: none;
    }
  }
  .halfDot //usato in parte, quindi green per forza
  {
    .copyDots:before {
      background-color: transparent;
      border-color: $success;
    }
    .copyDots:after {
      background-color: $success;
    }
  }
  .grayDot //valido ma non usato, grigio
  {
    .copyDots:before {
      background-color: transparent;
      border-color: $gray-600;
    }
    .copyDots:after {
      display: none;
    }
  }
  .redDot //non valido quindi non usato, rosso per forza
  {
    .copyDots:before {
      display: none;
    }
    .copyDots:after {
      display: none;
    }
  }

  .icons {
    .dcOk path {
      fill: $success;
    }
    .dcKo path {
      fill: $danger;
    }
  }
  .code {
    align-items: center;
    .infoDivisible {
      display: flex;
      align-items: center;
    }
  }
  .resume {
    padding-right: 1.5rem;
    align-items: center;
  }
  .remove {
    cursor: pointer;
    width: 18px;
    text-align: center;
  }

  .icons {
    width: 18px;
    text-align: center;
    margin-right: 1rem;
    svg {
      width: 100%;
    }
  }
  .code {
    padding-right: 1rem;
    flex: 1;
  }
  .resume {
    display: flex;
    text-align: left;
    justify-content: space-between;
    flex: 1;
  }
  .info {
  }
  .dc-item {
    .logo {
      display: block;
      width:100%;
      padding-bottom:6px;
    }
    span {
      display: block;
      width:100%;
    }
      margin-bottom: 0;
      display:flex;
      justify-content: space-between;
      align-items:center;
      flex-wrap: wrap;
      .logo
      {
          padding-right:20px;
          height:auto;

          width:40%;
          min-width:40%;
          max-width:40%;
      }
      .addButton
      {
          width:40px !important;
          padding:0;
          margin:0;
          background:transparent !important;
          border:0;
          outline:none;
          box-shadow: 0;
          display:flex;
          justify-content:flex-end;
          svg
          {
              width:20px;
              height:auto;
              circle
              {
                  transition:$transition-base;
              }
          }
      }

      .ko
      {
          display:none;
      }
      .ok
      {
          display:block;
      }
      .invalid ~ .addButton {
          //display:none;
          circle
          {
              //fill:#999;
          }
          &.ok
          {
              display:none;
          }
          &.ko
          {
              display:block;
          }
      }
  }
}
</style>