import _ from 'lodash'
import Axios from 'axios'
import Store from '@/vuex'
import { serialized_vuex_config_object as vuex_global_state_config_object } from '@/vuex/config'
import { serialized_vuex_config_object as vuex_module_state_config_object } from '@running_app_src/store'
import { log } from '@utilities'
import UAParser from 'ua-parser-js'

const default_global_state = JSON.parse(vuex_global_state_config_object)
const default_module_state = JSON.parse(vuex_module_state_config_object)
const service_handler_timeout = (service_name) => {
  // valore (in millisecondi) del tempo massimo consentito per la chiamata
  switch (service_name) {
    case 'book': return 180000
    default: return 60000
  }
}
const { device /*, browser, cpu*/ } = UAParser(navigator.userAgent)
const endpoint_config = process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_CONFIG_API
const controller = new AbortController() // CancelToken deprecati => https://axios-http.com/docs/cancellation
const signal = controller.signal
const api = Axios.create({
  headers: {
    post: {},
    get: {},
  }
})

let agent = {
  mobile: device.type == 'mobile' || device.type == 'tablet'  // [console, mobile, tablet, smarttv, wearable, embedded]
},
  endpoints = {},
  axios_config = {
    headers: {
      post: {
        "Content-Type": "application/json",
      },
      get: {
        "Content-Type": "application/json",
      },
    }
  } // => popolato dopo la get_config (Bearer ext_token)

signal.onabort = (event) => {
  log('[request aborted at service_handler]')
  log(event)
}

function set_axios_payload(payload = {}, service_config) {

  // if not external endpoints (eg: here.com) => add tokens/agent
  if (!service_config.external) {
    payload.token = Store.state.token
    payload.extToken = Store.state.ext_token
    payload.agent = agent
  }

  // [get] case (eg: here.com)
  if (service_config.api_method == 'get') return payload

  // [post] case (default) 
  return JSON.stringify(payload)
}

function service_promise_handler(promise, params = { caller: '[services/common.js] service_promise_handler()', success: (r) => { (r) }, error: (e) => { (e) } }) {
  // service_promise_handler gestisce una copia (passata per valore) della promise di axios per permettere di gestire l'errore a questo livello ed anche a livello di componente
  // la promise originale, SENZA error handler, viene ritornata al componente: in questo modo il componente può fare la sua gestione dell'errore e della response
  // la gestione di error/response delle due promises (una gestita a questo livello, una ritornata al componente) sono parallele, completamente indipendenti e opzionali: possono essere implementate entrambe, una sola delle due, nessuna

  let timeout = service_handler_timeout(params.caller)
  let service_handler_response_flag = false
  let race = Promise.race([
    promise,
    new Promise((_, reject) =>
      setTimeout(() => {
        if (service_handler_response_flag) return

        service_handler('abort')
        reject(new Error('service handler timeout reached: ' + timeout))

        // reset common store
        Object.keys(default_global_state).forEach(k => {
          if (k == 'token') return
          if (k == 'ext_token') return
          let value = default_global_state[k]
          if (k == 'loading') value = false
          if (k == 'searching') value = false
          log(`[global store reset]: ${k} - ${value}`)
          Store.commit(k, value)
        })

        // reset modules store
        Object.keys(Store._modules.root._children).forEach(m => {
          Object.keys(default_module_state).forEach(k => {
            log(`[${m} module store reset]: ${k} - ${default_module_state[k]}`)
            Store.commit(`${m}/${k}`, default_module_state[k])
          })
        })
      }, timeout)
    ),
  ])

  race
    .then(
      success => {
        service_handler_response_flag = true
        params.success(success)
      },
      error => {
        log(params.caller + ' [api error]', error)
        service_handler_response_flag = true
        params.error(error)
      },
    )
    .catch(error => {
      log(params.caller + ' [runtime error]', error)
      params.error(error)
    })
}

function send_notification(error) {
  let callback = function () {
    return log('Nessuna azione prevista')
  }

  let data = (error.response && error.response.data) ? error.response.data : ''
  if (data == '' && error.data) data = error.data

  if (data == '') {
    // errore non gestito lato server (es. PHP FATAL ERROR)
    data = {}
    data.routerPush = 'homepage'
  }

  if (error.response && error.response.status) data.status = error.response.status
  data.callback = callback
  window.eventBus.$emit('got_modal_notification', data)
  return error.response
}

// unica richiesta non centralizzata
export function get_config(token) {
  let params = { token: token, agent: agent }
  return api.post(
    endpoint_config,
    params,
  )
    .then((response) => {
      endpoints = response.data.endpoints
      return response.data
    }, (error) => {
      log(error)
    })
}

// servizio centralizzato per ogni richiesta
export function service_handler(
  service_name,
  service_body = {},
  service_config = {}
) {
  service_config = {
    // default config
    ...{
      modal: true,
      external: false, // external call (eg. here) => hide tokens
      api_method: 'post',
    },
    // requested config
    ...service_config
  }

  // interrompe tutte le pending request
  if (service_name == 'abort') {
    controller.abort()
    return
  }

  let promise = api[service_config.api_method](
    _.get(endpoints, service_name),
    set_axios_payload(service_body, service_config),
    axios_config,
  )

  // gestione centralizzata success/error
  service_promise_handler(
    promise,
    {
      caller: service_name,
      success: (response) => {
        (response)
        // nessuna azione prevista
      },
      error: (error) => {
        // trigger modal di errore (se non specificato diversamente da service_config, es: autocomplete)
        if (service_config.modal) send_notification(error)
        // es: if(service_name == 'otp') => gestisci una toast invece che una modal
      }
    }
  )

  // gestione delegata al componente success/error
  return promise
}

export default {
}