export default '<svg\n' +
'   xmlns:dc="http://purl.org/dc/elements/1.1/"\n' +
'   xmlns:cc="http://creativecommons.org/ns#"\n' +
'   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"\n' +
'   xmlns:svg="http://www.w3.org/2000/svg"\n' +
'   xmlns="http://www.w3.org/2000/svg"\n' +
'   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"\n' +
'   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"\n' +
'   width="320"\n' +
'   height="82.5"\n' +
'   viewBox="0 0 320 82.5"\n' +
'   fill="none"\n' +
'   version="1.1"\n' +
'   id="svg120"\n' +
'   sodipodi:docname="Logo-vacanze-VadoBay-pay-off_1.svg"\n' +
'   inkscape:version="1.0.2 (1.0.2+r75+1)">\n' +
'  <metadata\n' +
'     id="metadata124">\n' +
'    <rdf:RDF>\n' +
'      <cc:Work\n' +
'         rdf:about="">\n' +
'        <dc:format>image/svg+xml</dc:format>\n' +
'        <dc:type\n' +
'           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />\n' +
'        <dc:title></dc:title>\n' +
'      </cc:Work>\n' +
'    </rdf:RDF>\n' +
'  </metadata>\n' +
'  <sodipodi:namedview\n' +
'     pagecolor="#ffffff"\n' +
'     bordercolor="#666666"\n' +
'     borderopacity="1"\n' +
'     objecttolerance="10"\n' +
'     gridtolerance="10"\n' +
'     guidetolerance="10"\n' +
'     inkscape:pageopacity="0"\n' +
'     inkscape:pageshadow="2"\n' +
'     inkscape:window-width="1920"\n' +
'     inkscape:window-height="1016"\n' +
'     id="namedview122"\n' +
'     showgrid="false"\n' +
'     inkscape:zoom="1.2774946"\n' +
'     inkscape:cx="85.653424"\n' +
'     inkscape:cy="244.77988"\n' +
'     inkscape:window-x="0"\n' +
'     inkscape:window-y="27"\n' +
'     inkscape:window-maximized="1"\n' +
'     inkscape:current-layer="svg120" />\n' +
'  <path\n' +
'     d="m 181.02787,5.8565224 h 3.94979 l 3.02837,9.0842836 3.02739,-9.0842836 h 3.94979 l -5.26639,13.5605166 h -3.42256 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path2"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 195.77356,15.467149 c 0,-3.028075 2.23802,-4.344672 5.39746,-4.344672 1.31659,0 2.37007,0.263418 3.29149,0.526639 v -0.263319 c 0,-1.5798281 -0.92142,-2.5014067 -2.89632,-2.5014067 -1.44865,0 -2.50114,0.2633194 -3.68667,0.7899386 l -0.92142,-2.896425 c 1.44865,-0.6582789 2.89632,-1.0532285 5.26639,-1.0532285 2.10597,0 3.55462,0.5265896 4.47604,1.4481781 1.05347,1.0532382 1.44865,2.5014655 1.44865,4.2129435 v 7.767726 h -3.68667 v -1.448257 c -0.92142,1.053278 -2.23802,1.711576 -4.08086,1.711576 -2.50213,0.13166 -4.60809,-1.316597 -4.60809,-3.949693 z m 8.68895,-0.921618 v -0.658298 c -0.6583,-0.263221 -1.44767,-0.526541 -2.37007,-0.526541 -1.57972,0 -2.6332,0.6582 -2.6332,1.843138 0,1.053278 0.79036,1.579916 2.10695,1.579916 1.71178,0.13166 2.89632,-0.789958 2.89632,-2.238215 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path4"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 210.78139,12.702394 c 0,-3.8180037 2.8973,-6.9777186 6.97816,-6.9777186 2.50114,0 4.08086,0.7899091 5.39746,2.2381166 l -2.36909,2.501436 c -0.79035,-0.9215786 -1.71177,-1.4481681 -3.02837,-1.4481681 -1.84284,0 -3.29149,1.7114971 -3.29149,3.6863341 0,2.106457 1.31659,3.686275 3.42354,3.686275 1.3166,0 2.10597,-0.526541 3.02739,-1.448159 l 2.23802,2.238117 c -1.3166,1.448257 -2.76427,2.501436 -5.52951,2.501436 -3.94881,0 -6.84611,-3.159636 -6.84611,-6.977669 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path6"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 224.86977,15.467149 c 0,-3.028075 2.23801,-4.344672 5.39745,-4.344672 1.3166,0 2.37007,0.263418 3.2915,0.526639 v -0.263319 c 0,-1.5798281 -0.92143,-2.5014067 -2.89632,-2.5014067 -1.44865,0 -2.50213,0.2633194 -3.68667,0.7899386 l -0.92142,-2.896425 c 1.44865,-0.6582789 2.89632,-1.0532285 5.26639,-1.0532285 2.10596,0 3.55461,0.5265896 4.47603,1.4481781 1.05348,1.0532382 1.44865,2.5014655 1.44865,4.2129435 v 7.767726 h -3.68666 v -1.448257 c -0.92143,1.053278 -2.23802,1.711576 -4.08185,1.711576 -2.50114,0.13166 -4.6071,-1.316597 -4.6071,-3.949693 z m 8.68895,-0.921618 v -0.658298 c -0.6583,-0.263221 -1.44866,-0.526541 -2.37008,-0.526541 -1.57972,0 -2.63319,0.6582 -2.63319,1.843138 0,1.053278 0.79035,1.579916 2.10695,1.579916 1.71177,0.13166 2.89632,-0.789958 2.89632,-2.238215 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path8"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 240.53589,5.8561184 h 3.81774 v 1.8432064 c 0.92142,-1.184898 1.97489,-2.1064865 3.94979,-2.1064865 2.89632,0 4.60809,1.8431473 4.60809,5.0028717 v 8.689275 h -3.81872 v -7.504406 c 0,-1.8431475 -0.78937,-2.7647064 -2.23802,-2.7647064 -1.44767,0 -2.37007,0.9215589 -2.37007,2.7647064 v 7.504406 h -3.81774 V 5.8561184 Z"\n' +
'     fill="#0a76bd"\n' +
'     id="path10"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 255.67577,16.652284 6.84611,-7.6360073 h -6.71405 V 5.8565224 h 11.45321 v 2.7647653 l -6.97718,7.6360173 h 6.97718 v 3.028075 h -11.58527 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path12"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 269.10487,12.702394 c 0,-3.8180037 2.76426,-6.9777186 6.58298,-6.9777186 4.47604,0 6.582,3.4230343 6.582,7.2410376 0,0.26332 0,0.658299 0,1.053179 h -9.34725 c 0.39518,1.711577 1.57972,2.633195 3.2915,2.633195 1.31659,0 2.23802,-0.394979 3.29149,-1.316597 l 2.23802,1.974797 c -1.3166,1.579916 -3.02837,2.501436 -5.52951,2.501436 -4.08086,-0.26332 -7.10923,-3.028075 -7.10923,-7.109329 z m 9.4793,-1.184938 c -0.26312,-1.7114773 -1.18553,-2.8963851 -2.76525,-2.8963851 -1.57972,0 -2.63319,1.1849078 -2.89632,2.8963851 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path14"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 294.5144,16.520131 v 2.764756 h -1.18454 V 0.984863 h 1.18454 v 8.2942601 c 1.05348,-1.7115172 2.76525,-3.1597149 5.26639,-3.1597149 3.02837,0 6.18781,2.5014459 6.18781,6.5827888 0,4.212913 -3.15944,6.71435 -6.18781,6.71435 -2.63319,0.131659 -4.21291,-1.316499 -5.26639,-2.896416 z m 10.1376,-3.554615 c 0,-3.4230636 -2.37007,-5.5295599 -5.00228,-5.5295599 -2.63319,0 -5.26639,2.238156 -5.26639,5.5295599 0,3.291394 2.50115,5.529413 5.26639,5.529413 2.76427,0 5.00228,-2.106457 5.00228,-5.529413 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path16"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 308.2076,22.839798 0.3942,-1.053278 c 0.65829,0.263319 1.18552,0.526639 2.239,0.526639 1.31561,0 2.23802,-0.789959 3.15944,-3.028075 L 307.81243,6.6461555 h 1.44766 l 5.26639,11.4539905 4.60809,-11.4539905 h 1.3166 l -5.52951,13.1654685 c -1.18454,2.633194 -2.37007,3.686374 -4.21292,3.686374 -1.05347,-0.13166 -1.71177,-0.263221 -2.50114,-0.6582 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path18"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 80.179781,73.395649 c -1.711576,0 -3.291394,-0.263319 -4.871311,-0.789958 -1.448158,-0.52654 -2.764755,-1.184839 -3.817934,-2.106457 C 70.437258,69.577616 69.51564,68.392777 68.85744,67.07618 68.199142,65.628022 67.935822,64.048204 67.935822,62.204968 v -0.13166 c 0,-1.843137 0.394979,-3.554615 1.053278,-5.002872 0.6582,-1.448158 1.711478,-2.633096 2.896316,-3.554714 1.184938,-0.921519 2.764854,-1.711477 4.476332,-2.106457 1.711478,-0.526638 3.686374,-0.789958 5.79283,-0.789958 1.711478,0 3.291296,0.13166 4.871213,0.394979 1.579818,0.26332 2.764755,0.658299 3.818033,0.921618 v -0.394979 c 0,-1.843137 -0.526639,-3.291394 -1.711576,-4.344573 -1.184839,-1.053278 -3.028075,-1.579917 -5.397852,-1.579917 -1.974797,0 -3.686373,0.13166 -5.266191,0.526639 -1.579818,0.394979 -3.159735,0.789958 -4.739553,1.448158 l -2.633095,-8.952466 c 2.106457,-0.789958 4.212913,-1.579916 6.451129,-1.974896 2.238116,-0.52654 5.002773,-0.789859 8.162508,-0.789859 3.291394,0 6.056149,0.39488 8.294364,1.184839 2.238117,0.789958 4.081254,1.843137 5.529314,3.291394 1.316598,1.316498 2.370068,2.896415 3.028368,4.871212 0.6583,1.843236 0.92142,4.081353 0.92142,6.714448 V 72.605691 H 91.897003 c -0.6582,0 -1.184839,-0.52654 -1.184839,-1.184839 v -2.633096 c -1.184937,1.316499 -2.764755,2.501436 -4.476233,3.291395 -1.579917,0.921519 -3.686373,1.316498 -6.05615,1.316498 z m 4.476233,-7.899287 c 1.843138,0 3.423054,-0.526639 4.607893,-1.579916 1.184938,-1.05318 1.711577,-2.501338 1.711577,-4.344574 v -1.711478 c -0.658299,-0.263319 -1.316598,-0.526639 -2.238117,-0.658298 -0.921618,-0.13166 -1.711576,-0.26332 -2.764854,-0.26332 -1.843137,0 -3.291296,0.394979 -4.344573,1.184938 -1.05318,0.78986 -1.448159,1.843137 -1.448159,3.291296 v 0.131659 c 0,1.184938 0.394881,2.238117 1.316499,2.896415 0.789958,0.658299 1.974797,1.053278 3.159734,1.053278 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path20"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 123.09858,73.394959 c -2.10596,0 -4.21291,-0.394979 -6.05575,-1.316597 -1.9749,-0.921618 -3.68667,-2.106555 -5.13434,-3.686373 -1.44865,-1.579818 -2.63319,-3.554714 -3.42355,-5.92449 -0.92142,-2.369776 -1.31659,-4.871212 -1.31659,-7.767628 v -0.131659 c 0,-2.896415 0.39517,-5.529511 1.31659,-7.899287 0.79036,-2.369777 1.9749,-4.344672 3.42355,-5.92449 1.44767,-1.579917 3.15944,-2.896416 5.00228,-3.686374 1.84383,-0.78986 3.94979,-1.316498 6.05674,-1.316498 2.76427,0 4.87122,0.526638 6.71406,1.579818 1.71177,1.053277 3.15944,2.238116 4.34496,3.686373 V 30.475371 c 0,-3.554714 2.89632,-6.582788 6.582,-6.582788 h 6.18781 v 48.712418 h -11.58526 c -0.6583,0 -1.18455,-0.526639 -1.18455,-1.184937 V 67.73369 c -1.18552,1.579917 -2.76524,2.764756 -4.47702,3.949693 -1.44766,1.184839 -3.68568,1.711576 -6.45093,1.711576 z m 3.81774,-10.664141 c 1.05347,0 1.97489,-0.263319 2.8973,-0.658298 0.92142,-0.394881 1.71079,-0.92152 2.36909,-1.711478 0.6583,-0.78986 1.18553,-1.579818 1.57972,-2.633096 0.39517,-1.053179 0.52723,-2.106457 0.52723,-3.291394 v -0.13166 c 0,-1.184839 -0.13206,-2.238116 -0.52723,-3.291394 -0.39419,-0.921519 -0.92142,-1.843137 -1.57972,-2.633096 -0.6583,-0.789859 -1.44767,-1.316498 -2.36909,-1.711477 -0.92241,-0.39498 -1.84383,-0.658299 -2.8973,-0.658299 -1.05249,0 -1.97391,0.263319 -2.89632,0.658299 -0.92142,0.394979 -1.71079,0.921618 -2.36908,1.711477 -0.6583,0.658299 -1.18553,1.579917 -1.58071,2.633096 -0.39419,1.053278 -0.65731,2.106555 -0.65731,3.291394 v 0.13166 c 0,1.184937 0.26312,2.369776 0.65731,3.291394 0.39518,1.053278 0.92241,1.843236 1.58071,2.633096 0.65829,0.789958 1.44766,1.316597 2.36908,1.711478 0.92241,0.526639 1.9749,0.658298 2.89632,0.658298 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path22"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 170.75821,73.526422 c -2.89631,0 -5.52951,-0.526639 -8.03065,-1.448158 -2.37007,-0.921618 -4.47603,-2.369875 -6.31887,-4.081353 -1.84383,-1.711478 -3.16043,-3.686373 -4.08185,-5.92449 -0.92142,-2.238116 -1.44767,-4.739552 -1.44767,-7.240988 v -0.13166 c 0,-2.633194 0.52625,-5.002971 1.44767,-7.372747 1.05348,-2.238117 2.37007,-4.344573 4.21291,-6.056051 1.84383,-1.711576 3.9498,-3.028075 6.31987,-4.081353 2.50114,-1.053277 5.13433,-1.448257 8.03065,-1.448257 2.89632,0 5.52951,0.526639 8.03065,1.448257 2.37007,1.053278 4.47702,2.369777 6.31986,4.081353 1.84284,1.711478 3.15944,3.686275 4.08086,5.924391 0.92241,2.238215 1.44865,4.739651 1.44865,7.241087 v 0.13166 c 0,2.633096 -0.52624,5.002872 -1.44865,7.372648 -1.05249,2.238117 -2.36908,4.344672 -4.21291,6.05615 -1.84284,1.711478 -3.94979,3.028075 -6.31888,4.081353 -2.37007,0.921519 -5.13433,1.448158 -8.03164,1.448158 z m 0.13206,-10.927362 c 1.18454,0 2.23802,-0.263319 3.15944,-0.658299 0.9224,-0.394979 1.71177,-1.053179 2.37007,-1.711477 0.6583,-0.789959 1.18454,-1.579917 1.44865,-2.501436 0.39419,-0.921618 0.52625,-1.974896 0.52625,-3.028075 v -0.13166 c 0,-1.053278 -0.13206,-2.106555 -0.52625,-3.159734 -0.39518,-0.921618 -0.92241,-1.843236 -1.5807,-2.501436 -0.6583,-0.789959 -1.44767,-1.316598 -2.50114,-1.711577 -0.92143,-0.39488 -1.9749,-0.6582 -3.02838,-0.6582 -1.18454,0 -2.23801,0.26332 -3.15943,0.6582 -0.92143,0.394979 -1.71178,1.053278 -2.37008,1.711577 -0.65829,0.789859 -1.18454,1.579818 -1.44766,2.501436 -0.39518,0.921519 -0.52625,1.974797 -0.52625,3.028074 v 0.13166 c 0,1.053179 0.13107,2.106457 0.52625,3.159735 0.39517,0.921519 0.92142,1.843137 1.57972,2.501436 0.6583,0.789859 1.44865,1.316498 2.50114,1.711477 0.9224,0.526639 1.97489,0.658299 3.02837,0.658299 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path24"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 195.64151,29.948634 c 0,-2.238117 1.84284,-4.081254 4.08086,-4.081254 h 19.88002 c 2.89632,0 5.39844,0.39488 7.50441,1.053179 2.10695,0.658298 3.81872,1.711576 5.26639,3.159734 0.92142,0.921618 1.71177,1.974896 2.23801,3.291395 0.52723,1.184937 0.79036,2.633095 0.79036,4.212913 v 0.13166 c 0,2.764756 -0.6583,4.871311 -1.9749,6.582789 -1.3166,1.711477 -3.02837,2.896415 -5.13433,3.818033 2.89631,0.921519 5.13433,2.238116 6.8461,4.081254 1.71079,1.711576 2.50115,4.213012 2.50115,7.372747 v 0.131659 c 0,4.081254 -1.57972,7.240989 -4.60809,9.479106 -3.15944,2.238116 -7.50441,3.423054 -13.03392,3.423054 h -20.0111 c -2.23801,0 -4.08184,-1.843138 -4.08184,-4.081353 V 29.948634 Z m 19.35378,14.482075 c 1.97391,0 3.55461,-0.394979 4.6071,-1.053277 1.05348,-0.658299 1.58071,-1.711478 1.58071,-3.028075 v -0.13166 c 0,-1.316498 -0.52723,-2.238116 -1.44865,-3.027976 -0.92143,-0.658299 -2.50114,-1.053278 -4.47604,-1.053278 h -4.34497 c -2.23802,0 -4.08086,1.843138 -4.08086,4.081254 v 4.081353 h 8.16271 z m 1.97391,17.77347 c 1.97489,0 3.5556,-0.394979 4.60809,-1.053277 1.05347,-0.789959 1.57972,-1.843138 1.57972,-3.159735 v -0.13166 c 0,-1.316498 -0.52625,-2.369776 -1.57972,-3.028074 -1.05249,-0.78986 -2.6332,-1.184839 -4.87122,-1.184839 h -9.87349 v 4.476233 c 0,2.238116 1.84284,4.081352 4.08086,4.081352 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path26"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 251.59491,73.395649 c -1.71177,0 -3.29149,-0.263319 -4.87121,-0.789958 -1.44766,-0.52654 -2.76426,-1.184839 -3.81774,-2.106457 -1.05347,-0.921618 -1.97489,-2.106457 -2.63319,-3.423054 -0.6583,-1.448158 -0.92142,-3.027976 -0.92142,-4.871212 v -0.13166 c 0,-1.843137 0.39419,-3.554615 1.05249,-5.002872 0.6583,-1.448158 1.71177,-2.633096 2.8973,-3.554714 1.18454,-0.921519 2.76426,-1.711477 4.47604,-2.106457 1.71177,-0.526638 3.68568,-0.789958 5.79263,-0.789958 1.71177,0 3.29149,0.13166 4.87121,0.394979 1.57972,0.26332 2.76525,0.658299 3.81774,0.921618 v -0.394979 c 0,-1.843137 -0.6583,-3.291394 -1.71079,-4.344573 -1.18553,-1.053278 -3.02837,-1.579917 -5.39844,-1.579917 -1.9749,0 -3.68667,0.13166 -5.26639,0.526639 -1.57972,0.394979 -3.15944,0.789958 -4.73916,1.448158 l -2.63319,-8.952466 c 2.10695,-0.789958 4.21291,-1.579916 6.45093,-1.974896 2.23802,-0.52654 5.00327,-0.789859 8.1627,-0.789859 3.2915,0 6.05576,0.39488 8.29476,1.184839 2.23802,0.789958 4.08086,1.843137 5.52951,3.291394 1.3166,1.316498 2.36909,2.896415 3.02739,4.871212 0.6583,1.843236 0.92142,4.081353 0.92142,6.714448 v 20.669787 h -11.45321 c -0.6583,0 -1.18553,-0.52654 -1.18553,-1.184839 v -2.633096 c -1.18454,1.316499 -2.76426,2.501436 -4.47604,3.291395 -1.71177,0.921519 -3.68667,1.316498 -6.18781,1.316498 z m 4.60809,-7.899287 c 1.84285,0 3.42256,-0.526639 4.60809,-1.579916 1.18455,-1.05318 1.71178,-2.501338 1.71178,-4.344574 v -1.711478 c -0.6583,-0.263319 -1.3166,-0.526639 -2.23901,-0.658298 -0.92142,-0.13166 -1.71078,-0.26332 -2.76426,-0.26332 -1.84284,0 -3.29149,0.394979 -4.34497,1.184938 -1.05347,0.78986 -1.44766,1.843137 -1.44766,3.291296 v 0.131659 c 0,1.184938 0.39419,2.238117 1.31659,2.896415 0.78937,0.658299 1.84285,1.053278 3.15944,1.053278 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path28"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <path\n' +
'     d="m 290.69667,83.532757 c -2.23802,0 -4.34497,-0.263319 -6.18781,-0.921618 -1.57972,-0.52654 -3.02837,-1.053179 -4.47703,-1.711477 -0.52624,-0.26332 -0.78936,-0.921618 -0.52624,-1.448159 l 3.02837,-6.714448 c 0.26312,-0.526639 0.92142,-0.789958 1.57972,-0.526639 0.6583,0.394979 1.3166,0.658299 1.9749,0.921618 0.92142,0.394979 1.84284,0.52654 2.50114,0.52654 0.65829,0 1.18454,-0.131561 1.57972,-0.26322 0.52723,-0.26332 0.92142,-0.526639 1.31659,-1.184938 L 277.26757,36.663575 h 8.55788 c 2.76427,0 5.26541,1.843137 6.18781,4.476233 l 4.60711,13.955437 c 0.39517,1.053277 1.84382,1.053277 2.10695,0 l 4.34496,-13.823777 c 0.78937,-2.764756 3.42257,-4.607893 6.18781,-4.607893 h 8.29378 l -13.29704,35.283522 c -0.79035,2.106457 -1.57972,3.949693 -2.50114,5.397851 -0.79036,1.448257 -1.84383,2.764755 -2.89632,3.686373 -1.05347,0.921618 -2.239,1.579818 -3.68667,2.106457 -1.18454,0.13166 -2.76525,0.394979 -4.47603,0.394979 z"\n' +
'     fill="#0a76bd"\n' +
'     id="path30"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <mask\n' +
'     id="mask0_65_64"\n' +
'     maskUnits="userSpaceOnUse"\n' +
'     x="609"\n' +
'     y="326"\n' +
'     width="95"\n' +
'     height="147">\n' +
'    <path\n' +
'       d="m 609.088,472.574 c 2.672,-1.336 6.679,-1.335 9.351,-2.671 l 85.502,-133.596 c 0,-2.672 -1.336,-6.68 -1.336,-9.351 z"\n' +
'       fill="#ffffff"\n' +
'       id="path32" />\n' +
'  </mask>\n' +
'  <g\n' +
'     mask="url(#mask0_65_64)"\n' +
'     id="g37"\n' +
'     transform="matrix(0.09854769,0,0,0.09854769,0.40670778,0.88780703)">\n' +
'    <path\n' +
'       d="m 703.941,326.956 h -94.853 v 145.618 h 94.853 z"\n' +
'       fill="url(#paint0_linear_65_64)"\n' +
'       id="path35"\n' +
'       style="fill:url(#paint0_linear_65_64)" />\n' +
'  </g>\n' +
'  <mask\n' +
'     id="mask1_65_64"\n' +
'     maskUnits="userSpaceOnUse"\n' +
'     x="587"\n' +
'     y="306"\n' +
'     width="111"\n' +
'     height="170">\n' +
'    <path\n' +
'       d="m 587.712,475.244 c 2.672,0 5.344,0 8.016,0 L 697.26,314.929 c -1.336,-2.672 -1.336,-5.344 -2.672,-8.015 z"\n' +
'       fill="#ffffff"\n' +
'       id="path39" />\n' +
'  </mask>\n' +
'  <g\n' +
'     mask="url(#mask1_65_64)"\n' +
'     id="g44"\n' +
'     transform="matrix(0.09854769,0,0,0.09854769,0.40670778,0.88780703)">\n' +
'    <path\n' +
'       d="M 697.26,306.914 H 587.712 v 168.33 H 697.26 Z"\n' +
'       fill="url(#paint1_linear_65_64)"\n' +
'       id="path42"\n' +
'       style="fill:url(#paint1_linear_65_64)" />\n' +
'  </g>\n' +
'  <path\n' +
'     d="M 57.271582,47.722301 68.462264,30.080589 c -1.974797,-3.686373 -5.92449,-6.319469 -10.532383,-6.319469 -6.582789,0 -11.98064,5.397851 -11.98064,11.980541 0,6.451129 5.002872,11.585661 11.322341,11.98064 z"\n' +
'     fill="#fbae48"\n' +
'     id="path46"\n' +
'     style="stroke-width:0.0985477" />\n' +
'  <mask\n' +
'     id="mask2_65_64"\n' +
'     maskUnits="userSpaceOnUse"\n' +
'     x="463"\n' +
'     y="309"\n' +
'     width="220"\n' +
'     height="165">\n' +
'    <path\n' +
'       d="M 577.037,455.208 C 516.92,451.2 468.825,404.442 463.481,344.324 c 0,2.672 0,6.68 0,9.352 0,64.125 50.767,117.564 113.556,120.235 L 682.578,309.589 Z"\n' +
'       fill="#ffffff"\n' +
'       id="path48" />\n' +
'  </mask>\n' +
'  <g\n' +
'     mask="url(#mask2_65_64)"\n' +
'     id="g53"\n' +
'     transform="matrix(0.09854769,0,0,0.09854769,0.40670778,0.88780703)">\n' +
'    <path\n' +
'       d="M 681.254,309.589 H 462.158 v 164.322 h 219.096 z"\n' +
'       fill="url(#paint2_linear_65_64)"\n' +
'       id="path51"\n' +
'       style="fill:url(#paint2_linear_65_64)" />\n' +
'  </g>\n' +
'  <mask\n' +
'     id="mask3_65_64"\n' +
'     maskUnits="userSpaceOnUse"\n' +
'     x="0"\n' +
'     y="232"\n' +
'     width="513"\n' +
'     height="607">\n' +
'    <path\n' +
'       d="m 11.9255,232.104 c -9.35165,0 -14.69522,10.687 -9.3514,17.367 L 373.969,833.283 c 4.008,6.679 14.695,6.679 20.039,0 L 510.236,650.257 c 2.672,-4.008 2.672,-8.016 0,-12.024 L 293.812,297.565 C 268.429,256.151 223.006,232.104 173.576,232.104 Z"\n' +
'       fill="#ffffff"\n' +
'       id="path55" />\n' +
'  </mask>\n' +
'  <g\n' +
'     mask="url(#mask3_65_64)"\n' +
'     id="g60"\n' +
'     transform="matrix(0.09854769,0,0,0.09854769,0.40670778,0.88780703)">\n' +
'    <path\n' +
'       d="M 209.645,-20.395 810.824,484.595 299.154,1095.13 -303.361,590.136 Z"\n' +
'       fill="url(#paint3_linear_65_64)"\n' +
'       id="path58"\n' +
'       style="fill:url(#paint3_linear_65_64)" />\n' +
'  </g>\n' +
'  <mask\n' +
'     id="mask4_65_64"\n' +
'     maskUnits="userSpaceOnUse"\n' +
'     x="579"\n' +
'     y="297"\n' +
'     width="67"\n' +
'     height="28">\n' +
'    <path\n' +
'       d="m 613.112,308.251 c -6.68,-8.015 -18.703,-12.023 -29.391,-9.351 -1.335,0 -2.672,1.336 -4.008,4.008 0,1.336 0,4.007 1.336,5.343 1.336,1.336 2.672,1.336 4.008,1.336 4.008,-1.336 9.352,-1.336 13.36,1.336 4.008,2.672 6.679,5.344 8.015,9.352 0,2.672 2.672,4.008 5.344,4.008 2.672,0 4.008,-1.336 5.344,-4.008 1.336,-4.008 4.008,-8.016 8.016,-10.688 4.008,-2.672 9.351,-2.672 13.359,-1.336 2.672,1.336 5.344,0 6.68,-2.672 1.336,-2.672 0,-5.344 -2.672,-6.679 -2.672,-1.336 -5.344,-1.337 -8.015,-1.337 -8.016,0 -16.032,4.009 -21.376,10.688 z"\n' +
'       fill="#ffffff"\n' +
'       id="path62" />\n' +
'  </mask>\n' +
'  <g\n' +
'     mask="url(#mask4_65_64)"\n' +
'     id="g67"\n' +
'     transform="matrix(0.09854769,0,0,0.09854769,0.40670778,0.88780703)">\n' +
'    <path\n' +
'       d="m 646.499,296.229 h -65.462 v 28.055 h 65.462 z"\n' +
'       fill="url(#paint4_linear_65_64)"\n' +
'       id="path65"\n' +
'       style="fill:url(#paint4_linear_65_64)" />\n' +
'  </g>\n' +
'  <mask\n' +
'     id="mask5_65_64"\n' +
'     maskUnits="userSpaceOnUse"\n' +
'     x="448"\n' +
'     y="328"\n' +
'     width="93"\n' +
'     height="36">\n' +
'    <path\n' +
'       d="m 494.209,344.325 c -9.351,-13.36 -25.383,-18.703 -41.414,-14.696 -2.672,1.336 -4.008,4.008 -4.008,6.68 1.336,2.672 4.008,4.008 6.68,4.008 6.68,-2.672 14.695,-1.336 21.375,2.672 5.344,4.008 10.688,9.351 12.024,16.031 0,2.672 2.672,4.008 5.343,4.008 2.672,0 5.344,-1.336 5.344,-4.008 1.336,-6.68 5.344,-12.023 12.024,-16.031 6.679,-4.008 13.359,-4.008 21.375,-2.672 2.672,1.336 5.344,-1.336 6.68,-4.008 1.336,-2.672 0,-6.68 -4.008,-6.68 -4.008,-1.335 -6.68,-1.336 -10.688,-1.336 -12.023,0 -24.047,5.344 -30.727,16.032 z"\n' +
'       fill="#ffffff"\n' +
'       id="path69" />\n' +
'  </mask>\n' +
'  <g\n' +
'     mask="url(#mask5_65_64)"\n' +
'     id="g74"\n' +
'     transform="matrix(0.09854769,0,0,0.09854769,0.40670778,0.88780703)">\n' +
'    <path\n' +
'       d="m 540.968,325.622 h -92.181 v 37.407 h 92.181 z"\n' +
'       fill="url(#paint5_linear_65_64)"\n' +
'       id="path72"\n' +
'       style="fill:url(#paint5_linear_65_64)" />\n' +
'  </g>\n' +
'  <defs\n' +
'     id="defs118">\n' +
'    <linearGradient\n' +
'       id="paint0_linear_65_64"\n' +
'       x1="609.35498"\n' +
'       y1="399.50601"\n' +
'       x2="703.85602"\n' +
'       y2="399.50601"\n' +
'       gradientUnits="userSpaceOnUse">\n' +
'      <stop\n' +
'         stop-color="#FBAE48"\n' +
'         id="stop76" />\n' +
'      <stop\n' +
'         offset="0.5573"\n' +
'         stop-color="#E39E41"\n' +
'         id="stop78" />\n' +
'      <stop\n' +
'         offset="1"\n' +
'         stop-color="#E39E41"\n' +
'         id="stop80" />\n' +
'    </linearGradient>\n' +
'    <linearGradient\n' +
'       id="paint1_linear_65_64"\n' +
'       x1="588.24597"\n' +
'       y1="390.69501"\n' +
'       x2="698.448"\n' +
'       y2="390.69501"\n' +
'       gradientUnits="userSpaceOnUse">\n' +
'      <stop\n' +
'         stop-color="#FBAE48"\n' +
'         id="stop83" />\n' +
'      <stop\n' +
'         offset="0.5573"\n' +
'         stop-color="#E39E41"\n' +
'         id="stop85" />\n' +
'      <stop\n' +
'         offset="1"\n' +
'         stop-color="#E39E41"\n' +
'         id="stop87" />\n' +
'    </linearGradient>\n' +
'    <linearGradient\n' +
'       id="paint2_linear_65_64"\n' +
'       x1="462.81299"\n' +
'       y1="392.077"\n' +
'       x2="681.74902"\n' +
'       y2="392.077"\n' +
'       gradientUnits="userSpaceOnUse">\n' +
'      <stop\n' +
'         stop-color="#FBAE48"\n' +
'         id="stop90" />\n' +
'      <stop\n' +
'         offset="0.5573"\n' +
'         stop-color="#E39E41"\n' +
'         id="stop92" />\n' +
'      <stop\n' +
'         offset="1"\n' +
'         stop-color="#E39E41"\n' +
'         id="stop94" />\n' +
'    </linearGradient>\n' +
'    <linearGradient\n' +
'       id="paint3_linear_65_64"\n' +
'       x1="-41.037899"\n' +
'       y1="289.17401"\n' +
'       x2="482.01199"\n' +
'       y2="728.065"\n' +
'       gradientUnits="userSpaceOnUse">\n' +
'      <stop\n' +
'         stop-color="#0A76BD"\n' +
'         id="stop97" />\n' +
'      <stop\n' +
'         offset="0.9926"\n' +
'         stop-color="#065890"\n' +
'         id="stop99" />\n' +
'      <stop\n' +
'         offset="1"\n' +
'         stop-color="#065890"\n' +
'         id="stop101" />\n' +
'    </linearGradient>\n' +
'    <linearGradient\n' +
'       id="paint4_linear_65_64"\n' +
'       x1="580.94501"\n' +
'       y1="309.89099"\n' +
'       x2="645.82397"\n' +
'       y2="309.89099"\n' +
'       gradientUnits="userSpaceOnUse">\n' +
'      <stop\n' +
'         stop-color="#0A76BD"\n' +
'         id="stop104" />\n' +
'      <stop\n' +
'         offset="0.9926"\n' +
'         stop-color="#065890"\n' +
'         id="stop106" />\n' +
'      <stop\n' +
'         offset="1"\n' +
'         stop-color="#065890"\n' +
'         id="stop108" />\n' +
'    </linearGradient>\n' +
'    <linearGradient\n' +
'       id="paint5_linear_65_64"\n' +
'       x1="449.311"\n' +
'       y1="343.845"\n' +
'       x2="539.77698"\n' +
'       y2="343.845"\n' +
'       gradientUnits="userSpaceOnUse">\n' +
'      <stop\n' +
'         stop-color="#0A76BD"\n' +
'         id="stop111" />\n' +
'      <stop\n' +
'         offset="0.9926"\n' +
'         stop-color="#065890"\n' +
'         id="stop113" />\n' +
'      <stop\n' +
'         offset="1"\n' +
'         stop-color="#065890"\n' +
'         id="stop115" />\n' +
'    </linearGradient>\n' +
'  </defs>\n' +
'</svg>'
