import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/vuex/store'
import { prod, dev, commons } from './routes'
import { service_handler } from '@/services'
import { decode_parameters } from '@/utilities'

Vue.use(Router)

// prod routes => always
let routes = [
  ...prod
]

// dev routes => development only
if (process.env.VUE_APP_MODE == 'development') {
  routes = [
    ...dev,
    ...routes
  ]
}

// commons routes => always but LAST
routes = [
  ...routes,
  ...commons
]

let router = new Router({
  mode: process.env.VUE_APP_ROUTER_MODE,
  routes: routes,
})

router.beforeEach((to, from, next) => {
  // window.console.log('[router.js] - beforeEach()')

  // abort pending requests (only if there is a router change)
  if (from.name !== null) service_handler('abort')

  // reset scroll before browsing to another view
  window.scrollTo(0, 0)

  // push new route
  next()

  return
})

router.afterEach((to) => {
  // window.console.log('[router.js] - afterEach()')

  let route_query = to.query

  if (Object.keys(route_query).length) {
    let state = route_query.state
    if (state !== undefined) {
      window.console.log('replace_state with route QUERY')
      Store.replaceState(decode_parameters(state))
    }
  }

  // reset scroll when going using history back
  if (to.name == "homepage") {
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  }

  // scroll-to-# navigation handled for standalone navigation (NOT handled by router itself)
  if (to.hash && to.hash != '') {
    if (process.env.VUE_APP_MODE != 'production') window.console.log('fragment found')
    setTimeout(function () {
      if (process.env.VUE_APP_MODE != 'production') window.console.log('scrolling to ' + to.hash)
      document.getElementById(to.hash.substr(1)).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 250);
  }

})

export default router