export default '<svg width="43" height="30" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg">\
  <path d="M38.6488 30H3.94807C1.76937 30 0 28.2306 0 26.0519V3.94806C0 1.76936 1.76937 0 3.94807 0H38.6488C40.8275 0 42.5968 1.76936 42.5968 3.94806V26.0387C42.5968 28.2306 40.8275 30 38.6488 30Z" fill="white" />\
  <path d="M5.57595 12.1697H4V17.6763H5.56856C6.40267 17.6763 7.00426 17.4807 7.53388 17.0396C8.16131 16.5192 8.53407 15.7368 8.53407 14.9248C8.53223 13.3009 7.31797 12.1697 5.57595 12.1697ZM6.8308 16.307C6.49309 16.6115 6.0539 16.7443 5.36004 16.7443H5.07216V13.1034H5.36004C6.0539 13.1034 6.47464 13.2271 6.8308 13.5482C7.20172 13.8785 7.42501 14.3915 7.42501 14.9193C7.42501 15.4471 7.20172 15.9767 6.8308 16.307Z" fill="black" />\
  <path d="M10.1006 12.1697H9.02661V17.6763H10.1006V12.1697Z" fill="black" />\
  <path d="M12.7287 14.2829C12.0846 14.0448 11.8946 13.8861 11.8946 13.589C11.8946 13.2421 12.2323 12.9782 12.6954 12.9782C13.0184 12.9782 13.2823 13.111 13.5609 13.4248L14.1238 12.6885C13.6624 12.2843 13.1088 12.0776 12.5054 12.0776C11.531 12.0776 10.7873 12.7549 10.7873 13.6554C10.7873 14.4139 11.1343 14.8032 12.1418 15.1649C12.5626 15.3126 12.7766 15.4122 12.8837 15.4787C13.0977 15.6189 13.2066 15.8164 13.2066 16.0489C13.2066 16.4955 12.8523 16.8258 12.3725 16.8258C11.8613 16.8258 11.448 16.5693 11.2007 16.0913L10.5068 16.7593C11.0014 17.4864 11.5956 17.8075 12.4131 17.8075C13.5296 17.8075 14.312 17.0657 14.312 15.999C14.312 15.1243 13.9503 14.7276 12.7287 14.281V14.2829Z" fill="black" />\
  <path d="M14.6514 14.927C14.6514 16.5454 15.9228 17.8002 17.5578 17.8002C18.021 17.8002 18.4159 17.7098 18.9049 17.4792V16.2151C18.475 16.645 18.0948 16.8185 17.6076 16.8185C16.5263 16.8185 15.7586 16.0342 15.7586 14.9196C15.7586 13.8622 16.5503 13.0281 17.5578 13.0281C18.0708 13.0281 18.4584 13.2108 18.9049 13.6482V12.3841C18.4344 12.1442 18.045 12.0464 17.5837 12.0464C15.956 12.0464 14.6514 13.3271 14.6514 14.9289V14.927Z" fill="black" />\
  <path d="M27.4212 15.8678L25.9523 12.1697H24.7805L27.1168 17.8184H27.6944L30.0749 12.1697H28.9086L27.4212 15.8678Z" fill="black" />\
  <path d="M30.5603 17.6763H33.607V16.7443H31.6325V15.257H33.5332V14.3251H31.6325V13.1034H33.607V12.1697H30.5603V17.6763Z" fill="black" />\
  <path d="M37.8608 13.7954C37.8608 12.7639 37.1504 12.1697 35.9121 12.1697H34.3196V17.6763H35.3917V15.4637H35.532L37.0194 17.6763H38.3406L36.606 15.3566C37.4161 15.1924 37.8608 14.6388 37.8608 13.7954ZM35.7055 14.7052H35.3917V13.037H35.7221C36.3919 13.037 36.7555 13.3175 36.7555 13.8527C36.7555 14.3878 36.3919 14.7052 35.7055 14.7052Z" fill="black" />\
  <path d="M38.717 12.4579C38.717 12.3619 38.6505 12.3083 38.5323 12.3083H38.3772V12.794H38.4935V12.6057L38.6302 12.794H38.7724L38.6117 12.5927C38.6801 12.5743 38.7188 12.5244 38.7188 12.4579H38.717ZM38.512 12.5244H38.4917V12.397H38.512C38.5693 12.397 38.5988 12.4173 38.5988 12.4598C38.5988 12.5023 38.5693 12.5244 38.5102 12.5244H38.512Z" fill="black" />\
  <path d="M38.555 12.1274C38.3186 12.1274 38.1321 12.3158 38.1321 12.5503C38.1321 12.7849 38.3223 12.9751 38.555 12.9751C38.7877 12.9751 38.9742 12.7849 38.9742 12.5503C38.9742 12.3158 38.7858 12.1274 38.555 12.1274ZM38.555 12.8994C38.3685 12.8994 38.217 12.7443 38.217 12.5522C38.217 12.3601 38.3666 12.205 38.555 12.205C38.7433 12.205 38.8911 12.3638 38.8911 12.5522C38.8911 12.7406 38.7415 12.8994 38.555 12.8994Z" fill="black" />\
  <path d="M25.0855 14.9175C25.0855 16.5285 23.7808 17.8332 22.1698 17.8332C20.5588 17.8332 19.2542 16.5267 19.2542 14.9175C19.2542 13.3084 20.5588 12 22.1698 12C23.7808 12 25.0855 13.3065 25.0855 14.9157V14.9175Z" fill="url(#paint0_linear_499_2)" />\
  <path style="mix-blend-mode:multiply" opacity="0.65" d="M25.0855 14.9175C25.0855 16.5285 23.7808 17.8332 22.1698 17.8332C20.5588 17.8332 19.2542 16.5267 19.2542 14.9175C19.2542 13.3084 20.5588 12 22.1698 12C23.7808 12 25.0855 13.3065 25.0855 14.9157V14.9175Z" fill="url(#paint1_linear_499_2)" />\
  <g style="mix-blend-mode:multiply" opacity="0.5">\
    <mask id="mask0_499_2" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="19" y="12" width="7" height="6">\
      <path d="M25.0853 14.9179C25.0853 16.5289 23.7788 17.8336 22.1696 17.8336C20.5604 17.8336 19.2539 16.527 19.2539 14.9179C19.2539 13.3087 20.5586 12.0022 22.1696 12.0022C23.7806 12.0022 25.0853 13.3069 25.0853 14.9179Z" fill="white" />\
    </mask>\
    <g mask="url(#mask0_499_2)">\
      <path d="M22.1805 11.9539C23.8229 11.9539 25.1553 13.2844 25.1553 14.9267C25.1553 16.5691 23.8229 17.9015 22.1805 17.9033C22.1141 17.9033 22.0477 17.9015 21.9831 17.8978C20.4311 17.7963 19.2058 16.5064 19.2058 14.9304C19.2058 14.8898 19.2058 14.8511 19.2077 14.8105C19.2704 13.2216 20.5769 11.9539 22.1805 11.9539Z" fill="#010000" />\
      <path d="M22.1823 11.9575C23.8246 11.9575 25.1588 13.2862 25.1588 14.9286C25.1588 16.5709 23.8265 17.9051 22.1841 17.9051C22.1177 17.9051 22.0512 17.9033 21.9866 17.8996C20.4347 17.7981 19.2075 16.51 19.2075 14.9323C19.2075 14.8917 19.2075 14.8529 19.2094 14.8123C19.2721 13.2253 20.5805 11.9575 22.1823 11.9575Z" fill="#020100" />\
      <path d="M22.1842 11.9595C23.8266 11.9595 25.1608 13.2863 25.1608 14.9305C25.1608 16.5747 23.8284 17.9071 22.1861 17.9089C22.1196 17.9089 22.0532 17.9071 21.9886 17.9034C20.4366 17.8038 19.2095 16.5138 19.2095 14.9379C19.2095 14.8973 19.2095 14.8585 19.2113 14.818C19.2741 13.2272 20.5824 11.9595 22.1842 11.9595Z" fill="#030101" />\
      <path d="M22.1866 11.9634C23.829 11.9634 25.165 13.2902 25.165 14.9326C25.165 16.575 23.8327 17.9092 22.1922 17.911C22.1257 17.911 22.0593 17.9092 21.9947 17.9055C20.4427 17.8058 19.2156 16.5159 19.2156 14.94C19.2156 14.8994 19.2156 14.8606 19.2174 14.82C19.2765 13.2312 20.5848 11.9615 22.1866 11.9634Z" fill="#040101" />\
      <path d="M22.1882 11.9651C23.8306 11.9651 25.1666 13.2901 25.1666 14.9324C25.1666 16.5748 23.8343 17.909 22.1938 17.9127C22.1273 17.9127 22.0609 17.9109 21.9963 17.9072C20.4443 17.8075 19.2153 16.5195 19.2153 14.9435C19.2153 14.9029 19.2153 14.8642 19.2172 14.8236C19.2799 13.2329 20.5864 11.9651 22.1882 11.9651Z" fill="#050201" />\
      <path d="M22.1897 11.9688C23.8321 11.9688 25.1681 13.2919 25.1681 14.9343C25.1681 16.5766 23.8339 17.9108 22.1952 17.9145C22.1288 17.9145 22.0624 17.9127 21.9978 17.909C20.4458 17.8093 19.2168 16.5213 19.2168 14.9453C19.2168 14.9047 19.2168 14.866 19.2186 14.8254C19.2814 13.2365 20.5879 11.9669 22.1897 11.9688Z" fill="#060201" />\
      <path d="M22.1916 11.9702C23.834 11.9721 25.1719 13.2915 25.1719 14.9339C25.1719 16.5763 23.8377 17.9123 22.199 17.916C22.1326 17.916 22.0662 17.916 22.0016 17.9105C20.4496 17.8127 19.2206 16.5246 19.2188 14.9486C19.2188 14.908 19.2188 14.8693 19.2206 14.8287C19.2833 13.238 20.5899 11.9684 22.1916 11.9702Z" fill="#070301" />\
      <path d="M22.1931 11.9741C23.8355 11.976 25.1734 13.2936 25.1734 14.9359C25.1734 16.5783 23.8392 17.9144 22.2005 17.9199C22.1341 17.9199 22.0676 17.9199 22.003 17.9144C20.4511 17.8166 19.2202 16.5285 19.2202 14.9526C19.2202 14.912 19.2202 14.8732 19.2221 14.8326C19.2848 13.2419 20.5913 11.9704 22.1931 11.9741Z" fill="#080302" />\
      <path d="M22.1954 11.976C23.8359 11.9778 25.1757 13.2954 25.1757 14.9359C25.1757 16.5765 23.8415 17.9144 22.2046 17.9199C22.1382 17.9199 22.0718 17.9199 22.0072 17.9144C20.4552 17.8166 19.2244 16.5285 19.2244 14.9525C19.2244 14.912 19.2244 14.8732 19.2262 14.8326C19.289 13.2419 20.5955 11.9723 22.1973 11.9741L22.1954 11.976Z" fill="#090302" />\
      <path d="M22.1954 11.9795C23.8359 11.9832 25.1775 13.2971 25.1775 14.9376C25.1775 16.5782 23.8433 17.916 22.2065 17.9234C22.1401 17.9234 22.0736 17.9234 22.009 17.9179C20.4571 17.8219 19.2262 16.5339 19.2244 14.9579C19.2244 14.9173 19.2244 14.8786 19.2262 14.838C19.289 13.2473 20.5955 11.9776 22.1954 11.9795Z" fill="#0A0402" />\
      <path d="M22.1971 11.9813C23.8377 11.985 25.1792 13.2971 25.1792 14.9376C25.1792 16.5782 23.845 17.9179 22.2082 17.9234C22.1418 17.9234 22.0753 17.9234 22.0107 17.9179C20.4588 17.8219 19.2261 16.5339 19.2261 14.9579C19.2261 14.9173 19.2261 14.8786 19.2279 14.838C19.2907 13.2473 20.5972 11.9758 22.1971 11.9795V11.9813Z" fill="#0B0402" />\
      <path d="M22.1991 11.9834C23.8396 11.9871 25.1812 13.2973 25.1812 14.9378C25.1812 16.5784 23.847 17.9181 22.2101 17.9255C22.1437 17.9255 22.0773 17.9255 22.0127 17.92C20.4607 17.824 19.228 16.5378 19.228 14.9618C19.228 14.9212 19.228 14.8825 19.2299 14.8419C19.2926 13.2512 20.5991 11.9797 22.1991 11.9834Z" fill="#0C0402" />\
      <path d="M22.2005 11.9873C23.8411 11.991 25.1845 13.2994 25.1845 14.9417C25.1845 16.5841 23.8503 17.922 22.2135 17.9294C22.147 17.9294 22.0806 17.9294 22.016 17.9239C20.464 17.8279 19.2313 16.5417 19.2295 14.9657C19.2295 14.9251 19.2295 14.8864 19.2313 14.8458C19.2941 13.2551 20.6006 11.9836 22.2005 11.9873Z" fill="#0D0503" />\
      <path d="M22.2025 11.9888C23.843 11.9925 25.1865 13.3008 25.1865 14.9414C25.1865 16.5819 23.8504 17.9216 22.2154 17.9309C22.149 17.9309 22.0825 17.9309 22.018 17.9253C20.466 17.8312 19.2314 16.5431 19.2314 14.969C19.2314 14.9284 19.2314 14.8897 19.2333 14.8491C19.296 13.2584 20.6026 11.9869 22.2025 11.9906V11.9888Z" fill="#0E0503" />\
      <path d="M22.2046 11.9929C23.8451 11.9966 25.1904 13.3031 25.1904 14.9437C25.1904 16.5842 23.8543 17.9239 22.2212 17.935C22.1547 17.935 22.0883 17.935 22.0237 17.9295C20.4717 17.8354 19.2372 16.5491 19.2354 14.9732C19.2354 14.9326 19.2354 14.8938 19.2372 14.8533C19.2999 13.2607 20.6083 11.9892 22.2064 11.9929H22.2046Z" fill="#0F0603" />\
      <path d="M22.2063 11.9946C23.8468 11.9983 25.1921 13.303 25.1921 14.9435C25.1921 16.5841 23.856 17.9257 22.2229 17.9349C22.1564 17.9349 22.09 17.9349 22.0254 17.9293C20.4735 17.8352 19.2389 16.549 19.2371 14.9749C19.2371 14.9343 19.2371 14.8956 19.2389 14.855C19.3016 13.2624 20.61 11.9909 22.2081 11.9946H22.2063Z" fill="#100603" />\
      <path d="M22.208 11.9981C23.8485 12.0036 25.1938 13.3046 25.1938 14.9451C25.1938 16.5856 23.8577 17.9272 22.2246 17.9383C22.1581 17.9383 22.0917 17.9383 22.0271 17.9328C20.4752 17.8386 19.2388 16.5543 19.2388 14.9783C19.2388 14.9377 19.2388 14.899 19.2406 14.8584C19.3034 13.2658 20.6117 11.9944 22.208 11.9981Z" fill="#110603" />\
      <path d="M22.2099 11.9999C23.8505 12.0054 25.1976 13.3046 25.1976 14.9451C25.1976 16.5857 23.8615 17.9272 22.2284 17.9383C22.1619 17.9383 22.0955 17.9383 22.0309 17.9328C20.4771 17.8405 19.2426 16.5543 19.2407 14.9783C19.2407 14.9377 19.2407 14.899 19.2426 14.8584C19.3053 13.2658 20.6137 11.9925 22.2099 11.9981V11.9999Z" fill="#120703" />\
      <path d="M22.2116 12.0039C23.8522 12.0095 25.1993 13.3068 25.1993 14.9473C25.1993 16.5878 23.8632 17.9294 22.2301 17.9423C22.1637 17.9423 22.0972 17.9423 22.0326 17.9368C20.4788 17.8445 19.2443 16.5583 19.2424 14.9842C19.2424 14.9436 19.2424 14.9048 19.2443 14.8642C19.3052 13.2717 20.6154 11.9984 22.2116 12.0039Z" fill="#130704" />\
      <path d="M22.2135 12.0058C23.8522 12.0113 25.2011 13.3086 25.2011 14.9473C25.2011 16.586 23.8651 17.9313 22.2338 17.9423C22.1673 17.9423 22.1009 17.9423 22.0345 17.9368C20.4807 17.8445 19.2443 16.5601 19.2424 14.9842C19.2424 14.9436 19.2424 14.9048 19.2443 14.8642C19.3052 13.2717 20.6154 11.9984 22.2116 12.0039L22.2135 12.0058Z" fill="#140704" />\
      <path d="M22.2153 12.0089C23.854 12.0145 25.2048 13.3099 25.2048 14.9486C25.2048 16.5873 23.8687 17.9326 22.2374 17.9455C22.171 17.9455 22.1046 17.9455 22.0381 17.94C20.4843 17.8495 19.2479 16.5633 19.2461 14.9892C19.2461 14.9486 19.2461 14.9099 19.2479 14.8693C19.3088 13.2749 20.619 12.0016 22.2153 12.0071V12.0089Z" fill="#150804" />\
      <path d="M22.2172 12.0108C23.8559 12.0163 25.2067 13.3099 25.2067 14.9486C25.2067 16.5873 23.8707 17.9326 22.2394 17.9455C22.173 17.9455 22.1065 17.9455 22.0401 17.94C20.4863 17.8495 19.2499 16.5652 19.248 14.9892C19.248 14.9486 19.248 14.9099 19.2499 14.8693C19.3108 13.2749 20.621 12.0016 22.2172 12.0071V12.0108Z" fill="#160804" />\
      <path d="M22.2191 12.0127C23.8578 12.0182 25.2086 13.31 25.2086 14.9505C25.2086 16.5911 23.8707 17.9345 22.2412 17.9493C22.1748 17.9493 22.1084 17.9493 22.0419 17.9437C20.4881 17.8533 19.2499 16.5689 19.248 14.9948C19.248 14.9542 19.248 14.9155 19.2499 14.8749C19.3108 13.2805 20.621 12.0072 22.2154 12.0127H22.2191Z" fill="#170904" />\
      <path d="M22.2212 12.0166C23.8598 12.024 25.2125 13.3121 25.2125 14.9526C25.2125 16.5932 23.8746 17.9366 22.2451 17.9532C22.1787 17.9532 22.1123 17.9532 22.0458 17.9477C20.492 17.8572 19.2538 16.5729 19.252 14.9988C19.252 14.9582 19.252 14.9194 19.2538 14.8788C19.3147 13.2844 20.6249 12.0093 22.2193 12.0166H22.2212Z" fill="#180905" />\
      <path d="M22.2229 12.0183C23.8615 12.0257 25.2142 13.3138 25.2142 14.9525C25.2142 16.5912 23.8763 17.9383 22.2469 17.9531C22.1804 17.9531 22.114 17.9531 22.0476 17.9475C20.4938 17.8589 19.2555 16.5746 19.2537 15.0005C19.2537 14.9599 19.2537 14.9211 19.2555 14.8805C19.3164 13.2861 20.6266 12.011 22.221 12.0183H22.2229Z" fill="#190905" />\
      <path d="M22.2247 12.022C23.8634 12.0294 25.2179 13.3156 25.2179 14.9543C25.2179 16.593 23.88 17.9401 22.2524 17.9567C22.1859 17.9567 22.1195 17.9567 22.0531 17.953C20.4993 17.8644 19.2592 16.58 19.2573 15.0059C19.2573 14.9653 19.2573 14.9266 19.2592 14.886C19.3201 13.2916 20.6303 12.0164 22.2247 12.0238V12.022Z" fill="#1A0A05" />\
      <path d="M22.2247 12.0239C23.8634 12.0313 25.2179 13.3157 25.2179 14.9544C25.2179 16.5931 23.88 17.9402 22.2524 17.9568C22.1859 17.9568 22.1195 17.9568 22.0531 17.9531C20.4993 17.8645 19.2592 16.5802 19.2573 15.0079C19.2573 14.9673 19.2573 14.9286 19.2592 14.888C19.3201 13.2917 20.6303 12.0184 22.2247 12.0239Z" fill="#1B0A05" />\
      <path d="M22.2265 12.0279C23.8652 12.0352 25.2197 13.3178 25.2197 14.9565C25.2197 16.5952 23.8818 17.9423 22.2542 17.9607C22.1878 17.9607 22.1213 17.9607 22.0549 17.957C20.5011 17.8685 19.261 16.5859 19.2573 15.0118C19.2573 14.9712 19.2573 14.9325 19.2592 14.8919C19.3201 13.2956 20.6303 12.0205 22.2247 12.0279H22.2265Z" fill="#1C0A05" />\
      <path d="M22.2286 12.0297C23.8673 12.0371 25.2236 13.3178 25.2236 14.9565C25.2236 16.5952 23.8857 17.9441 22.2581 17.9607C22.1917 17.9607 22.1252 17.9607 22.0588 17.957C20.505 17.8703 19.2631 16.5859 19.2612 15.0118C19.2612 14.9712 19.2612 14.9325 19.2631 14.8919C19.324 13.2956 20.6342 12.0205 22.2286 12.0279V12.0297Z" fill="#1D0B06" />\
      <path d="M22.2301 12.0332C23.8687 12.0425 25.2251 13.3213 25.2251 14.9581C25.2251 16.595 23.8872 17.9458 22.2596 17.9643C22.1931 17.9643 22.1267 17.9643 22.0603 17.9606C20.5065 17.8738 19.2645 16.5913 19.2627 15.0172C19.2627 14.9766 19.2627 14.9378 19.2645 14.8972C19.3254 13.301 20.6357 12.0259 22.2282 12.0332H22.2301Z" fill="#1E0B06" />\
      <path d="M22.2318 12.0349C23.8686 12.0441 25.2268 13.3211 25.2268 14.9579C25.2268 16.5948 23.8889 17.9456 22.2631 17.964C22.1967 17.964 22.1303 17.964 22.0638 17.9603C20.51 17.8736 19.2681 16.5911 19.2644 15.017C19.2644 14.9764 19.2644 14.9376 19.2663 14.897C19.3271 13.3008 20.6374 12.0238 22.2299 12.033L22.2318 12.0349Z" fill="#1F0C06" />\
      <path d="M22.2341 12.0386C23.8709 12.0478 25.2309 13.323 25.2309 14.9616C25.2309 16.6003 23.8912 17.9493 22.2673 17.9696C22.2008 17.9696 22.1344 17.9696 22.068 17.9659C20.5142 17.881 19.2704 16.5966 19.2686 15.0244C19.2686 14.9838 19.2686 14.945 19.2704 14.9044C19.3313 13.3082 20.6415 12.0312 22.2341 12.0404V12.0386Z" fill="#200C06" />\
      <path d="M22.2358 12.0406C23.8726 12.0498 25.2327 13.3231 25.2327 14.9618C25.2327 16.6005 23.8929 17.9513 22.269 17.9716C22.2026 17.9716 22.1361 17.9716 22.0697 17.9679C20.5159 17.883 19.2721 16.6005 19.2703 15.0264C19.2703 14.9858 19.2703 14.947 19.2721 14.9064C19.333 13.3083 20.6432 12.0332 22.2358 12.0406Z" fill="#210C06" />\
      <path d="M22.2376 12.0442C23.8745 12.0535 25.2345 13.3249 25.2345 14.9636C25.2345 16.6023 23.8948 17.9531 22.2708 17.9734C22.2044 17.9734 22.138 17.9734 22.0715 17.9697C20.5177 17.8848 19.274 16.6023 19.2703 15.03C19.2703 14.9894 19.2703 14.9507 19.2721 14.9101C19.333 13.312 20.6432 12.035 22.2358 12.0442H22.2376Z" fill="#220D07" />\
      <path d="M22.2394 12.0462C23.8763 12.0554 25.2382 13.3269 25.2382 14.9637C25.2382 16.6006 23.8984 17.9532 22.2745 17.9754C22.2081 17.9754 22.1416 17.9754 22.0752 17.9717C20.5196 17.8868 19.2758 16.6061 19.2739 15.032C19.2739 14.9914 19.2739 14.9527 19.2758 14.9121C19.3367 13.314 20.6469 12.037 22.2394 12.0462Z" fill="#230D07" />\
      <path d="M22.2413 12.0479C23.8781 12.0571 25.24 13.3267 25.24 14.9636C25.24 16.6004 23.9003 17.9531 22.2763 17.9752C22.2099 17.9752 22.1435 17.9752 22.077 17.9715C20.5214 17.8885 19.2776 16.606 19.2739 15.0337C19.2739 14.9931 19.2739 14.9544 19.2758 14.9138C19.3367 13.3157 20.6469 12.0387 22.2376 12.0479H22.2413Z" fill="#240D07" />\
      <path d="M22.2432 12.0518C23.88 12.0629 25.2438 13.3288 25.2438 14.9657C25.2438 16.6025 23.904 17.9552 22.282 17.9792C22.2155 17.9792 22.1491 17.9792 22.0827 17.9755C20.527 17.8924 19.2832 16.6099 19.2795 15.0376C19.2795 14.997 19.2795 14.9583 19.2814 14.9177C19.3423 13.3196 20.6525 12.0408 22.2432 12.0518Z" fill="#250E07" />\
      <path d="M22.2449 12.0537C23.8818 12.0647 25.2455 13.3288 25.2455 14.9657C25.2455 16.6025 23.9057 17.957 22.2837 17.9792C22.2172 17.9792 22.1508 17.9792 22.0844 17.9755C20.5287 17.8924 19.2831 16.6117 19.2812 15.0376C19.2812 14.997 19.2813 14.9583 19.2831 14.9177C19.344 13.3196 20.6542 12.0408 22.2449 12.0518V12.0537Z" fill="#260E07" />\
      <path d="M22.2468 12.0571C23.8836 12.0681 25.2473 13.3304 25.2473 14.9672C25.2473 16.6041 23.9076 17.9586 22.2855 17.9826C22.2191 17.9826 22.1526 17.9826 22.0862 17.9789C20.5306 17.8977 19.2849 16.6151 19.2812 15.0429C19.2812 15.0023 19.2813 14.9635 19.2831 14.9229C19.344 13.323 20.6542 12.046 22.2449 12.0552L22.2468 12.0571Z" fill="#270F08" />\
      <path d="M22.2488 12.059C23.8857 12.0701 25.2512 13.3305 25.2512 14.9673C25.2512 16.6042 23.9097 17.9587 22.2894 17.9845C22.223 17.9845 22.1566 17.9845 22.0901 17.9808C20.5345 17.8996 19.2888 16.619 19.2852 15.0449C19.2852 15.0043 19.2852 14.9655 19.287 14.9249C19.3479 13.325 20.6581 12.0461 22.2488 12.0572V12.059Z" fill="#280F08" />\
      <path d="M22.2505 12.0627C23.8874 12.0738 25.2529 13.3342 25.2529 14.9692C25.2529 16.6042 23.9114 17.9605 22.2911 17.9863C22.2247 17.9863 22.1583 17.9863 22.0918 17.9827C20.5362 17.9015 19.2887 16.6208 19.2869 15.0485C19.2869 15.0079 19.2869 14.9692 19.2887 14.9286C19.3496 13.3286 20.6598 12.0498 22.2505 12.0609V12.0627Z" fill="#290F08" />\
      <path d="M22.2522 12.0645C23.8891 12.0755 25.2547 13.3341 25.2547 14.9709C25.2547 16.6078 23.9131 17.9641 22.2947 17.9899C22.2282 17.9899 22.1618 17.9899 22.0954 17.9862C20.5397 17.905 19.2923 16.6244 19.2886 15.0521C19.2886 15.0115 19.2886 14.9728 19.2904 14.934C19.3513 13.3341 20.6615 12.0552 22.2522 12.0663V12.0645Z" fill="#2A1008" />\
      <path d="M22.2541 12.0684C23.8891 12.0794 25.2583 13.3361 25.2583 14.973C25.2583 16.6098 23.9167 17.9662 22.2983 17.992C22.2319 17.992 22.1655 17.992 22.099 17.9883C20.5434 17.909 19.2959 16.6283 19.2922 15.056C19.2922 15.0154 19.2922 14.9767 19.2941 14.9379C19.355 13.338 20.6652 12.0591 22.2541 12.0702V12.0684Z" fill="#2B1008" />\
      <path d="M22.2542 12.0701C23.8892 12.083 25.2585 13.336 25.2585 14.9728C25.2585 16.6097 23.9169 17.966 22.2985 17.9937C22.232 17.9937 22.1656 17.9937 22.0992 17.99C20.5435 17.9107 19.2942 16.63 19.2905 15.0577C19.2905 15.0171 19.2905 14.9784 19.2924 14.9396C19.3533 13.3397 20.6635 12.059 22.2523 12.0719L22.2542 12.0701Z" fill="#2C1009" />\
      <path d="M22.2559 12.0738C23.8909 12.0867 25.2602 13.3379 25.2602 14.9747C25.2602 16.6116 23.9186 17.9679 22.3002 17.9956C22.2338 17.9956 22.1673 17.9956 22.1009 17.9919C20.5452 17.9126 19.2959 16.6337 19.2922 15.0615C19.2922 15.0209 19.2922 14.9821 19.2941 14.9434C19.355 13.3416 20.6652 12.0627 22.2541 12.0738H22.2559Z" fill="#2D1109" />\
      <path d="M22.258 12.0755C23.893 12.0884 25.2641 13.3396 25.2641 14.9746C25.2641 16.6096 23.9225 17.9678 22.3041 17.9973C22.2377 17.9973 22.1712 17.9973 22.1048 17.9936C20.5491 17.9143 19.2998 16.6354 19.2961 15.0632C19.2961 15.0226 19.2961 14.9838 19.298 14.9451C19.3589 13.3433 20.6691 12.0644 22.258 12.0755Z" fill="#2E1109" />\
      <path d="M22.2598 12.0775C23.8948 12.0904 25.2659 13.3397 25.2659 14.9747C25.2659 16.6097 23.9243 17.9698 22.3059 17.9974C22.2395 17.9974 22.1731 17.9974 22.1066 17.9937C20.551 17.9162 19.2998 16.6356 19.2961 15.0651C19.2961 15.0245 19.2961 14.9858 19.298 14.947C19.3589 13.3453 20.6691 12.0646 22.258 12.0775H22.2598Z" fill="#2F1209" />\
      <path d="M22.2615 12.0812C23.8965 12.0941 25.2694 13.3415 25.2694 14.9765C25.2694 16.6115 23.9279 17.9716 22.3113 18.0011C22.2449 18.0011 22.1784 18.0011 22.112 17.9974C20.5564 17.9199 19.3052 16.6411 19.3015 15.0688C19.3015 15.0282 19.3015 14.9895 19.3034 14.9507C19.3643 13.3489 20.6745 12.0682 22.2633 12.0812H22.2615Z" fill="#301209" />\
      <path d="M22.2633 12.0831C23.8983 12.096 25.2713 13.3416 25.2713 14.9766C25.2713 16.6116 23.9279 17.9717 22.3132 18.0031C22.2467 18.0031 22.1803 18.0031 22.1139 17.9994C20.5582 17.9219 19.307 16.643 19.3015 15.0708C19.3015 15.0302 19.3015 14.9914 19.3034 14.9527C19.3624 13.3509 20.6745 12.0702 22.2633 12.0831Z" fill="#31120A" />\
      <path d="M22.2653 12.0868C23.9003 12.1016 25.2732 13.3435 25.2732 14.9785C25.2732 16.6135 23.9298 17.9735 22.3151 18.0049C22.2487 18.0049 22.1822 18.0049 22.1158 18.0012C20.5602 17.9255 19.3072 16.6467 19.3035 15.0744C19.3035 15.0339 19.3035 14.9951 19.3053 14.9563C19.3644 13.3546 20.6764 12.072 22.2634 12.0868H22.2653Z" fill="#32130A" />\
      <path d="M22.2671 12.0887C23.9021 12.1035 25.2769 13.3454 25.2769 14.9786C25.2769 16.6117 23.9335 17.9754 22.3188 18.0068C22.2523 18.0068 22.1859 18.0068 22.1195 18.005C20.5638 17.9293 19.3108 16.6505 19.3071 15.0782C19.3071 15.0376 19.3071 14.9989 19.309 14.9601C19.368 13.3565 20.6801 12.0758 22.2671 12.0887Z" fill="#33130A" />\
      <path d="M22.2689 12.0923C23.9039 12.107 25.2787 13.3471 25.2787 14.9821C25.2787 16.6171 23.9353 17.979 22.3206 18.0104C22.2542 18.0104 22.1878 18.0104 22.1213 18.0085C20.5657 17.9329 19.3127 16.654 19.3071 15.0836C19.3071 15.0449 19.3071 15.0043 19.309 14.9655C19.368 13.3619 20.6801 12.0812 22.2671 12.0941L22.2689 12.0923Z" fill="#34130A" />\
      <path d="M22.2708 12.0942C23.9058 12.109 25.2806 13.3472 25.2806 14.9822C25.2806 16.6172 23.9371 17.9791 22.3243 18.0123C22.2578 18.0123 22.1914 18.0123 22.125 18.0105C20.5675 17.9348 19.3145 16.6578 19.3108 15.0856C19.3108 15.0468 19.3108 15.0062 19.3126 14.9675C19.3717 13.3639 20.6837 12.0813 22.2708 12.0961V12.0942Z" fill="#35140A" />\
      <path d="M22.2726 12.0979C23.9057 12.1127 25.2842 13.3491 25.2842 14.9841C25.2842 16.6191 23.9408 17.9809 22.3279 18.0142C22.2615 18.0142 22.1951 18.0142 22.1286 18.0123C20.5712 17.9385 19.3181 16.6597 19.3145 15.0892C19.3145 15.0505 19.3145 15.0099 19.3163 14.9711C19.3754 13.3675 20.6874 12.085 22.2744 12.0997L22.2726 12.0979Z" fill="#36140A" />\
      <path d="M22.2744 12.0996C23.9076 12.1144 25.2861 13.3489 25.2861 14.9839C25.2861 16.6189 23.9426 17.9808 22.3298 18.0159C22.2634 18.0159 22.1969 18.0159 22.1305 18.014C20.573 17.9402 19.32 16.6632 19.3145 15.091C19.3145 15.0522 19.3145 15.0116 19.3163 14.9729C19.3754 13.3692 20.6874 12.0867 22.2744 12.1015V12.0996Z" fill="#37150B" />\
      <path d="M22.2764 12.1033C23.9095 12.118 25.288 13.3507 25.288 14.9857C25.288 16.6207 23.9446 17.9845 22.3317 18.0177C22.2653 18.0177 22.1989 18.0177 22.1324 18.0158C20.5749 17.942 19.3201 16.665 19.3164 15.0946C19.3164 15.0559 19.3164 15.0153 19.3183 14.9765C19.3773 13.3729 20.6894 12.0904 22.2745 12.1051L22.2764 12.1033Z" fill="#38150B" />\
      <path d="M22.2783 12.1054C23.9115 12.122 25.2918 13.3528 25.2918 14.986C25.2918 16.6191 23.9465 17.9847 22.3355 18.0198C22.2691 18.0198 22.2027 18.0198 22.1362 18.0179C20.5787 17.9441 19.3239 16.6671 19.3184 15.0967C19.3184 15.0579 19.3184 15.0173 19.3202 14.9786C19.3793 13.3731 20.6913 12.0906 22.2765 12.1054H22.2783Z" fill="#39150B" />\
      <path d="M22.2803 12.1071C23.9134 12.1237 25.2938 13.3527 25.2938 14.9858C25.2938 16.619 23.9485 17.9846 22.3375 18.0215C22.2711 18.0215 22.2046 18.0215 22.1382 18.0196C20.5807 17.9477 19.3258 16.6707 19.3203 15.0984C19.3203 15.0597 19.3203 15.0191 19.3222 14.9803C19.3812 13.3748 20.6933 12.0923 22.2784 12.1071H22.2803Z" fill="#3A160B" />\
      <path d="M22.282 12.1107C23.9151 12.1273 25.2973 13.3545 25.2973 14.9877C25.2973 16.6208 23.952 17.9864 22.3429 18.0233C22.2764 18.0233 22.21 18.0233 22.1436 18.0214C20.5861 17.9495 19.3294 16.6725 19.3257 15.1021C19.3257 15.0633 19.3257 15.0227 19.3275 14.984C19.3866 13.3785 20.6986 12.096 22.2838 12.1107H22.282Z" fill="#3B160B" />\
      <path d="M22.2838 12.1125C23.917 12.1291 25.2991 13.3544 25.2991 14.9876C25.2991 16.6207 23.9539 17.9881 22.3447 18.025C22.2783 18.025 22.2118 18.025 22.1454 18.0232C20.5879 17.9512 19.3312 16.6761 19.3257 15.1038C19.3257 15.0651 19.3257 15.0245 19.3275 14.9857C19.3866 13.3802 20.6986 12.0959 22.2838 12.1125Z" fill="#3C160C" />\
      <path d="M22.2838 12.1161C23.917 12.1327 25.2991 13.3562 25.2991 14.9894C25.2991 16.6225 23.9539 17.9899 22.3447 18.0268C22.2783 18.0268 22.2118 18.0268 22.1454 18.025C20.5879 17.9549 19.3312 16.6779 19.3257 15.1075C19.3257 15.0687 19.3257 15.0281 19.3275 14.9894C19.3866 13.3839 20.7005 12.0995 22.2838 12.1161Z" fill="#3D170C" />\
      <path d="M22.2856 12.1182C23.9188 12.1348 25.3028 13.3583 25.3028 14.9914C25.3028 16.6246 23.9575 17.992 22.3484 18.0307C22.2819 18.0307 22.2155 18.0307 22.1491 18.0289C20.5916 17.9588 19.333 16.6818 19.3293 15.1114C19.3293 15.0726 19.3293 15.032 19.3312 14.9933C19.3902 13.3878 20.7041 12.1034 22.2875 12.12L22.2856 12.1182Z" fill="#3E170C" />\
      <path d="M22.2875 12.122C23.9206 12.1386 25.3047 13.3602 25.3047 14.9934C25.3047 16.6265 23.9594 17.994 22.3502 18.0327C22.2838 18.0327 22.2173 18.0327 22.1509 18.0309C20.5934 17.9607 19.3349 16.6856 19.3293 15.1152C19.3293 15.0764 19.3293 15.0358 19.3312 14.9971C19.3902 13.3898 20.7041 12.1054 22.2856 12.122H22.2875Z" fill="#3F180C" />\
      <path d="M22.289 12.1235C23.9222 12.142 25.3062 13.3599 25.3062 14.9931C25.3062 16.6262 23.961 17.9937 22.3536 18.0343C22.2872 18.0343 22.2208 18.0343 22.1543 18.0343C20.5968 17.9641 19.3383 16.689 19.3328 15.1186C19.3328 15.0798 19.3328 15.0392 19.3346 15.0005C19.3937 13.3931 20.7076 12.1088 22.289 12.1254V12.1235Z" fill="#40180C" />\
      <path d="M22.291 12.1274C23.9223 12.1459 25.31 13.362 25.31 14.9951C25.31 16.6283 23.9648 17.9976 22.3574 18.0382C22.291 18.0382 22.2246 18.0382 22.1581 18.0382C20.6006 17.9699 19.3403 16.6947 19.3347 15.1243C19.3347 15.0856 19.3347 15.045 19.3366 15.0062C19.3956 13.3989 20.7095 12.1145 22.291 12.1311V12.1274Z" fill="#41180D" />\
      <path d="M22.293 12.1292C23.9243 12.1476 25.312 13.3619 25.312 14.995C25.312 16.6282 23.9649 17.9974 22.3594 18.038C22.293 18.038 22.2265 18.038 22.1601 18.038C20.6026 17.9697 19.3422 16.6946 19.3367 15.1242C19.3367 15.0854 19.3367 15.0448 19.3385 15.0061C19.3976 13.3988 20.7115 12.1125 22.293 12.131V12.1292Z" fill="#42190D" />\
      <path d="M22.2949 12.1326C23.9262 12.151 25.3139 13.3653 25.3139 14.9966C25.3139 16.6279 23.9668 17.999 22.3613 18.0414C22.2949 18.0414 22.2285 18.0414 22.162 18.0414C20.6045 17.9732 19.3442 16.698 19.3386 15.1276C19.3386 15.0889 19.3386 15.0483 19.3405 15.0095C19.3995 13.4022 20.7134 12.116 22.2949 12.1344V12.1326Z" fill="#43190D" />\
      <path d="M22.2966 12.1345C23.9279 12.153 25.3175 13.3654 25.3175 14.9967C25.3175 16.628 23.9704 17.9991 22.3649 18.0416C22.2985 18.0416 22.232 18.0416 22.1656 18.0416C20.6081 17.9733 19.3459 16.7 19.3403 15.1296C19.3403 15.0908 19.3403 15.0502 19.3422 15.0115C19.4012 13.4041 20.7151 12.1179 22.2966 12.1364V12.1345Z" fill="#44190D" />\
      <path d="M22.2986 12.1366C23.9299 12.1551 25.3194 13.3657 25.3194 14.997C25.3194 16.6283 23.9723 18.0012 22.3668 18.0437C22.3004 18.0455 22.234 18.0437 22.1675 18.0437C20.6101 17.9772 19.3478 16.7021 19.3423 15.1317C19.3423 15.0929 19.3423 15.0523 19.3441 15.0136C19.4032 13.4044 20.7171 12.12 22.2986 12.1366Z" fill="#451A0D" />\
      <path d="M22.3005 12.1403C23.9318 12.1606 25.3232 13.3675 25.3232 14.9988C25.3232 16.6301 23.9761 18.0031 22.3725 18.0455C22.306 18.0474 22.2396 18.0455 22.1732 18.0455C20.6138 17.9791 19.3534 16.7058 19.3479 15.1354C19.3479 15.0966 19.3479 15.056 19.3497 15.0173C19.4088 13.4081 20.7227 12.1219 22.3023 12.1403H22.3005Z" fill="#461A0E" />\
      <path d="M22.3023 12.1421C23.9336 12.1624 25.3251 13.3674 25.3251 15.0006C25.3251 16.6337 23.9779 18.0048 22.3743 18.0491C22.3079 18.051 22.2414 18.0491 22.175 18.0491C20.6157 17.9827 19.3534 16.7094 19.3479 15.139C19.3479 15.1002 19.3479 15.0596 19.3497 15.0209C19.4088 13.4117 20.7227 12.1255 22.3023 12.1439V12.1421Z" fill="#471A0E" />\
      <path d="M22.304 12.1458C23.9354 12.1661 25.3268 13.3711 25.3268 15.0024C25.3268 16.6337 23.9796 18.0066 22.376 18.0509C22.3096 18.0528 22.2431 18.0509 22.1767 18.0509C20.6174 17.9863 19.3551 16.7112 19.3496 15.1426C19.3496 15.1039 19.3496 15.0633 19.3515 15.0245C19.4105 13.4154 20.7244 12.1291 22.304 12.1476V12.1458Z" fill="#481B0E" />\
      <path d="M22.3058 12.1477C23.9371 12.168 25.3303 13.3712 25.3303 15.0025C25.3303 16.6338 23.9832 18.0068 22.3796 18.0529C22.3131 18.0547 22.2467 18.0529 22.1803 18.0529C20.6209 17.9883 19.3587 16.715 19.3513 15.1446C19.3513 15.1058 19.3513 15.0652 19.3532 15.0265C19.4122 13.4173 20.7261 12.1293 22.3058 12.1496V12.1477Z" fill="#491B0E" />\
      <path d="M22.308 12.1511C23.9393 12.1714 25.3325 13.3728 25.3325 15.0041C25.3325 16.6354 23.9835 18.0102 22.3818 18.0563C22.3153 18.0582 22.2489 18.0563 22.1825 18.0563C20.6231 17.9917 19.3591 16.7184 19.3535 15.1498C19.3535 15.1111 19.3535 15.0705 19.3554 15.0317C19.4144 13.4226 20.7283 12.1345 22.308 12.1548V12.1511Z" fill="#4A1C0E" />\
      <path d="M22.3095 12.1533C23.9408 12.1736 25.3341 13.3731 25.3341 15.0044C25.3341 16.6357 23.9851 18.0105 22.3852 18.0566C22.3188 18.0585 22.2523 18.0566 22.1859 18.0566C20.6265 17.992 19.3625 16.7187 19.3569 15.1502C19.3569 15.1114 19.3569 15.0708 19.3588 15.032C19.4178 13.4229 20.7317 12.1348 22.3114 12.1533H22.3095Z" fill="#4B1C0F" />\
      <path d="M22.3115 12.1567C23.9409 12.177 25.3379 13.3747 25.3379 15.006C25.3379 16.6373 23.9889 18.0121 22.389 18.0601C22.3226 18.0619 22.2561 18.0601 22.1897 18.0601C20.6303 17.9973 19.3663 16.724 19.3589 15.1555C19.3589 15.1167 19.3589 15.0761 19.3607 15.0374C19.4198 13.4264 20.7337 12.1383 22.3115 12.1586V12.1567Z" fill="#4C1C0F" />\
      <path d="M22.3132 12.1584C23.9426 12.1806 25.3396 13.3745 25.3396 15.0058C25.3396 16.6372 23.9906 18.012 22.3907 18.0599C22.3243 18.0618 22.2578 18.0599 22.1914 18.0599C20.6321 17.9972 19.3661 16.7239 19.3606 15.1553C19.3606 15.1166 19.3606 15.076 19.3624 15.0372C19.4215 13.4262 20.7354 12.1381 22.3132 12.1584Z" fill="#4D1D0F" />\
      <path d="M22.3133 12.1623C23.9428 12.1845 25.3397 13.3784 25.3397 15.0079C25.3397 16.6374 23.9908 18.0159 22.3908 18.0638C22.3244 18.0657 22.258 18.0657 22.1915 18.0638C20.6322 18.0011 19.3663 16.7296 19.3589 15.1592C19.3589 15.1205 19.3589 15.0799 19.3607 15.0411C19.4179 13.4301 20.7337 12.142 22.3115 12.1623H22.3133Z" fill="#4E1D0F" />\
      <path d="M22.3151 12.1643C23.9446 12.1865 25.3434 13.3786 25.3434 15.0081C25.3434 16.6375 23.9944 18.016 22.3945 18.064C22.3281 18.0658 22.2616 18.0658 22.1952 18.064C20.6359 18.0031 19.3699 16.7298 19.3625 15.1612C19.3625 15.1225 19.3625 15.0819 19.3644 15.0431C19.4216 13.4321 20.7373 12.1422 22.3151 12.1643Z" fill="#4F1D0F" />\
      <path d="M22.3171 12.1661C23.9466 12.1882 25.3453 13.3785 25.3453 15.0079C25.3453 16.6374 23.9964 18.0159 22.3964 18.0657C22.33 18.0675 22.2636 18.0675 22.1971 18.0657C20.6378 18.0048 19.37 16.7315 19.3645 15.1629C19.3645 15.1242 19.3645 15.0836 19.3663 15.0448C19.4236 13.4338 20.7393 12.1439 22.3171 12.1661Z" fill="#501E10" />\
      <path d="M22.3186 12.1697C23.948 12.1918 25.3468 13.3802 25.3468 15.0115C25.3468 16.6428 23.9978 18.0195 22.3998 18.0693C22.3333 18.0712 22.2669 18.0712 22.2005 18.0693C20.6411 18.0084 19.3733 16.737 19.366 15.1684C19.366 15.1296 19.366 15.089 19.3678 15.0503C19.425 13.4393 20.7408 12.1494 22.3186 12.1697Z" fill="#511E10" />\
      <path d="M22.3206 12.1714C23.9501 12.1936 25.3507 13.3801 25.3507 15.0114C25.3507 16.6428 24.0018 18.0212 22.4037 18.0711C22.3372 18.0729 22.2708 18.0729 22.2044 18.0711C20.645 18.0102 19.3773 16.7387 19.3699 15.1701C19.3699 15.1314 19.3699 15.0908 19.3717 15.052C19.4289 13.4392 20.7447 12.1493 22.3225 12.1714H22.3206Z" fill="#521F10" />\
      <path d="M22.3226 12.1753C23.952 12.1974 25.3527 13.384 25.3527 15.0135C25.3527 16.6429 24.0019 18.0233 22.4056 18.0749C22.3392 18.0768 22.2727 18.0768 22.2063 18.0749C20.647 18.0159 19.3774 16.7444 19.3718 15.1759C19.3718 15.1371 19.3718 15.0965 19.3737 15.0578C19.4309 13.4449 20.7466 12.155 22.3226 12.1771V12.1753Z" fill="#531F10" />\
      <path d="M22.3245 12.177C23.954 12.201 25.3565 13.3839 25.3565 15.0134C25.3565 16.6428 24.0057 18.0232 22.4094 18.0748C22.343 18.0767 22.2765 18.0767 22.2101 18.0748C20.6508 18.0158 19.3812 16.7443 19.3738 15.1758C19.3738 15.137 19.3738 15.0964 19.3756 15.0577C19.4328 13.4448 20.7486 12.1549 22.3245 12.177Z" fill="#541F10" />\
      <path d="M22.3262 12.1809C23.9557 12.2049 25.3582 13.3859 25.3582 15.0154C25.3582 16.6449 24.0074 18.0252 22.4111 18.0787C22.3447 18.0806 22.2783 18.0806 22.2118 18.0787C20.6525 18.0197 19.3829 16.7482 19.3755 15.1815C19.3755 15.1427 19.3755 15.1021 19.3773 15.0634C19.4345 13.4505 20.7503 12.1606 22.3262 12.1828V12.1809Z" fill="#552010" />\
      <path d="M22.3279 12.1827C23.9574 12.2067 25.3599 13.3859 25.3599 15.0153C25.3599 16.6448 24.0091 18.0251 22.4147 18.0787C22.3482 18.0805 22.2818 18.0805 22.2154 18.0787C20.656 18.0196 19.3846 16.75 19.3772 15.1814C19.3772 15.1427 19.3772 15.1021 19.379 15.0633C19.4362 13.4505 20.752 12.1587 22.3279 12.1827Z" fill="#562011" />\
      <path d="M22.33 12.1862C23.9595 12.2102 25.3638 13.3875 25.3638 15.017C25.3638 16.6465 24.013 18.0286 22.4186 18.0822C22.3521 18.084 22.2857 18.084 22.2193 18.0822C20.6599 18.025 19.3885 16.7535 19.3811 15.1849C19.3811 15.1462 19.3811 15.1056 19.3811 15.0668C19.4383 13.454 20.7541 12.1622 22.33 12.1844V12.1862Z" fill="#572011" />\
      <path d="M22.3315 12.1882C23.9591 12.2122 25.3653 13.3895 25.3653 15.0171C25.3653 16.6447 24.0144 18.0288 22.42 18.0823C22.3536 18.0841 22.2872 18.0841 22.2207 18.0823C20.6596 18.0251 19.3899 16.7555 19.3826 15.1869C19.3826 15.1481 19.3826 15.1075 19.3826 15.0688C19.4398 13.4541 20.7555 12.1642 22.3315 12.1863V12.1882Z" fill="#582111" />\
      <path d="M22.3333 12.1921C23.9609 12.2161 25.3671 13.3916 25.3671 15.0192C25.3671 16.6468 24.0163 18.0309 22.4219 18.0862C22.3555 18.0881 22.289 18.0881 22.2226 18.0862C20.6614 18.029 19.3899 16.7594 19.3826 15.1908C19.3826 15.1521 19.3826 15.1115 19.3826 15.0727C19.4398 13.458 20.7555 12.1663 22.3296 12.1903L22.3333 12.1921Z" fill="#592111" />\
      <path d="M22.3354 12.1934C23.963 12.2193 25.371 13.3911 25.371 15.0187C25.371 16.6463 24.0202 18.0303 22.4258 18.0876C22.3594 18.0894 22.2929 18.0894 22.2265 18.0876C20.6653 18.0322 19.3939 16.7607 19.3865 15.194C19.3865 15.1553 19.3865 15.1147 19.3865 15.0759C19.4437 13.4612 20.7594 12.1695 22.3335 12.1934H22.3354Z" fill="#5A2211" />\
      <path d="M22.3373 12.1974C23.9649 12.2232 25.373 13.3931 25.373 15.0226C25.373 16.6521 24.0222 18.0361 22.4278 18.0915C22.3613 18.0933 22.2949 18.0933 22.2285 18.0915C20.6673 18.0361 19.3958 16.7665 19.3884 15.1979C19.3884 15.1592 19.3884 15.1186 19.3884 15.0798C19.4456 13.4651 20.7614 12.1734 22.3355 12.1974H22.3373Z" fill="#5B2212" />\
      <path d="M22.3393 12.1995C23.9669 12.2253 25.3749 13.3934 25.3749 15.0229C25.3749 16.6523 24.0223 18.0364 22.4315 18.0936C22.3651 18.0954 22.2987 18.0954 22.2323 18.0936C20.6711 18.0382 19.3978 16.7686 19.3904 15.2019C19.3904 15.1631 19.3904 15.1225 19.3904 15.0838C19.4476 13.4691 20.7633 12.1755 22.3374 12.2013L22.3393 12.1995Z" fill="#5C2212" />\
      <path d="M22.3409 12.2011C23.9685 12.227 25.3783 13.3933 25.3783 15.0227C25.3783 16.6522 24.0257 18.0362 22.435 18.0934C22.3685 18.0953 22.3021 18.0953 22.2357 18.0934C20.6745 18.0381 19.4012 16.7703 19.3938 15.2017C19.3938 15.163 19.3938 15.1224 19.3938 15.0836C19.451 13.4689 20.7668 12.1753 22.3409 12.1993V12.2011Z" fill="#5D2312" />\
      <path d="M22.3427 12.2049C23.9703 12.2308 25.3802 13.397 25.3802 15.0246C25.3802 16.6523 24.0275 18.0381 22.4368 18.0972C22.3704 18.099 22.3039 18.099 22.2375 18.0972C20.6763 18.0437 19.403 16.7741 19.3938 15.2073C19.3938 15.1686 19.3938 15.128 19.3938 15.0892C19.451 13.4727 20.7668 12.1809 22.3409 12.2049H22.3427Z" fill="#5E2312" />\
      <path d="M22.3428 12.2068C23.9704 12.2327 25.3821 13.3971 25.3821 15.0247C25.3821 16.6523 24.0295 18.0382 22.4388 18.0972C22.3723 18.0991 22.3059 18.0991 22.2395 18.0972C20.6783 18.0437 19.4031 16.7741 19.3958 15.2074C19.3958 15.1686 19.3958 15.128 19.3958 15.0893C19.453 13.4727 20.7687 12.1791 22.3428 12.205V12.2068Z" fill="#5F2312" />\
      <path d="M22.3448 12.2103C23.9724 12.2362 25.3841 13.3988 25.3841 15.0264C25.3841 16.654 24.0314 18.0417 22.4407 18.1008C22.3743 18.1026 22.3079 18.1045 22.2414 18.1008C20.6802 18.0472 19.4051 16.7795 19.3977 15.2128C19.3977 15.174 19.3977 15.1353 19.3977 15.0947C19.4549 13.4781 20.7707 12.1845 22.3429 12.2103H22.3448Z" fill="#602413" />\
      <path d="M22.3465 12.2122C23.9741 12.2399 25.3858 13.3988 25.3858 15.0264C25.3858 16.654 24.0331 18.0417 22.4443 18.1008C22.3778 18.1026 22.3114 18.1045 22.245 18.1008C20.6838 18.0472 19.4086 16.7795 19.3994 15.2128C19.3994 15.174 19.3994 15.1353 19.3994 15.0947C19.4566 13.4781 20.7724 12.1845 22.3446 12.2103L22.3465 12.2122Z" fill="#612413" />\
      <path d="M22.3483 12.2158C23.9759 12.2435 25.3895 13.4006 25.3895 15.0282C25.3895 16.6558 24.0368 18.0435 22.4479 18.1044C22.3815 18.1063 22.3151 18.1081 22.2486 18.1044C20.6875 18.0528 19.4105 16.785 19.4031 15.2164C19.4031 15.1777 19.4031 15.1389 19.4031 15.0983C19.4603 13.4818 20.776 12.1882 22.3483 12.214V12.2158Z" fill="#622513" />\
      <path d="M22.3502 12.218C23.976 12.2457 25.3914 13.4028 25.3914 15.0285C25.3914 16.6543 24.0388 18.0439 22.4499 18.1066C22.3835 18.1085 22.317 18.1103 22.2506 18.1066C20.6894 18.0549 19.4124 16.7872 19.405 15.2205C19.405 15.1817 19.405 15.143 19.405 15.1024C19.4622 13.4858 20.778 12.1904 22.3502 12.2162V12.218Z" fill="#632513" />\
      <path d="M22.3521 12.2216C23.9779 12.2493 25.3933 13.4045 25.3933 15.0303C25.3933 16.656 24.0388 18.0474 22.4517 18.1083C22.3853 18.1102 22.3189 18.112 22.2524 18.1083C20.6913 18.0567 19.4143 16.7889 19.405 15.2222C19.405 15.1834 19.405 15.1447 19.405 15.1041C19.4622 13.4857 20.778 12.1921 22.3502 12.2179L22.3521 12.2216Z" fill="#642513" />\
      <path d="M22.3539 12.2233C23.9797 12.251 25.3969 13.4043 25.3969 15.0319C25.3969 16.6596 24.0424 18.0491 22.4554 18.1119C22.389 18.1137 22.3225 18.1155 22.2561 18.1119C20.6949 18.062 19.4161 16.7943 19.4087 15.2275C19.4087 15.1888 19.4087 15.15 19.4087 15.1094C19.4659 13.491 20.7816 12.1974 22.3539 12.2233Z" fill="#652614" />\
      <path d="M22.3557 12.2269C23.9815 12.2546 25.3988 13.4061 25.3988 15.0337C25.3988 16.6613 24.0443 18.0509 22.4572 18.1136C22.3908 18.1155 22.3244 18.1173 22.2579 18.1136C20.6968 18.0638 19.4179 16.796 19.4087 15.2293C19.4087 15.1906 19.4087 15.1518 19.4087 15.1112C19.4659 13.4928 20.7816 12.1974 22.3539 12.225L22.3557 12.2269Z" fill="#662614" />\
      <path d="M22.3576 12.2287C23.9833 12.2564 25.4006 13.4061 25.4006 15.0337C25.4006 16.6613 24.0461 18.0508 22.4609 18.1154C22.3945 18.1191 22.328 18.1191 22.2616 18.1154C20.7004 18.0656 19.4216 16.7997 19.4124 15.233C19.4124 15.1942 19.4124 15.1555 19.4124 15.1149C19.4696 13.4965 20.7853 12.201 22.3557 12.2287H22.3576Z" fill="#672614" />\
      <path d="M22.3596 12.2306C23.9854 12.2601 25.4045 13.4079 25.4045 15.0337C25.4045 16.6594 24.05 18.0508 22.4648 18.1154C22.3984 18.1191 22.3319 18.1191 22.2655 18.1154C20.7043 18.0656 19.4236 16.7997 19.4163 15.233C19.4163 15.1942 19.4163 15.1555 19.4163 15.1149C19.4735 13.4965 20.7892 12.201 22.3596 12.2287V12.2306Z" fill="#682714" />\
      <path d="M22.3615 12.2345C23.9872 12.264 25.4063 13.41 25.4063 15.0357C25.4063 16.6615 24.0518 18.0548 22.4667 18.1193C22.4002 18.123 22.3338 18.123 22.2674 18.1193C20.7043 18.0714 19.4255 16.8036 19.4163 15.2387C19.4163 15.2 19.4163 15.1612 19.4163 15.1206C19.4735 13.5022 20.7892 12.2049 22.3596 12.2326L22.3615 12.2345Z" fill="#692714" />\
      <path d="M22.363 12.2363C23.9888 12.2658 25.4097 13.41 25.4097 15.0357C25.4097 16.6615 24.0552 18.0548 22.4701 18.1193C22.4036 18.123 22.3372 18.123 22.2708 18.1193C20.7077 18.0714 19.4289 16.8054 19.4197 15.2387C19.4197 15.2 19.4197 15.1612 19.4197 15.1206C19.4769 13.5004 20.7926 12.2049 22.363 12.2326V12.2363Z" fill="#6A2815" />\
      <path d="M22.3649 12.2397C23.9907 12.2692 25.4116 13.4115 25.4116 15.0373C25.4116 16.6631 24.0571 18.0563 22.4719 18.1228C22.4055 18.1265 22.3391 18.1265 22.2726 18.1228C20.7096 18.0748 19.4289 16.8089 19.4197 15.2421C19.4197 15.2034 19.4197 15.1646 19.4197 15.124C19.475 13.5038 20.7926 12.2084 22.363 12.236L22.3649 12.2397Z" fill="#6B2815" />\
      <path d="M22.3668 12.2418C23.9926 12.2714 25.4135 13.4118 25.4135 15.0376C25.4135 16.6633 24.059 18.0566 22.4757 18.1249C22.4093 18.1286 22.3429 18.1286 22.2746 18.1249C20.7115 18.0769 19.4309 16.8128 19.4216 15.2461C19.4216 15.2074 19.4216 15.1686 19.4216 15.128C19.477 13.5078 20.7964 12.2105 22.365 12.24L22.3668 12.2418Z" fill="#6C2815" />\
      <path d="M22.3684 12.2454C23.9942 12.2749 25.417 13.4154 25.417 15.0393C25.417 16.6632 24.0606 18.0602 22.4791 18.1266C22.4127 18.1303 22.3463 18.1303 22.278 18.1266C20.715 18.0805 19.4343 16.8145 19.425 15.2478C19.425 15.2091 19.425 15.1703 19.425 15.1297C19.4804 13.5095 20.7998 12.2122 22.3666 12.2417L22.3684 12.2454Z" fill="#6D2915" />\
      <path d="M22.3703 12.2473C23.9942 12.2787 25.4188 13.4154 25.4188 15.0412C25.4188 16.667 24.0625 18.0621 22.481 18.1304C22.4145 18.134 22.3481 18.134 22.2798 18.1304C20.7168 18.0842 19.4343 16.8183 19.425 15.2534C19.425 15.2147 19.425 15.1759 19.425 15.1353C19.4804 13.5151 20.7998 12.2178 22.3666 12.2473H22.3703Z" fill="#6E2915" />\
      <path d="M22.3722 12.251C23.9961 12.2823 25.4208 13.4172 25.4208 15.043C25.4208 16.6688 24.0644 18.0639 22.4829 18.1321C22.4165 18.1358 22.3501 18.1358 22.2818 18.1321C20.7188 18.086 19.4362 16.8219 19.427 15.2552C19.427 15.2165 19.427 15.1777 19.427 15.1371C19.4824 13.5169 20.8018 12.2196 22.3685 12.2473L22.3722 12.251Z" fill="#6F2916" />\
      <path d="M22.3723 12.2527C23.9962 12.2841 25.4227 13.4171 25.4227 15.0429C25.4227 16.6687 24.0664 18.0638 22.4849 18.1339C22.4185 18.1376 22.352 18.1376 22.2837 18.1339C20.7207 18.0896 19.4382 16.8237 19.429 15.2588C19.429 15.22 19.429 15.1813 19.429 15.1425C19.4843 13.5205 20.8038 12.2232 22.3705 12.2527H22.3723Z" fill="#702A16" />\
      <path d="M22.3742 12.2565C23.9981 12.2879 25.4246 13.4191 25.4246 15.0448C25.4246 16.6706 24.0682 18.0657 22.4867 18.1358C22.4203 18.1395 22.3539 18.1395 22.2856 18.1358C20.7226 18.0915 19.4382 16.8275 19.429 15.2608C19.429 15.222 19.429 15.1832 19.429 15.1445C19.4843 13.5224 20.8038 12.2251 22.3705 12.2546L22.3742 12.2565Z" fill="#712A16" />\
      <path d="M22.3762 12.2583C24.0002 12.2897 25.4266 13.419 25.4266 15.0448C25.4266 16.6706 24.0703 18.0675 22.4906 18.1376C22.4242 18.1413 22.3578 18.1413 22.2895 18.1395C20.7265 18.0952 19.4421 16.8311 19.4329 15.2663C19.4329 15.2275 19.4329 15.1887 19.4329 15.15C19.4882 13.5279 20.8077 12.2306 22.3744 12.2601L22.3762 12.2583Z" fill="#722B16" />\
      <path d="M22.3777 12.2603C24.0016 12.2916 25.4299 13.421 25.4299 15.0449C25.4299 16.6688 24.0736 18.0676 22.4939 18.1396C22.4275 18.1433 22.3611 18.1433 22.2928 18.1414C20.7298 18.0972 19.4454 16.8331 19.4343 15.2682C19.4343 15.2295 19.4343 15.1907 19.4343 15.1519C19.4897 13.5299 20.8091 12.2307 22.3758 12.2621L22.3777 12.2603Z" fill="#732B16" />\
      <path d="M22.3794 12.264C24.0033 12.2954 25.4316 13.4229 25.4316 15.0468C25.4316 16.6707 24.0753 18.0695 22.4957 18.1415C22.4292 18.1452 22.3628 18.1452 22.2945 18.1433C20.7315 18.1009 19.4453 16.8368 19.436 15.2701C19.436 15.2313 19.436 15.1926 19.436 15.1538C19.4914 13.5318 20.8108 12.2326 22.3757 12.264H22.3794Z" fill="#742B17" />\
      <path d="M22.3815 12.2656C24.0054 12.2988 25.4355 13.4227 25.4355 15.0466C25.4355 16.6705 24.0774 18.0693 22.4996 18.1431C22.4331 18.1468 22.3667 18.1468 22.2984 18.145C20.7354 18.1025 19.4492 16.8385 19.4399 15.2736C19.4399 15.2348 19.4399 15.1961 19.4399 15.1573C19.4953 13.5352 20.8147 12.2361 22.3796 12.2656H22.3815Z" fill="#752C17" />\
      <path d="M22.3833 12.2692C24.0072 12.3025 25.4374 13.4244 25.4374 15.0484C25.4374 16.6723 24.0792 18.0729 22.5014 18.1449C22.435 18.1486 22.3685 18.1486 22.3003 18.1467C20.7372 18.1043 19.451 16.8421 19.4399 15.2754C19.4399 15.2366 19.4399 15.1978 19.4399 15.1591C19.4953 13.5352 20.8147 12.236 22.3796 12.2674L22.3833 12.2692Z" fill="#762C17" />\
      <path d="M22.3854 12.2713C24.0093 12.3045 25.4395 13.4247 25.4395 15.0486C25.4395 16.6725 24.0813 18.0732 22.5053 18.147C22.4389 18.1507 22.3724 18.1507 22.3042 18.1488C20.7411 18.1064 19.4531 16.8441 19.4438 15.2793C19.4438 15.2405 19.4438 15.2018 19.4438 15.163C19.4992 13.5391 20.8186 12.2399 22.3835 12.2713H22.3854Z" fill="#772C17" />\
      <path d="M22.3869 12.2752C24.0109 12.3084 25.4429 13.4286 25.4429 15.0525C25.4429 16.6764 24.0847 18.0771 22.5087 18.1509C22.4423 18.1546 22.3759 18.1546 22.3076 18.1527C20.7446 18.1121 19.4565 16.848 19.4473 15.2832C19.4473 15.2444 19.4473 15.2057 19.4473 15.1669C19.5026 13.543 20.8221 12.2438 22.3869 12.2752Z" fill="#782D17" />\
      <path d="M22.3888 12.2771C24.0127 12.3103 25.4447 13.4286 25.4447 15.0525C25.4447 16.6765 24.0865 18.0771 22.5106 18.1528C22.4441 18.1564 22.3777 18.1564 22.3094 18.1546C20.7464 18.114 19.4583 16.8518 19.4473 15.2869C19.4473 15.2481 19.4473 15.2094 19.4473 15.1706C19.5026 13.5467 20.8221 12.2457 22.3869 12.2789L22.3888 12.2771Z" fill="#792D17" />\
      <path d="M22.3907 12.2804C24.0128 12.3136 25.4467 13.4301 25.4467 15.054C25.4467 16.6779 24.0885 18.0804 22.5125 18.1542C22.4461 18.1579 22.3797 18.1579 22.3114 18.1561C20.7484 18.1155 19.4584 16.8532 19.4492 15.2884C19.4492 15.2496 19.4492 15.2109 19.4492 15.1721C19.5046 13.5482 20.824 12.2472 22.387 12.2804H22.3907Z" fill="#7A2E18" />\
      <path d="M22.3924 12.2823C24.0145 12.3155 25.4502 13.4301 25.4502 15.054C25.4502 16.678 24.092 18.0804 22.5161 18.1561C22.4497 18.1598 22.3832 18.1598 22.3149 18.158C20.7501 18.1192 19.462 16.857 19.4509 15.2921C19.4509 15.2533 19.4509 15.2146 19.4509 15.1758C19.5063 13.5519 20.8257 12.2509 22.3888 12.2823H22.3924Z" fill="#7B2E18" />\
      <path d="M22.3945 12.2863C24.0166 12.3213 25.4523 13.4322 25.4523 15.0562C25.4523 16.6801 24.0941 18.0826 22.52 18.1601C22.4536 18.1638 22.3871 18.1638 22.3188 18.1619C20.754 18.1232 19.4659 16.8609 19.4548 15.2961C19.4548 15.2573 19.4548 15.2186 19.4548 15.1798C19.5102 13.554 20.8296 12.2531 22.3927 12.2863H22.3945Z" fill="#7C2E18" />\
      <path d="M22.3965 12.2877C24.0185 12.3228 25.4542 13.4337 25.4542 15.0558C25.4542 16.6779 24.096 18.0822 22.5219 18.1597C22.4555 18.1634 22.3891 18.1634 22.3208 18.1615C20.7559 18.1228 19.466 16.8606 19.4568 15.2975C19.4568 15.2588 19.4568 15.22 19.4568 15.1813C19.5121 13.5555 20.8316 12.2545 22.3946 12.2877H22.3965Z" fill="#7D2F18" />\
      <path d="M22.3984 12.2897C24.0205 12.3247 25.458 13.4338 25.458 15.0559C25.458 16.678 24.098 18.0823 22.5257 18.1617C22.4593 18.1653 22.391 18.1653 22.3246 18.1635C20.7597 18.1247 19.4698 16.8644 19.4587 15.2995C19.4587 15.2607 19.4587 15.222 19.4587 15.1832C19.5141 13.5575 20.8335 12.2565 22.3966 12.2897H22.3984Z" fill="#7E2F18" />\
      <path d="M22.3999 12.2933C24.022 12.3284 25.4595 13.4356 25.4595 15.0577C25.4595 16.6798 24.0995 18.086 22.5272 18.1635C22.4608 18.1672 22.3925 18.1672 22.3261 18.1672C20.7612 18.1303 19.4713 16.868 19.4602 15.3031C19.4602 15.2644 19.4602 15.2256 19.4602 15.1869C19.5156 13.5611 20.835 12.2601 22.398 12.2933H22.3999Z" fill="#7F2F19" />\
      <path d="M22.4019 12.2952C24.024 12.3302 25.4634 13.4356 25.4634 15.0577C25.4634 16.6798 24.1034 18.0859 22.5311 18.1653C22.4647 18.169 22.3964 18.169 22.33 18.169C20.7651 18.1321 19.4733 16.8698 19.4641 15.3068C19.4641 15.2681 19.4641 15.2293 19.4641 15.1906C19.5195 13.5648 20.8389 12.2619 22.4019 12.297V12.2952Z" fill="#803019" />\
      <path d="M22.4016 12.2991C24.0237 12.3342 25.4631 13.4377 25.4631 15.0598C25.4631 16.6819 24.103 18.088 22.5308 18.1674C22.4643 18.1711 22.3961 18.1711 22.3296 18.1711C20.7647 18.1342 19.473 16.8738 19.4619 15.3089C19.4619 15.2701 19.4619 15.2314 19.4619 15.1926C19.5173 13.5669 20.8367 12.264 22.3979 12.2973L22.4016 12.2991Z" fill="#813019" />\
      <path d="M22.4039 12.3008C24.026 12.3377 25.4654 13.4375 25.4654 15.0615C25.4654 16.6854 24.1053 18.0897 22.5349 18.1709C22.4685 18.1746 22.4002 18.1746 22.3338 18.1746C20.7689 18.1395 19.4771 16.8773 19.4661 15.3143C19.4661 15.2755 19.4661 15.2368 19.4661 15.198C19.5214 13.5704 20.8409 12.2694 22.402 12.3026L22.4039 12.3008Z" fill="#823119" />\
      <path d="M22.4056 12.3042C24.0277 12.3411 25.4689 13.4409 25.4689 15.063C25.4689 16.6851 24.1089 18.0931 22.5385 18.1724C22.472 18.1761 22.4038 18.1761 22.3373 18.1761C20.7725 18.1411 19.4788 16.8807 19.4678 15.3158C19.4678 15.277 19.4678 15.2383 19.4678 15.1995C19.5231 13.5719 20.8426 12.2691 22.4038 12.3042H22.4056Z" fill="#833119" />\
      <path d="M22.4073 12.3062C24.0294 12.3431 25.4706 13.4411 25.4706 15.0631C25.4706 16.6852 24.1106 18.0932 22.5402 18.1744C22.4737 18.1781 22.4055 18.18 22.339 18.1781C20.7742 18.1431 19.4806 16.8827 19.4695 15.3196C19.4695 15.2809 19.4695 15.2421 19.4695 15.2034C19.5248 13.5758 20.8443 12.2729 22.4055 12.308L22.4073 12.3062Z" fill="#84311A" />\
      <path d="M22.409 12.3101C24.0293 12.347 25.4723 13.4431 25.4723 15.0652C25.4723 16.6873 24.1123 18.0953 22.5419 18.1783C22.4755 18.182 22.4072 18.1839 22.3407 18.182C20.7759 18.147 19.4823 16.8866 19.4712 15.3235C19.4712 15.2848 19.4712 15.246 19.4712 15.2073C19.5266 13.5797 20.846 12.2768 22.4072 12.3119L22.409 12.3101Z" fill="#85321A" />\
      <path d="M22.411 12.3118C24.0312 12.3487 25.4761 13.443 25.4761 15.0651C25.4761 16.6871 24.1142 18.0952 22.5457 18.1782C22.4793 18.1819 22.411 18.1837 22.3445 18.1819C20.7797 18.1487 19.4842 16.8883 19.4731 15.3253C19.4731 15.2865 19.4731 15.2478 19.4731 15.209C19.5285 13.5814 20.8479 12.2767 22.4091 12.3136L22.411 12.3118Z" fill="#86321A" />\
      <path d="M22.4128 12.3154C24.033 12.3523 25.4779 13.4448 25.4779 15.0668C25.4779 16.6889 24.1161 18.0969 22.5493 18.1818C22.4829 18.1855 22.4146 18.1874 22.3482 18.1855C20.7833 18.1523 19.4879 16.8919 19.4768 15.3289C19.4768 15.2901 19.4768 15.2514 19.4768 15.2126C19.5322 13.585 20.8516 12.2803 22.4128 12.3154Z" fill="#87321A" />\
      <path d="M22.4146 12.3172C24.0349 12.3541 25.4798 13.4466 25.4798 15.0668C25.4798 16.6871 24.1179 18.0988 22.5512 18.1818C22.4848 18.1855 22.4165 18.1874 22.35 18.1855C20.7852 18.1523 19.4897 16.8938 19.4768 15.3289C19.4768 15.2901 19.4768 15.2514 19.4768 15.2126C19.5303 13.5832 20.8516 12.2803 22.4109 12.3154L22.4146 12.3172Z" fill="#88331A" />\
      <path d="M22.4167 12.3194C24.0369 12.3581 25.4837 13.4469 25.4837 15.0671C25.4837 16.6874 24.1218 18.0991 22.5551 18.184C22.4887 18.1876 22.4204 18.1895 22.354 18.1876C20.7891 18.1544 19.4918 16.8959 19.4807 15.3329C19.4807 15.2941 19.4807 15.2554 19.4807 15.2166C19.5342 13.5871 20.8555 12.2825 22.4149 12.3194H22.4167Z" fill="#89331B" />\
      <path d="M22.4184 12.3229C24.0386 12.3617 25.4854 13.4486 25.4854 15.0688C25.4854 16.6891 24.1235 18.1008 22.5568 18.1857C22.4904 18.1894 22.4221 18.1912 22.3557 18.1894C20.7908 18.158 19.4935 16.8976 19.4824 15.3346C19.4824 15.2958 19.4824 15.2571 19.4824 15.2183C19.5359 13.5888 20.8572 12.2842 22.4166 12.3211L22.4184 12.3229Z" fill="#8A331B" />\
      <path d="M22.4204 12.325C24.0406 12.3637 25.4892 13.4488 25.4892 15.0709C25.4892 16.6929 24.1273 18.1028 22.5606 18.1895C22.4942 18.1932 22.4259 18.1951 22.3595 18.1932C20.7946 18.1619 19.4973 16.9033 19.4844 15.3403C19.4844 15.3015 19.4844 15.2628 19.4844 15.224C19.5379 13.5946 20.8592 12.2899 22.4185 12.3268L22.4204 12.325Z" fill="#8B341B" />\
      <path d="M22.4221 12.3286C24.0423 12.3674 25.4909 13.4506 25.4909 15.0727C25.4909 16.6948 24.129 18.1065 22.5623 18.1932C22.4959 18.1969 22.4276 18.1987 22.3612 18.1969C20.7945 18.1655 19.4972 16.907 19.4861 15.3439C19.4861 15.3052 19.4861 15.2664 19.4861 15.2277C19.5396 13.5982 20.8609 12.2936 22.4202 12.3305L22.4221 12.3286Z" fill="#8C341B" />\
      <path d="M22.4239 12.3301C24.0441 12.3689 25.4927 13.4521 25.4927 15.0723C25.4927 16.6926 24.1309 18.1061 22.566 18.1928C22.4995 18.1965 22.4313 18.1984 22.3648 18.1965C20.7981 18.167 19.5008 16.9085 19.4897 15.3454C19.4897 15.3067 19.4897 15.2679 19.4897 15.2292C19.5433 13.5997 20.8645 12.2932 22.4239 12.3301Z" fill="#8D351B" />\
      <path d="M22.4261 12.334C24.0463 12.3728 25.4968 13.4542 25.4968 15.0744C25.4968 16.6946 24.1349 18.1082 22.57 18.1968C22.5036 18.2004 22.4353 18.2023 22.3689 18.2004C20.8022 18.1709 19.5049 16.9124 19.4919 15.3494C19.4919 15.3106 19.4919 15.2718 19.4919 15.2331C19.5455 13.6018 20.8667 12.2971 22.4242 12.334H22.4261Z" fill="#8E351C" />\
      <path d="M22.4275 12.336C24.0478 12.3747 25.4982 13.4543 25.4982 15.0745C25.4982 16.6947 24.1345 18.1083 22.5715 18.1969C22.5051 18.2006 22.4368 18.2024 22.3703 18.2006C20.8036 18.171 19.5045 16.9125 19.4934 15.3513C19.4934 15.3126 19.4934 15.2738 19.4934 15.235C19.5469 13.6037 20.8682 12.2991 22.4257 12.336H22.4275Z" fill="#8F351C" />\
      <path d="M22.4294 12.3395C24.0478 12.3801 25.5001 13.4559 25.5001 15.0761C25.5001 16.6964 24.1364 18.1099 22.5733 18.2004C22.5069 18.204 22.4386 18.2059 22.3722 18.204C20.8055 18.1745 19.5063 16.9178 19.4934 15.3548C19.4934 15.316 19.4934 15.2773 19.4934 15.2385C19.5469 13.6072 20.8682 12.3007 22.4257 12.3395H22.4294Z" fill="#90361C" />\
      <path d="M22.4315 12.3412C24.0498 12.3818 25.504 13.4558 25.504 15.076C25.504 16.6962 24.1403 18.1116 22.5772 18.2002C22.5108 18.2039 22.4425 18.2058 22.3761 18.2039C20.8094 18.1762 19.5102 16.9177 19.4973 15.3565C19.4973 15.3178 19.4973 15.279 19.4973 15.2402C19.5508 13.6089 20.8721 12.3024 22.4296 12.3412H22.4315Z" fill="#91361C" />\
      <path d="M22.4313 12.3453C24.0497 12.3859 25.5039 13.4599 25.5039 15.0783C25.5039 16.6967 24.1401 18.1139 22.5789 18.2044C22.5125 18.2081 22.4442 18.2099 22.3778 18.2081C20.8111 18.1804 19.5101 16.9237 19.499 15.3607C19.499 15.3219 19.499 15.2832 19.499 15.2444C19.5525 13.6131 20.8738 12.3066 22.4313 12.3453Z" fill="#92361C" />\
      <path d="M22.4332 12.3471C24.0516 12.3877 25.5057 13.4598 25.5057 15.0782C25.5057 16.6966 24.142 18.1138 22.5808 18.2043C22.5144 18.208 22.4461 18.2098 22.3796 18.208C20.8129 18.1803 19.5119 16.9236 19.499 15.3605C19.499 15.3218 19.499 15.283 19.499 15.2443C19.5525 13.613 20.8738 12.3046 22.4313 12.3434L22.4332 12.3471Z" fill="#93371D" />\
      <path d="M22.435 12.3486C24.0534 12.3892 25.5094 13.4595 25.5094 15.0779C25.5094 16.6963 24.1456 18.1136 22.5845 18.2058C22.518 18.2095 22.4497 18.2114 22.3833 18.2095C20.8166 18.1818 19.5156 16.9252 19.5027 15.364C19.5027 15.3252 19.5027 15.2865 19.5027 15.2477C19.5562 13.6146 20.8775 12.308 22.435 12.3468V12.3486Z" fill="#94371D" />\
      <path d="M22.4367 12.3524C24.0551 12.393 25.5111 13.4615 25.5111 15.0817C25.5111 16.7019 24.1473 18.1192 22.5862 18.2115C22.5197 18.2151 22.4515 18.217 22.385 18.2151C20.8183 18.1893 19.5155 16.9326 19.5044 15.3696C19.5044 15.3308 19.5044 15.2921 19.5044 15.2533C19.5579 13.6202 20.8792 12.3137 22.4348 12.3524H22.4367Z" fill="#95381D" />\
      <path d="M22.4389 12.3544C24.0573 12.3969 25.5151 13.4617 25.5151 15.0819C25.5151 16.7021 24.1514 18.1194 22.5902 18.2116C22.5238 18.2153 22.4555 18.2172 22.3891 18.2153C20.8223 18.1895 19.5195 16.9328 19.5066 15.3716C19.5066 15.3329 19.5066 15.2941 19.5066 15.2554C19.5601 13.6222 20.8814 12.3138 22.437 12.3544H22.4389Z" fill="#96381D" />\
      <path d="M22.4406 12.3579C24.059 12.4003 25.5168 13.4632 25.5168 15.0835C25.5168 16.7037 24.1531 18.1209 22.5919 18.2151C22.5255 18.2187 22.4572 18.2206 22.3908 18.2187C20.824 18.1929 19.5212 16.9381 19.5083 15.375C19.5083 15.3363 19.5083 15.2975 19.5083 15.2588C19.5618 13.6256 20.8831 12.3173 22.4387 12.3579H22.4406Z" fill="#97381D" />\
      <path d="M22.4425 12.3599C24.0609 12.4023 25.5188 13.4652 25.5188 15.0836C25.5188 16.702 24.1532 18.1211 22.5957 18.2152C22.5293 18.2189 22.461 18.2208 22.3946 18.2208C20.8278 18.1968 19.5232 16.9401 19.5103 15.3789C19.5103 15.3401 19.5103 15.3014 19.5103 15.2626C19.5638 13.6295 20.8851 12.3211 22.4407 12.3617L22.4425 12.3599Z" fill="#98391D" />\
      <path d="M22.4444 12.3634C24.0628 12.4058 25.5224 13.4669 25.5224 15.0853C25.5224 16.7037 24.1569 18.1228 22.5994 18.2187C22.5329 18.2224 22.4647 18.2243 22.3982 18.2243C20.8315 18.2003 19.5268 16.9436 19.5139 15.3824C19.5139 15.3437 19.5139 15.3049 19.5139 15.2662C19.5674 13.633 20.8887 12.3246 22.4444 12.3634Z" fill="#99391E" />\
      <path d="M22.4461 12.3654C24.0645 12.4079 25.5241 13.4671 25.5241 15.0855C25.5241 16.7039 24.1586 18.1248 22.6011 18.2189C22.5347 18.2226 22.4664 18.2245 22.3999 18.2245C20.8332 18.2005 19.5285 16.9456 19.5156 15.3844C19.5156 15.3457 19.5156 15.3069 19.5156 15.2682C19.5691 13.635 20.8904 12.3248 22.4461 12.3654Z" fill="#9A391E" />\
      <path d="M22.4479 12.3691C24.0663 12.4115 25.526 13.4689 25.526 15.0873C25.526 16.7057 24.1604 18.1266 22.6029 18.2226C22.5365 18.2263 22.4682 18.2281 22.4018 18.2281C20.8351 18.2041 19.5285 16.9493 19.5156 15.3881C19.5156 15.3494 19.5156 15.3106 19.5156 15.2719C19.5691 13.6369 20.8923 12.3285 22.4461 12.3691H22.4479Z" fill="#9B3A1E" />\
      <path d="M22.4497 12.3709C24.0663 12.4134 25.5297 13.4689 25.5297 15.0873C25.5297 16.7057 24.1641 18.1266 22.6066 18.2226C22.5402 18.2263 22.4719 18.2281 22.4054 18.2281C20.8387 18.206 19.5322 16.9511 19.5193 15.3881C19.5193 15.3494 19.5193 15.3106 19.5193 15.2719C19.5728 13.6369 20.8959 12.3285 22.4479 12.3691L22.4497 12.3709Z" fill="#9C3A1E" />\
      <path d="M22.4518 12.3745C24.0683 12.4188 25.5317 13.4725 25.5317 15.0891C25.5317 16.7056 24.1661 18.1284 22.6105 18.2262C22.5441 18.2299 22.4758 18.2317 22.4094 18.2317C20.8426 18.2096 19.5361 16.9547 19.5232 15.3936C19.5232 15.3548 19.5232 15.3161 19.5232 15.2773C19.5767 13.6423 20.8998 12.3321 22.4518 12.3745Z" fill="#9D3B1E" />\
      <path d="M22.4536 12.3765C24.0702 12.4208 25.5336 13.4726 25.5336 15.0892C25.5336 16.7057 24.168 18.1304 22.6123 18.2282C22.5459 18.2318 22.4776 18.2337 22.4112 18.2337C20.8426 18.2116 19.5361 16.9567 19.5232 15.3955C19.5232 15.3568 19.5232 15.318 19.5232 15.2793C19.5767 13.6443 20.8998 12.334 22.4518 12.3765H22.4536Z" fill="#9E3B1F" />\
      <path d="M22.4555 12.3803C24.072 12.4246 25.5372 13.4746 25.5372 15.093C25.5372 16.7114 24.1717 18.1342 22.616 18.232C22.5496 18.2357 22.4813 18.2375 22.4149 18.2375C20.8463 18.2154 19.5398 16.9624 19.5269 15.4012C19.5269 15.3624 19.5269 15.3237 19.5269 15.2849C19.5804 13.6499 20.9035 12.3397 22.4555 12.3803Z" fill="#9F3B1F" />\
      <path d="M22.4573 12.3819C24.0738 12.4261 25.5391 13.4743 25.5391 15.0927C25.5391 16.7111 24.1717 18.1339 22.6178 18.2335C22.5514 18.2372 22.4831 18.2391 22.4167 18.2391C20.8481 18.2188 19.5416 16.9639 19.5269 15.4027C19.5269 15.364 19.5269 15.3252 19.5269 15.2865C19.5804 13.6515 20.9035 12.3394 22.4555 12.3819H22.4573Z" fill="#A03C1F" />\
      <path d="M22.4589 12.3838C24.0754 12.4281 25.5425 13.4744 25.5425 15.0928C25.5425 16.7112 24.1751 18.134 22.6213 18.2336C22.5548 18.2373 22.4866 18.2392 22.4201 18.2392C20.8516 18.2189 19.5432 16.9659 19.5303 15.4047C19.5303 15.3659 19.5303 15.3272 19.5303 15.2884C19.5838 13.6516 20.9069 12.3414 22.4589 12.3838Z" fill="#A13C1F" />\
      <path d="M22.4608 12.3875C24.0774 12.4318 25.5444 13.4781 25.5444 15.0946C25.5444 16.7112 24.177 18.1358 22.6232 18.2373C22.5568 18.241 22.4885 18.2428 22.4221 18.2428C20.8535 18.2225 19.5451 16.9695 19.5322 15.4083C19.5322 15.3696 19.5322 15.3308 19.5322 15.2921C19.5857 13.6552 20.9089 12.345 22.459 12.3875H22.4608Z" fill="#A23C1F" />\
      <path d="M22.4608 12.3893C24.0774 12.4336 25.5444 13.478 25.5444 15.0946C25.5444 16.7111 24.177 18.1376 22.6251 18.2372C22.5586 18.2409 22.4904 18.2428 22.4239 18.2428C20.8554 18.2243 19.547 16.9695 19.5322 15.4101C19.5322 15.3714 19.5322 15.3326 19.5322 15.2939C19.5857 13.657 20.9089 12.345 22.459 12.3893H22.4608Z" fill="#A33D20" />\
      <path d="M22.4629 12.3929C24.0794 12.4391 25.5483 13.4799 25.5483 15.0964C25.5483 16.7129 24.1809 18.1394 22.629 18.2409C22.5625 18.2446 22.4943 18.2464 22.4278 18.2464C20.8593 18.228 19.5491 16.975 19.5361 15.4138C19.5361 15.3751 19.5361 15.3363 19.5361 15.2975C19.5896 13.6607 20.9128 12.3486 22.4629 12.3929Z" fill="#A43D20" />\
      <path d="M22.4647 12.395C24.0813 12.4412 25.5502 13.4801 25.5502 15.0966C25.5502 16.7132 24.1828 18.1397 22.6308 18.243C22.5644 18.2467 22.4961 18.2485 22.4297 18.2485C20.8611 18.2301 19.5509 16.9771 19.5361 15.4177C19.5361 15.379 19.5361 15.3402 19.5361 15.3015C19.5878 13.6646 20.9128 12.3526 22.4629 12.3969L22.4647 12.395Z" fill="#A53E20" />\
      <path d="M22.4662 12.3989C24.0827 12.4451 25.5517 13.4822 25.5517 15.0987C25.5517 16.7152 24.1842 18.1417 22.6323 18.2451C22.5659 18.2488 22.4976 18.2506 22.4311 18.2506C20.8626 18.2321 19.5524 16.981 19.5376 15.4198C19.5376 15.381 19.5376 15.3423 19.5376 15.3035C19.5893 13.6667 20.9142 12.3528 22.4644 12.3971L22.4662 12.3989Z" fill="#A63E20" />\
      <path d="M22.4683 12.4002C24.083 12.4463 25.5556 13.4815 25.5556 15.0981C25.5556 16.7146 24.1881 18.1429 22.6362 18.2463C22.5698 18.25 22.5015 18.2537 22.435 18.2518C20.8665 18.2352 19.5544 16.9822 19.5415 15.421C19.5415 15.3823 19.5415 15.3435 19.5415 15.3048C19.5932 13.6661 20.9181 12.354 22.4683 12.3983V12.4002Z" fill="#A73E20" />\
      <path d="M22.4702 12.4041C24.0849 12.4502 25.5575 13.4854 25.5575 15.1001C25.5575 16.7148 24.1901 18.145 22.64 18.2483C22.5736 18.252 22.5053 18.2557 22.4388 18.2539C20.8703 18.2373 19.5582 16.9843 19.5435 15.4249C19.5435 15.3862 19.5435 15.3474 19.5435 15.3087C19.5951 13.67 20.9201 12.3579 22.4702 12.4022V12.4041Z" fill="#A83F21" />\
      <path d="M22.4719 12.4061C24.0866 12.4523 25.5592 13.4857 25.5592 15.1022C25.5592 16.7187 24.19 18.1471 22.6417 18.2522C22.5753 18.2559 22.507 18.2596 22.4406 18.2578C20.872 18.2412 19.5599 16.99 19.5452 15.4288C19.5452 15.3901 19.5452 15.3513 19.5452 15.3126C19.5968 13.6739 20.9218 12.3618 22.4701 12.4061H22.4719Z" fill="#A93F21" />\
      <path d="M22.4737 12.4096C24.0884 12.4576 25.5629 13.4873 25.5629 15.1039C25.5629 16.7204 24.1936 18.1487 22.6454 18.2539C22.5789 18.2576 22.5106 18.2613 22.4442 18.2594C20.8756 18.2428 19.5617 16.9917 19.5488 15.4323C19.5488 15.3936 19.5488 15.3548 19.5488 15.3161C19.6005 13.6774 20.9255 12.3635 22.4737 12.4096Z" fill="#AA3F21" />\
      <path d="M22.4756 12.4116C24.0903 12.4596 25.5647 13.4874 25.5647 15.104C25.5647 16.7205 24.1955 18.1507 22.6472 18.2559C22.5808 18.2614 22.5125 18.2632 22.4461 18.2614C20.8775 18.2466 19.5636 16.9955 19.5488 15.4343C19.5488 15.3955 19.5488 15.3568 19.5488 15.318C19.6005 13.6793 20.9255 12.3654 22.4737 12.4116H22.4756Z" fill="#AB4021" />\
      <path d="M22.4774 12.4133C24.0921 12.4613 25.5684 13.4873 25.5684 15.1039C25.5684 16.7204 24.1991 18.1506 22.6509 18.2558C22.5844 18.2613 22.5162 18.2632 22.4497 18.2613C20.8812 18.2465 19.5673 16.9954 19.5525 15.436C19.5525 15.3973 19.5525 15.3585 19.5525 15.3198C19.6042 13.6811 20.9291 12.3672 22.4774 12.4115V12.4133Z" fill="#AC4021" />\
      <path d="M22.4792 12.4171C24.0939 12.4651 25.5702 13.4911 25.5702 15.1058C25.5702 16.7205 24.201 18.1525 22.6527 18.2595C22.5863 18.2651 22.518 18.2669 22.4516 18.2651C20.883 18.2503 19.5673 16.9991 19.5525 15.4398C19.5525 15.401 19.5525 15.3623 19.5525 15.3235C19.6042 13.683 20.9291 12.3691 22.4774 12.4152L22.4792 12.4171Z" fill="#AD4122" />\
      <path d="M22.4813 12.4189C24.096 12.4669 25.5723 13.4911 25.5723 15.1058C25.5723 16.7205 24.203 18.1525 22.6566 18.2613C22.5902 18.2669 22.5219 18.2687 22.4555 18.2669C20.8869 18.254 19.5712 17.0028 19.5564 15.4435C19.5564 15.4047 19.5564 15.366 19.5564 15.3272C19.6081 13.6867 20.933 12.3728 22.4813 12.4189Z" fill="#AE4122" />\
      <path d="M22.4831 12.4227C24.0978 12.4707 25.576 13.493 25.576 15.1077C25.576 16.7224 24.2067 18.1544 22.6603 18.2633C22.5938 18.2688 22.5256 18.2707 22.4591 18.2688C20.8887 18.2559 19.5748 17.0048 19.5601 15.4454C19.5601 15.4067 19.5601 15.3679 19.5601 15.3292C19.6117 13.6886 20.9367 12.3747 22.4831 12.4209V12.4227Z" fill="#AF4122" />\
      <path d="M22.485 12.4247C24.0997 12.4727 25.5778 13.4932 25.5778 15.1079C25.5778 16.7226 24.2085 18.1565 22.6621 18.2653C22.5957 18.2709 22.5274 18.2727 22.461 18.2709C20.8906 18.258 19.5748 17.0068 19.5601 15.4475C19.5601 15.4087 19.5601 15.37 19.5601 15.3312C19.6117 13.6907 20.9367 12.3749 22.4831 12.4229L22.485 12.4247Z" fill="#B04222" />\
      <path d="M22.4864 12.428C24.1011 12.4779 25.5793 13.4947 25.5793 15.1094C25.5793 16.7241 24.2082 18.1579 22.6636 18.2668C22.5972 18.2723 22.5289 18.2742 22.4624 18.2742C20.892 18.2612 19.5763 17.0119 19.5615 15.4526C19.5615 15.4138 19.5615 15.3751 19.5615 15.3363C19.6132 13.6958 20.9382 12.38 22.4846 12.428H22.4864Z" fill="#B14222" />\
      <path d="M22.4887 12.4301C24.1016 12.48 25.5834 13.4968 25.5834 15.1115C25.5834 16.7262 24.2123 18.16 22.6677 18.2707C22.6013 18.2763 22.533 18.2781 22.4666 18.2781C20.8962 18.267 19.5804 17.0159 19.5657 15.4565C19.5657 15.4178 19.5657 15.379 19.5657 15.3403C19.6173 13.6997 20.9423 12.384 22.4887 12.4301Z" fill="#B24223" />\
      <path d="M22.4904 12.4336C24.1033 12.4835 25.5851 13.4984 25.5851 15.1131C25.5851 16.7278 24.214 18.1617 22.6713 18.2724C22.6049 18.2779 22.5366 18.2798 22.4701 18.2798C20.8997 18.2687 19.5821 17.0194 19.5674 15.4601C19.5674 15.4213 19.5674 15.3825 19.5674 15.3438C19.6191 13.7014 20.944 12.3857 22.4904 12.4336Z" fill="#B34323" />\
      <path d="M22.4904 12.4354C24.1033 12.4852 25.5851 13.4983 25.5851 15.113C25.5851 16.7277 24.214 18.1634 22.6713 18.2741C22.6049 18.2796 22.5366 18.2815 22.4701 18.2815C20.8997 18.2704 19.5821 17.0211 19.5674 15.4618C19.5674 15.423 19.5674 15.3843 19.5674 15.3455C19.6191 13.7031 20.944 12.3874 22.4904 12.4354Z" fill="#B44323" />\
      <path d="M22.4924 12.4393C24.1053 12.4891 25.5889 13.5003 25.5889 15.115C25.5889 16.7297 24.2178 18.1654 22.6751 18.2762C22.6087 18.2817 22.5404 18.2835 22.4739 18.2835C20.9035 18.2743 19.5859 17.0232 19.5693 15.4657C19.5693 15.4269 19.5693 15.3882 19.5693 15.3494C19.621 13.707 20.946 12.3913 22.4924 12.4393Z" fill="#B54423" />\
      <path d="M22.4941 12.4409C24.107 12.4907 25.5906 13.5002 25.5906 15.1149C25.5906 16.7296 24.2195 18.1653 22.6768 18.2778C22.6104 18.2834 22.5421 18.2852 22.4757 18.2852C20.9052 18.276 19.5858 17.0267 19.571 15.4673C19.571 15.4286 19.571 15.3898 19.571 15.3529C19.6227 13.7105 20.9477 12.3948 22.4923 12.4428L22.4941 12.4409Z" fill="#B64423" />\
      <path d="M22.4958 12.4429C24.1087 12.4927 25.5924 13.5021 25.5924 15.115C25.5924 16.7278 24.2212 18.1654 22.6785 18.2798C22.6121 18.2853 22.5438 18.2872 22.4774 18.2872C20.907 18.2779 19.5875 17.0286 19.5728 15.4711C19.5728 15.4324 19.5728 15.3936 19.5728 15.3567C19.6244 13.7143 20.9494 12.3967 22.494 12.4466L22.4958 12.4429Z" fill="#B74424" />\
      <path d="M22.4978 12.4466C24.1106 12.4983 25.5961 13.504 25.5961 15.1168C25.5961 16.7297 24.225 18.1672 22.6823 18.2816C22.6159 18.2872 22.5476 18.289 22.4812 18.289C20.9108 18.2798 19.5913 17.0323 19.5747 15.473C19.5747 15.4342 19.5747 15.3955 19.5747 15.3586C19.6264 13.7162 20.9514 12.3986 22.4959 12.4466H22.4978Z" fill="#B84524" />\
      <path d="M22.4998 12.4485C24.1127 12.5002 25.5982 13.5041 25.5982 15.1169C25.5982 16.7298 24.2271 18.1692 22.6862 18.2836C22.6198 18.2891 22.5515 18.291 22.4851 18.291C20.9147 18.2836 19.5934 17.0343 19.5786 15.4749C19.5786 15.4362 19.5786 15.3974 19.5786 15.3605C19.6303 13.7163 20.9553 12.4006 22.4998 12.4485Z" fill="#B94524" />\
      <path d="M22.5017 12.4521C24.1145 12.5037 25.6019 13.5058 25.6019 15.1186C25.6019 16.7315 24.2289 18.1709 22.6899 18.2853C22.6234 18.2908 22.5552 18.2926 22.4887 18.2926C20.9183 18.2853 19.597 17.0359 19.5823 15.4785C19.5823 15.4397 19.5823 15.401 19.5823 15.364C19.6339 13.7198 20.9589 12.4022 22.5035 12.4521H22.5017Z" fill="#BA4524" />\
      <path d="M22.5035 12.454C24.1163 12.5057 25.6037 13.5059 25.6037 15.1187C25.6037 16.7316 24.2308 18.171 22.6917 18.2872C22.6253 18.2928 22.557 18.2946 22.4906 18.2946C20.9202 18.2872 19.5989 17.0397 19.5823 15.4804C19.5823 15.4417 19.5823 15.4029 19.5823 15.366C19.6339 13.7218 20.9589 12.4042 22.5035 12.454Z" fill="#BB4624" />\
      <path d="M22.5052 12.4575C24.1181 12.5092 25.6054 13.5075 25.6054 15.1222C25.6054 16.7369 24.2325 18.1745 22.6934 18.2907C22.627 18.2963 22.5587 18.2981 22.4923 18.2981C20.9219 18.2907 19.5987 17.0433 19.584 15.4858C19.584 15.447 19.584 15.4083 19.584 15.3714C19.6357 13.7271 20.9606 12.4095 22.5052 12.4594V12.4575Z" fill="#BC4624" />\
      <path d="M22.5069 12.4595C24.1179 12.5111 25.609 13.5095 25.609 15.1223C25.609 16.7352 24.236 18.1764 22.697 18.2927C22.6306 18.2982 22.5623 18.3001 22.4958 18.3001C20.9254 18.2945 19.6023 17.0471 19.5857 15.4877C19.5857 15.449 19.5857 15.4102 19.5857 15.3733C19.6374 13.7291 20.9623 12.4096 22.5051 12.4613L22.5069 12.4595Z" fill="#BD4725" />\
      <path d="M22.5087 12.4634C24.1197 12.5169 25.6108 13.5115 25.6108 15.1244C25.6108 16.7373 24.2378 18.1785 22.7006 18.2966C22.6342 18.3021 22.5659 18.304 22.4995 18.304C20.9291 18.2984 19.606 17.051 19.5894 15.4935C19.5894 15.4547 19.5894 15.416 19.5894 15.3791C19.641 13.7348 20.966 12.4154 22.5087 12.4652V12.4634Z" fill="#BE4725" />\
      <path d="M22.5107 12.4652C24.1217 12.5188 25.6128 13.5116 25.6128 15.1244C25.6128 16.7373 24.2398 18.1785 22.7026 18.2966C22.6362 18.3021 22.5679 18.304 22.5015 18.304C20.931 18.2984 19.6061 17.051 19.5913 15.4935C19.5913 15.4547 19.5913 15.4178 19.5913 15.3791C19.643 13.733 20.968 12.4154 22.5107 12.4652Z" fill="#BF4725" />\
      <path d="M22.5129 12.4688C24.1239 12.5223 25.6168 13.5132 25.6168 15.1261C25.6168 16.7389 24.2438 18.1802 22.7066 18.3001C22.6402 18.3057 22.5719 18.3075 22.5055 18.3075C20.9351 18.3038 19.6101 17.0563 19.5935 15.4988C19.5935 15.4601 19.5935 15.4232 19.5935 15.3844C19.6452 13.7384 20.9702 12.4189 22.5129 12.4706V12.4688Z" fill="#C04825" />\
      <path d="M22.5146 12.4707C24.1256 12.5242 25.6185 13.5133 25.6185 15.1262C25.6185 16.739 24.2455 18.1803 22.7084 18.3002C22.6419 18.3058 22.5736 18.3076 22.5072 18.3076C20.935 18.3039 19.6118 17.0564 19.5952 15.499C19.5952 15.4602 19.5952 15.4233 19.5952 15.3845C19.6469 13.7385 20.9719 12.419 22.5146 12.4707Z" fill="#C14825" />\
      <path d="M22.5164 12.4727C24.1274 12.5262 25.6203 13.5153 25.6203 15.1263C25.6203 16.7373 24.2455 18.1822 22.7102 18.3022C22.6438 18.3077 22.5755 18.3096 22.5091 18.3096C20.9368 18.3059 19.6118 17.0602 19.5952 15.5028C19.5952 15.464 19.5952 15.4271 19.5952 15.3883C19.645 13.7423 20.9719 12.4228 22.5146 12.4745L22.5164 12.4727Z" fill="#C24826" />\
      <path d="M22.518 12.4761C24.129 12.5296 25.6238 13.5169 25.6238 15.1279C25.6238 16.7389 24.249 18.1838 22.7136 18.3038C22.6472 18.3093 22.5789 18.3111 22.5125 18.3111C20.9402 18.3074 19.6152 17.0618 19.5986 15.5043C19.5986 15.4656 19.5986 15.4287 19.5986 15.3899C19.6485 13.7438 20.9753 12.4244 22.5162 12.4761H22.518Z" fill="#C34926" />\
      <path d="M22.52 12.4779C24.131 12.5314 25.6257 13.5169 25.6257 15.1279C25.6257 16.7389 24.2509 18.1838 22.7174 18.3056C22.651 18.3111 22.5827 18.313 22.5144 18.313C20.9422 18.3111 19.6172 17.0637 19.6006 15.5062C19.6006 15.4674 19.6006 15.4305 19.6006 15.3918C19.6504 13.7457 20.9772 12.4244 22.5181 12.4761L22.52 12.4779Z" fill="#C44926" />\
      <path d="M22.52 12.4816C24.131 12.5369 25.6276 13.5187 25.6276 15.1297C25.6276 16.7407 24.2528 18.1856 22.7193 18.3074C22.6528 18.313 22.5845 18.3148 22.5163 18.3148C20.944 18.313 19.6172 17.0673 19.6006 15.5098C19.6006 15.4711 19.6006 15.4342 19.6006 15.3954C19.6504 13.7475 20.9772 12.4281 22.5181 12.4797L22.52 12.4816Z" fill="#C54A26" />\
      <path d="M22.5219 12.4836C24.1329 12.539 25.6295 13.5189 25.6295 15.1318C25.6295 16.7446 24.2547 18.1895 22.7212 18.3113C22.6548 18.3169 22.5865 18.3187 22.5182 18.3187C20.946 18.3187 19.6191 17.0712 19.6025 15.5137C19.6025 15.475 19.6025 15.4381 19.6025 15.3993C19.6524 13.7514 20.9792 12.432 22.5201 12.4836H22.5219Z" fill="#C64A26" />\
      <path d="M22.5236 12.4871C24.1346 12.5424 25.6312 13.5223 25.6312 15.1333C25.6312 16.7443 24.2564 18.1911 22.7229 18.3147C22.6565 18.3203 22.5882 18.3221 22.5199 18.3221C20.9477 18.3221 19.6209 17.0765 19.6042 15.519C19.6042 15.4803 19.6042 15.4433 19.6042 15.4046C19.6541 13.7567 20.9809 12.4354 22.5218 12.4889L22.5236 12.4871Z" fill="#C74A27" />\
      <path d="M22.5253 12.4889C24.1363 12.5442 25.6348 13.5223 25.6348 15.1333C25.6348 16.7443 24.26 18.1911 22.7265 18.3147C22.66 18.3202 22.5918 18.3221 22.5235 18.3221C20.9512 18.3221 19.6226 17.0765 19.606 15.519C19.606 15.4802 19.606 15.4433 19.606 15.4046C19.6558 13.7566 20.9826 12.4354 22.5235 12.4889H22.5253Z" fill="#C84B27" />\
      <path d="M25.6369 15.1351C25.6369 16.7461 24.1384 18.3258 22.5274 18.3258C20.9164 18.3258 19.5619 17.0192 19.6099 15.4082C19.6597 13.7603 20.9884 12.439 22.5274 12.4925C24.1366 12.5479 25.6369 13.5241 25.6369 15.1351Z" fill="#C94B27" />\
      <path d="M22.5294 12.4945C24.1385 12.5499 25.6388 13.5279 25.6388 15.1389C25.6388 16.7499 24.1404 18.3277 22.5294 18.3277C20.9184 18.3277 19.5639 17.0212 19.6118 15.4102C19.6617 13.7623 20.9885 12.441 22.5294 12.4945Z" fill="#C94C28" />\
      <path opacity="0.99" d="M22.5311 12.4964C24.1402 12.5518 25.6387 13.5299 25.6387 15.1409C25.6387 16.7519 24.1421 18.3297 22.5311 18.3297C20.9201 18.3297 19.5656 17.0231 19.6135 15.4121C19.6634 13.7642 20.9902 12.4429 22.5311 12.4964Z" fill="#CA4D29" />\
      <path opacity="0.99" d="M22.5311 12.4982C24.1402 12.5535 25.6387 13.5334 25.6387 15.1444C25.6387 16.7554 24.1421 18.3314 22.5311 18.3314C20.9201 18.3314 19.5656 17.0248 19.6135 15.4138C19.6634 13.7659 20.9902 12.4446 22.5311 12.4982Z" fill="#CA4E2A" />\
      <path opacity="0.98" d="M22.5325 12.5001C24.1417 12.5555 25.6383 13.5372 25.6383 15.1482C25.6383 16.7592 24.1435 18.3333 22.5325 18.3333C20.9215 18.3333 19.567 17.0268 19.615 15.4158C19.6648 13.7679 20.9917 12.4466 22.5325 12.5001Z" fill="#CA4E2B" />\
      <path opacity="0.98" d="M22.5347 12.5018C24.1439 12.5553 25.6405 13.5389 25.6405 15.1499C25.6405 16.7609 24.1457 18.335 22.5347 18.335C20.9237 18.335 19.5692 17.0285 19.6172 15.4175C19.667 13.7696 20.9939 12.4483 22.5347 12.5018Z" fill="#CA4F2C" />\
      <path opacity="0.97" d="M22.5349 12.5038C24.1441 12.5573 25.6388 13.5427 25.6388 15.1537C25.6388 16.7647 24.1459 18.337 22.5349 18.337C20.9239 18.337 19.5712 17.0305 19.6174 15.4195C19.6653 13.7715 20.9922 12.4503 22.5349 12.5038Z" fill="#CB502D" />\
      <path opacity="0.97" d="M22.5366 12.5056C24.1458 12.5591 25.6405 13.5464 25.6405 15.1574C25.6405 16.7684 24.1476 18.3388 22.5366 18.3388C20.9256 18.3388 19.5729 17.0323 19.6191 15.4213C19.667 13.7734 20.9939 12.4539 22.5366 12.5056Z" fill="#CB512E" />\
      <path opacity="0.96" d="M22.5385 12.5076C24.1477 12.5611 25.6406 13.5484 25.6406 15.1594C25.6406 16.7704 24.1496 18.3408 22.5385 18.3408C20.9275 18.3408 19.5749 17.0343 19.621 15.4233C19.669 13.7772 20.9958 12.4559 22.5385 12.5076Z" fill="#CB522F" />\
      <path opacity="0.96" d="M22.5385 12.5093C24.1477 12.5628 25.6406 13.5519 25.6406 15.1629C25.6406 16.7739 24.1496 18.3425 22.5385 18.3425C20.9275 18.3425 19.5749 17.036 19.621 15.425C19.669 13.7789 20.9958 12.4576 22.5385 12.5093Z" fill="#CB5330" />\
      <path opacity="0.95" d="M22.54 12.511C24.1492 12.5645 25.6402 13.5555 25.6402 15.1665C25.6402 16.7775 24.151 18.3442 22.54 18.3442C20.929 18.3442 19.5763 17.0377 19.6225 15.4267C19.6705 13.7806 20.9973 12.4593 22.54 12.511Z" fill="#CC5331" />\
      <path opacity="0.95" d="M22.542 12.5132C24.1511 12.5667 25.6422 13.5595 25.6422 15.1687C25.6422 16.7778 24.153 18.3464 22.542 18.3464C20.931 18.3464 19.5783 17.0399 19.6244 15.4289C19.6724 13.7828 20.9992 12.4615 22.542 12.5132Z" fill="#CC5432" />\
      <path opacity="0.94" d="M22.542 12.5149C24.1511 12.5684 25.6403 13.5612 25.6403 15.1722C25.6403 16.7832 24.153 18.3481 22.542 18.3481C20.931 18.3481 19.5783 17.0416 19.6244 15.4306C19.6724 13.7845 20.9974 12.4632 22.542 12.5149Z" fill="#CC5533" />\
      <path opacity="0.94" d="M22.5437 12.5168C24.1528 12.5685 25.6421 13.565 25.6421 15.176C25.6421 16.787 24.1547 18.3501 22.5437 18.3501C20.9327 18.3501 19.58 17.0435 19.6261 15.4325C19.6741 13.7865 20.9991 12.4652 22.5437 12.5168Z" fill="#CC5634" />\
      <path opacity="0.94" d="M22.5458 12.5186C24.1549 12.5702 25.6423 13.5686 25.6423 15.1796C25.6423 16.7906 24.1568 18.3536 22.5458 18.3536C20.9348 18.3536 19.584 17.0471 19.6283 15.4361C19.6744 13.79 21.0012 12.4706 22.5458 12.5204V12.5186Z" fill="#CD5735" />\
      <path opacity="0.93" d="M22.5477 12.5202C24.1569 12.5718 25.6443 13.5702 25.6443 15.1812C25.6443 16.7922 24.1587 18.3534 22.5477 18.3534C20.9367 18.3534 19.5859 17.0469 19.6302 15.4358C19.6763 13.7898 21.0032 12.4703 22.5477 12.5202Z" fill="#CD5836" />\
      <path opacity="0.93" d="M22.5477 12.5221C24.1569 12.5738 25.6424 13.574 25.6424 15.185C25.6424 16.796 24.1587 18.3553 22.5477 18.3553C20.9367 18.3553 19.5859 17.0488 19.6302 15.4378C19.6763 13.7917 21.0032 12.4723 22.5477 12.5221Z" fill="#CD5837" />\
      <path opacity="0.92" d="M22.5492 12.5243C24.1584 12.576 25.6439 13.578 25.6439 15.189C25.6439 16.8 24.1602 18.3575 22.5492 18.3575C20.9382 18.3575 19.5874 17.051 19.6317 15.44C19.6778 13.7939 21.0028 12.4745 22.5492 12.5243Z" fill="#CD5938" />\
      <path opacity="0.92" d="M22.5512 12.526C24.1603 12.5777 25.644 13.5797 25.644 15.1907C25.644 16.8017 24.1622 18.3592 22.5512 18.3592C20.9401 18.3592 19.5893 17.0527 19.6336 15.4417C19.6798 13.7956 21.0047 12.4762 22.5512 12.526Z" fill="#CE5A39" />\
      <path opacity="0.91" d="M22.5512 12.5275C24.1603 12.5792 25.644 13.583 25.644 15.194C25.644 16.8051 24.1622 18.3607 22.5512 18.3607C20.9401 18.3607 19.5893 17.0542 19.6336 15.4432C19.6798 13.7989 21.0047 12.4777 22.5512 12.5275Z" fill="#CE5B3A" />\
      <path opacity="0.91" d="M22.5531 12.5294C24.1623 12.5793 25.6441 13.5868 25.6441 15.1978C25.6441 16.8089 24.1641 18.3627 22.5531 18.3627C20.9421 18.3627 19.5913 17.0561 19.6356 15.4451C19.6817 13.8009 21.0067 12.4796 22.5531 12.5294Z" fill="#CE5C3B" />\
      <path opacity="0.9" d="M22.5548 12.5314C24.164 12.5812 25.6458 13.5888 25.6458 15.1998C25.6458 16.8108 24.1658 18.3646 22.5548 18.3646C20.9438 18.3646 19.593 17.0581 19.6373 15.4471C19.6834 13.8029 21.0084 12.4816 22.5548 12.5314Z" fill="#CE5D3C" />\
      <path opacity="0.9" d="M22.555 12.5333C24.1641 12.5831 25.6441 13.5925 25.6441 15.2035C25.6441 16.8145 24.166 18.3665 22.555 18.3665C20.944 18.3665 19.595 17.0599 19.6374 15.4489C19.6817 13.8047 21.0086 12.4853 22.555 12.5333Z" fill="#CF5D3D" />\
      <path opacity="0.89" d="M22.5569 12.5352C24.1661 12.585 25.6461 13.5963 25.6461 15.2073C25.6461 16.8183 24.1679 18.3684 22.5569 18.3684C20.9459 18.3684 19.597 17.0619 19.6394 15.4509C19.6837 13.8067 21.0087 12.4872 22.5569 12.5352Z" fill="#CF5E3E" />\
      <path opacity="0.89" d="M22.5586 12.5369C24.1678 12.5867 25.6459 13.598 25.6459 15.209C25.6459 16.82 24.1696 18.3701 22.5586 18.3701C20.9476 18.3701 19.5987 17.0636 19.6411 15.4526C19.6854 13.8084 21.0104 12.4889 22.5586 12.5369Z" fill="#CF5F3F" />\
      <path opacity="0.88" d="M22.5586 12.5386C24.1678 12.5884 25.6459 13.6016 25.6459 15.2126C25.6459 16.8236 24.1696 18.3718 22.5586 18.3718C20.9476 18.3718 19.5987 17.0653 19.6411 15.4543C19.6854 13.8101 21.0104 12.4906 22.5586 12.5386Z" fill="#CF6040" />\
      <path opacity="0.88" d="M22.5604 12.5408C24.1695 12.5906 25.6458 13.6056 25.6458 15.2166C25.6458 16.8276 24.1714 18.374 22.5604 18.374C20.9493 18.374 19.6004 17.0675 19.6428 15.4565C19.6871 13.8123 21.0121 12.4928 22.5604 12.5408Z" fill="#D06141" />\
      <path opacity="0.87" d="M22.5623 12.5425C24.1715 12.5905 25.6478 13.6092 25.6478 15.2183C25.6478 16.8275 24.1733 18.3757 22.5623 18.3757C20.9513 18.3757 19.6023 17.0692 19.6448 15.4582C19.6891 13.814 21.014 12.4945 22.5623 12.5425Z" fill="#D06242" />\
      <path opacity="0.87" d="M22.5623 12.5462C24.1715 12.5942 25.6459 13.6128 25.6459 15.2238C25.6459 16.8348 24.1715 18.3794 22.5623 18.3794C20.9531 18.3794 19.6023 17.0729 19.6448 15.4619C19.6891 13.8177 21.0122 12.4982 22.5623 12.5462Z" fill="#D06243" />\
      <path opacity="0.87" d="M22.5643 12.548C24.1734 12.596 25.6479 13.6165 25.6479 15.2275C25.6479 16.8385 24.1753 18.3813 22.5643 18.3813C20.9532 18.3813 19.6043 17.0747 19.6467 15.4637C19.691 13.8214 21.0141 12.5019 22.5643 12.548Z" fill="#D06344" />\
      <path opacity="0.86" d="M22.5659 12.55C24.1769 12.598 25.6477 13.6204 25.6477 15.2314C25.6477 16.8424 24.175 18.3851 22.5659 18.3851C20.9567 18.3851 19.6078 17.0786 19.6484 15.4676C19.6908 13.8252 21.0158 12.5058 22.5659 12.5519V12.55Z" fill="#D16445" />\
      <path opacity="0.86" d="M22.5681 12.552C24.1791 12.5999 25.6498 13.6223 25.6498 15.2333C25.6498 16.8443 24.1791 18.3852 22.5681 18.3852C20.9571 18.3852 19.61 17.0786 19.6506 15.4676C19.693 13.8253 21.018 12.5058 22.5681 12.552Z" fill="#D16546" />\
      <path opacity="0.85" d="M22.5681 12.5534C24.1791 12.6014 25.6498 13.6256 25.6498 15.2366C25.6498 16.8476 24.1791 18.3866 22.5681 18.3866C20.9571 18.3866 19.61 17.0801 19.6506 15.4691C19.693 13.8267 21.018 12.5073 22.5681 12.5534Z" fill="#D16647" />\
      <path opacity="0.85" d="M22.5698 12.5556C24.1808 12.6036 25.6497 13.6296 25.6497 15.2406C25.6497 16.8516 24.1808 18.3888 22.5698 18.3888C20.9588 18.3888 19.6117 17.0823 19.6523 15.4713C19.6947 13.8289 21.0197 12.5095 22.5698 12.5556Z" fill="#D16748" />\
      <path opacity="0.84" d="M22.5717 12.5576C24.1828 12.6037 25.6517 13.6316 25.6517 15.2426C25.6517 16.8536 24.1828 18.3908 22.5717 18.3908C20.9607 18.3908 19.6136 17.0843 19.6542 15.4733C19.6967 13.8309 21.0198 12.5114 22.5717 12.5576Z" fill="#D26749" />\
      <path opacity="0.84" d="M22.5717 12.5593C24.1828 12.6054 25.6498 13.6351 25.6498 15.2461C25.6498 16.8571 24.1828 18.3925 22.5717 18.3925C20.9607 18.3925 19.6136 17.086 19.6542 15.475C19.6967 13.8326 21.0198 12.5131 22.5717 12.5593Z" fill="#D2684A" />\
      <path opacity="0.83" d="M22.5735 12.561C24.1845 12.6071 25.6515 13.6387 25.6515 15.2497C25.6515 16.8607 24.1845 18.3942 22.5735 18.3942C20.9624 18.3942 19.6153 17.0877 19.6559 15.4767C19.6984 13.8343 21.0215 12.5149 22.5735 12.561Z" fill="#D2694B" />\
      <path opacity="0.83" d="M22.5752 12.5629C24.1862 12.609 25.6514 13.6405 25.6514 15.2516C25.6514 16.8626 24.1862 18.3961 22.5752 18.3961C20.9642 18.3961 19.617 17.0895 19.6576 15.4785C19.7001 13.8362 21.0232 12.5186 22.5752 12.5629Z" fill="#D26A4C" />\
      <path opacity="0.82" d="M22.5751 12.5646C24.1861 12.6107 25.6513 13.6441 25.6513 15.2551C25.6513 16.8661 24.1861 18.3978 22.5751 18.3978C20.9641 18.3978 19.6188 17.0913 19.6576 15.4802C19.6982 13.8379 21.0231 12.5203 22.5751 12.5646Z" fill="#D36B4D" />\
      <path opacity="0.82" d="M22.577 12.5668C24.188 12.6129 25.6514 13.6481 25.6514 15.2592C25.6514 16.8702 24.188 18.4 22.577 18.4C20.966 18.4 19.6208 17.0935 19.6595 15.4824C19.7001 13.8401 21.0232 12.5225 22.577 12.5668Z" fill="#D36C4E" />\
      <path opacity="0.81" d="M22.5792 12.5687C24.1902 12.6148 25.6536 13.6501 25.6536 15.2611C25.6536 16.8721 24.1902 18.4019 22.5792 18.4019C20.9682 18.4019 19.623 17.0954 19.6617 15.4844C19.7023 13.8439 21.0254 12.5244 22.5792 12.5687Z" fill="#D36C4F" />\
      <path opacity="0.81" d="M22.5792 12.5699C24.1902 12.6142 25.6518 13.6532 25.6518 15.2642C25.6518 16.8752 24.1902 18.4031 22.5792 18.4031C20.9682 18.4031 19.623 17.0966 19.6617 15.4856C19.7023 13.8451 21.0254 12.5256 22.5792 12.5699Z" fill="#D36D50" />\
      <path opacity="0.81" d="M22.5809 12.5721C24.192 12.6164 25.6535 13.6572 25.6535 15.2682C25.6535 16.8792 24.192 18.4053 22.5809 18.4053C20.9699 18.4053 19.6247 17.0988 19.6634 15.4878C19.704 13.8473 21.0271 12.5278 22.5809 12.5721Z" fill="#D46E51" />\
      <path opacity="0.8" d="M22.5827 12.5738C24.1937 12.6181 25.6533 13.6608 25.6533 15.2699C25.6533 16.8791 24.1937 18.4071 22.5827 18.4071C20.9716 18.4071 19.6264 17.1005 19.6651 15.4895C19.7057 13.849 21.0289 12.5295 22.5827 12.5738Z" fill="#D46F52" />\
      <path opacity="0.8" d="M22.5827 12.5757C24.1937 12.62 25.6533 13.6626 25.6533 15.2736C25.6533 16.8846 24.1937 18.4089 22.5827 18.4089C20.9716 18.4089 19.6264 17.1024 19.6651 15.4914C19.7057 13.8509 21.0289 12.5333 22.5827 12.5757Z" fill="#D47053" />\
      <path opacity="0.79" d="M22.5844 12.5777C24.1954 12.6219 25.6532 13.6664 25.6532 15.2774C25.6532 16.8884 24.1954 18.4109 22.5844 18.4109C20.9734 18.4109 19.6281 17.1043 19.6668 15.4933C19.7074 13.8528 21.0287 12.5352 22.5844 12.5777Z" fill="#D47154" />\
      <path opacity="0.79" d="M22.5862 12.5793C24.1972 12.6236 25.6551 13.67 25.6551 15.281C25.6551 16.892 24.1972 18.4144 22.5862 18.4144C20.9752 18.4144 19.6318 17.1079 19.6687 15.4969C19.7075 13.8563 21.0306 12.5387 22.5862 12.5812V12.5793Z" fill="#D57155" />\
      <path opacity="0.78" d="M22.5862 12.5811C24.1972 12.6254 25.6532 13.6717 25.6532 15.2827C25.6532 16.8937 24.1972 18.4143 22.5862 18.4143C20.9752 18.4143 19.6318 17.1078 19.6687 15.4968C19.7075 13.8562 21.0306 12.5386 22.5862 12.5811Z" fill="#D57256" />\
      <path opacity="0.78" d="M22.5884 12.5833C24.1994 12.6257 25.6554 13.6757 25.6554 15.2867C25.6554 16.8977 24.1994 18.4165 22.5884 18.4165C20.9774 18.4165 19.634 17.11 19.6709 15.499C19.7097 13.8584 21.0328 12.5408 22.5884 12.5833Z" fill="#D57357" />\
      <path opacity="0.77" d="M22.5901 12.585C24.2012 12.6274 25.6553 13.6793 25.6553 15.2903C25.6553 16.9013 24.2012 18.4182 22.5901 18.4182C20.9791 18.4182 19.6357 17.1117 19.6726 15.5007C19.7114 13.8601 21.0345 12.5425 22.5901 12.585Z" fill="#D57458" />\
      <path opacity="0.77" d="M22.5916 12.5867C24.2026 12.6291 25.6568 13.681 25.6568 15.292C25.6568 16.903 24.2026 18.4199 22.5916 18.4199C20.9806 18.4199 19.6372 17.1134 19.6741 15.5024C19.7128 13.8618 21.036 12.5442 22.5916 12.5867Z" fill="#D67559" />\
      <path opacity="0.76" d="M22.5916 12.5886C24.2026 12.6311 25.6549 13.6848 25.6549 15.2958C25.6549 16.9068 24.2026 18.4219 22.5916 18.4219C20.9806 18.4219 19.6372 17.1153 19.6741 15.5043C19.7128 13.8656 21.0341 12.5462 22.5916 12.5886Z" fill="#D6765A" />\
      <path opacity="0.76" d="M22.5938 12.5905C24.2048 12.633 25.6571 13.6885 25.6571 15.2995C25.6571 16.9105 24.2048 18.4237 22.5938 18.4237C20.9828 18.4237 19.6394 17.1172 19.6763 15.5062C19.715 13.8675 21.0363 12.5499 22.5938 12.5905Z" fill="#D6765B" />\
      <path opacity="0.75" d="M22.5955 12.5922C24.2065 12.6347 25.657 13.6902 25.657 15.3012C25.657 16.9122 24.2065 18.4254 22.5955 18.4254C20.9845 18.4254 19.6411 17.1189 19.678 15.5079C19.7167 13.8692 21.038 12.5516 22.5955 12.5922Z" fill="#D6775C" />\
      <path opacity="0.75" d="M22.5955 12.5944C24.2065 12.6369 25.657 13.6943 25.657 15.3053C25.657 16.9163 24.2065 18.4276 22.5955 18.4276C20.9845 18.4276 19.6411 17.1211 19.678 15.5101C19.7149 13.8714 21.038 12.5538 22.5955 12.5944Z" fill="#D7785D" />\
      <path opacity="0.75" d="M22.5974 12.5959C24.2084 12.6383 25.657 13.6976 25.657 15.3086C25.657 16.9196 24.2084 18.4291 22.5974 18.4291C20.9864 18.4291 19.6448 17.1226 19.6799 15.5116C19.7168 13.8729 21.0399 12.5553 22.5974 12.5959Z" fill="#D7795E" />\
      <path opacity="0.74" d="M22.5994 12.5978C24.2104 12.6384 25.659 13.6995 25.659 15.3105C25.659 16.9215 24.2104 18.4311 22.5994 18.4311C20.9883 18.4311 19.6468 17.1245 19.6818 15.5135C19.7187 13.8748 21.04 12.5572 22.5994 12.5978Z" fill="#D77A5F" />\
      <path opacity="0.74" d="M22.5994 12.6C24.2104 12.6406 25.6571 13.7036 25.6571 15.3146C25.6571 16.9256 24.2104 18.4332 22.5994 18.4332C20.9883 18.4332 19.6468 17.1267 19.6818 15.5157C19.7187 13.877 21.04 12.5594 22.5994 12.6Z" fill="#D77B60" />\
      <path opacity="0.73" d="M22.6011 12.6017C24.2121 12.6423 25.6588 13.7071 25.6588 15.3181C25.6588 16.9291 24.2121 18.435 22.6011 18.435C20.9901 18.435 19.6485 17.1284 19.6835 15.5174C19.7204 13.8787 21.0417 12.5611 22.6011 12.6017Z" fill="#D87B61" />\
      <path opacity="0.73" d="M22.6028 12.6031C24.2138 12.6437 25.6587 13.7104 25.6587 15.3195C25.6587 16.9287 24.2119 18.4363 22.6028 18.4363C20.9936 18.4363 19.6502 17.1298 19.6852 15.5188C19.7221 13.8801 21.0434 12.5644 22.6028 12.6031Z" fill="#D87C62" />\
      <path opacity="0.72" d="M22.6028 12.6053C24.2138 12.6459 25.6587 13.7125 25.6587 15.3236C25.6587 16.9346 24.2138 18.4385 22.6028 18.4385C20.9918 18.4385 19.6502 17.132 19.6852 15.521C19.7221 13.8823 21.0434 12.5666 22.6028 12.6053Z" fill="#D87D63" />\
      <path opacity="0.72" d="M22.605 12.609C24.216 12.6496 25.6591 13.7181 25.6591 15.3291C25.6591 16.9401 24.2141 18.4422 22.605 18.4422C20.9958 18.4422 19.6524 17.1357 19.6874 15.5247C19.7243 13.8878 21.0456 12.5702 22.605 12.609Z" fill="#D87E64" />\
      <path opacity="0.71" d="M22.6067 12.6106C24.2177 12.6512 25.6608 13.7215 25.6608 15.3325C25.6608 16.9435 24.2177 18.4457 22.6067 18.4457C20.9957 18.4457 19.6541 17.1391 19.6891 15.5281C19.7242 13.8913 21.0455 12.5737 22.6067 12.6124V12.6106Z" fill="#D97F65" />\
      <path opacity="0.71" d="M22.6069 12.6124C24.2179 12.6512 25.6609 13.7233 25.6609 15.3343C25.6609 16.9453 24.2179 18.4456 22.6069 18.4456C20.9958 18.4456 19.6561 17.1391 19.6893 15.5281C19.7244 13.8912 21.0457 12.5737 22.6069 12.6124Z" fill="#D98066" />\
      <path opacity="0.7" d="M22.6083 12.6144C24.2193 12.6531 25.6606 13.7271 25.6606 15.3381C25.6606 16.9491 24.2193 18.4476 22.6083 18.4476C20.9973 18.4476 19.6576 17.1411 19.6908 15.53C19.7259 13.8932 21.0471 12.5756 22.6083 12.6144Z" fill="#D98067" />\
      <path opacity="0.7" d="M22.6105 12.6163C24.2215 12.6551 25.6628 13.7309 25.6628 15.3419C25.6628 16.9529 24.2215 18.4495 22.6105 18.4495C20.9995 18.4495 19.6598 17.143 19.693 15.532C19.728 13.8952 21.0493 12.5776 22.6105 12.6163Z" fill="#D98168" />\
      <path opacity="0.69" d="M22.6122 12.6183C24.2232 12.657 25.6626 13.7329 25.6626 15.3439C25.6626 16.9549 24.2232 18.4515 22.6122 18.4515C21.0012 18.4515 19.6615 17.145 19.6947 15.5339C19.7298 13.8971 21.051 12.5795 22.6122 12.6183Z" fill="#DA8269" />\
      <path opacity="0.69" d="M22.6122 12.6199C24.2232 12.6587 25.6626 13.7363 25.6626 15.3474C25.6626 16.9584 24.2232 18.4531 22.6122 18.4531C21.0012 18.4531 19.6615 17.1466 19.6947 15.5356C19.7298 13.8987 21.0492 12.583 22.6122 12.6199Z" fill="#DA836A" />\
      <path opacity="0.69" d="M22.6139 12.6219C24.2249 12.6606 25.6625 13.7401 25.6625 15.3512C25.6625 16.9622 24.2249 18.4551 22.6139 18.4551C21.0029 18.4551 19.6632 17.1485 19.6964 15.5375C19.7315 13.9007 21.0509 12.5849 22.6139 12.6219Z" fill="#DA846B" />\
      <path opacity="0.68" d="M22.6161 12.6236C24.2271 12.6623 25.6647 13.7419 25.6647 15.3529C25.6647 16.9639 24.2271 18.4568 22.6161 18.4568C21.0051 18.4568 19.6654 17.1503 19.6986 15.5392C19.7337 13.9024 21.0531 12.5867 22.6161 12.6236Z" fill="#DA856C" />\
      <path opacity="0.68" d="M22.6161 12.6258C24.2271 12.6627 25.6628 13.7459 25.6628 15.3569C25.6628 16.9679 24.2271 18.459 22.6161 18.459C21.0051 18.459 19.6654 17.1525 19.6986 15.5414C19.7318 13.9046 21.0531 12.5889 22.6161 12.6258Z" fill="#DB856D" />\
      <path opacity="0.67" d="M22.6175 12.6275C24.2285 12.6644 25.6642 13.7495 25.6642 15.3605C25.6642 16.9715 24.2285 18.4607 22.6175 18.4607C21.0065 18.4607 19.6686 17.1542 19.7 15.5432C19.7332 13.9063 21.0545 12.5906 22.6175 12.6275Z" fill="#DB866E" />\
      <path opacity="0.67" d="M22.6195 12.6292C24.2305 12.6661 25.6643 13.7512 25.6643 15.3622C25.6643 16.9732 24.2305 18.4624 22.6195 18.4624C21.0085 18.4624 19.6706 17.1559 19.702 15.5449C19.7352 13.9099 21.0565 12.5923 22.6195 12.6292Z" fill="#DB876F" />\
      <path opacity="0.66" d="M22.6195 12.6311C24.2305 12.668 25.6643 13.755 25.6643 15.366C25.6643 16.977 24.2305 18.4643 22.6195 18.4643C21.0085 18.4643 19.6706 17.1578 19.702 15.5468C19.7352 13.9118 21.0546 12.5942 22.6195 12.6311Z" fill="#DB8870" />\
      <path opacity="0.66" d="M22.6217 12.633C24.2327 12.6699 25.6647 13.7587 25.6647 15.3697C25.6647 16.9807 24.2327 18.4662 22.6217 18.4662C21.0107 18.4662 19.6728 17.1597 19.7042 15.5487C19.7374 13.9137 21.0568 12.5979 22.6217 12.633Z" fill="#DC8971" />\
      <path opacity="0.65" d="M22.6234 12.6347C24.2344 12.6716 25.6664 13.7622 25.6664 15.3714C25.6664 16.9806 24.2344 18.4679 22.6234 18.4679C21.0124 18.4679 19.6745 17.1614 19.7059 15.5504C19.7391 13.9154 21.0585 12.5997 22.6234 12.6347Z" fill="#DC8A72" />\
      <path opacity="0.65" d="M22.6234 12.6369C24.2344 12.6738 25.6646 13.7644 25.6646 15.3754C25.6646 16.9865 24.2344 18.4701 22.6234 18.4701C21.0124 18.4701 19.6745 17.1636 19.7059 15.5526C19.7391 13.9176 21.0585 12.6019 22.6234 12.6369Z" fill="#DC8A73" />\
      <path opacity="0.64" d="M22.6249 12.6384C24.2359 12.6734 25.666 13.7677 25.666 15.3788C25.666 16.9898 24.2359 18.4716 22.6249 18.4716C21.0138 18.4716 19.676 17.1651 19.7073 15.5541C19.7405 13.9191 21.06 12.6033 22.6249 12.6384Z" fill="#DC8B74" />\
      <path opacity="0.64" d="M22.6268 12.6403C24.2378 12.6754 25.6661 13.7715 25.6661 15.3826C25.6661 16.9936 24.2378 18.4735 22.6268 18.4735C21.0158 18.4735 19.6779 17.167 19.7093 15.556C19.7407 13.921 21.0601 12.6053 22.6268 12.6403Z" fill="#DD8C75" />\
      <path opacity="0.63" d="M22.627 12.6423C24.238 12.6773 25.6663 13.7735 25.6663 15.3845C25.6663 16.9955 24.238 18.4755 22.627 18.4755C21.016 18.4755 19.6799 17.169 19.7095 15.558C19.7408 13.923 21.0603 12.6072 22.627 12.6423Z" fill="#DD8D76" />\
      <path opacity="0.63" d="M22.6287 12.6442C24.2397 12.6793 25.6662 13.7773 25.6662 15.3883C25.6662 16.9993 24.2397 18.4775 22.6287 18.4775C21.0177 18.4775 19.6816 17.1709 19.7112 15.5599C19.7425 13.9249 21.062 12.6092 22.6287 12.6442Z" fill="#DD8E77" />\
      <path opacity="0.62" d="M22.6307 12.6457C24.2417 12.6808 25.6681 13.7806 25.6681 15.3916C25.6681 17.0026 24.2417 18.4789 22.6307 18.4789C21.0196 18.4789 19.6836 17.1724 19.7131 15.5614C19.7445 13.9264 21.0639 12.6106 22.6307 12.6457Z" fill="#DD8F78" />\
      <path opacity="0.62" d="M22.6329 12.6478C24.2439 12.6829 25.6685 13.7827 25.6685 15.3937C25.6685 17.0048 24.2439 18.481 22.6329 18.481C21.0218 18.481 19.6858 17.1745 19.7153 15.5635C19.7467 13.9304 21.0661 12.6146 22.6329 12.6478Z" fill="#DE8F79" />\
      <path opacity="0.62" d="M22.6329 12.6495C24.2439 12.6846 25.6685 13.7863 25.6685 15.3973C25.6685 17.0083 24.2439 18.4828 22.6329 18.4828C21.0218 18.4828 19.6858 17.1762 19.7153 15.5652C19.7467 13.9321 21.0661 12.6163 22.6329 12.6495Z" fill="#DE907A" />\
      <path opacity="0.61" d="M22.6341 12.6515C24.2451 12.6847 25.6679 13.7901 25.6679 15.4011C25.6679 17.0121 24.2451 18.4847 22.6341 18.4847C21.0231 18.4847 19.687 17.1782 19.7165 15.5672C19.7479 13.934 21.0655 12.6183 22.6341 12.6515Z" fill="#DE917B" />\
      <path opacity="0.61" d="M22.636 12.6534C24.247 12.6867 25.6698 13.792 25.6698 15.403C25.6698 17.0141 24.247 18.4867 22.636 18.4867C21.025 18.4867 19.689 17.1801 19.7185 15.5691C19.7499 13.936 21.0675 12.6202 22.636 12.6534Z" fill="#DE927C" />\
      <path opacity="0.6" d="M22.636 12.6549C24.247 12.6881 25.668 13.7954 25.668 15.4064C25.668 17.0174 24.247 18.4881 22.636 18.4881C21.025 18.4881 19.689 17.1816 19.7185 15.5706C19.748 13.9374 21.0675 12.6217 22.636 12.6549Z" fill="#DF937D" />\
      <path opacity="0.6" d="M22.6382 12.6569C24.2492 12.6901 25.6701 13.7991 25.6701 15.4102C25.6701 17.0212 24.2492 18.4901 22.6382 18.4901C21.0272 18.4901 19.693 17.1836 19.7206 15.5725C19.7502 13.9394 21.0696 12.6236 22.6382 12.6569Z" fill="#DF947E" />\
      <path opacity="0.59" d="M22.6399 12.6588C24.2509 12.692 25.67 13.8029 25.67 15.4121C25.67 17.0213 24.2509 18.492 22.6399 18.492C21.0289 18.492 19.6947 17.1855 19.7223 15.5745C19.7519 13.9414 21.0713 12.6256 22.6399 12.6588Z" fill="#DF947F" />\
      <path opacity="0.59" d="M22.6399 12.6607C24.2509 12.6939 25.67 13.8048 25.67 15.4158C25.67 17.0269 24.2509 18.4939 22.6399 18.4939C21.0289 18.4939 19.6947 17.1874 19.7223 15.5764C19.7519 13.9432 21.0695 12.6293 22.6399 12.6607Z" fill="#DF9580" />\
      <path opacity="0.58" d="M22.6418 12.6624C24.2528 12.6956 25.6701 13.8084 25.6701 15.4194C25.6701 17.0304 24.251 18.4956 22.6418 18.4956C21.0327 18.4956 19.6966 17.1891 19.7243 15.5781C19.7538 13.9449 21.0714 12.631 22.6418 12.6624Z" fill="#E09681" />\
      <path opacity="0.58" d="M22.6435 12.6644C24.2545 12.6957 25.6718 13.8122 25.6718 15.4214C25.6718 17.0305 24.2545 18.4976 22.6435 18.4976C21.0325 18.4976 19.6983 17.1911 19.726 15.5801C19.7555 13.9469 21.0731 12.633 22.6435 12.6644Z" fill="#E09782" />\
      <path opacity="0.57" d="M22.6435 12.6661C24.2545 12.6974 25.6699 13.8139 25.6699 15.4249C25.6699 17.0359 24.2527 18.4993 22.6435 18.4993C21.0344 18.4993 19.6983 17.1928 19.726 15.5818C19.7555 13.9486 21.0731 12.6347 22.6435 12.6661Z" fill="#E09883" />\
      <path opacity="0.57" d="M22.6452 12.6678C24.2563 12.6992 25.6717 13.8175 25.6717 15.4285C25.6717 17.0395 24.2563 18.501 22.6452 18.501C21.0342 18.501 19.7 17.1945 19.7277 15.5835C19.7572 13.9522 21.0748 12.6364 22.6452 12.6678Z" fill="#E09984" />\
      <path opacity="0.56" d="M22.6474 12.67C24.2585 12.7014 25.6739 13.8215 25.6739 15.4325C25.6739 17.0435 24.2585 18.5032 22.6474 18.5032C21.0364 18.5032 19.7022 17.1967 19.7299 15.5857C19.7576 13.9544 21.077 12.6386 22.6474 12.67Z" fill="#E19985" />\
      <path opacity="0.56" d="M22.6474 12.6734C24.2584 12.7048 25.672 13.8249 25.672 15.4359C25.672 17.0469 24.2584 18.5066 22.6474 18.5066C21.0364 18.5066 19.704 17.2001 19.7299 15.5891C19.7575 13.9578 21.0751 12.642 22.6474 12.6734Z" fill="#E19A86" />\
      <path opacity="0.56" d="M22.6491 12.6756C24.2601 12.707 25.6737 13.829 25.6737 15.44C25.6737 17.051 24.2601 18.5088 22.6491 18.5088C21.0381 18.5088 19.7057 17.2023 19.7316 15.5913C19.7593 13.96 21.0768 12.6442 22.6491 12.6756Z" fill="#E19B87" />\
      <path opacity="0.55" d="M22.6508 12.6772C24.2618 12.7086 25.6735 13.8324 25.6735 15.4435C25.6735 17.0545 24.2618 18.5105 22.6508 18.5105C21.0398 18.5105 19.7074 17.2039 19.7333 15.5929C19.761 13.9616 21.0786 12.6477 22.6508 12.6772Z" fill="#E19C88" />\
      <path opacity="0.55" d="M22.6528 12.6792C24.2638 12.7087 25.6755 13.8344 25.6755 15.4454C25.6755 17.0564 24.2638 18.5124 22.6528 18.5124C21.0418 18.5124 19.7094 17.2059 19.7352 15.5949C19.7629 13.9636 21.0805 12.6497 22.6528 12.6792Z" fill="#E29D89" />\
      <path opacity="0.54" d="M22.6528 12.6809C24.2638 12.7104 25.6736 13.838 25.6736 15.449C25.6736 17.06 24.2638 18.5141 22.6528 18.5141C21.0418 18.5141 19.7094 17.2076 19.7352 15.5966C19.7629 13.9653 21.0805 12.6514 22.6528 12.6809Z" fill="#E29E8A" />\
      <path opacity="0.54" d="M22.6547 12.6826C24.2657 12.7121 25.6756 13.8415 25.6756 15.4525C25.6756 17.0635 24.2657 18.5158 22.6547 18.5158C21.0437 18.5158 19.7114 17.2093 19.7372 15.5983C19.7649 13.967 21.0825 12.6531 22.6547 12.6826Z" fill="#E29E8B" />\
      <path opacity="0.53" d="M22.6564 12.6846C24.2674 12.7141 25.6754 13.8435 25.6754 15.4545C25.6754 17.0655 24.2674 18.5178 22.6564 18.5178C21.0454 18.5178 19.7131 17.2113 19.7389 15.6003C19.7666 13.9689 21.0823 12.655 22.6564 12.6846Z" fill="#E29F8C" />\
      <path opacity="0.53" d="M22.6564 12.6868C24.2674 12.7163 25.6754 13.8475 25.6754 15.4585C25.6754 17.0695 24.2674 18.52 22.6564 18.52C21.0454 18.52 19.7131 17.2135 19.7389 15.6025C19.7647 13.9711 21.0823 12.6572 22.6564 12.6868Z" fill="#E3A08D" />\
      <path opacity="0.52" d="M22.6586 12.688C24.2696 12.7175 25.6758 13.8506 25.6758 15.4616C25.6758 17.0726 24.2696 18.5212 22.6586 18.5212C21.0476 18.5212 19.7171 17.2147 19.741 15.6037C19.7669 13.9724 21.0845 12.6585 22.6586 12.688Z" fill="#E3A18E" />\
      <path opacity="0.52" d="M22.66 12.6901C24.271 12.7197 25.6772 13.8546 25.6772 15.4637C25.6772 17.0729 24.271 18.5233 22.66 18.5233C21.049 18.5233 19.7185 17.2168 19.7425 15.6058C19.7683 13.9763 21.0859 12.6624 22.66 12.6901Z" fill="#E3A28F" />\
      <path opacity="0.51" d="M22.66 12.6921C24.271 12.7216 25.6754 13.8565 25.6754 15.4675C25.6754 17.0785 24.271 18.5253 22.66 18.5253C21.049 18.5253 19.7185 17.2188 19.7425 15.6078C19.7683 13.9783 21.0859 12.6644 22.66 12.6921Z" fill="#E3A390" />\
      <path opacity="0.51" d="M22.662 12.6938C24.273 12.7215 25.6773 13.8601 25.6773 15.4711C25.6773 17.0821 24.273 18.527 22.662 18.527C21.051 18.527 19.7205 17.2205 19.7445 15.6095C19.7703 13.98 21.086 12.6661 22.662 12.6938Z" fill="#E4A391" />\
      <path opacity="0.5" d="M22.6642 12.696C24.2752 12.7237 25.6777 13.8641 25.6777 15.4733C25.6777 17.0824 24.2752 18.5292 22.6642 18.5292C21.0532 18.5292 19.7227 17.2227 19.7467 15.6117C19.7725 13.9822 21.0882 12.6683 22.6642 12.696Z" fill="#E4A492" />\
      <path opacity="0.5" d="M22.6642 12.6975C24.2752 12.7251 25.6777 13.8656 25.6777 15.4766C25.6777 17.0876 24.2752 18.5307 22.6642 18.5307C21.0532 18.5307 19.7227 17.2241 19.7467 15.6131C19.7725 13.9837 21.0882 12.6698 22.6642 12.6975Z" fill="#E4A593" />\
      <path opacity="0.5" d="M22.6659 12.6992C24.2769 12.7268 25.6775 13.8691 25.6775 15.4801C25.6775 17.0911 24.2769 18.5324 22.6659 18.5324C21.0549 18.5324 19.7244 17.2259 19.7484 15.6148C19.7724 13.9854 21.09 12.6715 22.6659 12.6992Z" fill="#E4A694" />\
      <path opacity="0.49" d="M22.6674 12.7014C24.2784 12.729 25.679 13.8732 25.679 15.4842C25.679 17.0952 24.2784 18.5346 22.6674 18.5346C21.0564 18.5346 19.7258 17.228 19.7498 15.617C19.7738 13.9876 21.0914 12.6737 22.6674 12.7014Z" fill="#E4A795" />\
      <path opacity="0.49" d="M22.6673 12.7031C24.2783 12.7307 25.6771 13.8749 25.6771 15.4859C25.6771 17.0969 24.2783 18.5363 22.6673 18.5363C21.0563 18.5363 19.7276 17.2298 19.7498 15.6188C19.7738 13.9893 21.0914 12.6754 22.6673 12.7031Z" fill="#E5A796" />\
      <path opacity="0.48" d="M22.6695 12.7047C24.2805 12.7324 25.6793 13.8784 25.6793 15.4894C25.6793 17.1004 24.2805 18.5379 22.6695 18.5379C21.0585 18.5379 19.7298 17.2314 19.752 15.6204C19.776 13.9909 21.0917 12.6789 22.6695 12.7047Z" fill="#E5A897" />\
      <path opacity="0.48" d="M22.6712 12.7069C24.2822 12.7328 25.6792 13.8824 25.6792 15.4934C25.6792 17.1044 24.2822 18.5401 22.6712 18.5401C21.0602 18.5401 19.7315 17.2336 19.7537 15.6226C19.7777 13.9931 21.0934 12.6811 22.6712 12.7069Z" fill="#E5A998" />\
      <path opacity="0.47" d="M22.6732 12.7086C24.2842 12.7345 25.6811 13.8841 25.6811 15.4951C25.6811 17.1061 24.2842 18.5418 22.6732 18.5418C21.0622 18.5418 19.7335 17.2353 19.7556 15.6243C19.7796 13.9967 21.0954 12.6828 22.6732 12.7086Z" fill="#E5AA99" />\
      <path opacity="0.47" d="M22.6732 12.7103C24.2842 12.7362 25.6793 13.8877 25.6793 15.4987C25.6793 17.1097 24.2842 18.5435 22.6732 18.5435C21.0622 18.5435 19.7335 17.237 19.7556 15.626C19.7796 13.9984 21.0954 12.6845 22.6732 12.7103Z" fill="#E6AB9A" />\
      <path opacity="0.46" d="M22.6751 12.7125C24.2861 12.7384 25.6812 13.8917 25.6812 15.5027C25.6812 17.1137 24.2861 18.5457 22.6751 18.5457C21.0641 18.5457 19.7355 17.2392 19.7576 15.6282C19.7816 14.0006 21.0973 12.6867 22.6751 12.7125Z" fill="#E6AC9B" />\
      <path opacity="0.46" d="M22.6766 12.714C24.2876 12.7398 25.6809 13.8932 25.6809 15.5042C25.6809 17.1152 24.2876 18.5472 22.6766 18.5472C21.0656 18.5472 19.7369 17.2407 19.7591 15.6297C19.7812 14.0021 21.097 12.6882 22.6766 12.714Z" fill="#E6AC9C" />\
      <path opacity="0.45" d="M22.6766 12.716C24.2876 12.7418 25.6809 13.897 25.6809 15.508C25.6809 17.119 24.2876 18.5492 22.6766 18.5492C21.0656 18.5492 19.7369 17.2426 19.7591 15.6316C19.7812 14.004 21.097 12.6901 22.6766 12.716Z" fill="#E6AD9D" />\
      <path opacity="0.45" d="M22.6785 12.7181C24.2895 12.7439 25.6809 13.901 25.6809 15.512C25.6809 17.123 24.2895 18.5513 22.6785 18.5513C21.0675 18.5513 19.7407 17.2448 19.761 15.6338C19.7831 14.0062 21.0989 12.6941 22.6785 12.7181Z" fill="#E7AE9E" />\
      <path opacity="0.44" d="M22.6805 12.7198C24.2915 12.7438 25.6829 13.9045 25.6829 15.5137C25.6829 17.1229 24.2915 18.553 22.6805 18.553C21.0694 18.553 19.7426 17.2465 19.7629 15.6355C19.7851 14.0079 21.1008 12.6958 22.6805 12.7198Z" fill="#E7AF9F" />\
      <path opacity="0.44" d="M22.6805 12.7215C24.2915 12.7455 25.681 13.9062 25.681 15.5173C25.681 17.1283 24.2896 18.5547 22.6805 18.5547C21.0713 18.5547 19.7426 17.2482 19.7629 15.6372C19.7851 14.0096 21.1008 12.6975 22.6805 12.7215Z" fill="#E7B0A0" />\
      <path opacity="0.44" d="M22.6824 12.7232C24.2934 12.7472 25.683 13.9098 25.683 15.5208C25.683 17.1318 24.2934 18.5564 22.6824 18.5564C21.0714 18.5564 19.7446 17.2499 19.7649 15.6389C19.787 14.0113 21.1028 12.6992 22.6824 12.7232Z" fill="#E7B1A1" />\
      <path opacity="0.43" d="M22.6844 12.7252C24.2954 12.7492 25.6831 13.9136 25.6831 15.5228C25.6831 17.1319 24.2935 18.5584 22.6844 18.5584C21.0752 18.5584 19.7465 17.2519 19.7668 15.6409C19.789 14.0132 21.1029 12.7012 22.6844 12.7252Z" fill="#E8B1A2" />\
      <path opacity="0.43" d="M22.6844 12.7271C24.2954 12.7511 25.6831 13.9155 25.6831 15.5266C25.6831 17.1376 24.2954 18.5603 22.6844 18.5603C21.0734 18.5603 19.7465 17.2538 19.7668 15.6428C19.789 14.0152 21.1029 12.7031 22.6844 12.7271Z" fill="#E8B2A3" />\
      <path opacity="0.42" d="M22.6861 12.7291C24.2971 12.7531 25.6848 13.9193 25.6848 15.5304C25.6848 17.1414 24.2971 18.5623 22.6861 18.5623C21.0751 18.5623 19.7482 17.2558 19.7685 15.6448C19.7888 14.019 21.1046 12.7051 22.6861 12.7291Z" fill="#E8B3A4" />\
      <path opacity="0.42" d="M22.6878 12.7305C24.2988 12.7545 25.6847 13.9226 25.6847 15.5336C25.6847 17.1446 24.2988 18.5637 22.6878 18.5637C21.0768 18.5637 19.75 17.2572 19.7702 15.6462C19.7905 14.0204 21.1063 12.7084 22.6878 12.7305Z" fill="#E8B4A5" />\
      <path opacity="0.41" d="M22.6877 12.7325C24.2987 12.7546 25.6846 13.9246 25.6846 15.5356C25.6846 17.1466 24.2987 18.5657 22.6877 18.5657C21.0767 18.5657 19.7518 17.2591 19.7702 15.6481C19.7905 14.0224 21.1063 12.7103 22.6877 12.7325Z" fill="#E9B5A6" />\
      <path opacity="0.41" d="M22.6894 12.7364C24.3005 12.7585 25.6845 13.9303 25.6845 15.5413C25.6845 17.1523 24.3005 18.5696 22.6894 18.5696C21.0784 18.5696 19.7535 17.2631 19.7719 15.652C19.7922 14.0263 21.1061 12.7142 22.6894 12.7364Z" fill="#E9B6A7" />\
      <path opacity="0.4" d="M22.6916 12.7381C24.3027 12.7602 25.6867 13.9339 25.6867 15.5449C25.6867 17.1559 24.3027 18.5713 22.6916 18.5713C21.0806 18.5713 19.7557 17.2648 19.7741 15.6538C19.7944 14.028 21.1083 12.7159 22.6916 12.7381Z" fill="#E9B6A8" />\
      <path opacity="0.4" d="M22.6934 12.74C24.3044 12.7622 25.6865 13.9358 25.6865 15.5468C25.6865 17.1578 24.3044 18.5732 22.6934 18.5732C21.0823 18.5732 19.7574 17.2667 19.7758 15.6557C19.7961 14.0299 21.11 12.7179 22.6934 12.74Z" fill="#E9B7A9" />\
      <path opacity="0.39" d="M22.6934 12.7417C24.3044 12.7639 25.6865 13.9394 25.6865 15.5504C25.6865 17.1614 24.3044 18.5749 22.6934 18.5749C21.0823 18.5749 19.7574 17.2684 19.7758 15.6574C19.7961 14.0316 21.11 12.7196 22.6934 12.7417Z" fill="#EAB8AA" />\
      <path opacity="0.39" d="M22.6951 12.7437C24.3061 12.7658 25.6864 13.9432 25.6864 15.5542C25.6864 17.1652 24.3061 18.5769 22.6951 18.5769C21.0841 18.5769 19.7591 17.2704 19.7775 15.6594C19.7978 14.0336 21.1117 12.7215 22.6951 12.7437Z" fill="#EAB9AB" />\
      <path opacity="0.38" d="M22.6973 12.7456C24.3083 12.7678 25.6886 13.9451 25.6886 15.5561C25.6886 17.1671 24.3083 18.5789 22.6973 18.5789C21.0863 18.5789 19.7613 17.2723 19.7797 15.6613C19.7982 14.0356 21.1139 12.7235 22.6973 12.7456Z" fill="#EABAAC" />\
      <path opacity="0.38" d="M22.6973 12.7473C24.3083 12.7676 25.6868 13.9486 25.6868 15.5596C25.6868 17.1707 24.3083 18.5805 22.6973 18.5805C21.0863 18.5805 19.7613 17.274 19.7797 15.663C19.7982 14.0372 21.1121 12.727 22.6973 12.7473Z" fill="#EABBAD" />\
      <path opacity="0.38" d="M22.6989 12.7493C24.3099 12.7696 25.6884 13.9524 25.6884 15.5634C25.6884 17.1745 24.3099 18.5825 22.6989 18.5825C21.0879 18.5825 19.7648 17.2759 19.7814 15.6649C19.7999 14.041 21.1138 12.729 22.6989 12.7493Z" fill="#EBBBAE" />\
      <path opacity="0.37" d="M22.7006 12.7512C24.3117 12.7715 25.6883 13.9562 25.6883 15.5654C25.6883 17.1746 24.3117 18.5844 22.7006 18.5844C21.0896 18.5844 19.7665 17.2779 19.7831 15.6669C19.8016 14.043 21.1155 12.7309 22.7006 12.7512Z" fill="#EBBCAF" />\
      <path opacity="0.37" d="M22.7006 12.7529C24.3117 12.7732 25.6883 13.9579 25.6883 15.569C25.6883 17.18 24.3117 18.5861 22.7006 18.5861C21.0896 18.5861 19.7665 17.2796 19.7831 15.6686C19.8016 14.0447 21.1155 12.7326 22.7006 12.7529Z" fill="#EBBDB0" />\
      <path opacity="0.36" d="M22.7026 12.7549C24.3136 12.7752 25.6884 13.9617 25.6884 15.5728C25.6884 17.1838 24.3136 18.5881 22.7026 18.5881C21.0916 18.5881 19.7685 17.2816 19.7851 15.6706C19.8035 14.0466 21.1174 12.7346 22.7026 12.7549Z" fill="#EBBEB1" />\
      <path opacity="0.36" d="M22.7045 12.7566C24.3156 12.7769 25.6904 13.9653 25.6904 15.5745C25.6904 17.1836 24.3156 18.5898 22.7045 18.5898C21.0935 18.5898 19.7704 17.2833 19.787 15.6723C19.8055 14.0483 21.1175 12.7363 22.7045 12.7566Z" fill="#ECBFB2" />\
      <path opacity="0.35" d="M22.7045 12.7583C24.3156 12.7786 25.6885 13.967 25.6885 15.578C25.6885 17.189 24.3156 18.5915 22.7045 18.5915C21.0935 18.5915 19.7704 17.285 19.787 15.674C19.8055 14.05 21.1175 12.738 22.7045 12.7583Z" fill="#ECC0B3" />\
      <path opacity="0.35" d="M22.7063 12.7604C24.3173 12.7789 25.6902 13.971 25.6902 15.582C25.6902 17.193 24.3173 18.5937 22.7063 18.5937C21.0953 18.5937 19.7721 17.2871 19.7887 15.6761C19.8053 14.0522 21.1192 12.742 22.7063 12.7604Z" fill="#ECC0B4" />\
      <path opacity="0.34" d="M22.7085 12.7624C24.3195 12.7809 25.6906 13.9748 25.6906 15.5858C25.6906 17.1968 24.3195 18.5956 22.7085 18.5956C21.0974 18.5956 19.7743 17.2891 19.7909 15.6781C19.8075 14.0542 21.1214 12.7439 22.7085 12.7624Z" fill="#ECC1B5" />\
      <path opacity="0.34" d="M22.7084 12.7641C24.3194 12.7826 25.6905 13.9765 25.6905 15.5875C25.6905 17.1985 24.3194 18.5973 22.7084 18.5973C21.0974 18.5973 19.7761 17.2908 19.7909 15.6798C19.8075 14.0559 21.1214 12.7457 22.7084 12.7641Z" fill="#EDC2B6" />\
      <path opacity="0.33" d="M22.7099 12.7658C24.3209 12.7843 25.6902 13.9801 25.6902 15.5911C25.6902 17.2021 24.3209 18.599 22.7099 18.599C21.0989 18.599 19.7776 17.2925 19.7924 15.6815C19.809 14.0576 21.1229 12.7474 22.7099 12.7658Z" fill="#EDC3B7" />\
      <path opacity="0.33" d="M22.7118 12.7675C24.3229 12.786 25.6921 13.9836 25.6921 15.5946C25.6921 17.2056 24.3229 18.6007 22.7118 18.6007C21.1008 18.6007 19.7795 17.2942 19.7943 15.6832C19.8109 14.0593 21.123 12.7491 22.7118 12.7675Z" fill="#EDC4B8" />\
      <path opacity="0.32" d="M22.7118 12.7695C24.3229 12.7879 25.6903 13.9856 25.6903 15.5966C25.6903 17.2076 24.3229 18.6027 22.7118 18.6027C21.1008 18.6027 19.7795 17.2962 19.7943 15.6852C19.8109 14.0631 21.123 12.751 22.7118 12.7695Z" fill="#EDC5B9" />\
      <path opacity="0.32" d="M22.7138 12.7717C24.3248 12.7901 25.6922 13.9896 25.6922 15.6006C25.6922 17.2116 24.3248 18.6049 22.7138 18.6049C21.1028 18.6049 19.7815 17.2984 19.7963 15.6874C19.8129 14.0653 21.1249 12.7532 22.7138 12.7717Z" fill="#EEC5BA" />\
      <path opacity="0.31" d="M22.7155 12.7731C24.3265 12.7916 25.6921 13.9929 25.6921 15.6039C25.6921 17.2149 24.3265 18.6064 22.7155 18.6064C21.1045 18.6064 19.7832 17.2998 19.798 15.6888C19.8146 14.0667 21.1266 12.7547 22.7155 12.7731Z" fill="#EEC6BB" />\
      <path opacity="0.31" d="M22.7177 12.7748C24.3287 12.7914 25.6943 13.9946 25.6943 15.6056C25.6943 17.2166 24.3287 18.608 22.7177 18.608C21.1067 18.608 19.7854 17.3015 19.8002 15.6905C19.8149 14.0684 21.1288 12.7582 22.7177 12.7748Z" fill="#EEC7BC" />\
      <path opacity="0.31" d="M22.7177 12.777C24.3287 12.7936 25.6924 13.9986 25.6924 15.6097C25.6924 17.2207 24.3287 18.6102 22.7177 18.6102C21.1067 18.6102 19.7854 17.3037 19.8002 15.6927C19.8149 14.0706 21.1288 12.7604 22.7177 12.777Z" fill="#EEC8BD" />\
      <path opacity="0.3" d="M22.7191 12.7787C24.3301 12.7953 25.6938 14.0022 25.6938 15.6132C25.6938 17.2242 24.3301 18.6119 22.7191 18.6119C21.1081 18.6119 19.7868 17.3054 19.8034 15.6944C19.8181 14.0723 21.1302 12.7621 22.7191 12.7787Z" fill="#EFC9BE" />\
      <path opacity="0.3" d="M22.7212 12.7807C24.3322 12.7973 25.6941 14.006 25.6941 15.6152C25.6941 17.2243 24.3322 18.6139 22.7212 18.6139C21.1102 18.6139 19.7908 17.3074 19.8055 15.6964C19.8203 14.0743 21.1324 12.7641 22.7212 12.7807Z" fill="#EFCABF" />\
      <path opacity="0.29" d="M22.7212 12.7824C24.3322 12.799 25.6941 14.0077 25.6941 15.6187C25.6941 17.2297 24.3322 18.6156 22.7212 18.6156C21.1102 18.6156 19.7908 17.3091 19.8055 15.6981C19.8203 14.076 21.1324 12.7658 22.7212 12.7824Z" fill="#EFCAC0" />\
      <path opacity="0.29" d="M22.7229 12.7843C24.3339 12.8009 25.694 14.0115 25.694 15.6225C25.694 17.2335 24.3321 18.6176 22.7229 18.6176C21.1138 18.6176 19.7925 17.311 19.8073 15.7C19.822 14.0779 21.1341 12.7677 22.7229 12.7843Z" fill="#EFCBC1" />\
      <path opacity="0.28" d="M22.7249 12.7859C24.3359 12.8025 25.6959 14.0149 25.6959 15.6259C25.6959 17.2369 24.3359 18.6209 22.7249 18.6209C21.1139 18.6209 19.7944 17.3144 19.8092 15.7034C19.824 14.0813 21.136 12.7711 22.7249 12.7877V12.7859Z" fill="#F0CCC2" />\
      <path opacity="0.28" d="M22.7249 12.788C24.3359 12.8027 25.6959 14.017 25.6959 15.628C25.6959 17.239 24.3359 18.6212 22.7249 18.6212C21.1139 18.6212 19.7944 17.3147 19.8092 15.7036C19.824 14.0816 21.1342 12.7732 22.7249 12.788Z" fill="#F0CDC3" />\
      <path opacity="0.27" d="M22.7266 12.7899C24.3376 12.8047 25.6958 14.0208 25.6958 15.6318C25.6958 17.2428 24.3376 18.6231 22.7266 18.6231C21.1156 18.6231 19.7962 17.3166 19.8109 15.7056C19.8238 14.0854 21.1359 12.7752 22.7266 12.7899Z" fill="#F0CEC4" />\
      <path opacity="0.27" d="M22.7286 12.7914C24.3396 12.8061 25.6978 14.0241 25.6978 15.6351C25.6978 17.2461 24.3396 18.6246 22.7286 18.6246C21.1175 18.6246 19.7981 17.3181 19.8129 15.7071C19.8258 14.0868 21.1378 12.7766 22.7286 12.7914Z" fill="#F0CFC5" />\
      <path opacity="0.26" d="M22.7286 12.7936C24.3396 12.8083 25.6959 14.0263 25.6959 15.6373C25.6959 17.2483 24.3396 18.6268 22.7286 18.6268C21.1175 18.6268 19.7981 17.3203 19.8129 15.7093C19.8258 14.089 21.1378 12.7788 22.7286 12.7936Z" fill="#F1CFC6" />\
      <path opacity="0.26" d="M22.7302 12.7955C24.3412 12.8103 25.6976 14.0301 25.6976 15.6411C25.6976 17.2521 24.3412 18.6287 22.7302 18.6287C21.1192 18.6287 19.8016 17.3222 19.8145 15.7112C19.8275 14.091 21.1395 12.7808 22.7302 12.7955Z" fill="#F1D0C7" />\
      <path opacity="0.25" d="M22.7322 12.799C24.3432 12.8137 25.6977 14.0353 25.6977 15.6464C25.6977 17.2574 24.3432 18.6322 22.7322 18.6322C21.1212 18.6322 19.8036 17.3256 19.8165 15.7146C19.8294 14.0944 21.1415 12.7842 22.7322 12.799Z" fill="#F1D1C8" />\
      <path opacity="0.25" d="M22.7322 12.8007C24.3432 12.8154 25.6977 14.0371 25.6977 15.6481C25.6977 17.2591 24.3432 18.6339 22.7322 18.6339C21.1212 18.6339 19.8036 17.3274 19.8165 15.7163C19.8294 14.0961 21.1396 12.7859 22.7322 12.8007Z" fill="#F1D2C9" />\
      <path opacity="0.25" d="M22.7339 12.8029C24.3449 12.8158 25.6976 14.0411 25.6976 15.6521C25.6976 17.2631 24.3449 18.6361 22.7339 18.6361C21.1229 18.6361 19.8053 17.3295 19.8182 15.7185C19.8311 14.0983 21.1413 12.7881 22.7339 12.8029Z" fill="#F2D3CA" />\
      <path opacity="0.24" d="M22.7358 12.8048C24.3469 12.8177 25.6995 14.0449 25.6995 15.6559C25.6995 17.2669 24.3469 18.638 22.7358 18.638C21.1248 18.638 19.8072 17.3315 19.8202 15.7205C19.8331 14.1002 21.1433 12.7919 22.7358 12.8048Z" fill="#F2D4CB" />\
      <path opacity="0.24" d="M22.7378 12.8065C24.3488 12.8194 25.6996 14.0484 25.6996 15.6576C25.6996 17.2668 24.3488 18.6397 22.7378 18.6397C21.1268 18.6397 19.8092 17.3332 19.8221 15.7222C19.8332 14.1019 21.1452 12.7936 22.7378 12.8065Z" fill="#F2D4CC" />\
      <path opacity="0.23" d="M22.7378 12.8082C24.3488 12.8211 25.6996 14.0501 25.6996 15.6611C25.6996 17.2722 24.3488 18.6414 22.7378 18.6414C21.1268 18.6414 19.8092 17.3349 19.8221 15.7239C19.8332 14.1037 21.1452 12.7953 22.7378 12.8082Z" fill="#F2D5CD" />\
      <path opacity="0.23" d="M22.7398 12.8099C24.3508 12.8228 25.6997 14.0537 25.6997 15.6647C25.6997 17.2757 24.3508 18.6431 22.7398 18.6431C21.1287 18.6431 19.8112 17.3366 19.8241 15.7256C19.8351 14.1072 21.1454 12.797 22.7398 12.8099Z" fill="#F3D6CE" />\
      <path opacity="0.22" d="M22.7414 12.8119C24.3524 12.8248 25.7014 14.0575 25.7014 15.6666C25.7014 17.2758 24.3524 18.6451 22.7414 18.6451C21.1304 18.6451 19.8147 17.3386 19.8258 15.7275C19.8368 14.1092 21.147 12.7989 22.7414 12.8119Z" fill="#F3D7CF" />\
      <path opacity="0.22" d="M22.7414 12.8141C24.3524 12.827 25.6996 14.0597 25.6996 15.6707C25.6996 17.2817 24.3524 18.6473 22.7414 18.6473C21.1304 18.6473 19.8147 17.3408 19.8258 15.7297C19.8368 14.1114 21.147 12.8011 22.7414 12.8141Z" fill="#F3D8D0" />\
      <path opacity="0.21" d="M22.7431 12.8155C24.3542 12.8266 25.7013 14.063 25.7013 15.674C25.7013 17.285 24.3542 18.6487 22.7431 18.6487C21.1321 18.6487 19.8164 17.3422 19.8275 15.7312C19.8385 14.1128 21.1487 12.8026 22.7431 12.8155Z" fill="#F3D9D1" />\
      <path opacity="0.21" d="M22.7451 12.8174C24.3561 12.8285 25.7014 14.0667 25.7014 15.6777C25.7014 17.2887 24.3561 18.6524 22.7451 18.6524C21.1341 18.6524 19.8183 17.3459 19.8294 15.7349C19.8405 14.1165 21.1507 12.8082 22.7451 12.8192V12.8174Z" fill="#F4D9D2" />\
      <path opacity="0.2" d="M22.7451 12.8194C24.3561 12.8305 25.7014 14.0687 25.7014 15.6797C25.7014 17.2907 24.3561 18.6526 22.7451 18.6526C21.1341 18.6526 19.8183 17.3461 19.8294 15.7351C19.8405 14.1167 21.1507 12.8083 22.7451 12.8194Z" fill="#F4DAD3" />\
      <path opacity="0.2" d="M22.7468 12.8211C24.3578 12.8322 25.7012 14.0723 25.7012 15.6833C25.7012 17.2943 24.3578 18.6543 22.7468 18.6543C21.1358 18.6543 19.8201 17.3478 19.8311 15.7368C19.8404 14.1184 21.1506 12.81 22.7468 12.8211Z" fill="#F4DBD4" />\
      <path opacity="0.19" d="M22.749 12.8231C24.36 12.8341 25.7034 14.0761 25.7034 15.6871C25.7034 17.2981 24.36 18.6563 22.749 18.6563C21.138 18.6563 19.8222 17.3498 19.8333 15.7388C19.8425 14.1204 21.1528 12.812 22.749 12.8231Z" fill="#F4DCD5" />\
      <path opacity="0.19" d="M22.749 12.8248C24.36 12.8358 25.7016 14.0778 25.7016 15.6888C25.7016 17.2998 24.36 18.658 22.749 18.658C21.138 18.658 19.8222 17.3515 19.8333 15.7405C19.8425 14.1221 21.1528 12.8137 22.749 12.8248Z" fill="#F5DDD6" />\
      <path opacity="0.19" d="M22.7507 12.8267C24.3617 12.8378 25.7033 14.0816 25.7033 15.6926C25.7033 17.3036 24.3617 18.6599 22.7507 18.6599C21.1397 18.6599 19.8258 17.3534 19.835 15.7424C19.8442 14.124 21.1544 12.8157 22.7507 12.8267Z" fill="#F5DED7" />\
      <path opacity="0.18" d="M22.7522 12.8284C24.3632 12.8377 25.7029 14.0851 25.7029 15.6961C25.7029 17.3072 24.3632 18.6617 22.7522 18.6617C21.1412 18.6617 19.8272 17.3551 19.8365 15.7441C19.8457 14.1257 21.1559 12.8174 22.7522 12.8284Z" fill="#F5DED8" />\
      <path opacity="0.18" d="M22.7522 12.8306C24.3632 12.8399 25.7029 14.0873 25.7029 15.6983C25.7029 17.3093 24.3632 18.6638 22.7522 18.6638C21.1412 18.6638 19.8272 17.3573 19.8365 15.7463C19.8457 14.1298 21.1541 12.8196 22.7522 12.8306Z" fill="#F5DFD9" />\
      <path opacity="0.17" d="M22.7544 12.8323C24.3654 12.8416 25.7033 14.0909 25.7033 15.7019C25.7033 17.3129 24.3654 18.6655 22.7544 18.6655C21.1433 18.6655 19.8294 17.359 19.8387 15.748C19.8479 14.1315 21.1563 12.8231 22.7544 12.8323Z" fill="#F6E0DA" />\
      <path opacity="0.17" d="M22.7561 12.8338C24.3671 12.843 25.705 14.0942 25.705 15.7052C25.705 17.3162 24.3671 18.667 22.7561 18.667C21.1451 18.667 19.8312 17.3605 19.8404 15.7495C19.8496 14.1329 21.158 12.8246 22.7561 12.8338Z" fill="#F6E1DB" />\
      <path opacity="0.16" d="M22.758 12.836C24.369 12.8452 25.7051 14.0982 25.7051 15.7074C25.7051 17.3165 24.369 18.6692 22.758 18.6692C21.147 18.6692 19.8331 17.3627 19.8423 15.7517C19.8497 14.1351 21.1599 12.8268 22.758 12.836Z" fill="#F6E2DC" />\
      <path opacity="0.16" d="M22.758 12.8379C24.369 12.8472 25.7051 14.1002 25.7051 15.7112C25.7051 17.3222 24.369 18.6712 22.758 18.6712C21.147 18.6712 19.8331 17.3646 19.8423 15.7536C19.8497 14.1371 21.1599 12.8287 22.758 12.8379Z" fill="#F6E3DD" />\
      <path opacity="0.15" d="M22.7597 12.8396C24.3707 12.8489 25.7049 14.1037 25.7049 15.7147C25.7049 17.3257 24.3707 18.6729 22.7597 18.6729C21.1487 18.6729 19.8348 17.3663 19.844 15.7553C19.8514 14.1388 21.1616 12.8304 22.7597 12.8396Z" fill="#F7E3DE" />\
      <path opacity="0.15" d="M22.7617 12.8414C24.3727 12.8487 25.7069 14.1073 25.7069 15.7164C25.7069 17.3256 24.3727 18.6746 22.7617 18.6746C21.1507 18.6746 19.8386 17.368 19.846 15.757C19.8534 14.1405 21.1617 12.8321 22.7617 12.8414Z" fill="#F7E4DF" />\
      <path opacity="0.14" d="M22.7617 12.8431C24.3727 12.8504 25.705 14.109 25.705 15.72C25.705 17.331 24.3708 18.6763 22.7617 18.6763C21.1525 18.6763 19.8386 17.3698 19.846 15.7587C19.8534 14.1422 21.1617 12.8338 22.7617 12.8431Z" fill="#F7E5E0" />\
      <path opacity="0.14" d="M22.7636 12.845C24.3746 12.8524 25.707 14.1128 25.707 15.7238C25.707 17.3348 24.3746 18.6782 22.7636 18.6782C21.1526 18.6782 19.8405 17.3717 19.8479 15.7607C19.8553 14.1441 21.1637 12.8376 22.7636 12.845Z" fill="#F7E6E1" />\
      <path opacity="0.13" d="M22.7653 12.8472C24.3763 12.8545 25.7087 14.1168 25.7087 15.7278C25.7087 17.3388 24.3763 18.6822 22.7653 18.6822C21.1543 18.6822 19.8423 17.3757 19.8496 15.7647C19.857 14.1482 21.1654 12.8416 22.7653 12.849V12.8472Z" fill="#F8E7E2" />\
      <path opacity="0.13" d="M22.7653 12.8489C24.3763 12.8563 25.7068 14.1185 25.7068 15.7295C25.7068 17.3405 24.3763 18.6821 22.7653 18.6821C21.1543 18.6821 19.8423 17.3756 19.8496 15.7646C19.857 14.1499 21.1654 12.8415 22.7653 12.8489Z" fill="#F8E8E3" />\
      <path opacity="0.13" d="M22.7673 12.8506C24.3783 12.858 25.7088 14.1221 25.7088 15.7331C25.7088 17.3441 24.3783 18.6838 22.7673 18.6838C21.1563 18.6838 19.8442 17.3773 19.8516 15.7663C19.8571 14.1516 21.1655 12.8432 22.7673 12.8506Z" fill="#F8E8E4" />\
      <path opacity="0.12" d="M22.769 12.8526C24.38 12.86 25.7087 14.1259 25.7087 15.7369C25.7087 17.3479 24.38 18.6858 22.769 18.6858C21.158 18.6858 19.8459 17.3793 19.8533 15.7683C19.8588 14.1536 21.1672 12.8452 22.769 12.8526Z" fill="#F8E9E5" />\
      <path opacity="0.12" d="M22.769 12.8543C24.38 12.8598 25.7087 14.1276 25.7087 15.7386C25.7087 17.3496 24.38 18.6875 22.769 18.6875C21.158 18.6875 19.8459 17.381 19.8533 15.77C19.8588 14.1553 21.1672 12.8469 22.769 12.8543Z" fill="#F9EAE6" />\
      <path opacity="0.11" d="M22.7709 12.8562C24.3819 12.8618 25.7088 14.1314 25.7088 15.7424C25.7088 17.3534 24.3819 18.6894 22.7709 18.6894C21.1599 18.6894 19.8497 17.3829 19.8552 15.7719C19.8608 14.1572 21.1691 12.8489 22.7709 12.8562Z" fill="#F9EBE7" />\
      <path opacity="0.11" d="M22.7729 12.8582C24.3839 12.8637 25.7107 14.1352 25.7107 15.7462C25.7107 17.3572 24.3839 18.6914 22.7729 18.6914C21.1619 18.6914 19.8517 17.3849 19.8572 15.7739C19.8627 14.1592 21.1711 12.8508 22.7729 12.8582Z" fill="#F9ECE8" />\
      <path opacity="0.1" d="M22.7729 12.8618C24.3839 12.8674 25.7089 14.1388 25.7089 15.7498C25.7089 17.3608 24.3839 18.695 22.7729 18.695C21.1619 18.695 19.8517 17.3885 19.8572 15.7775C19.8627 14.1628 21.1711 12.8563 22.7729 12.8618Z" fill="#F9EDE9" />\
      <path opacity="0.1" d="M22.7748 12.8638C24.3858 12.8693 25.7108 14.1426 25.7108 15.7536C25.7108 17.3646 24.3858 18.697 22.7748 18.697C21.1638 18.697 19.8536 17.3905 19.8591 15.7795C19.8647 14.1648 21.1712 12.8583 22.7748 12.8638Z" fill="#FAEDEA" />\
      <path opacity="0.09" d="M22.7763 12.8655C24.3873 12.871 25.7104 14.1462 25.7104 15.7572C25.7104 17.3682 24.3873 18.6987 22.7763 18.6987C21.1653 18.6987 19.8551 17.3922 19.8606 15.7812C19.8661 14.1665 21.1727 12.86 22.7763 12.8655Z" fill="#FAEEEB" />\
      <path opacity="0.09" d="M22.778 12.8672C24.389 12.8727 25.7121 14.1497 25.7121 15.7589C25.7121 17.3681 24.389 18.7004 22.778 18.7004C21.167 18.7004 19.8568 17.3939 19.8623 15.7829C19.866 14.1682 21.1744 12.8617 22.778 12.8672Z" fill="#FAEFEC" />\
      <path opacity="0.08" d="M22.778 12.8692C24.389 12.8747 25.7103 14.1517 25.7103 15.7627C25.7103 17.3737 24.389 18.7024 22.778 18.7024C21.167 18.7024 19.8568 17.3959 19.8623 15.7848C19.866 14.1701 21.1744 12.8636 22.778 12.8692Z" fill="#FAF0ED" />\
      <path opacity="0.08" d="M22.7802 12.8711C24.3912 12.8748 25.7125 14.1555 25.7125 15.7665C25.7125 17.3775 24.3912 18.7043 22.7802 18.7043C21.1692 18.7043 19.859 17.3978 19.8645 15.7868C19.8682 14.1739 21.1766 12.8656 22.7802 12.8711Z" fill="#FBF1EE" />\
      <path opacity="0.07" d="M22.7821 12.8731C24.3932 12.8768 25.7126 14.1593 25.7126 15.7684C25.7126 17.3776 24.3932 18.7063 22.7821 18.7063C21.1711 18.7063 19.8628 17.3998 19.8665 15.7887C19.8702 14.1759 21.1785 12.8675 22.7821 12.8731Z" fill="#FBF2EF" />\
      <path opacity="0.07" d="M22.7821 12.875C24.3932 12.8787 25.7126 14.1612 25.7126 15.7722C25.7126 17.3832 24.3932 18.7082 22.7821 18.7082C21.1711 18.7082 19.8628 17.4017 19.8665 15.7907C19.8702 14.1778 21.1767 12.8713 22.7821 12.875Z" fill="#FBF2F0" />\
      <path opacity="0.06" d="M22.7839 12.8762C24.3949 12.8799 25.7125 14.1643 25.7125 15.7753C25.7125 17.3863 24.3949 18.7094 22.7839 18.7094C21.1728 18.7094 19.8645 17.4029 19.8682 15.7919C19.8719 14.1791 21.1784 12.8725 22.7839 12.8762Z" fill="#FBF3F1" />\
      <path opacity="0.06" d="M22.7856 12.8784C24.3966 12.8821 25.7142 14.1683 25.7142 15.7793C25.7142 17.3903 24.3966 18.7135 22.7856 18.7135C21.1746 18.7135 19.8662 17.407 19.8699 15.7959C19.8736 14.1831 21.1801 12.8766 22.7856 12.8803V12.8784Z" fill="#FCF4F2" />\
      <path opacity="0.06" d="M22.7856 12.8804C24.3966 12.8841 25.7123 14.1703 25.7123 15.7813C25.7123 17.3923 24.3966 18.7136 22.7856 18.7136C21.1746 18.7136 19.8662 17.4071 19.8699 15.7961C19.8736 14.1832 21.1801 12.8767 22.7856 12.8804Z" fill="#FCF5F3" />\
      <path opacity="0.05" d="M22.7873 12.8821C24.3983 12.8858 25.714 14.1738 25.714 15.7849C25.714 17.3959 24.3983 18.7153 22.7873 18.7153C21.1763 18.7153 19.8679 17.4088 19.8716 15.7978C19.8734 14.1849 21.1818 12.8784 22.7873 12.8821Z" fill="#FCF6F4" />\
      <path opacity="0.05" d="M22.7892 12.884C24.4002 12.8859 25.7141 14.1776 25.7141 15.7887C25.7141 17.3997 24.4002 18.7173 22.7892 18.7173C21.1782 18.7173 19.8699 17.4107 19.8735 15.7997C19.8754 14.1869 21.1819 12.8804 22.7892 12.884Z" fill="#FCF7F5" />\
      <path opacity="0.04" d="M22.7892 12.8857C24.4002 12.8876 25.7141 14.1794 25.7141 15.7904C25.7141 17.4014 24.4002 18.719 22.7892 18.719C21.1782 18.719 19.8699 17.4124 19.8735 15.8014C19.8754 14.1886 21.1819 12.8821 22.7892 12.8857Z" fill="#FDF7F6" />\
      <path opacity="0.04" d="M22.7914 12.8875C24.4024 12.8893 25.7145 14.1829 25.7145 15.7939C25.7145 17.4049 24.4024 18.7207 22.7914 18.7207C21.1804 18.7207 19.8739 17.4142 19.8757 15.8031C19.8776 14.1903 21.1841 12.8838 22.7914 12.8875Z" fill="#FDF8F7" />\
      <path opacity="0.03" d="M22.7931 12.8897C24.4041 12.8897 25.7162 14.1869 25.7162 15.798C25.7162 17.409 24.4041 18.7229 22.7931 18.7229C21.1821 18.7229 19.8756 17.4163 19.8774 15.8053C19.8774 14.1925 21.1858 12.8878 22.7931 12.8897Z" fill="#FDF9F8" />\
      <path opacity="0.03" d="M22.7931 12.8914C24.4041 12.8914 25.7143 14.1887 25.7143 15.7997C25.7143 17.4107 24.4041 18.7246 22.7931 18.7246C21.1821 18.7246 19.8756 17.4181 19.8774 15.807C19.8774 14.196 21.1858 12.8895 22.7931 12.8914Z" fill="#FDFAF9" />\
      <path opacity="0.02" d="M22.7948 12.8931C24.4058 12.8931 25.7161 14.1922 25.7161 15.8032C25.7161 17.4142 24.4058 18.7263 22.7948 18.7263C21.1838 18.7263 19.8773 17.4198 19.8792 15.8088C19.8792 14.1977 21.1875 12.8912 22.7948 12.8931Z" fill="#FEFBFA" />\
      <path opacity="0.02" d="M22.7968 12.895C24.4078 12.895 25.7162 14.196 25.7162 15.807C25.7162 17.418 24.4078 18.7282 22.7968 18.7282C21.1858 18.7282 19.8793 17.4217 19.8811 15.8107C19.8811 14.1997 21.1876 12.8932 22.7968 12.895Z" fill="#FEFCFB" />\
      <path opacity="0.01" d="M22.7985 12.897C24.4095 12.897 25.7179 14.1998 25.7179 15.809C25.7179 17.4181 24.4095 18.7302 22.7985 18.7302C21.1875 18.7302 19.881 17.4237 19.8828 15.8127C19.8828 14.2017 21.1893 12.8951 22.7985 12.897Z" fill="#FEFCFC" />\
    </g>\
  </g>\
  <defs>\
    <linearGradient id="paint0_linear_499_2" x1="23.6627" y1="17.2538" x2="21.6439" y2="14.0926" gradientUnits="userSpaceOnUse">\
      <stop stop-color="#F9A020" />\
      <stop offset="0.25" stop-color="#F89B20" />\
      <stop offset="0.53" stop-color="#F68D20" />\
      <stop offset="0.62" stop-color="#F68820" />\
      <stop offset="0.73" stop-color="#F58120" />\
      <stop offset="1" stop-color="#F47521" />\
    </linearGradient>\
    <linearGradient id="paint1_linear_499_2" x1="23.3435" y1="17.2113" x2="20.3927" y2="11.4427" gradientUnits="userSpaceOnUse">\
      <stop stop-color="#F68820" stop-opacity="0" />\
      <stop offset="0.11" stop-color="#EE7F22" stop-opacity="0.14" />\
      <stop offset="0.31" stop-color="#E37226" stop-opacity="0.35" />\
      <stop offset="0.5" stop-color="#DB6829" stop-opacity="0.52" />\
      <stop offset="0.69" stop-color="#D5602C" stop-opacity="0.64" />\
      <stop offset="0.85" stop-color="#D15C2D" stop-opacity="0.71" />\
      <stop offset="0.98" stop-color="#D05B2E" stop-opacity="0.74" />\
    </linearGradient>\
  </defs>\
</svg>'