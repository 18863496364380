import { is_development, log, is_mobile, is_authenticated, get_config, get_availability, format_price, get_site_name } from './commons'

export default {
  methods: {
    is_development: is_development,
    log: log,
    is_mobile: is_mobile,
    is_authenticated: is_authenticated,
    get_config: get_config,
    get_availability: get_availability,
    format_price: format_price,
    get_site_name: get_site_name
  }
}