<template>
  <div>
    <h5 class="vb-heading">Recapito di viaggio</h5>
    <loader v-if="loading_beneficiaries" />
    <validation-observer
      v-if="loading_beneficiaries == false"
      v-slot="{ invalid }"
      ref="observer"
    >
      <!-- se allowCustomBeneficiary==false allora nome, cognome, codice fiscale vanno estrapolati dal beneficiario scelto nella select -->
      <div v-show="!allowCustomBeneficiary">
        <label class="vb-label" v-html="'Viaggiatore principale'" />
        <b-form-select :required="true" v-model="beneficiaryId" :options="beneficiaries" @change="update_beneficiary()"/>
      </div>

      <validation-provider
        v-for="(field,key) in guest"
        v-bind:key="'input_'+key"
        :rules="field.rules"
        :name="key"
        :vid="key"
        v-slot="{ errors, validate, failedRules }">
        <!-- se allowCustomBeneficiary==false allora nome, cognome, codice fiscale vanno inseriti a mano -->
        <div v-if="['guestFirstName','guestLastName', 'guestFiscalCode', 'guestEmail', 'guestEmailConf'].includes(key) == true">
          <div v-show="allowCustomBeneficiary | is_development()"><!--TODO: is_development() mostra i campi anche se allowCustomBeneficiary==false, serve solo per i primi rilasci in test-->
            <label class="vb-label" v-html="field.name" />
            <vb-input
              @input="validate_fields($event,validate,key)"
              v-model="guest[key].value"
              :vb_options="{
                name: key,
                placeholder: field.name,
                disabled: !allowCustomBeneficiary,
              }"
            />
            <!--<span class="vee--errors">{{ errors[0] }}</span>-->
            <div v-for="(rule,key) in failedRules" v-bind:key="_uid+key">
              <span v-if="key!=='required'" class="vee--errors">{{ rule }}</span>
            </div>
          </div>
        </div>

        <div v-else-if="field.type == 'input'" class="mb-4">
          <label class="vb-label" v-html="field.name" />
          <vb-input
            @input="validate_fields($event,validate,key)"
            v-model="guest[key].value"
            :vb_options="{
              name: key,
              placeholder: field.name,
            }"
          />
          <!--<span class="vee--errors">{{ errors[0] }}</span>-->
          <div v-for="(rule,key) in failedRules" v-bind:key="_uid+key">
            <span v-if="key!=='required'" class="vee--errors">{{ rule }}</span>
          </div>
        </div>

        <div v-else-if="field.type == 'checkbox'">
          <vb-checkbox
            v-model="guest[key].value"
            @change="validate_fields($event,validate,key)"
            :vb_options="{
              name: key,
              text: '<p>'+field.text+'</p>',
            }"
          />
          <!--<span class="vee--errors">{{ errors[0] }}</span>-->
        </div>

      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import { service_handler } from '@/services'
import * as Validation from '@resources/validation'
export default {
  name: "sidebar-guest",

  props: {
    validate_on_mount: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading_beneficiaries: true,
      beneficiaryId: '', // default vuoto, non cambiare, viene usato al checkout, setBeneficiaryById
      beneficiaries: [],
      allowCustomBeneficiary: false,
      beneficiaryRelations: [],
      guestRelation: 0,
      guest: {
        guestFirstName: Validation.first_name,
        guestLastName: Validation.last_name,
        guestFiscalCode: Validation.fiscal_code,
        guestEmail: Validation.email,
        guestEmailConf: {
          ...Validation.email_conf,
          rules: 'required|email|confirm_email:@guestEmail', //necessario per specificare che la cross validation va fatta sul campo "guestEmail"
        },

        // TODO b2c gerry
        /*guestAddress: Validation.address,
        guestCity: Validation.city,
        guestProvince: Validation.province,*/

        guestPhone: Validation.phone,
        guestPrivacy: Validation.privacy,
        guestGdpr: Validation.gdpr,
      }
    }
  },

  mounted() {
    this.handle_beneficiaries()
      .then( () => {
        this.emit_results(false)
        /* validazione resettata dopo chiamata ai beneficiari (serviva solo per avere il pulsante "Continua" attivo nel caso in cui il browser autocompletasse i campi, ma non è necessario perché comunque le checkbox vanno spuntate a mano)
        this.$nextTick(() => {
          this.$refs.observer.validate().then(res => {
            let payload = res ? this.get_payload() : false
            this.emit_results(payload)
          })
        })
        */
      })
    
    // in caso di 500 alla book, <sidebar /> forza dall'alto la ri-validazione dei campi per evitare che il pulsante di acquisto resti disabilitato
    // per farlo, ho bisogno di attendere che this.$refs.observer sia disponibile
    if (this.validate_on_mount) {
      let timer = setInterval(() => {
        if (this.$refs.observer) {
          clearInterval(timer)
          this.$refs.observer.validate().then(res => {
            let payload = res ? this.get_payload() : false
            this.emit_results(payload)
          })
        }
      }, 500)
    }      
  },

  methods: {
    async validate_fields(value, validate, name) {
      await validate(value).then(res => {
        if (res.valid == false) {
          this.emit_results(false)
          return
        }

        let fields = this.$refs.observer.fields

        for (let index = 0; index < Object.keys(fields).length; index++) {
          const key = Object.keys(fields)[index]
          if (key == name) continue
          if (fields[key].valid == false) {
            this.emit_results(false)
            return
          }
        }
        this.emit_results(this.get_payload())
      })
    },

    update_beneficiary() {
      let beneficiary = this.beneficiaries.filter(b => { return b.value == this.beneficiaryId})[0]
      this.guest.guestFirstName.value = beneficiary.name
      this.guest.guestLastName.value = beneficiary.surname
      this.guest.guestFiscalCode.value = beneficiary.fiscalCode
      this.guest.guestEmail.value = beneficiary.email
      this.guest.guestEmailConf.value = beneficiary.email
      //this.guest.guestRelation.value = beneficiary.relation
      this.$nextTick( () => {
        this.$refs.observer.validate().then(res => {
          this.log(res)
          let payload = res ? this.get_payload() : false
          this.emit_results(payload)
        })
      })
    },

    get_payload() {
      let fields = this.$refs.observer.fields,
        payload = {}
      for (let index = 0; index < Object.keys(fields).length; index++) {
        const key = Object.keys(fields)[index]
        payload = {
          ...payload,
          [key]: this.guest[key].value
        }
      }
      if(this.allowCustomBeneficiary == false) payload.beneficiaryId = this.beneficiaryId

      return payload
    },

    emit_results(payload) {
      this.$emit('change', {name: 'guest-update', payload: payload})
    },

    handle_beneficiaries() {
      return service_handler('beneficiaries', {
        token: this.$config.token,
        routeName: this.$route.name,
      })
        .then(
          (success) => {
            this.allowCustomBeneficiary = success.data.allowCustomBeneficiary
            let bens = Object.values(success.data.beneficiaries)
            bens.forEach(ben => {
              this.beneficiaries.push({
                value: ben.id,
                name: ben.name,
                surname: ben.surname,
                fiscalCode: ben.fiscalCode,
                email: ben.email,
                text: ben.name + ' ' + ben.surname
              })
            })
            this.beneficiaryId = (this.beneficiaries.length !== 0 ? this.beneficiaries[0].value : 0)
            if(this.allowCustomBeneficiary) {
              success.data.beneficiaryRelations.forEach((rel,key) => {
                this.beneficiaryRelations.push({
                  value: key,
                  name: rel,
                  text: rel,
                })
              })
            }
            let customBen = bens[Object.keys(bens)[0]]
            this.guest.guestFirstName.value = customBen.name
            this.guest.guestLastName.value = customBen.surname
            this.guest.guestFiscalCode.value = customBen.fiscalCode
            this.guest.guestEmail.value = customBen.email
            this.guest.guestEmailConf.value = customBen.email
            this.beneficiaryId = customBen.id
            this.loading_beneficiaries = false
          },
          (error) => {
            this.log('[beneficiaries] - error')
            this.log(error.response)
          }
        )
    },

  }
}
</script>

<style lang="scss" scoped>
.vb-label {
  margin-top: 0.835rem;
}
</style>