<template>
  <section
    class="booking-engine"
    ref="booking-engine"
    v-click-outside="close_engine">

    <b-row>
      <!-- occupancy -->
      <b-col sm="12" lg="2" xl="2" offset-xl="1" class="booking-engine__column" :class="{ current: current_tab == 0 }" @click="current_tab = 0">
        <div class="booking-engine__occupancy active">
          <div class="vb-form">
            <div @click="() => {
              scroll_into_view()
              go_to_tab(0)
            }"
              class="booking-engine__occupancy__form">
              <b-form-input
                ref="input-occupancy"
                id="input-occupancy"
                :placeholder="occupancy_placeholder"
                v-bind="occupancy"
                class="vb-input"
                :class="{ 'focus': current_tab == 2}"
              />
              <div class="booking-engine__occupancy__icon">
                <vb-icon :name="'occupants'" :color="'666666'" />
              </div>              
            </div>
            <div class="booking-engine__floating--occupancy">
              <b-row class="d-flex align-items-end flex-wrap">
                <b-col md="8">
                  <occupancy
                      v-bind="occupancy"
                      @updated="e => {
                        last_departure_airports_selection = departure_airports  // mantengo ultima selezione aeroporti in caso di cambio occupancy
                        this.$store.commit('liveat/departure_airports', [])
                        this.$store.commit('liveat/availabilities', {})
                        this.$store.commit('liveat/keydsmap', [])
                        this.$store.commit('liveat/location', {})
                        this.$store.commit('occupancy', e.occupancy)
                        keyd_map_init = false
                      }"
                      :max_rooms="1"
                      :max_pax="4"
                      :occupancy="occupancy">
                    <template v-slot:title>
                      <p class="semibold d-inline-flex align-items-center cursor-pointer" v-b-popover.hover.top.html="'Il Beneficiario della prestazione (Viaggiatore principale) potrai essere tu, in qualità di titolare del credito welfare, o dovrai, in alternativa, indicare un familiare. Con &quot;familiare&quot; si intende una persona così come definita dal combinato disposto dell\'Art. 433 del Codice Civile e dell\'Art. 12 del D.P.R. del 22.12.1986 n. 917 e smi (TUIR).<br><br>Ti ricordiamo che ai sensi dell\'art. 76 del DPR 445/2000, le dichiarazioni mendaci, la falsità e l\'uso di fatti falsi sono punite ai sensi del Codice penale.'">
                        Chi viaggerà? <vb-icon :name="'info'" :color="'adb5bd'" :size="14" class="ml-1" />
                      </p>
                    </template>
                    <!--<template v-slot:disclaimer>
                      <p class="text--sm mt-3 mb-0">
                        Se il tuo bambino compie gli anni in viaggio indicaci l’età come segue: <br> 0-2 anni al momento del rientro / 2-17 anni al momento della partenza
                      </p>
                    </template>-->
                  </occupancy>
                </b-col>
                <b-col md="4" class="d-flex align-items-end justify-content-end">
                  <div class="text-center">
                    <vb-button
                      @click.prevent="() => {
                        go_to_tab(1)
                        keyd_map_init = true
                      }"
                      :vb_options="{
                        text: 'OK',
                        icon_disabled: 'lock',
                        disabled: occupancy[0].adults==0,
                      }"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

      </b-col>

      <!-- destinations -->
      <b-col sm="12" lg="3" xl="2" class="booking-engine__column" :class="{ current: current_tab == 1 }">
        <div class="booking-engine__autocomplete" :class="{ active: keyd_map_init }">
          <div class="vb-form">
            <button ref="hidden_input" class="focus-dump-button" />
            <!--serve per togliere il focus dagli autocomplete dopo il click, così poi al focus si seleziona di nuovo tutto il testo ed è più semplice cancellarlo-->
            <autocomplete
                base-class="booking-engine__autocomplete"
                ref="autocomplete"
                :key="location.label"
                :search="get_keyds"
                :placeholder="autocomplete_placeholder"
                :debounce-time="500"
                @submit="on_submit_location">
              <template #default="{ inputProps, inputListeners, results, noResults, focused, resultProps, resultListProps, resultListListeners }">
                <div class="booking-engine__autocomplete__form">
                  <input
                    type="search"
                    class=""
                    :class="[
                      'vb-input form-control',
                      { 'autocomplete-input-no-results': noResults },
                      { 'autocomplete-input-focused': focused },
                      { 'focus': current_tab == 0}
                    ]"
                    v-bind="inputProps"
                    v-on="inputListeners"
                    @focus="$event => {
                      autocomplete_focus = true
                      scroll_into_view()
                      $event.target.select()
                      current_tab = 1
                    }"
                    @blur="() => {
                      autocomplete_focus = false
                      autocomplete_blur
                    }"
                  />
                  <div class="booking-engine__autocomplete__icon">
                    <vb-icon :name="'symbol_vv'" />
                  </div>
                </div>
                
                <b-row>
                  <b-col>
                    <ul v-bind="resultListProps" v-on="resultListListeners" class="booking-engine__autocomplete-result-wrap">
                      <li
                        v-for="(result, index) in results"
                        :style="result.type == 'noresults' ? 'pointer-events: none' : ''"
                        :key="resultProps[index].keyd"
                        :data-type="result.type"
                        v-bind="resultProps[index]">
                        <div v-if="result.type == 'noresults'" v-html="result.label" />
                        <div v-else class="cursor-pointer" :style="'padding-left:calc('+(result.level-1)+'*0.5rem)'" v-html="result.label.charAt(0).toUpperCase() + result.label.slice(1).toLowerCase()" />
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                
                <div class="booking-engine__floating--suggestions" v-show="resultProps.length == 0">
                  <keyd-map @keyd="e => on_submit_location(e)" v-if="keyd_map_init" ref="keyd-map" />
                </div>

              </template>
            </autocomplete>
          </div>
        </div>
      </b-col>

      <!-- airports -->
      <b-col sm="12" lg="3" xl="2" class="booking-engine__column" :class="{ current: current_tab == 2 }">
        <div
          class="booking-engine__airports"
          :class="{ active: departure_airports.length > 0 }"
        >
          <div class="vb-form">
            <div @click="() => {
              scroll_into_view()
              go_to_tab(2)
            }"
              class="booking-engine__airports__form">
              <b-form-input
                ref="input-airports"
                id="input-airports"
                :placeholder="airports_placeholder"
                v-bind="departure_airports"
                class="vb-input"
                :class="{ 'focus': current_tab == 2}"
              />
              <div class="booking-engine__airports__icon">
                <vb-icon :name="'airplane'" :color="'666666'" />
              </div>              
            </div>
            <div class="booking-engine__floating">
              <loader v-if="loading" />
              <div v-else>
                <div v-if="departure_airports_no_results">
                  Purtroppo non ci sono disponibilità per questa destinazione/passeggeri
                  <br/><br/>
                </div>
                <div v-else>
                  <div class="booking-engine__airports-list">
                    <b-row>
                      <b-col sm="12">
                        <p class="semibold">Scegli l'aeroporto</p>
                      </b-col>
                      <b-col
                          sm="6"
                          md="4"
                          v-for="(airport,airport_index) in departure_airports"
                          v-bind:key="_uid+airport_index"
                          class="mb-3 mb-lg-2"
                      >
                        <vb-checkbox
                          @change="get_availabilities"
                          v-model="airport.selected"
                          :vb_options="{
                              text: airport.name.toLowerCase() + ' (' + airport.iata_code + ')',
                              disabled: false,
                            }"
                        />
                      </b-col>
                    </b-row>
                  </div>
                  <div class="booking-engine__airports-list-btn">
                    <div class="mr-2" v-show="search_params.departure_airports.length && !has_availabilities" v-html="'Attendi'" />
                    <vb-button
                      @click.prevent="go_to_tab(3)"
                      :vb_options="{
                        text: 'OK',
                        disabled: !has_availabilities,
                        icon_disabled: 'lock'
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <!-- availability -->
      <b-col sm="12" lg="2" xl="2" class="booking-engine__column" :class="{ current: current_tab == 3 }">
        <div
          class="booking-engine__dates"
          :class="{ active: has_availabilities }">
          <div class="vb-form">
            <div @click="scroll_into_view();go_to_tab(3)" class="booking-engine__dates__form">
              <b-form-input
                id="input-availabilities"
                :placeholder="availabilities_placeholder"
                v-bind="availabilities_bind"
                class="vb-input"
                :class="{ 'focus': current_tab == 3}"
              />
              <div class="booking-engine__dates__icon">
                <vb-icon :name="'calendar'" :color="'666666'" />
              </div>
            </div>
            <div class="booking-engine__floating">
              <div class="booking-engine__dates-list">

                <div class="d-flex justify-content-between">
                  <!--<p class="semibold mb-2">Durata della vacanza</p>
                  <vb-checkbox
                    v-model="flex_dates"
                    :vb_options="{
                      text: 'Data check-in flessibile (+/- 3 giorni)',
                      switch:true,
                    }"
                  />-->
                </div>
                <b-tabs vertical :tag="'span'">
                  <template #tabs-start>
                    <p class="semibold mb-2 w-100">Durata</p>
                  </template>
                  <b-tab
                    v-for="(availability,availability_index) in availabilities"
                    v-bind:key="_uid+availability_index"
                    :active="availability_index == 1"
                    :disabled="availability.length == 0">

                    <template #title>
                      <vb-button
                          :vb_options="{
                            text: availability_index.replace('-','/') + ' notti',
                            variant: 'dark',
                          }"
                      >
                        <b-badge pill variant="primary">{{ availability.length }}</b-badge>
                      </vb-button>
                    </template>

                    <div class="booking-engine__dates-content">
                      <p class="semibold mb-2">Data di partenza</p>
                      <v-calendar 
                        :locale="{ masks: { weekdays: 'WWW' } }"
                        disable-page-swipe is-expanded
                        v-if="availability.length"
                        :min-date="new Date(availability[0].dep)"
                        :max-date="new Date(availability[availability.length - 1].dep)"
                        :attributes="availability.map((a,index) => {
                          return {
                            key:index,
                            dates: new Date(a.dep),
                            customData: {
                              ...a
                            },
                          }
                        })">
                        <template v-slot:day-content="{ day, attributes }">
                          <div v-if="attributes !== undefined"
                            :id="'av-index--'+availability_index+'-'+encode_parameters(day)"
                            @click.stop="() => on_submit_date(attributes[0].customData)"
                            class="cursor-pointer"
                            :class="{
                              'ok': date_check(day),
                              'ko': !date_check(day),
                              'inactive': (attributes.length == 0),
                              'selected': attributes[0] !== undefined ? (attributes[0].customData.dep == departure_date) : false,
                              'multiple': (attributes.length > 1),
                            }">
                            <div class="day-label text-sm text-gray-900 text-center">{{ day.day }}</div>
                            <!--<div v-if="attributes.length == 0">
                              <vb-button v-if="!is_mobile()"
                                class="invisible"
                                :vb_options="{
                                  variant: 'disabled',
                                  size: 'sm',
                                  text: 'Placeholder btn'
                                }"
                              />
                            </div>
                            <div v-if="attributes.length > 0">
                              <div>
                                <div class="text-center">
                                  <div v-if="!is_mobile()">
                                    <b-button>
                                      <span class="text text--sm">da</span>
                                      <span class="price text text--sm"> {{attributes[0].customData.p}} €</span>
                                    </b-button>

                                    <div v-if="attributes.length > 1">
                                      <b-badge variant="info">{{ attributes.length }} soluzioni</b-badge>
                                      <b-popover :target="'av-index--'+availability_index+'-'+encode_parameters(day)" triggers="hover" placement="bottom">
                                        <div v-for="attr in attributes"
                                          :key="attr.key"
                                          class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1 d-flex align-items-center"
                                          v-click-outside="close_popovers">
                                          <span class="d-inline-block mr-3">
                                            {{ attr.customData.n }} notti - {{ attr.customData.p }} €
                                          </span>
                                          <vb-button
                                            :vb_options="{
                                              size: 'sm',
                                              variant: 'info shadow',
                                              //variant: (attr.customData.dep == departure_date && attr.customData.n == nights) ? 'success shadow' : 'info shadow', // => NO: se ho più soluzioni nello stesso giorno con lo stesso numero di notti ma con prezzo diverso poi mi vengono mostrate entrambe green
                                              //text: attr.customData.p + ' €<div class=\'d-inline-block mx-1\'>|</div>' + attr.customData.n + ' N'
                                              text: 'scegli'
                                            }"
                                            @click.stop="() => on_submit_date(attr.customData)"/>
                                        </div>
                                      </b-popover>
                                    </div>                                    
                                  </div>

                                  <b-button v-if="is_mobile()">
                                    <b-badge variant="success"></b-badge>
                                  </b-button>


                                </div>
                              </div>
                            </div>-->
                          </div>
                        </template>
                      </v-calendar>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <!-- search button -->
      <b-col sm="12" lg="2" xl="2" class="booking-engine__column d-flex align-self-end">
        <div class="booking-engine__search" :class="{ active: departure_date !== '' }">
          <div class="vb-form text-center">
            <b-form-group
              label-for="input-autocomplete"
              label=""
              label-class="vb-label">
              <vb-button
                :key="searching"
                @click="search"
                :vb_options="{
                  text: (searching ? 'Attendi' : 'Cerca'),
                  disabled: (departure_date == '' || searching),
                  variant: 'info',
                  icon_disabled: 'lock'
                }"/>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import Occupancy from '@components/blocks/occupancy.vue'
import KeydMap from '@alpitour/components/blocks/keyd-map/keyd-map.vue'
import { service_handler } from '@services'
import { encode_parameters, reverse_date, get_config, is_mobile, custom_event } from '@utilities'

export default {
  name: 'alpitour-booking-engine',

  components: {
    'occupancy': Occupancy,
    'keyd-map': KeydMap,
  },

  props: {
  },

  computed: {
    // global state keys
    ...mapState([
      'occupancy',
      'searching',
    ]),    
    // module state keys
    ...mapState(
      'liveat', [
        'location',
        'departure_airports',
        'products',
        'statics',
        'keyds',
        'availabilities',
        'last_search',
        'current_search',
        'departure_date',
        'flex_dates',
        'nights',
        'keydsmap',
      ]
    ),
    flex_dates: {
      get() {
        return this.$store.state.liveat.flex_dates
      },
      set(value) {
        this.$store.commit('liveat/flex_dates', value)
      }
    },
    search_params() {
      return {
        occupancy: this.occupancy[0],
        keyd: this.location.keyd,
        nights: this.nights,
        departure_date: this.departure_date,
        flex_dates: this.flex_dates,
        departure_airports: Object.values(this.departure_airports.map(a => {
          if (a.selected) {
            return [a.iata_code]
          }
          return []
        })).flat(),
        brands: get_config().guiSettings.filterBrands ? get_config().guiSettings.filterBrands : [],
      }
    },
    autocomplete_placeholder() {
      let default_placeholder = 'Dove vuoi andare'
      if (this.autocomplete_focus) return default_placeholder
      if (this.keydsmap.length == 0) return default_placeholder
      return this.location.label ? (this.location.label.charAt(0).toUpperCase() + this.location.label.slice(1).toLowerCase()) : default_placeholder
    },
    airports_placeholder() {
      if (this.search_params.departure_airports.length == 0) return 'Da dove vuoi partire?'
      let placeholder = this.departure_airports.map(a => {
        if (a.selected) return (a.name.charAt(0).toUpperCase() + a.name.slice(1).toLowerCase())
      })
        .filter(n => n) //remove empty elements (a.selected == false)
        .join(', ')

      //placeholder += ', '+this.occupancy[0].adults+' ad'
      //if (this.occupancy[0].children.length) placeholder += ', '+this.occupancy[0].children.length + ' bamb'
      return placeholder
    },
    occupancy_placeholder() {
      if (this.occupancy[0].adults == 0) return 'Quanti ospiti?'
      let placeholder = this.occupancy[0].adults + ' adult' + (this.occupancy[0].adults == 1 ? 'o' : 'i')
      if (this.occupancy[0].children.length) placeholder += ', '+this.occupancy[0].children.length + ' bambin' + (this.occupancy[0].children.length == 1 ? 'o' : 'i')
      return placeholder
    },
    availabilities_placeholder() {
      if (this.departure_date == '') return 'Quando?'
      return reverse_date(this.departure_date).replaceAll('-', '/')
    },
    availabilities_bind() {
      let availabilities_bind = {}
      Object.keys(this.availabilities).forEach(key => {
        availabilities_bind['a' + key.replace('-', '').replace('+', '')] = this.availabilities[key]
      })
      return availabilities_bind
    },
    has_availabilities() {
      return Object.values(this.availabilities).length > 0
    }
  },

  created() {
    this.encode_parameters = encode_parameters
    this.reverse_date = reverse_date
    this.is_mobile = is_mobile
  },

  data() {
    //const month = new Date().getMonth()
    //const year = new Date().getFullYear()
    return {
      current_tab: -1,
      calendar_config: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      attributes: [
        {
          key: 1,
          dates: new Date('2023-02-01')
        }
      ],
      autocomplete_focus: false,
      departure_airports_no_results: false,
      loading: false,
      default_departure_date: '',
      last_departure_airports_selection: [],
      keyd_map_init: false,
    }
  },

  mounted() {
    window.eventBus.$off('suggestion')
    window.eventBus.$on('suggestion', () => {
      this.default_departure_date = this.departure_date
      this.search()
      this.get_availabilities()
    })
    this.keyd_map_init = (this.occupancy[0].adults > 0)
  },

  methods: {
    scroll_into_view() {
      this.$refs['booking-engine'].scrollIntoView({ behavior: 'smooth' })
    },
    close_engine() {
      if (this.current_tab == 0 && this.occupancy[0].adults == 0) return
      if (this.keydsmap.length == 0) return
      this.current_tab = -1
    },
    close_popovers() {
      this.$root.$emit('bv::hide::popover')
    },
    go_to_tab(target) {
      setTimeout(() => {
        this.current_tab = target
      }, 1)
    },
    date_check(day) {
      return new Date(day.id) >= (new Date())
    },
    autocomplete_blur() {
      this.$refs['autocomplete'].$data.value = ''//this.location.label
    },
    reset_search_param(param) {
      switch (param) {
        case 'departure_date':
        case 'keyd':
        case 'nights':
          this.$store.commit('liveat/' + param, '')
          break

        case 'location':
          this.$store.commit('liveat/' + param, {
            label: '',
            path: '',
            keyd: -1,
          })
          break

        case 'statics':
        case 'keyds':
        case 'availabilities':
          this.$store.commit('liveat/' + param, {})
          break

        case 'products':
        case 'departure_airports':
          this.$store.commit('liveat/' + param, [])
          break

        default:
          break;
      }
    },
    encode_search_params() {
      return encode_parameters(this.search_params)
    },
    get_keyds(q) {
      // condizioni uscita chiamata autocomplete
      if (!q) return []
      if (q == '') return []
      if (q.length < 3) return []
      //TODO: impedire chiamate indesiderate se (q == this.location_label)
      return service_handler(
        'keyds',
        {
          q: q,
          brands: (get_config()['guiSettings']['filterBrands'])? get_config()['guiSettings']['filterBrands'] : [],
        },
        {
          modal: false // blocca la modal in caso di errore
        }
      ).then(
        (response) => {
          let suggestions = response.data.data.keyds
          if (suggestions.length == 0) {
            suggestions = [{
              'type': 'noresults',
              'keyd': '',
              'label': 'nessun risultato'
            }]
          }
          return suggestions
        },
        (error) => {
          (error)
          return [{
            'type': 'noresults',
            'keyd': '',
            'label': 'si è verificato un problema con la ricerca'
          }]
        }
      )
    },
    get_airports() {
      if (this.departure_airports.length > 0) this.last_departure_airports_selection = this.departure_airports
      this.reset_search_param('departure_airports')
      this.reset_search_param('availabilities')
      this.$store.commit('liveat/current_search', this.encode_search_params())
      this.departure_airports_no_results = false
      this.loading = true
      service_handler(
        'airports',
        {
          keyd: this.location.keyd,
          occupancy: this.occupancy[0],
          brands: (get_config()['guiSettings']['filterBrands'])? get_config()['guiSettings']['filterBrands'] : [],
        }
      ).then(
        (success) => {
          let airports = success.data.data.airports
          let departure_airports = airports.map(a => {
            return {
              ...a,
              selected: this.last_departure_airports_selection.filter(dep => { return (dep.iata_code == a.iata_code) && dep.selected }).length > 0,
            }
          })
          this.$store.commit('liveat/departure_airports', departure_airports)
          // se c'è almeno un aeroporto già selezionato faccio partire subito la richiesta alle availabilities
          if (departure_airports.filter(a => { return a.selected }).length > 0) this.get_availabilities()
          if (departure_airports.length == 0) this.departure_airports_no_results = true
          this.loading = false
          this.current_tab = 2
        },
        (error) => {
          (error)
        }
      )
    },
    get_availabilities() {
      this.$store.commit('liveat/availabilities', {})
      this.$store.commit('liveat/departure_date', '')
      this.$store.commit('liveat/current_search', this.encode_search_params())
      if (this.search_params.departure_airports.length == 0) return
      service_handler(
        'availabilities',
        {
          keyd: this.location.keyd,
          departure_airports: Object.values(this.departure_airports.map(a => {
            if (a.selected) {
              return [a.iata_code]
            }
            return []
          })).flat(),
          mu: (get_config()['mu'])? get_config()['mu'] : '',
          occupancy: this.occupancy[0],
          brands: (get_config()['guiSettings']['filterBrands'])? get_config()['guiSettings']['filterBrands'] : [],
        }
      ).then(
        (success) => {
          let availabilities = {}
          Object.keys(success.data.data.availabilities).forEach(av => {
            if (success.data.data.availabilities[av].length > 0)
            availabilities = {
              ...availabilities,
              [av]: success.data.data.availabilities[av]
            }
          })
          this.$store.commit('liveat/availabilities', availabilities)
          if (this.default_departure_date) {
            /*
              la default_departure_date arriva dall'evento 'suggestion' generato dalle searchSuggestions
              se c'è una default_departure_date, allora aggiorno anche current_search e last_search per evitare che compaia la alert warning che segnala il cambio di criteri di ricerca
              la stessa cosa avviene, a prescindere da default_departure_date, dentro search() dopo ogni ricerca
              questa eccezione deve essere innescata solo al click su una delle suggestions, altrimenti va indagata più a fondo ed eventualmente ripensata              
            */
            this.$store.commit('liveat/departure_date', this.default_departure_date)
            this.$store.commit('liveat/current_search', this.encode_search_params())
            this.$store.commit('liveat/last_search', this.encode_search_params())
          }
        },
        (error) => {
          (error)
        }
      )
    },
    search() {
      // site specific logic
      this.close_engine()
      this.reset_search_param('products')
      this.reset_search_param('statics')
      this.reset_search_param('keyds')
      this.$store.commit('liveat/filter_keyd', [])
      this.$store.commit('liveat/filter_accommodation', [])
      this.$store.commit('liveat/filter_rating', [])
      this.$store.commit('liveat/filter_airport', [])
      this.$store.commit('liveat/filter_brand', [])
      this.$store.commit('liveat/filter_availability', false)
      // shared logic
      this.$store.commit('liveat/current_search', this.encode_search_params())
      this.$store.commit('liveat/last_search', this.encode_search_params())
      this.$store.commit('searching', true)
      this.$store.commit('liveat/trigger_search', true)
      this.$emit('search', {})
    },
    on_submit_date(attrs) {
      this.$store.commit('liveat/departure_date', attrs.dep)
      this.$store.commit('liveat/nights', attrs.n)
      this.close_engine()
      //this.search()
    },
    on_submit_location(location) {
      this.$refs.hidden_input.click()
      this.$store.commit('liveat/location', location)
      this.reset_search_param('availabilities')
      this.reset_search_param('departure_date')
      this.$store.commit('liveat/current_search', this.encode_search_params())
      this.current_tab = 2 // per evitare che resti aperto il tab <keyd-map>
      custom_event('keyd', location.label, location.keyd)
      this.get_airports()
    }
  }
}
</script>

<style lang="scss">

// extend sfondo incavato grafica
%bg-window {
  //box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
  padding: 1rem;
  border-radius: .313rem;
  margin-top: .65rem;
  background-color: $white;//$vb-body-bg;
}

%step {
  z-index: 10;
  height: 100%;
  &:not(.active) {
    pointer-events: none;

    input {
      background: $gray-300;
    }
  }
}

/* rimosso lo scoped: non permetteva di modificare lo style delle tabs */
.booking-engine {
  $this: &;
  $border: 1px solid $gray-400;
  position: relative;
  z-index: 100;
  max-width: 1140px;
  margin: 0 auto;

  // Focus Button
  .focus-dump-button {
    opacity: 0;
    position: absolute;
    left: -999999px;
    top: -999999px;
  }


  // Classi Utility

  .static {
    position: static;
  }

  .current {
    .booking-engine__floating {
      display: block;
    }
  }


  // Input

  .form-group {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  input {
    &::placeholder {
      //text-transform: capitalize;
    }
    &:focus {
    }
  }

  #input-occupancy,
  #input-airports,
  #input-availabilities {
    pointer-events: none;
  }

  // Wrappers

  &__column {
    @extend .static;
    padding: 0 .313rem !important;
    margin-bottom: $grid-gutter-width/10;

    &:last-child {
      margin-top: $grid-gutter-width/2;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;

      &.current:not(:first-child) {
        border-bottom-color: $white;
      }
    }

    &.current input {
    }
  }

  &__floating {
    display: none;
    @extend %bg-window;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: auto;
      left: 0;
      width: 100%;

      // per gli aeroporti il wrapper deve occupare più spazio
      @at-root #{$this}__airports & {
        @include media-breakpoint-up(lg) {
          width: 150%;
        }
      }

      // per il calendar il wrapper deve occupare più spazio
      @at-root #{$this}__dates & {
        width: 100%;
        left: auto;
        right: 0;

        &:before {
          left: auto;
          right: 26%;
        }

        @include media-breakpoint-up(xl) {
          width: 70%;
          &:before {
            left: auto;
            right: auto;
          }
        }
      }
    }

    // titolo checkbox
    .vb-check__title {
      text-transform: capitalize;

      @at-root #{$this}__airports & {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-sm;
        }
      }
    }

    &--suggestions {
      @extend .booking-engine__floating;
      left: -$grid-gutter-width/4;
      right: -$grid-gutter-width/4;
      width: auto;
      @include media-breakpoint-up(xl) {
        left: calc(100%/12 - #{$grid-gutter-width/6});
        right: calc(100%/12 - #{$grid-gutter-width/6});
      }
    }

    &--occupancy {
      @extend .booking-engine__floating;
      left: -$grid-gutter-width/4;
      width: auto;
    }
    
    &--occupancy {
      @extend .booking-engine__floating;
      left: -$grid-gutter-width/4;
      width: auto;
    }
  }


  // Occupancy
  &__occupancy {
    @extend %step;
    position: static;
    .booking-engine__floating {
      width: auto;
      @include media-breakpoint-up(md) {
        max-width: calc(70% - #{$grid-gutter-width});
      }
      @include media-breakpoint-up(xl) {
        left: calc(100%/12 - .313rem);
        max-width: calc(50%);
      }      
    }
  }

  // Destinazione
  &__autocomplete {
    @extend %step;
    position: static;
    z-index: 11;

    &__form {
      position: static;
    }

    input {
      caret-color: $info;
      &:focus {
        &~.booking-engine__autocomplete__icon {
          display: none;
        }
      }
    }

    // classi generate con vue-autocomplete
    &-result {
      list-style: none;
      border-bottom: 1px solid $gray-300;
      margin-bottom: .313rem;
      padding-bottom: .25rem;
      text-transform: capitalize;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0gMTYgMyBDIDExLjA0Mjk2OSAzIDcgNy4wNDI5NjkgNyAxMiBDIDcgMTMuNDA2MjUgNy41NzAzMTMgMTUuMDE5NTMxIDguMzQzNzUgMTYuNzgxMjUgQyA5LjExNzE4OCAxOC41NDI5NjkgMTAuMTEzMjgxIDIwLjQxNDA2MyAxMS4xMjUgMjIuMTU2MjUgQyAxMy4xNDg0MzggMjUuNjQ0NTMxIDE1LjE4NzUgMjguNTYyNSAxNS4xODc1IDI4LjU2MjUgTCAxNiAyOS43NSBMIDE2LjgxMjUgMjguNTYyNSBDIDE2LjgxMjUgMjguNTYyNSAxOC44NTE1NjMgMjUuNjQ0NTMxIDIwLjg3NSAyMi4xNTYyNSBDIDIxLjg4NjcxOSAyMC40MTQwNjMgMjIuODgyODEzIDE4LjU0Mjk2OSAyMy42NTYyNSAxNi43ODEyNSBDIDI0LjQyOTY4OCAxNS4wMTk1MzEgMjUgMTMuNDA2MjUgMjUgMTIgQyAyNSA3LjA0Mjk2OSAyMC45NTcwMzEgMyAxNiAzIFogTSAxNiA1IEMgMTkuODc4OTA2IDUgMjMgOC4xMjEwOTQgMjMgMTIgQyAyMyAxMi44MDA3ODEgMjIuNTcwMzEzIDE0LjMxNjQwNiAyMS44NDM3NSAxNS45Njg3NSBDIDIxLjExNzE4OCAxNy42MjEwOTQgMjAuMTEzMjgxIDE5LjQ1MzEyNSAxOS4xMjUgMjEuMTU2MjUgQyAxNy41NTQ2ODggMjMuODY3MTg4IDE2LjU3ODEyNSAyNS4zMDA3ODEgMTYgMjYuMTU2MjUgQyAxNS40MjE4NzUgMjUuMzAwNzgxIDE0LjQ0NTMxMyAyMy44NjcxODggMTIuODc1IDIxLjE1NjI1IEMgMTEuODg2NzE5IDE5LjQ1MzEyNSAxMC44ODI4MTMgMTcuNjIxMDk0IDEwLjE1NjI1IDE1Ljk2ODc1IEMgOS40Mjk2ODggMTQuMzE2NDA2IDkgMTIuODAwNzgxIDkgMTIgQyA5IDguMTIxMDk0IDEyLjEyMTA5NCA1IDE2IDUgWiBNIDE2IDEwIEMgMTQuODk0NTMxIDEwIDE0IDEwLjg5NDUzMSAxNCAxMiBDIDE0IDEzLjEwNTQ2OSAxNC44OTQ1MzEgMTQgMTYgMTQgQyAxNy4xMDU0NjkgMTQgMTggMTMuMTA1NDY5IDE4IDEyIEMgMTggMTAuODk0NTMxIDE3LjEwNTQ2OSAxMCAxNiAxMCBaIi8+PC9zdmc+");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 1.2rem;
      padding-left: 1.563rem;

      &:hover {
        color: $info;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      // wrap li, contenitore per la scrollbar
      &-wrap {
        overflow: auto;
        max-height: 250px;

        // solo per firefox
        scrollbar-width: thin;
        scrollbar-color: $gray-500 transparent;
        //

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-600;
          border-radius: 2.5px;
        }
      }

      // ul
      &-list {
        @extend %bg-window;
      }
    }

    &__floating--suggestions {
      @include media-breakpoint-up(md) {
        left: calc(100%  / 12);
        width: auto;
      }
    }
  }

  // Volo
  &__airports {
    @extend %step;

    &-list-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid $gray-300;
      padding-top: $grid-gutter-width/2;
      margin-top: $grid-gutter-width/2;
    }
    
    .booking-engine__floating {
      @include media-breakpoint-up(xl) {
        left: calc(100%/12*5 + #{$grid-gutter-width/6});
        right: calc(100%/12 - #{$grid-gutter-width/6});
        width: auto;
      }
    }
  }

  // Periodo
  &__dates {
    @extend %step;

    // override tab bootstrap
    .nav {

      &-item {
        margin-bottom: $grid-gutter-width/4;
        margin-right: $grid-gutter-width/4;
        width: 45%;

        @include media-breakpoint-up(md) {
          margin-right: 0;
          width: auto;
        }

        a,button {
          width: 100%;
        }
      }

      &-tabs {
        margin-top: 0;//$grid-gutter-width;
        margin-bottom: $grid-gutter-width/2;
        flex-direction: row !important;

        @include media-breakpoint-up(md) {
          flex-direction: column !important;
        }

      }

      &-link {
        height: 100%;
        padding: 0;
        margin-right: $grid-gutter-width/2;

        &.active {
          & > button {
            color: $vb-body-color;
            font-weight: $vb-font-weight-semibold;
          }
        }

        button {
          height: 100%;
          .badge-pill {
            transform:translateY(-5px);
            margin-left:6px;
            display: inline-flex;
            aspect-ratio: 1 / 1;
            align-items: center;
            justify-content: center;
            padding:0 0.3rem;
          }
          span:last-child {
            
          }
        }
      }
    }

    // v-calendar
    .vc {
      &-container {
        border-radius: $border-radius;
        background-color: transparent;
        width: 100%;
        border: 0;
      }

      &-header {
        margin-bottom: $grid-gutter-width/4;
        * {
          font-size: $font-size-base;
        }
      }

      &-weeks {
        padding:0;
        grid-gap:1px;
      }

      &-weekday {
        //box-shadow:0 0 0 1px $gray-300;
        color: $gray-600;
        text-transform: uppercase;
        font-size: $font-size-sm;
      }

      &-arrow {

        &:hover {
          background: none;
        }
        svg {
          width: 25px;
          color: $vb-body-color;
        }
      }

      &-day {
        color: $info;
        .is-disabled {
          color: $dark !important;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        margin-bottom:0;
        //height: 46px;
        box-shadow:0 0 0 1px $gray-300;
        &>* {
          display: block;
          width: 100%;
          padding:0.35rem;
          height:100%;
        }
        .day-label,
        .vc-day-content {
          font-size: $font-size-sm;
          border-radius: 100px;
          line-height: 1;
          margin: 0 auto;
          display: block;
          color: $gray-600;
        }

        .day-label {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;          
        }

        &.is-not-in-month {
          visibility: hidden;
          height:1px;
          min-height:auto;
          overflow: hidden;

          .ok {
            display: none;
          }
        }

        .ok {
          max-height: 46px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          &.multiple {
            max-height: 70px;
            button {
              display: none;
            }
            .badge {
              display: block;
            }
          }
          &:not(.inactive) {
            .day-label {
              //font-size: $font-size-base;
              font-weight: $vb-font-weight-bold;
            }
            //color: $info;
            //font-weight: $vb-font-weight-semibold;
            transition: $transition-base;
            * {
              transition: $transition-base;
            }
            &:not(.selected) {
              * {
                transition: $transition-fade !important;
              }
              &:hover {
                color: $info;
                //font-weight: $vb-font-weight-semibold;
                /*
                .day-label {
                  transform: scale(1.35);
                }
                .btn {
                  transform: scale(1.2);
                }
                */
                background-color: $info;
                color: $white;
                * {
                  color: $white;
                }
              }
            }
          }

          &.selected,
          &.selected * {
            border: 0;
            background: $success !important;
            //border-radius: $border-radius;
            color: $white !important;
          }
        }

        &>span:not(.ok),
        .inactive {
          cursor: auto;
          pointer-events: none;
          //background: rgba($vb-disabled, 0.1);

          //& > * {
            opacity: 0.35;
          //}
        }

        .btn {
          padding: 0;
          border: 0;
          text-decoration: none;
          background: transparent !important;
          box-shadow: none !important;
          color: inherit !important;
          display: inline;
          max-width: 100%;
          width: 70px;

          &-success {
            color: $success !important;
          }

          .price {
            font-weight: $font-weight-bold;
          }

          .nights {
            font-size: $font-size-sm;
          }

          // todo: da rivedere il flusso
          .badge {
            width: 10px;
            height: 10px;
            display: block;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
              margin-top: -15px;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      .vc {
        &-day {
          height: auto;
          * {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .booking-engine__floating {
      @include media-breakpoint-up(xl) {
        left: calc(100%/12*3);
        right: calc(100%/12 - #{$grid-gutter-width/6});
        width: auto;
      }
    }
  }

  &__search {
    @extend %step;
    flex-basis: 100%;
    z-index: auto;

    button {
      font-weight: $vb-font-weight-semibold;
      justify-content: center;
      box-shadow: none !important;
      height: calc(1.5em + 1.25rem + 2px);
      width: 80%;

      @include media-breakpoint-up(lg) {
        width: 100%;
      }
      
    }
  }

  // icons
  &__occupancy,
  &__airports,
  &__dates,
  &__autocomplete {
    &__form {
      position: relative;
    }
    &__icon {
      position: absolute;
      top:50%;
      right:1rem;
      height:24px;
      transform: translateY(-50%);
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      filter:grayscale(100%);
      svg {
        height:24px;
        width:auto;
      }
    }
  }

  .popover {
    * {
      border:0px;
      box-shadow: none;
    }
  }
}
</style>