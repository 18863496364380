// node_modules dependencies
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import VueGtm from 'vue-gtm'
import VueMeta from 'vue-meta'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/it.json'
import VueVirtualScroller, { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'bootstrap-vue/src/icons.scss'
import { vb_library } from '@library'
import { vb_icons } from '@components/svg'
import VCalendar from 'v-calendar'
import Autocomplete from '@trevoreyre/autocomplete-vue'
//import VueLazyload from 'vue-lazyload'
import VueMoment from 'vue-moment'
import { register } from 'swiper/swiper-element'
import SidebarCheckout from '@src/components/blocks/sidebar/sidebar.vue'

// app dependencies
import site_config from '@running_app_src/main'
import Store from '@vuex'
import Router from '@router/router'
import App from '@src/App.vue'
import Mixins from '@utilities/mixins'
import Sass from '@utilities/sass'
import { ClickOutside, CustomLink, capitalize, uppercase, lowercase } from '@/resources'
import ModalNotification from '@components/blocks/modal-notification.vue'
import ToastNotification from '@components/blocks/toast-notification.vue'

// components
Vue.component('sidebar', SidebarCheckout)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('custom-link', CustomLink)
Vue.component('dynamic-scroller', DynamicScroller)
Vue.component('dynamic-scroller-item', DynamicScrollerItem)
Vue.component('modal-notification', ModalNotification)
Vue.component('toast-notification', ToastNotification)
Vue.component('autocomplete', Autocomplete)
vb_library()
vb_icons()
register() //register Swiper custom elements

// directives
Vue.directive('click-outside', ClickOutside)

// mixins
Vue.mixin(Mixins)
Vue.mixin(Sass)

// filters
Vue.filter('capitalize', e => capitalize(e)) //ex: {{ 'text to be capitalized' | capitalize }}
Vue.filter('uppercase', e => uppercase(e))
Vue.filter('lowercase', e => lowercase(e))

// use declaration
Vue.use(VueCookies)
//Vue.use(VueLazyload, {})
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons) //https://icons.getbootstrap.com/#icons
Vue.use(VueVirtualScroller)
Vue.use(VueGtm, {
  id: process.env.VUE_APP_TAG_MANAGER, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled: (process.env.VUE_APP_TAG_MANAGER_ENABLED == 'true'), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: (process.env.VUE_APP_DEBUG == 'true'), // Whether or not display console logs debugs (optional)
  loadScript: (process.env.VUE_APP_TAG_MANAGER_ENABLED == 'true'), // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: Router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
})
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true, // optional pluginOptions
})
Vue.use(VueMoment)
Vue.use(VCalendar)

// global settings
Vue.prototype.window = window
window.eventBus = new Vue() //catch events from api.js
Vue.config.productionTip = false
Vue.config.performance = (process.env.VUE_APP_MODE == 'development' ? true : false)
Object.keys(rules).forEach(rule => { // extend rules for vee validate
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})
extend('confirm_email', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Gli indirizzi email non coincidono'
})

extend("price", {
  validate: (value, { decimals = '2', separator = '.' } = {}) => {

    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }

    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }

    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };

  },
  message: 'Il campo {_field_} deve essere un importo valido'
})

// Custom rule for alphanumeric characters and spaces
extend('alpha_num_spaces', {
  validate(value) {
    return /^[A-Za-z0-9\xC0-\xFF\s']*$/.test(value);
  },
  message: 'Il campo {_field_} può contenere solo caratteri alfanumerici, numeri e spazi'
})

Vue.$cookies.config('30d')

// site specific components and settings
site_config()

new Vue({
  router: Router,
  el: '#app',
  store: Store,
  mixins: [
    Mixins,
  ],
  render: h => h(App),
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  update() {
  },
  beforeDestroy() {
  },
  destroyed() {
  }
})