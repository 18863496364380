import Vue from 'vue'
import Icon from './Icon'
import Logo from "./logo";

const vb_icons = () => {
  Vue.component('vb-icon', Icon)
}

const logo = () => {
  Vue.component('logo', Logo)
}

export {
  vb_icons,
  logo
}