<template>
  <!--:value="value"-->
  <b-form-checkbox
    v-model="value"
    :class="switched ? (type === 'light' ? 'vb-switch vb-switch--light' : 'vb-switch')
            : (type === 'light' ? 'vb-check vb-check--light' : 'vb-check')"
    :aria-label="aria_label"
    :aria-labelledby="aria_labelledby"
    :autofocus="autofocus"
    :button="button"
    :button-variant="button_variant"
    :disabled="disabled"
    :form="form"
    :id="id"
    :indeterminate="indeterminate"
    :inline="inline"
    :name="name"
    :plain="plain"
    :required="required"
    :size="size"
    :state="state"
    :switch="switched"
    :unchecked-value="unchecked_value"
    :checked="checked"
    @change="$emit('change', value)"
    @input="$emit('input', value)"
  >
    <slot name="default">
      <span
        class="vb-check__title pl-1 d-inline-block align-middle"
        v-html="text"
      />
    </slot>
  </b-form-checkbox>
</template>

<script>

export default {
  name: 'vb-checkbox',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // custom options
      text: this.vb_options.text !== undefined ? this.vb_options.text : '',
      type: this.vb_options.type !== undefined ? this.vb_options.type : '',

      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/form-checkbox#form-checkbox
      aria_label: this.vb_options.aria_label !== undefined ? this.vb_options.aria_label : '',                         //Sets the value of `aria-label` attribute on the rendered element
      aria_labelledby: this.vb_options.aria_labelledby !== undefined ? this.vb_options.aria_labelledby : '',          //The ID of the element that provides a label for this component. Used as the value for the `aria-labelledby` attribute
      autofocus: this.vb_options.autofocus !== undefined ? this.vb_options.autofocus : false,                         //When set to `true`, attempts to auto-focus the control when it is mounted, or re-activated when in a keep-alive. Does not set the `autofocus` attribute on the control button this.vb_options.value !== undefined ? this.vb_options.value : false                         //When set, renders the checkbox with the appearance of a button
      button: this.vb_options.button !== undefined ? this.vb_options.button : false,                                  //When set, renders the checkbox with the appearance of a button
      button_variant: this.vb_options.button_variant !== undefined ? this.vb_options.button_variant : '',             //Applies on of Bootstrap's theme colors when in 'button' mode
      checked: this.vb_options.checked !== undefined ? this.vb_options.checked : null,                                //The current value of the checkbox(es). Must be an array when there are multiple checkboxes bound to the same v-model
      disabled: this.vb_options.disabled !== undefined ? this.vb_options.disabled : false,                            //When set to `true`, disables the component's functionality and places it in a disabled state
      form: this.vb_options.form !== undefined ? this.vb_options.form : '',                                           //ID of the form that the form control belongs to. Sets the `form` attribute on the control
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',                                                 //Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
      indeterminate: this.vb_options.indeterminate !== undefined ? this.vb_options.indeterminate : false,             //Renders the checkbox in an indeterminate state. Syncable via the .sync modifier
      inline: this.vb_options.inline !== undefined ? this.vb_options.inline : false,                                  //When set, renders the checkbox as an inline element rather than as a 100% width block
      name: this.vb_options.name !== undefined ? this.vb_options.name : '',                                           //Sets the value of the `name` attribute on the form control
      plain: this.vb_options.plain !== undefined ? this.vb_options.plain : false,                                     //Render the form control in plain mode, rather than custom styled mode
      required: this.vb_options.required !== undefined ? this.vb_options.required : false,                            //Adds the `required` attribute to the form control
      size: this.vb_options.size !== undefined ? this.vb_options.size : '',                                           //Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
      state: this.vb_options.state !== undefined ? this.vb_options.state : null,                                      //Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state
      switched: this.vb_options.switch !== undefined ? this.vb_options.switch : false,                                //When set, renders the checkbox with the appearance of a switch
      unchecked_value: this.vb_options.unchecked_value !== undefined ? this.vb_options.unchecked_value : false,       //Value returned when this checkbox is unchecked. Note not applicable when multiple checkboxes bound to the same v-model array
      value: this.vb_options.value !== undefined ? this.vb_options.value : false,                                     //Value returned when this checkbox is checked      
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
    // set value to parent's v-model (if v-model is set)
    if (this.$options._parentVnode.data.model) this.value = this.$options._parentVnode.data.model.value
  }
}
</script>

<style lang="scss">

  .vb-check {

    input:disabled~label:after {
      display: none;
    }

    &--light input:disabled~label:before {
      background: #f9f9f9 !important;
    }

    label {

      &:before {
        $size: 1.25rem;
        width: $size;
        height: $size;
        border: 0 !important;
        background: linear-gradient(0deg, rgba(242, 241, 241, 1) 40%, rgba(229, 229, 229, 1) 100%) !important;
        box-shadow: inset -.5px -1px 0 rgb(255, 255, 255), inset .5px 1px 0 rgb(204, 206, 218, 100%), inset 0 -7px 3px rgb(255 255 255 / 20%) !important;
      }

      &:after {
        $size: 1.5rem;
        width: $size;
        height: $size;
        top: .125rem;
        left: -1.625rem;
        background-size: 100% 100%;
        border: 0 !important;

      }

      &:hover {
        &:after {
          background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' stroke='white' stroke-width='1'/></svg>")
        }
      }
    }

    &--light {

      label {

        &:before {
          background: $white !important;
          box-shadow: none !important;
          border: 1px solid #ececec !important;
        }

        &:hover {
          &:after {
            background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23f2f1f1' xmlns='http://www.w3.org/2000/svg'><path d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z'/></svg>")
          }
        }
      }
    }
  }

  .vb-switch {

    input:checked~label:after {
      background-color: $success !important;
      transform: translateX(0.9rem) !important;
      box-shadow: 0 -1px 0 rgb(255, 255, 255), 0 1px 0 darken($success, 8%), -1px 1px 2px #ccceda;
    }

    &--light input {
      &:checked~label:after {
        box-shadow: none;
      }

      &:disabled~label:before {
        background: #f9f9f9 !important;
      }
    }

    input:disabled~label:after {
      opacity: .5;
    }

    label {
      &:before {
        height: 1.25rem;
        background: linear-gradient(0deg, rgba(242, 241, 241, 1) 40%, rgba(229, 229, 229, 1) 100%) !important;
        box-shadow: inset 0 -1px 0 rgb(255, 255, 255), inset 0 1px 0 rgb(204, 206, 218, 100%), inset 0 -7px 3px rgb(255 255 255 / 20%) !important;
      }

      &:after {
        $size: .9rem !important;
        width: $size;
        height: $size;
        top: .425rem !important;
        left: -2.5rem !important;
        box-shadow: 0 -1px 0 #fff, 0 1px 0 #ccceda, -1px 1px 2px #ccceda;
      }
    }

    &--light {

      label {

        &:before {
          background: $white !important;
          box-shadow: none !important;
          border: 1px solid #ececec !important;
        }

        &:after {
          box-shadow: none;
        }
      }
    }
  }

</style>