<template>
  <div class="keyd-map" ref="keyd-map">
    <loader v-if="loading" />
    <div v-else>
      <b-row v-if="keydsmap.length == 0">
        <b-col sm="12">
          <p class="text-center my-4 mb-0">
            <i>Non abbiamo trovato disponibilità per questo numero di viaggiatori, prova a cambiare il numero di viaggiatori</i>
          </p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="6" class="keyd-map__column" v-for="(item) in keydsmap" v-bind:key="item.l" :ref="get_toggle_id(item.l)" >
          <h5 class="keyd-map__title">
            <vb-icon class="mr-2" :name="'pin'" :size="18" /> {{ item.l | capitalize }}
          </h5>
          <div v-for="sub in item.d" v-bind:key="sub.l" class="pb-1 keyd-map__holder" :ref="get_toggle_id(sub.l)">
            <div class="d-inline-flex align-items-center keyd-map__label"
              @click.stop="
                if (sub.d) return
                get_location(sub)
              "
              v-b-toggle="get_toggle_id(sub.l)">
              <div class="mr-2">{{ sub.l | capitalize }}
                <!--<span v-if="sub.pf" class="text text--sm text-primary ml-1">da {{sub.pf}} €</span>-->
              </div>
            </div>
            <b-collapse v-if="sub.d" class="ml-2" :id="get_toggle_id(sub.l)">
              <div class="d-flex align-items-center keyd-map__label" @click="get_location(sub)">
                {{ sub.l | capitalize }}
              </div>
              <div v-for="sub_d in sub.d" v-bind:key="sub_d.l"
                class="ml-2 d-flex align-items-center keyd-map__label"
                :ref="'label-'+get_toggle_id(sub_d.l)"
                @click.stop="
                  if (sub_d.d) {
                    get_content(sub, sub_d)
                    set_position(
                      get_toggle_id(sub.l),
                      get_toggle_id(item.l), 
                      get_toggle_id(sub_d.l)
                    )
                    return
                  }
                  get_location(sub_d)
                ">
                <div class="mr-2">{{ sub_d.l | capitalize }}</div>
              </div>
            </b-collapse>
          </div>
        </b-col>
        <b-col cols="6" class="keyd-map__column">
          <!--<div class="keyd-map__panel" :style="'top:'+pos_y+';left:'+pos_x+';'">-->
          <div class="keyd-map__panel" :class="{ show: show_panel }">
            <vb-icon class="ml-3 mt-2 cursor-pointer" :name="'arrow_l'" :size="20" @click.stop="close_panel()" />
            <ul class="keyd-map__panel--first">
              <keyd-node :node="panel_content" :parent="panel_parents" @keyd="event => get_location(event)" />
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import keydNode from './keyd-node'
import { mapState } from 'vuex' 
import { service_handler } from '@services'
import { encode_parameters } from '@utilities'

export default {
  name: 'keyd-map',

  components: {
    'keyd-node': keydNode,
  },

  props: {
  },

  computed: {
    // module state keys
    ...mapState(
      'liveat', [
        'keydsmap',
      ]
    ),
    // global state keys
    ...mapState([
      'occupancy'
    ]),
  },

  created() {
    this.encode_parameters = encode_parameters
  },

  data() {
    return {
      panel_content: {},
      panel_parents: {},
      pos_y: 0,
      pos_x: 0,
      show_panel: false,
      loading: false,
    }
  },

  mounted() {
    if (this.occupancy[0].adults == 0) return
    this.get_keyd_map()
  },

  methods: { 
    get_keyd_map() {
      this.loading = true
      service_handler(
        'keydsMap',
        {
          brands: (this.get_config()['guiSettings']['filterBrands'])? this.get_config()['guiSettings']['filterBrands'] : [],//"EM"
          occupancy: this.occupancy[0],
        },
      ).then(
        (success) => {
          this.$store.commit('liveat/keydsmap', success.data.keydsMap)
          this.loading = false
        },
        () => {
          (error) => {
            this.log('[keyd-map.vue] - error')
            this.log(error)
          }
        }
      )
    },
    get_toggle_id(str) {
      return str.replaceAll(' ','')
    },
    get_content(item, item_d) {
      let active = document.querySelectorAll('.keyd-map__label.active')
      if (active.length) active[0].classList.remove('active')
      this.panel_content = item_d
      this.panel_parents = item
      this.show_panel = true
    },
    get_location(item) {
      this.$emit('keyd', item.k)
    },
    set_position(ref_top, ref_left, child) {
      let item = this.$refs['label-'+child][0],
        active = document.querySelectorAll('.keyd-map__label.active'),
        item_top = this.$refs[ref_top][0],
        item_left = this.$refs[ref_left][0].offsetLeft

      if (item.classList.contains('active')) return
      if (active.length) active[0].classList.remove('active')
      this.pos_y = (item_top.offsetTop + item_top.offsetHeight/2) + 'px'
      this.pos_x = (item_left + item.offsetWidth + 20) + 'px'
      item.classList.add('active')
    },
    close_panel() {
      this.show_panel = false
      let active = document.querySelectorAll('.keyd-map__label.active')
      if (active.length) active[0].classList.remove('active')
    }
  }
}
</script>

<style lang="scss">
  .keyd-map {
    overflow: hidden;
    position: relative;
    &__column {
      position: static !important;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include media-breakpoint-up(md) {
        position:relative !important;
        flex: 20% !important;
        max-width: 20% !important;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      color: $gray-500;
      svg path {
        fill: $gray-500;
      }
      border-bottom: 1px solid $gray-200;
      min-width: 100%;
      padding-bottom: 0.35rem;
      margin-bottom: 0.35rem;
    }
    &__holder {
      position: relative;
      display: inline-block;
      .not-collapsed {
        color: $primary;
      }
    }
    &__panel {
      position:absolute;
      top: 0;
      right: 0;
      //transform:translateY(-30%);
      //max-width: 300px;
      transition: $transition-base;
      background: $white;// $gray-200;
      border-radius: $border-radius;
      transform: translateX(100%);
      opacity: 0;
      width:75%;
      padding-bottom: 16px;
      &.show {
        transform: none;
        opacity: 1;
        background: $vb-body-bg;
        * {
          background: transparent;
        }        
      }
      &--first {
        margin:0;
        padding:0;
        height: calc(100% - 28px);
        &>.keyd-map__node {
          width:100%;
          height:100%;
          max-height: 360px;
          overflow: scroll;
        }
      }
      @include media-breakpoint-up(md) {
        width:calc(100% - #{$grid-gutter-width});
        right: $grid-gutter-width/2;
        bottom: 0;
        &--first {
          &>.keyd-map__node {
            height:100%;
            max-height: 100%;
          }
        }
      }
    }
    &__label {
      &.active {
        color: $primary;
      }
      cursor: pointer;
      transition: $transition-base;
      &:hover {
        color: $primary;
      }
    }
  }
</style>