<template>
  <header :class="'site-header ' + variant">
    <b-container>
      <div class="site-header__wrapper">
        <div class="site-header__logo">
          <slot name="logo" />
        </div>
        <div class="site-header__navigation">
          <slot name="navigation">
            <navigation :variant="variant" />
          </slot>
        </div>
        <div class="site-header__user" v-if="is_authenticated()">
          <slot name="user">
            <user :variant="variant" />
          </slot>
        </div>
      </div>
    </b-container>
  </header>
</template>

<script>
import User from './user.vue'
import Navigation from './navigation.vue'

export default {
  name: "site-header",
  components: {
    user: User,
    navigation: Navigation,
  },
  props: {
    variant: {
      type: String,
      default: 'light',
    }
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  data() {
    return {};
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="scss">

  .site-header {
    user-select: none;
    position: relative;
    z-index: 200;

    &.dark {
      background: $primary;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      padding: $grid-gutter-width/2 0;
    }
    &__logo {
      flex: 1;

      svg {
        height: 40px;
        width:auto;

        @include media-breakpoint-up(lg) {
          height: 50px;
        }
      }
    }
    &__navigation {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include media-breakpoint-up(lg) {
        margin-right: $grid-gutter-width;
      }
    }

    @media print {
      display: none;
    }
  }

</style>