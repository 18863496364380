
const prod = [
    {
        path: '/',
        name: 'homepage',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/homepage.vue').then(Homepage => {
            return Homepage
        }),
    },
    {
        path: '/results',
        name: 'results',
        props: true,
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/results').then(Results => {
            return Results
        }),
    },
    {
        path: '/detail',
        name: 'detail',
        props: true,
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/detail').then(Detail => {
            return Detail
        }),
    },
    {
        path: '/quote',
        name: 'quote',
        props: true,
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/quote').then(Quote => {
            return Quote
        }),
    },
    {
        path: '/checkout',
        name: 'checkout',
        props: true,
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/checkout').then(Checkout => {
            return Checkout
        }),
    },
    {
        path: '/confirm/:holiday_voucher_id/:confirm_code',
        name: 'confirm',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/confirm').then(function (Confirm) {
            if (process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm View loaded')
            return Confirm
        }),
        props: (route) => {
            return {
                confirm_code: route.params.confirm_code,
                holiday_voucher_id: route.params.holiday_voucher_id,
                ignore_redirect: false,
            }
        },
    },
    {
        path: '/prenotazioni/:holiday_voucher_id/:confirm_code',
        name: 'prenotazioni',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/confirm').then(function (Confirm) {
            if (process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm View loaded')
            return Confirm
        }),
        props: (route) => {
            return {
                confirm_code: route.params.confirm_code,
                holiday_voucher_id: route.params.holiday_voucher_id,
                ignore_redirect: true,
            }
        },
    },
    {
        path: '/termini-e-condizioni',
        name: 'terms',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/terms').then(function (Terms) {
            return Terms
        }),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/about.vue').then(function (About) {
            return About
        }),
    },
    {
        path: '/vadobay-per-le-aziende',
        name: 'vadobay-per-le-aziende',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/per-le-aziende.vue').then(function (PerLeAziende) {
            return PerLeAziende
        }),
    },
    {
        path: '/viaggia-con-noi',
        name: 'viaggia-con-noi',
        component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/viaggia-con-noi.vue').then(function (ViaggiaConNoi) {
            return ViaggiaConNoi
        }),
    },
]

export {
    prod
}
