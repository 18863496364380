const countries = [
  {
    "country_code": "AF",
    "country_name": "Afghanistan",
    "phone_code": "93",
    "phone_display": "Afghanistan +93"
  },
  {
    "country_code": "AX",
    "country_name": "\u00c5land Islands",
    "phone_code": "35818",
    "phone_display": "\u00c5land Islands +35818"
  },
  {
    "country_code": "AL",
    "country_name": "Albania",
    "phone_code": "355",
    "phone_display": "Albania +355"
  },
  {
    "country_code": "DZ",
    "country_name": "Algeria",
    "phone_code": "213",
    "phone_display": "Algeria +213"
  },
  {
    "country_code": "AS",
    "country_name": "Samoa Americane",
    "phone_code": "1684",
    "phone_display": "Samoa Americane +1684"
  },
  {
    "country_code": "AD",
    "country_name": "Andorra",
    "phone_code": "376",
    "phone_display": "Andorra +376"
  },
  {
    "country_code": "AO",
    "country_name": "Angola",
    "phone_code": "244",
    "phone_display": "Angola +244"
  },
  {
    "country_code": "AI",
    "country_name": "Anguilla",
    "phone_code": "1264",
    "phone_display": "Anguilla +1264"
  },
  {
    "country_code": "AQ",
    "country_name": "Antarctica",
    "phone_code": "672",
    "phone_display": "Antarctica +672"
  },
  {
    "country_code": "AG",
    "country_name": "Antigua e Barbuda",
    "phone_code": "1268",
    "phone_display": "Antigua e Barbuda +1268"
  },
  {
    "country_code": "AR",
    "country_name": "Argentina",
    "phone_code": "54",
    "phone_display": "Argentina +54"
  },
  {
    "country_code": "AM",
    "country_name": "Armenia",
    "phone_code": "374",
    "phone_display": "Armenia +374"
  },
  {
    "country_code": "AW",
    "country_name": "Aruba",
    "phone_code": "297",
    "phone_display": "Aruba +297"
  },
  {
    "country_code": "AC",
    "country_name": "Ascension Island",
    "phone_code": "247",
    "phone_display": "Ascension Island +247"
  },
  {
    "country_code": "AU",
    "country_name": "Australia",
    "phone_code": "61",
    "phone_display": "Australia +61"
  },
  {
    "country_code": "AT",
    "country_name": "Austria",
    "phone_code": "43",
    "phone_display": "Austria +43"
  },
  {
    "country_code": "AZ",
    "country_name": "Azerbaigian",
    "phone_code": "994",
    "phone_display": "Azerbaigian +994"
  },
  {
    "country_code": "BS",
    "country_name": "Bahamas",
    "phone_code": "1242",
    "phone_display": "Bahamas +1242"
  },
  {
    "country_code": "BH",
    "country_name": "Bahrain",
    "phone_code": "973",
    "phone_display": "Bahrain +973"
  },
  {
    "country_code": "BD",
    "country_name": "Bangladesh",
    "phone_code": "880",
    "phone_display": "Bangladesh +880"
  },
  {
    "country_code": "BB",
    "country_name": "Barbados",
    "phone_code": "1246",
    "phone_display": "Barbados +1246"
  },
  {
    "country_code": "BY",
    "country_name": "Bielorussia",
    "phone_code": "375",
    "phone_display": "Bielorussia +375"
  },
  {
    "country_code": "BE",
    "country_name": "Belgio",
    "phone_code": "32",
    "phone_display": "Belgio +32"
  },
  {
    "country_code": "BZ",
    "country_name": "Belize",
    "phone_code": "501",
    "phone_display": "Belize +501"
  },
  {
    "country_code": "BJ",
    "country_name": "Benin",
    "phone_code": "229",
    "phone_display": "Benin +229"
  },
  {
    "country_code": "BM",
    "country_name": "Bermuda",
    "phone_code": "1441",
    "phone_display": "Bermuda +1441"
  },
  {
    "country_code": "BT",
    "country_name": "Bhutan",
    "phone_code": "975",
    "phone_display": "Bhutan +975"
  },
  {
    "country_code": "BO",
    "country_name": "Bolivia",
    "phone_code": "591",
    "phone_display": "Bolivia +591"
  },
  {
    "country_code": "BA",
    "country_name": "Bosnia ed Erzegovina",
    "phone_code": "387",
    "phone_display": "Bosnia ed Erzegovina +387"
  },
  {
    "country_code": "BW",
    "country_name": "Botswana",
    "phone_code": "267",
    "phone_display": "Botswana +267"
  },
  {
    "country_code": "BV",
    "country_name": "Bouvet",
    "phone_code": "0055",
    "phone_display": "Bouvet +0055"
  },
  {
    "country_code": "BR",
    "country_name": "Brasile",
    "phone_code": "55",
    "phone_display": "Brasile +55"
  },
  {
    "country_code": "IO",
    "country_name": "Territorio Britannico dell'Oceno Indiano",
    "phone_code": "246",
    "phone_display": "Territorio Britannico dell'Oceno Indiano +246"
  },
  {
    "country_code": "VG",
    "country_name": "Isole Vergini Britanniche",
    "phone_code": "1284",
    "phone_display": "Isole Vergini Britanniche +1284"
  },
  {
    "country_code": "BN",
    "country_name": "Brunei",
    "phone_code": "673",
    "phone_display": "Brunei +673"
  },
  {
    "country_code": "BG",
    "country_name": "Bulgaria",
    "phone_code": "359",
    "phone_display": "Bulgaria +359"
  },
  {
    "country_code": "BF",
    "country_name": "Burkina Faso",
    "phone_code": "226",
    "phone_display": "Burkina Faso +226"
  },
  {
    "country_code": "BI",
    "country_name": "Burundi",
    "phone_code": "257",
    "phone_display": "Burundi +257"
  },
  {
    "country_code": "KH",
    "country_name": "Cambogia",
    "phone_code": "855",
    "phone_display": "Cambogia +855"
  },
  {
    "country_code": "CM",
    "country_name": "Camerun",
    "phone_code": "237",
    "phone_display": "Camerun +237"
  },
  {
    "country_code": "CA",
    "country_name": "Canada",
    "phone_code": "1",
    "phone_display": "Canada +1"
  },
  {
    "country_code": "CV",
    "country_name": "Capo Verde",
    "phone_code": "238",
    "phone_display": "Capo Verde +238"
  },
  {
    "country_code": "BQ",
    "country_name": "Bonaire, Sint Eustatius e Saba",
    "phone_code": "599",
    "phone_display": "Bonaire, Sint Eustatius e Saba +599"
  },
  {
    "country_code": "KY",
    "country_name": "Isole Cayman",
    "phone_code": "1345",
    "phone_display": "Isole Cayman +1345"
  },
  {
    "country_code": "CF",
    "country_name": "Central African Republic",
    "phone_code": "236",
    "phone_display": "Central African Republic +236"
  },
  {
    "country_code": "TD",
    "country_name": "Ciad",
    "phone_code": "235",
    "phone_display": "Ciad +235"
  },
  {
    "country_code": "CL",
    "country_name": "Cile",
    "phone_code": "56",
    "phone_display": "Cile +56"
  },
  {
    "country_code": "CN",
    "country_name": "Cina",
    "phone_code": "86",
    "phone_display": "Cina +86"
  },
  {
    "country_code": "CX",
    "country_name": "Isola di Natale",
    "phone_code": "61",
    "phone_display": "Isola di Natale +61"
  },
  {
    "country_code": "CC",
    "country_name": "Isole Cocos",
    "phone_code": "61",
    "phone_display": "Isole Cocos +61"
  },
  {
    "country_code": "CO",
    "country_name": "Colombia",
    "phone_code": "57",
    "phone_display": "Colombia +57"
  },
  {
    "country_code": "KM",
    "country_name": "Isole Comore",
    "phone_code": "269",
    "phone_display": "Isole Comore +269"
  },
  {
    "country_code": "CG",
    "country_name": "Repubblica del Congo",
    "phone_code": "242",
    "phone_display": "Repubblica del Congo +242"
  },
  {
    "country_code": "CD",
    "country_name": "Repubblica Democratica del Congo",
    "phone_code": "243",
    "phone_display": "Repubblica Democratica del Congo +243"
  },
  {
    "country_code": "CK",
    "country_name": "Isole Cook",
    "phone_code": "682",
    "phone_display": "Isole Cook +682"
  },
  {
    "country_code": "CR",
    "country_name": "Costa Rica",
    "phone_code": "506",
    "phone_display": "Costa Rica +506"
  },
  {
    "country_code": "CI",
    "country_name": "Costa d'Avorio",
    "phone_code": "225",
    "phone_display": "Costa d'Avorio +225"
  },
  {
    "country_code": "HR",
    "country_name": "Croazia",
    "phone_code": "385",
    "phone_display": "Croazia +385"
  },
  {
    "country_code": "CU",
    "country_name": "Cuba",
    "phone_code": "53",
    "phone_display": "Cuba +53"
  },
  {
    "country_code": "CW",
    "country_name": "Cura\u00e7ao",
    "phone_code": "599",
    "phone_display": "Cura\u00e7ao +599"
  },
  {
    "country_code": "CY",
    "country_name": "Cipro",
    "phone_code": "357",
    "phone_display": "Cipro +357"
  },
  {
    "country_code": "CZ",
    "country_name": "Repubblica Ceca",
    "phone_code": "420",
    "phone_display": "Repubblica Ceca +420"
  },
  {
    "country_code": "DK",
    "country_name": "Danimarca",
    "phone_code": "45",
    "phone_display": "Danimarca +45"
  },
  {
    "country_code": "DJ",
    "country_name": "Gibuti",
    "phone_code": "253",
    "phone_display": "Gibuti +253"
  },
  {
    "country_code": "DM",
    "country_name": "Dominica",
    "phone_code": "1767",
    "phone_display": "Dominica +1767"
  },
  {
    "country_code": "DO",
    "country_name": "Repubblica Dominicana",
    "phone_code": "1809",
    "phone_display": "Repubblica Dominicana +1809"
  },
  {
    "country_code": "EC",
    "country_name": "Ecuador",
    "phone_code": "593",
    "phone_display": "Ecuador +593"
  },
  {
    "country_code": "EG",
    "country_name": "Egitto",
    "phone_code": "20",
    "phone_display": "Egitto +20"
  },
  {
    "country_code": "SV",
    "country_name": "El Salvador",
    "phone_code": "503",
    "phone_display": "El Salvador +503"
  },
  {
    "country_code": "GQ",
    "country_name": "Guinea Equatoriale",
    "phone_code": "240",
    "phone_display": "Guinea Equatoriale +240"
  },
  {
    "country_code": "ER",
    "country_name": "Eritrea",
    "phone_code": "291",
    "phone_display": "Eritrea +291"
  },
  {
    "country_code": "EE",
    "country_name": "Estonia",
    "phone_code": "372",
    "phone_display": "Estonia +372"
  },
  {
    "country_code": "ET",
    "country_name": "Etiopia",
    "phone_code": "251",
    "phone_display": "Etiopia +251"
  },
  {
    "country_code": "FK",
    "country_name": "Isole Falkland",
    "phone_code": "500",
    "phone_display": "Isole Falkland +500"
  },
  {
    "country_code": "FO",
    "country_name": "Isole Faroe",
    "phone_code": "298",
    "phone_display": "Isole Faroe +298"
  },
  {
    "country_code": "FJ",
    "country_name": "Figi",
    "phone_code": "679",
    "phone_display": "Figi +679"
  },
  {
    "country_code": "FI",
    "country_name": "Finlandia",
    "phone_code": "358",
    "phone_display": "Finlandia +358"
  },
  {
    "country_code": "FR",
    "country_name": "Francia",
    "phone_code": "33",
    "phone_display": "Francia +33"
  },
  {
    "country_code": "GF",
    "country_name": "Guiana Francese",
    "phone_code": "594",
    "phone_display": "Guiana Francese +594"
  },
  {
    "country_code": "PF",
    "country_name": "Polinesia Francese",
    "phone_code": "689",
    "phone_display": "Polinesia Francese +689"
  },
  {
    "country_code": "TF",
    "country_name": "Territori Francesi Meridionali e Antartici",
    "phone_code": "262",
    "phone_display": "Territori Francesi Meridionali e Antartici +262"
  },
  {
    "country_code": "GA",
    "country_name": "Gabon",
    "phone_code": "241",
    "phone_display": "Gabon +241"
  },
  {
    "country_code": "GM",
    "country_name": "Gambia",
    "phone_code": "220",
    "phone_display": "Gambia +220"
  },
  {
    "country_code": "GE",
    "country_name": "Georgia",
    "phone_code": "995",
    "phone_display": "Georgia +995"
  },
  {
    "country_code": "DE",
    "country_name": "Germania",
    "phone_code": "49",
    "phone_display": "Germania +49"
  },
  {
    "country_code": "GH",
    "country_name": "Ghana",
    "phone_code": "233",
    "phone_display": "Ghana +233"
  },
  {
    "country_code": "GI",
    "country_name": "Gibilterra",
    "phone_code": "350",
    "phone_display": "Gibilterra +350"
  },
  {
    "country_code": "GR",
    "country_name": "Grecia",
    "phone_code": "30",
    "phone_display": "Grecia +30"
  },
  {
    "country_code": "GL",
    "country_name": "Groenlandia",
    "phone_code": "299",
    "phone_display": "Groenlandia +299"
  },
  {
    "country_code": "GD",
    "country_name": "Grenada",
    "phone_code": "1473",
    "phone_display": "Grenada +1473"
  },
  {
    "country_code": "GP",
    "country_name": "Guadalupa",
    "phone_code": "590",
    "phone_display": "Guadalupa +590"
  },
  {
    "country_code": "GU",
    "country_name": "Guam",
    "phone_code": "1671",
    "phone_display": "Guam +1671"
  },
  {
    "country_code": "GT",
    "country_name": "Guatemala",
    "phone_code": "502",
    "phone_display": "Guatemala +502"
  },
  {
    "country_code": "GG",
    "country_name": "Guernsey",
    "phone_code": "441481",
    "phone_display": "Guernsey +441481"
  },
  {
    "country_code": "GN",
    "country_name": "Guinea",
    "phone_code": "224",
    "phone_display": "Guinea +224"
  },
  {
    "country_code": "GW",
    "country_name": "Guinea-Bissau",
    "phone_code": "245",
    "phone_display": "Guinea-Bissau +245"
  },
  {
    "country_code": "GY",
    "country_name": "Guyana",
    "phone_code": "592",
    "phone_display": "Guyana +592"
  },
  {
    "country_code": "HT",
    "country_name": "Haiti",
    "phone_code": "509",
    "phone_display": "Haiti +509"
  },
  {
    "country_code": "HM",
    "country_name": "Isole Heard e McDonald",
    "phone_code": "672",
    "phone_display": "Isole Heard e McDonald +672"
  },
  {
    "country_code": "HN",
    "country_name": "Honduras",
    "phone_code": "504",
    "phone_display": "Honduras +504"
  },
  {
    "country_code": "HK",
    "country_name": "Regione Amministrativa Speciale di Hong Kong",
    "phone_code": "852",
    "phone_display": "Regione Amministrativa Speciale di Hong Kong +852"
  },
  {
    "country_code": "HU",
    "country_name": "Ungheria",
    "phone_code": "36",
    "phone_display": "Ungheria +36"
  },
  {
    "country_code": "IS",
    "country_name": "Islanda",
    "phone_code": "354",
    "phone_display": "Islanda +354"
  },
  {
    "country_code": "IN",
    "country_name": "India",
    "phone_code": "91",
    "phone_display": "India +91"
  },
  {
    "country_code": "ID",
    "country_name": "Indonesia",
    "phone_code": "62",
    "phone_display": "Indonesia +62"
  },
  {
    "country_code": "IR",
    "country_name": "Iran",
    "phone_code": "98",
    "phone_display": "Iran +98"
  },
  {
    "country_code": "IQ",
    "country_name": "Iraq",
    "phone_code": "964",
    "phone_display": "Iraq +964"
  },
  {
    "country_code": "IE",
    "country_name": "Irlanda",
    "phone_code": "353",
    "phone_display": "Irlanda +353"
  },
  {
    "country_code": "IM",
    "country_name": "Isle of Man",
    "phone_code": "441624",
    "phone_display": "Isle of Man +441624"
  },
  {
    "country_code": "IL",
    "country_name": "Israele",
    "phone_code": "972",
    "phone_display": "Israele +972"
  },
  {
    "country_code": "IT",
    "country_name": "Italia",
    "phone_code": "39",
    "phone_display": "Italia +39"
  },
  {
    "country_code": "JM",
    "country_name": "Giamaica",
    "phone_code": "1876",
    "phone_display": "Giamaica +1876"
  },
  {
    "country_code": "JP",
    "country_name": "Giappone",
    "phone_code": "81",
    "phone_display": "Giappone +81"
  },
  {
    "country_code": "JE",
    "country_name": "Jersey",
    "phone_code": "441534",
    "phone_display": "Jersey +441534"
  },
  {
    "country_code": "JO",
    "country_name": "Giordania",
    "phone_code": "962",
    "phone_display": "Giordania +962"
  },
  {
    "country_code": "KZ",
    "country_name": "Kazakistan",
    "phone_code": "7",
    "phone_display": "Kazakistan +7"
  },
  {
    "country_code": "KE",
    "country_name": "Kenya",
    "phone_code": "254",
    "phone_display": "Kenya +254"
  },
  {
    "country_code": "KI",
    "country_name": "Kiribati",
    "phone_code": "686",
    "phone_display": "Kiribati +686"
  },
  {
    "country_code": "XK",
    "country_name": "Kosovo",
    "phone_code": "383",
    "phone_display": "Kosovo +383"
  },
  {
    "country_code": "KW",
    "country_name": "Kuwait",
    "phone_code": "965",
    "phone_display": "Kuwait +965"
  },
  {
    "country_code": "KG",
    "country_name": "Kirghizistan",
    "phone_code": "996",
    "phone_display": "Kirghizistan +996"
  },
  {
    "country_code": "LA",
    "country_name": "Laos",
    "phone_code": "856",
    "phone_display": "Laos +856"
  },
  {
    "country_code": "LV",
    "country_name": "Lettonia",
    "phone_code": "371",
    "phone_display": "Lettonia +371"
  },
  {
    "country_code": "LB",
    "country_name": "Libano",
    "phone_code": "961",
    "phone_display": "Libano +961"
  },
  {
    "country_code": "LS",
    "country_name": "Lesotho",
    "phone_code": "266",
    "phone_display": "Lesotho +266"
  },
  {
    "country_code": "LR",
    "country_name": "Liberia",
    "phone_code": "231",
    "phone_display": "Liberia +231"
  },
  {
    "country_code": "LY",
    "country_name": "Libia",
    "phone_code": "218",
    "phone_display": "Libia +218"
  },
  {
    "country_code": "LI",
    "country_name": "Liechtenstein",
    "phone_code": "423",
    "phone_display": "Liechtenstein +423"
  },
  {
    "country_code": "LT",
    "country_name": "Lituania",
    "phone_code": "370",
    "phone_display": "Lituania +370"
  },
  {
    "country_code": "LU",
    "country_name": "Lussemburgo",
    "phone_code": "352",
    "phone_display": "Lussemburgo +352"
  },
  {
    "country_code": "MO",
    "country_name": "Regione Amministrativa Speciale di Macao",
    "phone_code": "853",
    "phone_display": "Regione Amministrativa Speciale di Macao +853"
  },
  {
    "country_code": "MK",
    "country_name": "Macedonia del Nord",
    "phone_code": "389",
    "phone_display": "Macedonia del Nord +389"
  },
  {
    "country_code": "MG",
    "country_name": "Madagascar",
    "phone_code": "261",
    "phone_display": "Madagascar +261"
  },
  {
    "country_code": "MW",
    "country_name": "Malawi",
    "phone_code": "265",
    "phone_display": "Malawi +265"
  },
  {
    "country_code": "MY",
    "country_name": "Malesia",
    "phone_code": "60",
    "phone_display": "Malesia +60"
  },
  {
    "country_code": "MV",
    "country_name": "Maldive",
    "phone_code": "960",
    "phone_display": "Maldive +960"
  },
  {
    "country_code": "ML",
    "country_name": "Mali",
    "phone_code": "223",
    "phone_display": "Mali +223"
  },
  {
    "country_code": "MT",
    "country_name": "Malta",
    "phone_code": "356",
    "phone_display": "Malta +356"
  },
  {
    "country_code": "MH",
    "country_name": "Isole Marshall",
    "phone_code": "692",
    "phone_display": "Isole Marshall +692"
  },
  {
    "country_code": "MQ",
    "country_name": "Martinica",
    "phone_code": "596",
    "phone_display": "Martinica +596"
  },
  {
    "country_code": "MR",
    "country_name": "Mauritania",
    "phone_code": "222",
    "phone_display": "Mauritania +222"
  },
  {
    "country_code": "MU",
    "country_name": "Mauritius",
    "phone_code": "230",
    "phone_display": "Mauritius +230"
  },
  {
    "country_code": "YT",
    "country_name": "Mayotte",
    "phone_code": "262",
    "phone_display": "Mayotte +262"
  },
  {
    "country_code": "MX",
    "country_name": "Messico",
    "phone_code": "52",
    "phone_display": "Messico +52"
  },
  {
    "country_code": "FM",
    "country_name": "Stati Federati di Micronesia",
    "phone_code": "691",
    "phone_display": "Stati Federati di Micronesia +691"
  },
  {
    "country_code": "MD",
    "country_name": "Moldavia",
    "phone_code": "373",
    "phone_display": "Moldavia +373"
  },
  {
    "country_code": "MC",
    "country_name": "Monaco",
    "phone_code": "377",
    "phone_display": "Monaco +377"
  },
  {
    "country_code": "MN",
    "country_name": "Mongolia",
    "phone_code": "976",
    "phone_display": "Mongolia +976"
  },
  {
    "country_code": "ME",
    "country_name": "Montenegro",
    "phone_code": "382",
    "phone_display": "Montenegro +382"
  },
  {
    "country_code": "MS",
    "country_name": "Montserrat",
    "phone_code": "1664",
    "phone_display": "Montserrat +1664"
  },
  {
    "country_code": "MA",
    "country_name": "Marocco",
    "phone_code": "212",
    "phone_display": "Marocco +212"
  },
  {
    "country_code": "MZ",
    "country_name": "Mozambico",
    "phone_code": "258",
    "phone_display": "Mozambico +258"
  },
  {
    "country_code": "MM",
    "country_name": "Myanmar",
    "phone_code": "95",
    "phone_display": "Myanmar +95"
  },
  {
    "country_code": "NA",
    "country_name": "Namibia",
    "phone_code": "264",
    "phone_display": "Namibia +264"
  },
  {
    "country_code": "NR",
    "country_name": "Nauru",
    "phone_code": "674",
    "phone_display": "Nauru +674"
  },
  {
    "country_code": "NP",
    "country_name": "Nepal",
    "phone_code": "977",
    "phone_display": "Nepal +977"
  },
  {
    "country_code": "NL",
    "country_name": "Paesi Bassi",
    "phone_code": "31",
    "phone_display": "Paesi Bassi +31"
  },
  {
    "country_code": "NC",
    "country_name": "Nuova Caledonia",
    "phone_code": "687",
    "phone_display": "Nuova Caledonia +687"
  },
  {
    "country_code": "NZ",
    "country_name": "Nuova Zelanda",
    "phone_code": "64",
    "phone_display": "Nuova Zelanda +64"
  },
  {
    "country_code": "NI",
    "country_name": "Nicaragua",
    "phone_code": "505",
    "phone_display": "Nicaragua +505"
  },
  {
    "country_code": "NE",
    "country_name": "Niger",
    "phone_code": "227",
    "phone_display": "Niger +227"
  },
  {
    "country_code": "NG",
    "country_name": "Nigeria",
    "phone_code": "234",
    "phone_display": "Nigeria +234"
  },
  {
    "country_code": "NU",
    "country_name": "Niue",
    "phone_code": "683",
    "phone_display": "Niue +683"
  },
  {
    "country_code": "NF",
    "country_name": "Norfolk",
    "phone_code": "672",
    "phone_display": "Norfolk +672"
  },
  {
    "country_code": "KP",
    "country_name": "North Korea",
    "phone_code": "850",
    "phone_display": "North Korea +850"
  },
  {
    "country_code": "MP",
    "country_name": "Marianne Settentrionali",
    "phone_code": "1670",
    "phone_display": "Marianne Settentrionali +1670"
  },
  {
    "country_code": "NO",
    "country_name": "Norvegia",
    "phone_code": "47",
    "phone_display": "Norvegia +47"
  },
  {
    "country_code": "OM",
    "country_name": "Oman",
    "phone_code": "968",
    "phone_display": "Oman +968"
  },
  {
    "country_code": "PK",
    "country_name": "Pakistan",
    "phone_code": "92",
    "phone_display": "Pakistan +92"
  },
  {
    "country_code": "PW",
    "country_name": "Palau",
    "phone_code": "680",
    "phone_display": "Palau +680"
  },
  {
    "country_code": "PS",
    "country_name": "Territori Palestinesi",
    "phone_code": "970",
    "phone_display": "Territori Palestinesi +970"
  },
  {
    "country_code": "PA",
    "country_name": "Panama",
    "phone_code": "507",
    "phone_display": "Panama +507"
  },
  {
    "country_code": "PG",
    "country_name": "Papua Nuova Guinea",
    "phone_code": "675",
    "phone_display": "Papua Nuova Guinea +675"
  },
  {
    "country_code": "PY",
    "country_name": "Paraguay",
    "phone_code": "595",
    "phone_display": "Paraguay +595"
  },
  {
    "country_code": "PE",
    "country_name": "Per\u00f9",
    "phone_code": "51",
    "phone_display": "Per\u00f9 +51"
  },
  {
    "country_code": "PH",
    "country_name": "Filippine",
    "phone_code": "63",
    "phone_display": "Filippine +63"
  },
  {
    "country_code": "PN",
    "country_name": "Pitcairn",
    "phone_code": "870",
    "phone_display": "Pitcairn +870"
  },
  {
    "country_code": "PL",
    "country_name": "Polonia",
    "phone_code": "48",
    "phone_display": "Polonia +48"
  },
  {
    "country_code": "PT",
    "country_name": "Portogallo",
    "phone_code": "351",
    "phone_display": "Portogallo +351"
  },
  {
    "country_code": "PR",
    "country_name": "Porto Rico",
    "phone_code": "1787",
    "phone_display": "Porto Rico +1787"
  },
  {
    "country_code": "QA",
    "country_name": "Qatar",
    "phone_code": "974",
    "phone_display": "Qatar +974"
  },
  {
    "country_code": "RE",
    "country_name": "La Riunione",
    "phone_code": "262",
    "phone_display": "La Riunione +262"
  },
  {
    "country_code": "RO",
    "country_name": "Romania",
    "phone_code": "40",
    "phone_display": "Romania +40"
  },
  {
    "country_code": "RU",
    "country_name": "Russia",
    "phone_code": "7",
    "phone_display": "Russia +7"
  },
  {
    "country_code": "RW",
    "country_name": "Ruanda",
    "phone_code": "250",
    "phone_display": "Ruanda +250"
  },
  {
    "country_code": "WS",
    "country_name": "Samoa",
    "phone_code": "685",
    "phone_display": "Samoa +685"
  },
  {
    "country_code": "SM",
    "country_name": "San Marino",
    "phone_code": "378",
    "phone_display": "San Marino +378"
  },
  {
    "country_code": "ST",
    "country_name": "S\u00e3o Tom\u00e9 e Pr\u00edncipe",
    "phone_code": "239",
    "phone_display": "S\u00e3o Tom\u00e9 e Pr\u00edncipe +239"
  },
  {
    "country_code": "SA",
    "country_name": "Arabia Saudita",
    "phone_code": "966",
    "phone_display": "Arabia Saudita +966"
  },
  {
    "country_code": "SN",
    "country_name": "Senegal",
    "phone_code": "221",
    "phone_display": "Senegal +221"
  },
  {
    "country_code": "RS",
    "country_name": "Serbia",
    "phone_code": "381",
    "phone_display": "Serbia +381"
  },
  {
    "country_code": "SC",
    "country_name": "Seychelles",
    "phone_code": "248",
    "phone_display": "Seychelles +248"
  },
  {
    "country_code": "SL",
    "country_name": "Sierra Leone",
    "phone_code": "232",
    "phone_display": "Sierra Leone +232"
  },
  {
    "country_code": "SG",
    "country_name": "Singapore",
    "phone_code": "65",
    "phone_display": "Singapore +65"
  },
  {
    "country_code": "SX",
    "country_name": "Sint Maarten",
    "phone_code": "599",
    "phone_display": "Sint Maarten +599"
  },
  {
    "country_code": "SK",
    "country_name": "Slovacchia",
    "phone_code": "421",
    "phone_display": "Slovacchia +421"
  },
  {
    "country_code": "SI",
    "country_name": "Slovenia",
    "phone_code": "386",
    "phone_display": "Slovenia +386"
  },
  {
    "country_code": "SB",
    "country_name": "Isole Salomone",
    "phone_code": "677",
    "phone_display": "Isole Salomone +677"
  },
  {
    "country_code": "SO",
    "country_name": "Somalia",
    "phone_code": "252",
    "phone_display": "Somalia +252"
  },
  {
    "country_code": "ZA",
    "country_name": "Sudafrica",
    "phone_code": "27",
    "phone_display": "Sudafrica +27"
  },
  {
    "country_code": "GS",
    "country_name": "South Georgia & South Sandwich Islands",
    "phone_code": "500",
    "phone_display": "South Georgia & South Sandwich Islands +500"
  },
  {
    "country_code": "KR",
    "country_name": "Corea del Sud",
    "phone_code": "82",
    "phone_display": "Corea del Sud +82"
  },
  {
    "country_code": "SS",
    "country_name": "South Sudan",
    "phone_code": "211",
    "phone_display": "South Sudan +211"
  },
  {
    "country_code": "ES",
    "country_name": "Spagna",
    "phone_code": "34",
    "phone_display": "Spagna +34"
  },
  {
    "country_code": "LK",
    "country_name": "Sri Lanka",
    "phone_code": "94",
    "phone_display": "Sri Lanka +94"
  },
  {
    "country_code": "BL",
    "country_name": "St. Barthelemy",
    "phone_code": "590",
    "phone_display": "St. Barthelemy +590"
  },
  {
    "country_code": "SH",
    "country_name": "St. Helena",
    "phone_code": "290",
    "phone_display": "St. Helena +290"
  },
  {
    "country_code": "KN",
    "country_name": "St. Kitts e Nevis",
    "phone_code": "1869",
    "phone_display": "St. Kitts e Nevis +1869"
  },
  {
    "country_code": "LC",
    "country_name": "St. Lucia",
    "phone_code": "1758",
    "phone_display": "St. Lucia +1758"
  },
  {
    "country_code": "MF",
    "country_name": "St. Martin",
    "phone_code": "590",
    "phone_display": "St. Martin +590"
  },
  {
    "country_code": "PM",
    "country_name": "St. Pierre e Miquelon",
    "phone_code": "508",
    "phone_display": "St. Pierre e Miquelon +508"
  },
  {
    "country_code": "VC",
    "country_name": "St. Vincent e Grenadine",
    "phone_code": "1784",
    "phone_display": "St. Vincent e Grenadine +1784"
  },
  {
    "country_code": "SD",
    "country_name": "Sudan",
    "phone_code": "249",
    "phone_display": "Sudan +249"
  },
  {
    "country_code": "SR",
    "country_name": "Suriname",
    "phone_code": "597",
    "phone_display": "Suriname +597"
  },
  {
    "country_code": "SJ",
    "country_name": "Svalbard",
    "phone_code": "47",
    "phone_display": "Svalbard +47"
  },
  {
    "country_code": "SZ",
    "country_name": "Swaziland",
    "phone_code": "268",
    "phone_display": "Swaziland +268"
  },
  {
    "country_code": "SE",
    "country_name": "Svezia",
    "phone_code": "46",
    "phone_display": "Svezia +46"
  },
  {
    "country_code": "CH",
    "country_name": "Svizzera",
    "phone_code": "41",
    "phone_display": "Svizzera +41"
  },
  {
    "country_code": "SY",
    "country_name": "Syria",
    "phone_code": "963",
    "phone_display": "Syria +963"
  },
  {
    "country_code": "TW",
    "country_name": "Taiwan",
    "phone_code": "886",
    "phone_display": "Taiwan +886"
  },
  {
    "country_code": "TJ",
    "country_name": "Tajikistan",
    "phone_code": "992",
    "phone_display": "Tajikistan +992"
  },
  {
    "country_code": "TZ",
    "country_name": "Tanzania",
    "phone_code": "255",
    "phone_display": "Tanzania +255"
  },
  {
    "country_code": "TH",
    "country_name": "Tailandia",
    "phone_code": "66",
    "phone_display": "Tailandia +66"
  },
  {
    "country_code": "TL",
    "country_name": "Timor-Leste",
    "phone_code": "670",
    "phone_display": "Timor-Leste +670"
  },
  {
    "country_code": "TG",
    "country_name": "Togo",
    "phone_code": "228",
    "phone_display": "Togo +228"
  },
  {
    "country_code": "TK",
    "country_name": "Tokelau",
    "phone_code": "690",
    "phone_display": "Tokelau +690"
  },
  {
    "country_code": "TO",
    "country_name": "Tonga",
    "phone_code": "676",
    "phone_display": "Tonga +676"
  },
  {
    "country_code": "TT",
    "country_name": "Trinidad e Tobago",
    "phone_code": "1868",
    "phone_display": "Trinidad e Tobago +1868"
  },
  {
    "country_code": "TN",
    "country_name": "Tunisia",
    "phone_code": "216",
    "phone_display": "Tunisia +216"
  },
  {
    "country_code": "TR",
    "country_name": "Turchia",
    "phone_code": "90",
    "phone_display": "Turchia +90"
  },
  {
    "country_code": "TM",
    "country_name": "Turkmenistan",
    "phone_code": "993",
    "phone_display": "Turkmenistan +993"
  },
  {
    "country_code": "TC",
    "country_name": "Turks e Caicos",
    "phone_code": "1649",
    "phone_display": "Turks e Caicos +1649"
  },
  {
    "country_code": "TV",
    "country_name": "Tuvalu",
    "phone_code": "688",
    "phone_display": "Tuvalu +688"
  },
  {
    "country_code": "UM",
    "country_name": "Isole Minori Esterne degli Stati Uniti",
    "phone_code": "246",
    "phone_display": "Isole Minori Esterne degli Stati Uniti +246"
  },
  {
    "country_code": "VI",
    "country_name": "Isole Vergini",
    "phone_code": "1340",
    "phone_display": "Isole Vergini +1340"
  },
  {
    "country_code": "UG",
    "country_name": "Uganda",
    "phone_code": "256",
    "phone_display": "Uganda +256"
  },
  {
    "country_code": "UA",
    "country_name": "Ucraina",
    "phone_code": "380",
    "phone_display": "Ucraina +380"
  },
  {
    "country_code": "AE",
    "country_name": "Emirati Arabi Uniti",
    "phone_code": "971",
    "phone_display": "Emirati Arabi Uniti +971"
  },
  {
    "country_code": "GB",
    "country_name": "Regno Unito",
    "phone_code": "44",
    "phone_display": "Regno Unito +44"
  },
  {
    "country_code": "US",
    "country_name": "Stati Uniti d'America",
    "phone_code": "1",
    "phone_display": "Stati Uniti d'America +1"
  },
  {
    "country_code": "UY",
    "country_name": "Uruguay",
    "phone_code": "598",
    "phone_display": "Uruguay +598"
  },
  {
    "country_code": "UZ",
    "country_name": "Uzbekistan",
    "phone_code": "998",
    "phone_display": "Uzbekistan +998"
  },
  {
    "country_code": "VU",
    "country_name": "Vanuatu",
    "phone_code": "678",
    "phone_display": "Vanuatu +678"
  },
  {
    "country_code": "VA",
    "country_name": "Citt\u00e0 del Vaticano",
    "phone_code": "379",
    "phone_display": "Citt\u00e0 del Vaticano +379"
  },
  {
    "country_code": "VE",
    "country_name": "Venezuela",
    "phone_code": "58",
    "phone_display": "Venezuela +58"
  },
  {
    "country_code": "VN",
    "country_name": "Vietnam",
    "phone_code": "84",
    "phone_display": "Vietnam +84"
  },
  {
    "country_code": "WF",
    "country_name": "Wallis e Futuna",
    "phone_code": "681",
    "phone_display": "Wallis e Futuna +681"
  },
  {
    "country_code": "EH",
    "country_name": "Western Sahara",
    "phone_code": "212",
    "phone_display": "Western Sahara +212"
  },
  {
    "country_code": "YE",
    "country_name": "Yemen",
    "phone_code": "967",
    "phone_display": "Yemen +967"
  },
  {
    "country_code": "ZM",
    "country_name": "Zambia",
    "phone_code": "260",
    "phone_display": "Zambia +260"
  },
  {
    "country_code": "ZW",
    "country_name": "Zimbabwe",
    "phone_code": "263",
    "phone_display": "Zimbabwe +263"
  }
]

export default countries