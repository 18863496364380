export default '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\
  <path d = "M15.6001 7.7001C14.2001 6.0001 11.3001 4.1001 8.20012 4.1001C5.00012 4.1001 2.2001 6.1001 0.800098 7.7001C0.700098 7.9001 0.700098 8.10008 0.800098 8.30008C2.2001 10.0001 5.10012 11.9001 8.20012 11.9001C11.4001 11.9001 14.3001 9.90008 15.6001 8.30008C15.8001 8.10008 15.8001 7.9001 15.6001 7.7001ZM11.2001 8.00009C11.2001 9.70009 9.90012 11.0001 8.20012 11.0001C6.50012 11.0001 5.20012 9.70009 5.20012 8.00009C5.20012 6.30009 6.50012 5.00009 8.20012 5.00009C9.90012 5.00009 11.2001 6.40009 11.2001 8.00009ZM1.8001 8.00009C2.3001 7.50009 3.50012 6.4001 5.20012 5.7001C4.10012 7.1001 4.10012 9.00009 5.20012 10.4001C3.90012 9.80009 2.7001 9.00009 1.8001 8.00009ZM11.3001 10.4001C12.4001 9.00009 12.4001 7.1001 11.3001 5.7001C12.6001 6.2001 13.8001 7.10009 14.7001 8.00009C14.2001 8.50009 13.0001 9.60009 11.3001 10.4001Z" fill = "var(--color)" />\
  <path d = "M6.7002 8C6.7002 8.8 7.4002 9.5 8.2002 9.5C9.0002 9.5 9.7002 8.8 9.7002 8C9.7002 7.2 9.0002 6.5 8.2002 6.5C7.4002 6.5 6.7002 7.2 6.7002 8ZM8.90018 8C8.90018 8.3 8.60017 8.60001 8.30017 8.60001C8.00017 8.60001 7.7002 8.3 7.7002 8C7.7002 7.7 8.00017 7.39999 8.30017 7.39999C8.60017 7.39999 8.90018 7.7 8.90018 8Z" fill = "var(--color)" />\
<path d="M8.20032 2.40009C8.40032 2.40009 8.60031 2.20009 8.60031 2.00009V1.00009C8.60031 0.800092 8.40032 0.600098 8.20032 0.600098C8.00032 0.600098 7.80029 0.800092 7.80029 1.00009V2.00009C7.80029 2.20009 8.00032 2.40009 8.20032 2.40009Z" fill="var(--color)"/>\
<path d="M10.9003 2.99987C11.1003 3.09987 11.4004 2.99985 11.5004 2.79985L12.1004 1.79985C12.2004 1.59985 12.1003 1.29988 11.9003 1.19988C11.7003 1.09988 11.4003 1.19986 11.3003 1.39986L10.7004 2.39986C10.6004 2.59986 10.7003 2.89987 10.9003 2.99987Z" fill="var(--color)"/>\
<path d="M5.00007 2.89998C5.10007 3.09998 5.40008 3.19999 5.60008 3.09999C5.80008 2.99999 5.90006 2.69998 5.80006 2.49998L5.20009 1.49998C5.10009 1.29998 4.80008 1.19997 4.60008 1.29997C4.40008 1.39997 4.30007 1.69998 4.40007 1.89998L5.00007 2.89998Z" fill="var(--color)"/>\
<path d="M8.20032 13.6001C8.00032 13.6001 7.80029 13.8001 7.80029 14.0001V15.0001C7.80029 15.2001 8.00032 15.4001 8.20032 15.4001C8.40032 15.4001 8.60031 15.2001 8.60031 15.0001V14.0001C8.70031 13.8001 8.50032 13.6001 8.20032 13.6001Z" fill="var(--color)"/>\
<path d="M5.60008 13.0002C5.40008 12.9002 5.10007 13.0002 5.00007 13.2002L4.40007 14.2002C4.30007 14.4002 4.40008 14.7002 4.60008 14.8002C4.80008 14.9002 5.10009 14.8002 5.20009 14.6002L5.80006 13.6002C5.90006 13.4002 5.80008 13.1002 5.60008 13.0002Z" fill="var(--color)"/>\
<path d="M11.5004 13.2002C11.4004 13.0002 11.1003 12.9002 10.9003 13.0002C10.7003 13.1002 10.6004 13.4002 10.7004 13.6002L11.3003 14.6002C11.4003 14.8002 11.7003 14.9002 11.9003 14.8002C12.1003 14.7002 12.2004 14.4002 12.1004 14.2002L11.5004 13.2002Z" fill="var(--color)"/>\
</svg>'
