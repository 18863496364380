// sites routes
import running_app_routes from '@running_app_src/routes/index'

// development only routes
const dev = [
  {
    path: '/test/notifications',
    name: 'notifications',
    component: () => import('@/components/views/test/notifications').then(Notifications => {
      return Notifications
    }),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/components/views/test/test').then(Test => {
      return Test
    }),
  },
  {
    path: '/library',
    name: 'library',
    redirect: '/library/homepage',  // Aggiunto il redirect al percorso desiderato
    component: () => import('@/components/library/index.vue').then(Library => {
      return Library
    }),
    children: [
      {
        path: 'homepage',  // Rimossa la parte "/library/" dal percorso figlio
        name: 'library_homepage',
        component: () => import('@/components/library/views/homepage').then(Homepage => {
          return Homepage
        }),
      },
      {
        path: '/library/typography',
        name: 'typography',
        component: () => import('@/components/library/views/typography').then(Typography => {
          return Typography
        }),
      },
      {
        path: '/library/components',
        name: 'components',
        component: () => import('@/components/library/views/components').then(Components => {
          return Components
        }),
      },
      {
        path: '/library/icons',
        name: 'icons',
        component: () => import('@/components/library/views/icons').then(Icons => {
          return Icons
        }),
      },
    ],
  },

  ...running_app_routes.dev
]

// commons routes
const commons = [
  {
    path: '/error',
    name: 'error',
    component: () => import('@/components/views/error').then(Error => {
      return Error
    }),
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/components/views/not-found').then(NotFound => {
      return NotFound
    }),
    props: {
      prerenderStatusCode: '404',
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    beforeEnter() { location.href = 'https://www.iubenda.com/privacy-policy/32772449' },
    publicRoute: true,
  },
  {
    path: '/acquista-un-buono',
    name: 'acquista-un-buono',
    props: true,
    component: () => import('@/components/views/acquista-un-buono').then(AcquistaUnBuono => {
      return AcquistaUnBuono
    }),
  },
  {
    path: '/ho-un-buono', // controllo su config.guiSettings.allowDcLogin delegato al componente
    name: 'dc-sso',
    props: true,
    component: () => import('@/components/views/ho-un-buono').then(HoUnBuono => {
      return HoUnBuono
    }),
  },
  {
    path: '/ho-un-buono/:discount_code_url',  // controllo su config.guiSettings.allowDcLogin delegato al componente
    name: 'dc-sso',
    props: true,
    component: () => import('@/components/views/ho-un-buono').then(HoUnBuono => {
      return HoUnBuono
    }),
  },
  {
    path: '/ho-un-buono/:discount_code_url/:name_url/:surname_url/:email_url',  // controllo su config.guiSettings.allowDcLogin delegato al componente
    name: 'dc-sso',
    props: true,
    component: () => import('@/components/views/ho-un-buono').then(HoUnBuono => {
      return HoUnBuono
    }),
  },
  {
    path: '/scheda/:codScheda',
    name: 'scheda',
    component: () => import('@/components/views/scheda').then(Scheda => {
      return Scheda
    }),
    props: true,
  },
]

// production routes
const prod = running_app_routes.prod

export {
  dev,
  prod,
  commons,
}