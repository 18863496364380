<template>
  <li class="keyd-map__node">
    <div v-if="parent" class="keyd-map__label p" @click.stop="$emit('keyd', parent)">{{ parent.l | capitalize }}</div>
    <span class="keyd-map__label ch" @click.stop="$emit('keyd', node)">{{ node.l | capitalize }}</span>
    <ul v-if="node.d && node.d.length">
      <keyd-node v-for="child in node.d" v-bind:key="child.l"
        :node="{...child}"
        @keyd="event => $emit('keyd', event)"
      />
    </ul>
  </li>
</template>

<script>

export default {
  name: 'keyd-node',

  components: {
  },

  props: {
    node: Object,
    parent: {
      type: [Object, Boolean],
      default: false,
    }
  },

  computed: {
  },

  created() {
  },

  data() {
    return {
    }
  },

  mounted() {
  },

  methods: { 
  }
}
</script>

<style lang="scss">
  .keyd-map__node {
    list-style: none;
    padding: 0.5rem 1rem 0 1rem;
    ul {
      padding-left: 0.5rem;
    }
    li {
      padding: 0;
    }
  }
</style>