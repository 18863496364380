import { get_config } from '@/services'
import Vue from 'vue'
import Router from '@/router/router'
import Store from '@/vuex'

export function app_config() {
  let token = ''

  // token as querystring
  if (Router.history.current.query.token) token = Router.history.current.query.token

  // token as state key
  if (token == '') token = Store.state.token

  Store.commit('token', token)

  return get_config(token)
    .then((value) => {
      Vue.prototype.$config = value

      if (!value) return

      Store.commit('token', value.token)  // per essere sicuri di usare il token che c'è in config
      Store.commit('ext_token', value.extToken)

      // Store.replaceState({})

      if (value.integration == undefined) return

      // se c'è availability la metto nello state
      if (value.integration.availability) Store.commit('availability', value.integration.availability)
      // [codici sconto] implementazione vadobay: se c'è un codice sconto lo metto nello state
      // if (value.integration.loginDiscountCode) Store.commit('discount_codes', [{ discountCode: value.integration.loginDiscountCode }])
      // [codici sconto] implementazione liveflight: se c'è un array di oggetti con i codici sconto li metto nello state
      if (value.integration.loginDiscountCode) Store.commit('discount_codes', value.integration.loginDiscountCodes)
      else Store.commit('discount_codes', [])

      // tawk, gtm, logrocket, clarity
      setTimeout(function () {

        if (window.Tawk_API) {

          let tawkAttributes = {
            'name': (value.integration.displayName) ? value.integration.displayName : '',
            'email': (value.integration.email) ? value.integration.email : '',
            'token': (value.token) ? value.token : '',
            'portale': (value.integration.customer) ? value.integration.customer : '',
            'azienda': (value.integration.company) ? value.integration.company : '',
            'credito': (value.integration.availability) ? value.integration.availability : '',
          }

          if (window.Tawk_API.setAttributes !== undefined) {

            window.Tawk_API.setAttributes(tawkAttributes, function (error) {

              if (error) {
                window.console.log('tawk setAttributes error')
                window.console.log(error)
              }

            })

          } else {

            window.Tawk_API.onLoad = function () {
              window.Tawk_API.setAttributes(tawkAttributes, function (error) {

                if (error) {
                  window.console.log('tawk onLoad setAttributes error')
                  window.console.log(error)
                }

              })
            }

          }

        }

        if (window.LogRocket) {
          window.LogRocket.identify(value.integration.email, {
            name: (value.integration.displayName) ? value.integration.displayName : '',
            email: (value.integration.email) ? value.integration.email : '',
            // custom variables
            customer: (value.integration.customer) ? value.integration.customer : '',
            company: (value.integration.company) ? value.integration.company : '',
            token: (value.token) ? value.token : '',
          });

          window.LogRocket.track('sso')
        }

        if (window.clarity) {
          window.clarity("set", "token", value.token)
          window.clarity("set", "customer", (value.integration.customer) ? value.integration.customer : '')
          window.clarity("set", "company", (value.integration.company) ? value.integration.company : '')
          window.clarity("set", "sso", (value.integration.email) ? value.integration.email : '')
        }

        if (Vue.prototype.$gtm) Vue.prototype.$gtm.trackEvent({
          event: 'customEvent',  // Event type [default = 'interaction'] (Optional) tag manager tracks 'customEvent'
          category: 'userBehaviour',
          action: 'sso',
          //label: Vue.prototype.$config.integration.email,
          value: (Vue.prototype.$config.integration.availability >= 0) ? Vue.prototype.$config.integration.availability : 0,
          //noninteraction: false // Optional
        })

        if (Vue.prototype.$gtm) Vue.prototype.$gtm.trackEvent({
          event: 'customEvent',
          category: 'partnerBehaviour',
          action: 'sso',
          label: Vue.prototype.$config.integration.customerDisplayName,
          value: (Vue.prototype.$config.integration.availability >= 0) ? Vue.prototype.$config.integration.availability : 0,
        })

        if (Vue.prototype.$gtm) Vue.prototype.$gtm.trackEvent({
          event: 'customEvent',
          category: 'partnerBehaviour',
          action: 'welfare',
          label: (Vue.prototype.$config.integration.welfare) ? 'welfare' : '',
          value: (Vue.prototype.$config.integration.welfare) ? 1 : 0,
        })

        if (Vue.prototype.$gtm && Vue.prototype.$config.integration.customizationUse) Vue.prototype.$gtm.trackEvent({
          event: 'customEvent',
          category: 'partnerBehaviour',
          action: 'customizationUse',
          label: Vue.prototype.$config.integration.customizationUse,
        })

        if (Vue.prototype.$gtm) Vue.prototype.$gtm.trackEvent({
          event: 'customEvent',
          category: 'companyBehaviour',
          action: 'sso',
          label: Vue.prototype.$config.integration.company,
          value: (Vue.prototype.$config.integration.availability >= 0) ? Vue.prototype.$config.integration.availability : 0,
        })

        if (window.fbq) window.fbq('trackCustom', 'Sso', {
          customer: (value.integration.customer) ? value.integration.customer : '',
          company: (value.integration.company) ? value.integration.company : '',
          availability: (Vue.prototype.$config.integration.availability >= 0) ? Vue.prototype.$config.integration.availability : 0,
        })

      }, 2000)


    })
}