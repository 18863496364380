var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close_engine),expression:"close_engine"}],ref:"booking-engine",staticClass:"booking-engine"},[_c('b-row',[_c('b-col',{staticClass:"booking-engine__column",class:{ current: _vm.current_tab == 0 },attrs:{"sm":"12","lg":"2","xl":"2","offset-xl":"1"},on:{"click":function($event){_vm.current_tab = 0}}},[_c('div',{staticClass:"booking-engine__occupancy active"},[_c('div',{staticClass:"vb-form"},[_c('div',{staticClass:"booking-engine__occupancy__form",on:{"click":() => {
            _vm.scroll_into_view()
            _vm.go_to_tab(0)
          }}},[_c('b-form-input',_vm._b({ref:"input-occupancy",staticClass:"vb-input",class:{ 'focus': _vm.current_tab == 2},attrs:{"id":"input-occupancy","placeholder":_vm.occupancy_placeholder}},'b-form-input',_vm.occupancy,false)),_c('div',{staticClass:"booking-engine__occupancy__icon"},[_c('vb-icon',{attrs:{"name":'occupants',"color":'666666'}})],1)],1),_c('div',{staticClass:"booking-engine__floating--occupancy"},[_c('b-row',{staticClass:"d-flex align-items-end flex-wrap"},[_c('b-col',{attrs:{"md":"8"}},[_c('occupancy',_vm._b({attrs:{"max_rooms":1,"max_pax":4,"occupancy":_vm.occupancy},on:{"updated":e => {
                      _vm.last_departure_airports_selection = _vm.departure_airports  // mantengo ultima selezione aeroporti in caso di cambio occupancy
                      this.$store.commit('liveat/departure_airports', [])
                      this.$store.commit('liveat/availabilities', {})
                      this.$store.commit('liveat/keydsmap', [])
                      this.$store.commit('liveat/location', {})
                      this.$store.commit('occupancy', e.occupancy)
                      _vm.keyd_map_init = false
                    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.html",value:('Il Beneficiario della prestazione (Viaggiatore principale) potrai essere tu, in qualità di titolare del credito welfare, o dovrai, in alternativa, indicare un familiare. Con "familiare" si intende una persona così come definita dal combinato disposto dell\'Art. 433 del Codice Civile e dell\'Art. 12 del D.P.R. del 22.12.1986 n. 917 e smi (TUIR).<br><br>Ti ricordiamo che ai sensi dell\'art. 76 del DPR 445/2000, le dichiarazioni mendaci, la falsità e l\'uso di fatti falsi sono punite ai sensi del Codice penale.'),expression:"'Il Beneficiario della prestazione (Viaggiatore principale) potrai essere tu, in qualità di titolare del credito welfare, o dovrai, in alternativa, indicare un familiare. Con \"familiare\" si intende una persona così come definita dal combinato disposto dell\\'Art. 433 del Codice Civile e dell\\'Art. 12 del D.P.R. del 22.12.1986 n. 917 e smi (TUIR).<br><br>Ti ricordiamo che ai sensi dell\\'art. 76 del DPR 445/2000, le dichiarazioni mendaci, la falsità e l\\'uso di fatti falsi sono punite ai sensi del Codice penale.'",modifiers:{"hover":true,"top":true,"html":true}}],staticClass:"semibold d-inline-flex align-items-center cursor-pointer"},[_vm._v(" Chi viaggerà? "),_c('vb-icon',{staticClass:"ml-1",attrs:{"name":'info',"color":'adb5bd',"size":14}})],1)]},proxy:true}])},'occupancy',_vm.occupancy,false))],1),_c('b-col',{staticClass:"d-flex align-items-end justify-content-end",attrs:{"md":"4"}},[_c('div',{staticClass:"text-center"},[_c('vb-button',{attrs:{"vb_options":{
                      text: 'OK',
                      icon_disabled: 'lock',
                      disabled: _vm.occupancy[0].adults==0,
                    }},on:{"click":function($event){$event.preventDefault();return (() => {
                      _vm.go_to_tab(1)
                      _vm.keyd_map_init = true
                    }).apply(null, arguments)}}})],1)])],1)],1)])])]),_c('b-col',{staticClass:"booking-engine__column",class:{ current: _vm.current_tab == 1 },attrs:{"sm":"12","lg":"3","xl":"2"}},[_c('div',{staticClass:"booking-engine__autocomplete",class:{ active: _vm.keyd_map_init }},[_c('div',{staticClass:"vb-form"},[_c('button',{ref:"hidden_input",staticClass:"focus-dump-button"}),_c('autocomplete',{key:_vm.location.label,ref:"autocomplete",attrs:{"base-class":"booking-engine__autocomplete","search":_vm.get_keyds,"placeholder":_vm.autocomplete_placeholder,"debounce-time":500},on:{"submit":_vm.on_submit_location},scopedSlots:_vm._u([{key:"default",fn:function({ inputProps, inputListeners, results, noResults, focused, resultProps, resultListProps, resultListListeners }){return [_c('div',{staticClass:"booking-engine__autocomplete__form"},[_c('input',_vm._g(_vm._b({class:[
                    'vb-input form-control',
                    { 'autocomplete-input-no-results': noResults },
                    { 'autocomplete-input-focused': focused },
                    { 'focus': _vm.current_tab == 0}
                  ],attrs:{"type":"search"},on:{"focus":$event => {
                    _vm.autocomplete_focus = true
                    _vm.scroll_into_view()
                    $event.target.select()
                    _vm.current_tab = 1
                  },"blur":() => {
                    _vm.autocomplete_focus = false
                    _vm.autocomplete_blur
                  }}},'input',inputProps,false),inputListeners)),_c('div',{staticClass:"booking-engine__autocomplete__icon"},[_c('vb-icon',{attrs:{"name":'symbol_vv'}})],1)]),_c('b-row',[_c('b-col',[_c('ul',_vm._g(_vm._b({staticClass:"booking-engine__autocomplete-result-wrap"},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].keyd,style:(result.type == 'noresults' ? 'pointer-events: none' : ''),attrs:{"data-type":result.type}},'li',resultProps[index],false),[(result.type == 'noresults')?_c('div',{domProps:{"innerHTML":_vm._s(result.label)}}):_c('div',{staticClass:"cursor-pointer",style:('padding-left:calc('+(result.level-1)+'*0.5rem)'),domProps:{"innerHTML":_vm._s(result.label.charAt(0).toUpperCase() + result.label.slice(1).toLowerCase())}})])}),0)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(resultProps.length == 0),expression:"resultProps.length == 0"}],staticClass:"booking-engine__floating--suggestions"},[(_vm.keyd_map_init)?_c('keyd-map',{ref:"keyd-map",on:{"keyd":e => _vm.on_submit_location(e)}}):_vm._e()],1)]}}])})],1)])]),_c('b-col',{staticClass:"booking-engine__column",class:{ current: _vm.current_tab == 2 },attrs:{"sm":"12","lg":"3","xl":"2"}},[_c('div',{staticClass:"booking-engine__airports",class:{ active: _vm.departure_airports.length > 0 }},[_c('div',{staticClass:"vb-form"},[_c('div',{staticClass:"booking-engine__airports__form",on:{"click":() => {
            _vm.scroll_into_view()
            _vm.go_to_tab(2)
          }}},[_c('b-form-input',_vm._b({ref:"input-airports",staticClass:"vb-input",class:{ 'focus': _vm.current_tab == 2},attrs:{"id":"input-airports","placeholder":_vm.airports_placeholder}},'b-form-input',_vm.departure_airports,false)),_c('div',{staticClass:"booking-engine__airports__icon"},[_c('vb-icon',{attrs:{"name":'airplane',"color":'666666'}})],1)],1),_c('div',{staticClass:"booking-engine__floating"},[(_vm.loading)?_c('loader'):_c('div',[(_vm.departure_airports_no_results)?_c('div',[_vm._v(" Purtroppo non ci sono disponibilità per questa destinazione/passeggeri "),_c('br'),_c('br')]):_c('div',[_c('div',{staticClass:"booking-engine__airports-list"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('p',{staticClass:"semibold"},[_vm._v("Scegli l'aeroporto")])]),_vm._l((_vm.departure_airports),function(airport,airport_index){return _c('b-col',{key:_vm._uid+airport_index,staticClass:"mb-3 mb-lg-2",attrs:{"sm":"6","md":"4"}},[_c('vb-checkbox',{attrs:{"vb_options":{
                            text: airport.name.toLowerCase() + ' (' + airport.iata_code + ')',
                            disabled: false,
                          }},on:{"change":_vm.get_availabilities},model:{value:(airport.selected),callback:function ($$v) {_vm.$set(airport, "selected", $$v)},expression:"airport.selected"}})],1)})],2)],1),_c('div',{staticClass:"booking-engine__airports-list-btn"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.search_params.departure_airports.length && !_vm.has_availabilities),expression:"search_params.departure_airports.length && !has_availabilities"}],staticClass:"mr-2",domProps:{"innerHTML":_vm._s('Attendi')}}),_c('vb-button',{attrs:{"vb_options":{
                      text: 'OK',
                      disabled: !_vm.has_availabilities,
                      icon_disabled: 'lock'
                    }},on:{"click":function($event){$event.preventDefault();return _vm.go_to_tab(3)}}})],1)])])],1)])])]),_c('b-col',{staticClass:"booking-engine__column",class:{ current: _vm.current_tab == 3 },attrs:{"sm":"12","lg":"2","xl":"2"}},[_c('div',{staticClass:"booking-engine__dates",class:{ active: _vm.has_availabilities }},[_c('div',{staticClass:"vb-form"},[_c('div',{staticClass:"booking-engine__dates__form",on:{"click":function($event){_vm.scroll_into_view();_vm.go_to_tab(3)}}},[_c('b-form-input',_vm._b({staticClass:"vb-input",class:{ 'focus': _vm.current_tab == 3},attrs:{"id":"input-availabilities","placeholder":_vm.availabilities_placeholder}},'b-form-input',_vm.availabilities_bind,false)),_c('div',{staticClass:"booking-engine__dates__icon"},[_c('vb-icon',{attrs:{"name":'calendar',"color":'666666'}})],1)],1),_c('div',{staticClass:"booking-engine__floating"},[_c('div',{staticClass:"booking-engine__dates-list"},[_c('div',{staticClass:"d-flex justify-content-between"}),_c('b-tabs',{attrs:{"vertical":"","tag":'span'},scopedSlots:_vm._u([{key:"tabs-start",fn:function(){return [_c('p',{staticClass:"semibold mb-2 w-100"},[_vm._v("Durata")])]},proxy:true}])},_vm._l((_vm.availabilities),function(availability,availability_index){return _c('b-tab',{key:_vm._uid+availability_index,attrs:{"active":availability_index == 1,"disabled":availability.length == 0},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('vb-button',{attrs:{"vb_options":{
                          text: availability_index.replace('-','/') + ' notti',
                          variant: 'dark',
                        }}},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(_vm._s(availability.length))])],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"booking-engine__dates-content"},[_c('p',{staticClass:"semibold mb-2"},[_vm._v("Data di partenza")]),(availability.length)?_c('v-calendar',{attrs:{"locale":{ masks: { weekdays: 'WWW' } },"disable-page-swipe":"","is-expanded":"","min-date":new Date(availability[0].dep),"max-date":new Date(availability[availability.length - 1].dep),"attributes":availability.map((a,index) => {
                        return {
                          key:index,
                          dates: new Date(a.dep),
                          customData: {
                            ...a
                          },
                        }
                      })},scopedSlots:_vm._u([{key:"day-content",fn:function({ day, attributes }){return [(attributes !== undefined)?_c('div',{staticClass:"cursor-pointer",class:{
                            'ok': _vm.date_check(day),
                            'ko': !_vm.date_check(day),
                            'inactive': (attributes.length == 0),
                            'selected': attributes[0] !== undefined ? (attributes[0].customData.dep == _vm.departure_date) : false,
                            'multiple': (attributes.length > 1),
                          },attrs:{"id":'av-index--'+availability_index+'-'+_vm.encode_parameters(day)},on:{"click":function($event){$event.stopPropagation();return (() => _vm.on_submit_date(attributes[0].customData)).apply(null, arguments)}}},[_c('div',{staticClass:"day-label text-sm text-gray-900 text-center"},[_vm._v(_vm._s(day.day))])]):_vm._e()]}}],null,true)}):_vm._e()],1)])}),1)],1)])])])]),_c('b-col',{staticClass:"booking-engine__column d-flex align-self-end",attrs:{"sm":"12","lg":"2","xl":"2"}},[_c('div',{staticClass:"booking-engine__search",class:{ active: _vm.departure_date !== '' }},[_c('div',{staticClass:"vb-form text-center"},[_c('b-form-group',{attrs:{"label-for":"input-autocomplete","label":"","label-class":"vb-label"}},[_c('vb-button',{key:_vm.searching,attrs:{"vb_options":{
                text: (_vm.searching ? 'Attendi' : 'Cerca'),
                disabled: (_vm.departure_date == '' || _vm.searching),
                variant: 'info',
                icon_disabled: 'lock'
              }},on:{"click":_vm.search}})],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }