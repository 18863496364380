export default '<svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
'    <path d="M22.6561 32.8532C23.0923 32.7599 23.5258 32.6533 23.95 32.5268L35.5629 14.3189C35.5002 13.8753 35.4108 13.441 35.3121 13.0081L22.6561 32.8532Z" fill="url(#paint0_linear_0_1)"/>\n' +
'    <path d="M19.7708 33.1928C20.1483 33.1755 20.5285 33.1649 20.8993 33.1276L34.8253 11.2935C34.6999 10.9352 34.5598 10.5848 34.4131 10.2372L19.7708 33.1928Z" fill="url(#paint1_linear_0_1)"/>\n' +
'    <path d="M18.2004 33.1371L33.7324 8.78526C30.9365 3.62449 25.4849 0.114262 19.1955 0.114262C10.0503 0.114262 2.63776 7.51838 2.63776 16.6516C2.63776 25.4492 9.5207 32.6202 18.2004 33.1371Z" fill="#F8A432"/>\n' +
'    <path d="M18.2008 30.5126C9.97325 30.0223 3.36912 23.548 2.7035 15.3819C2.66882 15.8149 2.63812 16.2479 2.63812 16.6888C2.63812 25.4877 9.52105 32.66 18.2008 33.1756L32.5376 10.6941L18.2008 30.5126Z" fill="url(#paint2_linear_0_1)"/>\n' +
'    <path d="M19.2519 9.10592C19.0145 9.17652 18.8464 9.34969 18.7917 9.58015C18.7357 9.81994 18.8144 10.081 18.9931 10.2476C19.1585 10.4034 19.3813 10.4541 19.6121 10.3861C20.2457 10.2063 20.9406 10.2928 21.5142 10.6232C22.0464 10.9309 22.4173 11.4172 22.5587 11.9847C22.6294 12.2937 22.8881 12.5016 23.2056 12.5016C23.5217 12.5016 23.7805 12.2937 23.8485 11.99C23.9913 11.4145 24.3647 10.9296 24.8996 10.6206C25.4719 10.2902 26.1602 10.2049 26.7804 10.3861C27.1486 10.4914 27.4968 10.2995 27.6048 9.93717C27.7129 9.57083 27.5221 9.21381 27.1539 9.10458C25.6866 8.69294 24.1073 9.2178 23.1989 10.3608C22.5093 9.48024 21.4168 8.96469 20.275 8.96469C19.9349 8.96469 19.5907 9.01 19.2519 9.10592Z" fill="url(#paint3_linear_0_1)"/>\n' +
'    <path d="M6.97998 15.3018C5.75012 13.566 3.46647 12.7574 1.32689 13.3702C0.916044 13.4967 0.702638 13.9057 0.829359 14.324C0.954746 14.7396 1.34156 14.9514 1.75773 14.8302C2.72748 14.5505 3.77594 14.6823 4.63897 15.1819C5.45265 15.6534 6.01958 16.4021 6.23434 17.292C6.31837 17.6344 6.61314 17.8635 6.97196 17.8635C7.32944 17.8635 7.62557 17.6344 7.70828 17.2934C7.92437 16.4048 8.49128 15.6548 9.30763 15.1819C10.1787 14.6796 11.2258 14.5531 12.1835 14.8289C12.585 14.9488 12.9905 14.733 13.1146 14.324C13.24 13.9057 13.0266 13.4967 12.6104 13.3688C12.1302 13.2316 11.6433 13.165 11.1618 13.165C9.50107 13.165 7.91905 13.9537 6.97998 15.3018Z" fill="url(#paint4_linear_0_1)"/>\n' +
'    <defs>\n' +
'        <linearGradient id="paint0_linear_0_1" x1="22.6557" y1="22.931" x2="35.5634" y2="22.931" gradientUnits="userSpaceOnUse">\n' +
'            <stop stop-color="#F8A432"/>\n' +
'            <stop offset="0.557292" stop-color="#E3952D"/>\n' +
'            <stop offset="1" stop-color="#E3952D"/>\n' +
'        </linearGradient>\n' +
'        <linearGradient id="paint1_linear_0_1" x1="19.7716" y1="21.7146" x2="34.825" y2="21.7146" gradientUnits="userSpaceOnUse">\n' +
'            <stop stop-color="#F8A432"/>\n' +
'            <stop offset="0.557292" stop-color="#E3952D"/>\n' +
'            <stop offset="1" stop-color="#E3952D"/>\n' +
'        </linearGradient>\n' +
'        <linearGradient id="paint2_linear_0_1" x1="2.6371" y1="21.9354" x2="32.5391" y2="21.9354" gradientUnits="userSpaceOnUse">\n' +
'            <stop stop-color="#F8A432"/>\n' +
'            <stop offset="0.557292" stop-color="#E3952D"/>\n' +
'            <stop offset="1" stop-color="#E3952D"/>\n' +
'        </linearGradient>\n' +
'        <linearGradient id="paint3_linear_0_1" x1="18.7739" y1="10.7333" x2="27.6354" y2="10.7333" gradientUnits="userSpaceOnUse">\n' +
'            <stop stop-color="#3572C2"/>\n' +
'            <stop offset="0.992642" stop-color="#27538D"/>\n' +
'            <stop offset="1" stop-color="#27538D"/>\n' +
'        </linearGradient>\n' +
'        <linearGradient id="paint4_linear_0_1" x1="0.793462" y1="15.5141" x2="13.1497" y2="15.5141" gradientUnits="userSpaceOnUse">\n' +
'            <stop stop-color="#3572C2"/>\n' +
'            <stop offset="0.992642" stop-color="#27538D"/>\n' +
'            <stop offset="1" stop-color="#27538D"/>\n' +
'        </linearGradient>\n' +
'    </defs>\n' +
'</svg>\n'
