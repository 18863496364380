import Vue from 'vue'
import BookingEngine from '@alpitour/components/blocks/booking-engine.vue'
import Pad from '@alpitour/components/blocks/pad.vue'
import Loader from "@components/blocks/loader.vue"
import Mixins from "@booking/utilities/mixins"
//import SentryInit from '@src/sentry'

// site specific components and settings
export default function site_config() {
  Vue.component('booking-engine', BookingEngine)
  Vue.component('pad', Pad)
  Vue.component('loader', Loader)
  Vue.mixin(Mixins)
  //SentryInit()
}