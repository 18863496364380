<template>
  <div class="occupancy">

    <!--title (slot)-->
    <div class="occupancy--header" v-if="$slots.title || max_rooms > 1">
      <b-row>
        <b-col>
          <div class="occupancy--title">
            <slot name="title" />
          </div>
          <div class="occupancy--add-room" @click="add_room" v-if="internal_occupancy.length < max_rooms && max_rooms > 1">
            <u>aggiungi camera</u>
            <vb-icon
              :name="'plus-v2'"
              :size="16"
              :color="'006ce4'"
            />
          </div>
        </b-col>
      </b-row>
    </div>
      
    <div v-for="(occupancy_item, room) in internal_occupancy" v-bind:key="room" class="occupancy--room-wrapper" :class="{ singleroom: max_rooms == 1 }">
      <div class="occupancy--room">
        <b-row>
          <!--adults-->
          <b-col xs="4" class="occupancy--adults">
            <div>Adulti</div>
            <div class="occupancy--content">
              <vb-icon
                :name="'minus-circle'"
                :size="20"
                :color="occupancy_item.adults > 1 ? '003b95' : 'a3a3a3'"
                @click.stop="remove_adult(room)"
              />
              <div class="occupancy--qty">{{occupancy_item.adults}}</div>
              <vb-icon
                :name="'plus-circle'"
                :size="20"
                :color="occupancy_item.adults < max_adults_per_room ? '003b95' : 'a3a3a3'"
                @click.stop="add_adult(room)"
              />
            </div>
            <div class="occupancy--content mobile">
              <vb-select
                @change="value => { set_adult(room, value) }"
                :vb_options="{
                  options: adults_select,
                  value: occupancy_item.adults,
                  size: 'sm',
                }"/>              
            </div>
          </b-col>

          <!--children-->
          <b-col xs="4" class="occupancy--children">
            <div>Bambini</div>
            <div class="occupancy--content">
              <vb-icon
                :name="'minus-circle'"
                :size="20"
                :color="occupancy_item.children.length > 0 ? '003b95' : 'a3a3a3'"
                @click.stop="remove_child(room)"
              />
              <div class="occupancy--qty">{{occupancy_item.children.length}}</div>
              <vb-icon
                :name="'plus-circle'"
                :size="20"
                :color="'003b95'"
                @click.stop="add_child(room)"
              />
            </div>
            <div class="occupancy--content mobile">
              <vb-select
                @change="value => { set_children(room, value) }"
                :vb_options="{
                  options: children_select,
                  value: occupancy_item.children.length,
                  size: 'sm',
                }"/>              
            </div>            
          </b-col>

          <!--remove room-->
          <b-col xs="4" class="occupancy--remove-room">
            <!--<div :class="{disabled: internal_occupancy.length == 1}">-->
            <div>
              <div class="occupancy--remove-room--index" v-if="max_rooms > 1">
                <b-badge variant="light">Camera {{ room + 1 }}</b-badge>
              </div>
              <div class="occupancy--remove-room--icon" v-if="internal_occupancy.length > 1" @click.stop="remove_room(room)">
                &#10005;
              </div>
            </div>
          </b-col>

        </b-row>

        <!--children ages-->
        <b-row v-if="occupancy_item.children.length">
          <b-col cols="12" class="occupancy--children-disclaimer">
            <!--children disclaimer (slot)-->
            <slot name="disclaimer"/>
          </b-col>
          <b-col cols="3" md="6" xl="4" v-for="(m,index) in occupancy_item.children" v-bind:key="_uid+index">
            <vb-select
              class="occupancy--age"
              @change="value => {set_child_age(value,index,room)}"
              :vb_options="{
                options: options,
                value: m,
                //size: 'sm',
              }"
            />
            <vb-select
              class="occupancy--age mobile"
              @change="value => {set_child_age(value,index,room)}"
              :vb_options="{
                options: options.map(o => { return { value: o.value, text: o.value } }),
                value: m,
                size: 'sm',
              }"
            />
          </b-col>
        </b-row>

      </div>

      <!--max_pax reached-->
      <b-row v-if="pax_total == max_pax">
        <b-col>
          <div class="occupancy--limit-reached" v-b-popover.hover.top="'Non sono disponibili pacchetti per un numero superiore di viaggiatori. Serviranno eventualmente due prenotazioni diverse. Contatta l’assistenza per maggiori informazioni.'">
            <vb-icon :name="'info'" :color="'f8af3c'" :size="14" class="mr-1" />
            <span>Non puoi aggiungere altri viaggiatori</span>
          </div>
        </b-col>
      </b-row>
    </div>

    <!--children disclaimer (slot)
    <div v-if="internal_occupancy.filter(room => { return room.children.length > 0 }).length > 0">
      <slot name="disclaimer" class="mt-3" />
    </div>
    -->

    <!--room disclaimer-->
    <div v-if="max_rooms > 1 && internal_occupancy.length == max_rooms" class="text--sm">
      Hai raggiunto il numero massimo di camere prenotabili,
      se ti occorrono più camere contatta l’assistenza.
    </div>

    <!--footer slot (buttons and similar)-->
    <slot name="footer" />
  </div>
</template>

<script>
import { number, max, min } from 'mathjs'

export default {
  props: {
    max_children_per_room: {
      type: Number,
      default: 4,
    },
    max_adults_per_room: {
      type: Number,
      default: 4,
    },
    max_rooms: {
      type: Number,
      default: 1,
    },
    max_pax: {
      type: Number,
      default: 24,
    },
    default_room: {
      type: Object,
      default: () => {
        return {
          adults: 1,
          children: [],
        }
      }
    },
    occupancy: Array,
    children_options: {
      type: Array,
      default: () => {
        return [
          { value: 17, text: '17 anni' },
          { value: 16, text: '16 anni' },
          { value: 15, text: '15 anni' },
          { value: 14, text: '14 anni' },
          { value: 13, text: '13 anni' },
          { value: 12, text: '12 anni' },
          { value: 11, text: '11 anni' },
          { value: 10, text: '10 anni' },
          { value: 9, text: '9 anni' },
          { value: 8, text: '8 anni' },
          { value: 7, text: '7 anni' },
          { value: 6, text: '6 anni' },
          { value: 5, text: '5 anni' },
          { value: 4, text: '4 anni' },
          { value: 3, text: '3 anni' },
          { value: 2, text: '2 anni' },
          { value: 1, text: '1 anno' },
          { value: 0, text: '0 anni' }
        ]
      }
    },
  },
  created() {
  },
  computed: {
    // pax totali in tutte le stanze
    pax_total() {
      let pax_total = 0
      this.internal_occupancy.forEach(room => {
        pax_total += room.adults
        pax_total += room.children.length
      })
      return pax_total
    }
  },
  data() {
    return {
      options: this.children_options,
      adults_select: [],
      children_select: [],
      internal_occupancy: this.occupancy.map((item,index) => {
        return {
          ...item,
          index: index,
        }
      }),
    }
  },
  methods:
  {
    updated() {
      // rimuovo la chiave "index" da this.internal_occupancy
      let occupancy = this.internal_occupancy.map(room => {
        return {
          adults: room.adults,
          children: room.children,
        }
      })
      this.$emit('updated', {
        occupancy: occupancy,
      })
    },
    add_child(room=0) {
      if (this.internal_occupancy[room].children.length == this.max_children_per_room) return
      if (this.pax_total == this.max_pax) return
      let children = this.internal_occupancy[room].children
      children.push(11) // CHILD < 12y
      this.updated()
    },
    remove_child(room=0) {
      if (this.internal_occupancy[room].children.length == 0) return
      let children = this.internal_occupancy[room].children
      children.splice(children.length-1, 1)
      this.updated()
    },
    remove_child_by_index(index, room=0) {
      let children = this.internal_occupancy[room].children
      children.splice(index, 1)
      this.updated()
    },
    set_children(room=0, value) {
      let children = number(this.internal_occupancy[room].children.length)
      value = number(value)
      if (children.length == value) return
      if (value < children) {
        this.log('<')
        for (let i = 0; i < (children-value); i++) {
          this.remove_child(room)
        }
      }
      if (value > children) {
        this.log('>')
        for (let i = 0; i < (value-children); i++) {
          this.add_child(room)
        }
      }
      this.updated()
    },
    set_child_age(value, index, room=0) {
      let children = this.internal_occupancy[room].children
      children[index] = value
      this.updated()
    },
    add_adult(room=0) {
      if (this.pax_total == this.max_pax) return
      this.internal_occupancy[room].adults = min(this.internal_occupancy[room].adults + 1, this.max_adults_per_room)
      this.updated()
    },
    remove_adult(room=0) {
      this.internal_occupancy[room].adults = max(this.internal_occupancy[room].adults - 1, 1)
      this.updated()
    },
    set_adult(room=0, value) {
      this.internal_occupancy[room].adults = min(number(value), this.max_adults_per_room)
      this.updated()
    },
    add_room() {
      if (this.internal_occupancy.length == this.max_rooms) return
      let room = {
        ...this.default_room,
        children: [
          // necessario, altrimenti viene utilizzato sempre lo stesso array passato per riferimento
          ...this.default_room.children,
        ],
      }
      this.internal_occupancy.push(room)
      this.updated()
    },
    remove_room(room=0) {
      this.internal_occupancy.splice(room, 1)
      this.updated()
    },
  },
  mounted() {
    //this.updated()
    for (let i = 1; i <= this.max_adults_per_room; i++) {
      this.adults_select.push(i)
    }
    for (let i = 0; i <= this.max_children_per_room; i++) {
      this.children_select.push(i)
    }
  }
}
</script>

<style lang="scss">
.occupancy {
  min-width: 100%;
  @include media-breakpoint-up(md) {
    min-width: 440px;
  }
  right: 0;
  z-index: 1;
  &--block {
    border-bottom: 1px solid $gray-200;
    padding-bottom: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;
  }
  &--header {
    @extend .occupancy--block;
    .col {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &--title {
    * {
      margin-bottom: 0;
    }
  }
  &--add-room {
    color: $info;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: 6px;
      transform: scale(-1, 1);
    }
  }

  &--room-wrapper {
    @extend .occupancy--block;
    //font-size: $font-size-md;
    &.singleroom {
      margin-bottom: 0;
      padding-bottom: 0;
      border:0;
      .row.occupancy--age {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  &--room {
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .row {
      flex:1;
      min-width: 100%;
      width: 100%;
      padding: $grid-gutter-width/4 0;
      .occupancy--age {
        margin-bottom: $grid-gutter-width/4;
      }
    }
  }

  &--adults {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &--children {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &--age {
    margin-top: $grid-gutter-width/4;
    @include media-breakpoint-up(md) {
      &>*:nth-child(even) {
        padding-left: $grid-gutter-width/4;
      }
      &>*:nth-child(odd) {
        padding-right: $grid-gutter-width/4;
      }
    }
    .vb-select {
      margin-top: $grid-gutter-width/2;
    }
  }

  &--remove-room {
    &>* {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    font-size: $font-size-base;
    cursor: pointer;
    .disabled {
      pointer-events: none;
      opacity: 0.25;
    }
    &--index {
      padding-right: 6px;
      .badge {
        font-weight: $font-weight-normal !important;
      }
    }
    &--icon {
      color: $danger;
      font-size: $font-size-base;
    }
  }

  &--title {
    font-size: $font-size-lg;
  }

  &--content {
    display: flex;
    align-items: center;
    margin-left: $grid-gutter-width/2;
    svg {
      cursor: pointer;
    }   
  }

  &--qty {
    white-space: nowrap;
    padding: 0 .5em;
    font-weight: $vb-font-weight-semibold;
  }

  &--limit-reached {
    font-size: $font-size-sm;
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: $vb-font-weight-semibold;
    padding: .625rem 0;
    margin-top: 0.625rem;
    cursor: pointer;

    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    &--room .row {
      padding: 0;
      align-items: flex-start;
    }
    &--adults,
    &--children {
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
    }
    &--content {
      margin-left:0;
    }    
  }

  @include media-breakpoint-down(sm) {
    &--title {
      display: none;
    }
    &--add-room {
      text-align: center;
      justify-content: center;
      min-width: 100%;
    }
    &--adults {
      order: 2;
    }
    &--children {
      order: 3;
    }
    &--remove-room {
      order: 1;
      min-width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.35rem;
      &--index {
        background: $gray-200;
        border-radius: $border-radius-sm;
      }
      &>div {
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &--room {
    .occupancy--children-disclaimer {}
    .occupancy--content,
    .occupancy--age {
      &.mobile {
        display: none;
      }
    }
    @include media-breakpoint-down(sm){
      .occupancy--children-disclaimer {
        margin-top: $grid-gutter-width/4;
      }
      .occupancy--adults,
      .occupancy--children {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 50%;
      }
      .occupancy--content,
      .occupancy--age {
        display: none;
        &.mobile {
          display: block;
        }
      }
      .occupancy--content {
        margin-left: $grid-gutter-width/2;
        .vb-select {
          min-width: 60px;
        }
      }
    }
  }
}
</style>