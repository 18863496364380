<template>
  <b-breadcrumb class="vb-breadcrumbs" :items="items">
    <slot></slot>
  </b-breadcrumb>
</template>

<script>

export default {
  name: 'breadcrumbs',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      items: this.vb_options.items !== undefined ? this.vb_options.items : [],
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  }
}
</script>

<style lang="scss">

  .vb-breadcrumbs {

    a {
      color: $vb-body-color;
    }

    .active {
      font-weight: $vb-font-weight-semibold;

      &:before {
        color: $info;
      }

      &:after {
        content: '.';
        display: inline-block;
        color: $info;
      }
    }
  }

</style>