<template>
  <div v-if="!loading" :class="{ 'site-loading': loading, 'site-loaded': !loading }">
    <div v-if="is_maintenance" class="maintenance">
      <logo name="logo_headline" />
    </div>
    <div v-else>
      <pinned-message />

      <global-header :variant="header_variant">
        <template #logo>
          <router-link :to="{ name: 'homepage' }">
            <logo :type="header_variant == 'dark' ? 'logo_headline_white' : 'logo_headline'" />
          </router-link>
        </template>
        <!--<template #navigation></template>-->
        <!--<template #user></template>-->
      </global-header>

      <main class="site-main">
        <router-view />
        <!--<button @click="throw_sentry_error">Throw error</button>-->
      </main>

      <global-prefooter />

      <global-footer :variant="footer_variant">
        <template #logo>
          <logo type="logo_headline" />
        </template>
        <template #columns>
          <site-footer />
        </template>
      </global-footer>

      <modal-notification ref="modal_notification" />
      <toast-notification ref="toast_notification" />
      <b-alert :show="VUE_APP_MODE !== 'production'" id="debug-alert">
        <b>SITO DEMO</b>
        <br>
        vai su <a :href="prodUrl">{{ prodName }}</a>
      </b-alert>

      <back-to-top bottom="20px" right="105px" visibleoffset="600">
        <b-button variant="info">
          <vb-icon :name="'angle_small_up'" :color="'ffffff'" :size=24 />
        </b-button>
      </back-to-top>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import BackToTop from 'vue-backtotop'
import { app_config } from '@/config'
import PinnedMessage from '@/components/blocks/pinned-message'
import Logo from '@/components/svg/logo'
import Header from '@/components/blocks/header/header'
import Footer from '@/components/blocks/footer/footer'
import Prefooter from '@/components/blocks/prefooter'
import SiteFooter from '@running_app_src/components/blocks/footer/footer'
import { header, footer } from '@running_app_src/scss/abstract/_custom.scss'

export default {
  name: 'app',

  metaInfo: {
    title: process.env.VUE_APP_SITE_PAYOFF,
    titleTemplate: '%s - ' + process.env.VUE_APP_SITE_TITLE,
    meta: [
      { vmid: 'metaTitle', name: 'Title', content: 'Prenota online voli. Semplice e sicuro!' },
      { vmid: 'metaDescription', name: 'Description', content: 'Prenota online i tuoi voli in modo semplice e soprattutto sicuro. ' + process.env.VUE_APP_SITE_TITLE + ', soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
      { vmid: 'metaKeywords', name: 'Keywords', content: process.env.VUE_APP_SITE_TITLE + ', viaggi online, online travel booking, online travel agency, agenzia viaggi online, prenotazione online viaggi, viaggi welfare aziendale, viaggi welfare, viaggi promozioni, viaggi marketing, viaggi viaggi programmi fedeltà, viaggi programma fedeltà, viaggi loyalty program, catalogo a premi viaggi' },
      { vmid: 'metaAbstract', name: 'abstract', content: process.env.VUE_APP_SITE_TITLE + 'è un servizio di prenotazione voli online pensato per gli utenti del welfare aziendale. ' + process.env.VUE_APP_SITE_TITLE + ' offre soluzioni di online travel booking per i programmi fedeltà, per le promozioni e i concorsi' },
      { vmid: 'metaClassification', name: 'Classification', content: 'Commercial' },
      { vmid: 'metaResourceType', name: 'resource-type', content: 'document' },
      { vmid: 'metaDistribution', name: 'Distribution', content: 'Global' },
      { vmid: 'metaRating', name: 'Rating', content: 'General' },
      { vmid: 'metaLanguage', name: 'Language', content: 'IT' },
      { vmid: 'metaOwner', name: 'Owner', content: 'Salabam SRL' },
      { vmid: 'ogTitle', property: 'og:title', content: 'Prenota voli online in tempo reale' },
      { vmid: 'ogSiteName', name: 'site_name', content: process.env.VUE_APP_SITE_PAYOFF + ' ' + process.env.VUE_APP_SITE_TITLE },
      { vmid: 'ogType', name: 'og:type', content: 'website' },
      { vmid: 'ogUrl', name: 'og:url', content: window.location.href },
      { vmid: 'ogImage', name: 'og:image', content: process.env.VUE_APP_SITE_HOST + 'assets/' + process.env.VUE_APP_OG_IMAGE },
      { vmid: 'ogImageWidth', name: 'og:image:width', content: '512' },
      { vmid: 'ogImageHeight', name: 'og:image:height', content: '512' },
      { vmid: 'ogDescription', name: 'og:description', content: 'Prenota voli online in modo semplice e soprattutto sicuro. ' + process.env.VUE_APP_SITE_TITLE + ', soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
      { vmid: 'dcTitle', name: 'DC.title', lang: 'it', content: 'Prenota voli online in modo semplice e soprattutto sicuro. ' + process.env.VUE_APP_SITE_TITLE + ', soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
      { vmid: 'dcCreator', name: 'DC.creator', content: 'Salabam SRL' },
      { vmid: 'dcSubject', name: 'DC.subject', lang: 'it', content: process.env.VUE_APP_SITE_TITLE + ';viaggi online;online travel booking;online travel agency;agenzia viaggi online;prenotazione online viaggi;viaggi welfare aziendale;viaggi welfare;viaggi promozioni;viaggi marketing;viaggi viaggi programmi fedeltà;viaggi programma fedeltà;viaggi loyalty program;catalogo a premi viaggi' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: process.env.VUE_APP_SITE_TITLE + 'è un servizio di prenotazione voli online pensato per gli utenti del welfare aziendale. ' + process.env.VUE_APP_SITE_TITLE + ' offre soluzioni di online travel booking per i programmi fedeltà, per le promozioni e i concorsi' },
      { vmid: 'dcType', name: 'DC.type', scheme: 'DCTERMS.DCMIType', content: 'Text' },
      { vmid: 'dcFormat', name: 'DC.format', content: 'text/html' },
      { vmid: 'dcIdentifier', name: 'DC.identifier', scheme: 'DCTERMS.URI', content: process.env.VUE_APP_SITE_HOST },
      { vmid: 'dcLanguage', name: 'DC.language', scheme: 'DCTERMS.RFC1766', content: 'IT' },
      { vmid: 'robots', name: 'robots', content: process.env.VUE_APP_META_ROBOTS },
    ]
  },

  components: {
    'pinned-message': PinnedMessage,
    'logo': Logo,
    'global-header': Header,
    'global-footer': Footer,
    'global-prefooter': Prefooter,
    'site-footer': SiteFooter,
    'back-to-top': BackToTop,
      },

  props: {
  },

  data() {
    return {
      VUE_APP_MODE: process.env.VUE_APP_MODE,
      prodUrl: process.env.VUE_APP_PROD_URL,
      prodName: process.env.VUE_APP_PROD_NAME,
      is_maintenance: false,
    }
  },

  computed: {
    // global state keys
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(value) {
        this.$store.commit('loading', value)
      }
    },
    ...mapState([
      'occupancy'
    ]),    
  },

  created() {
    this.header_variant = header
    this.footer_variant = footer
  },

  beforeMount() {
    this.app_config = app_config
    let wait_router_interval = setInterval(() => {
      // aspetto che il router carichi la rotta attuale, altrimenti app_config non riesce a leggere l'eventuale token/session in get
      if (this.$route.name == null) return
      clearInterval(wait_router_interval)
      this.app_config()
        .then(() => {
          // caricamento (eventulamente condizionale) di submodules dello state
          let liveat = require('@running_app_src/store')
          this.log('[App.vue] - async: Liveat state module loaded')
          liveat.register_state_module(Vue.prototype)
          // avvio app
          this.is_maintenance = false
          this.loading = false
        })
    }, 100)
  },

  mounted() {
    window.eventBus.$on('got_modal_notification', data => {
      this.$refs.modal_notification.handle_notification(data)
    })
    window.eventBus.$on('got_toast_notification', data => {
      this.$refs.toast_notification.handle_notification(data)
    })
  },

  destroyed() {
  },

  methods: {
    throw_sentry_error() {
      throw new Error('Sentry Error');
    }
  }
}
</script>

<style lang="scss">

  @import "scss/_app.scss";

  .maintenance {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 400px;
      height: auto;
      max-width: 80%;
      margin:0 auto;
      margin-bottom:20vh;
    }
  }

</style>