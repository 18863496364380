import Vue from 'vue'
import Vuex from 'vuex'
import Store from './store'
import {
  vuex_config_object,
  shared_store_keys,
} from './config.js'
import {
  session_handler,
  load_state_from_local_storage,
  localstorage_sessions_key,
  localstorage_store_key
} from '@/resources'

export function vuex_subscribe_module(module_name, module_state, module_store) {
  (module_state)

  if (Store.hasModule(module_name)) Store.unregisterModule(module_name)

  let localstorage_store = {},
    localstorage_sessions = {
      ...(Vue.$localStorage.get(localstorage_sessions_key))
    },
    localstorage_session = {}

  if (localstorage_sessions[Vue.prototype.$localstorage_session_key]) {
    localstorage_session = {
      ...localstorage_sessions[Vue.prototype.$localstorage_session_key]
    }
  }

  // chiavi globali dello state (vanno salvate in localstorage condiviso)
  shared_store_keys.global.forEach(key => {
    localstorage_store[key] = Store.state[key]
  })

  // chiavi di sessione dello state (vanno salvate in localstorage di sessione)
  shared_store_keys.session.forEach(key => {
    localstorage_session[key] = Store.state[key]
  })

  // aggiungo submodule a sessione in localstorage (NB: è il submodule che decide cosa salvare o meno in sessione tramite load_state_from_local_storage())
  localstorage_session = {
    token: Store.state.token,
    ...localstorage_session,
    [module_name]: load_state_from_local_storage(module_name, module_state)
  }

  localstorage_sessions[Vue.prototype.$localstorage_session_key] = localstorage_session

  Vue.$localStorage.set(localstorage_store_key, (localstorage_store))
  Vue.$localStorage.set(localstorage_sessions_key, (localstorage_sessions))

  Store.registerModule(module_name, module_store)
}

export function vuex_store_config(vuex_config_object) {
  let config = {
    state: vuex_config_object,
    getters: {},
    mutations: {},
    actions: {},
  }
  Object.keys(vuex_config_object).forEach(key => {
    config.getters[key] = (state) => { return state[key] }
    config.mutations[key] = (state, payload) => { state[key] = payload }
    config.actions = {} // TODO: actions = ...
  })
  return config
}

Vue.use(Vuex)
const state_config = vuex_store_config(vuex_config_object)
const refresh_localstorage_store = session_handler()

export default new Vuex.Store({
  state: load_state_from_local_storage('', state_config.state),
  getters: state_config.getters,
  mutations: state_config.mutations, // mutations -> operazioni SINCRONE (triggerate da .commit())
  actions: state_config.actions, // actions -> operazioni ASINCRONE che lanciano mutations (triggerate da .dispatch())
})

// struttura dati che verrà salvata in local storage
// la struttura dati che in questo momento sto per aggiornare in localstorage è già definita in commons.js / vuex_subscribe_module
Store.subscribe((mutation, state) => {
  (mutation, state)

  let localstorage_sessions = {
    ...(Vue.$localStorage.get(localstorage_sessions_key))
  },
    localstorage_session = {
      ...localstorage_sessions[Vue.prototype.$localstorage_session_key]
    },
    localstorage_store = {
      ...(Vue.$localStorage.get(localstorage_store_key))
    }

  Object.keys(localstorage_store).forEach(key => {
    localstorage_store[key] = Store.state[key]
  })

  Object.keys(localstorage_session).forEach(key => {
    localstorage_session[key] = Store.state[key]
    if (Vue.prototype.$submodules && Vue.prototype.$submodules[key]) {
      let submodule_config = Vue.prototype.$submodules[key].get_local_storage_submodule,
        submodule_object = {}
      Object.keys(submodule_config).forEach(submodule_key => {
        submodule_object = {
          ...submodule_object,
          [submodule_key]: Store.state[key][submodule_key]
        }
      })
      localstorage_session[key] = submodule_object
    }
  })

  localstorage_sessions[Vue.prototype.$localstorage_session_key] = localstorage_session

  if (refresh_localstorage_store) Vue.$localStorage.set(localstorage_store_key, (localstorage_store))

  Vue.$localStorage.set(localstorage_sessions_key, (localstorage_sessions))
})