<template>
  <footer :class="'site-footer ' + variant">
    <b-container>
      <div class="site-footer__wrapper">
        <!--<div class="site-footer__logo">
          <slot name="logo"></slot>
        </div>-->
        <b-row>

          <slot name="columns"></slot>

          <b-col sm="6" md="3">
            <div class="h6 site-footer__title">
              <!--{{ get_config().site.brand }}-->
              Legal
            </div>
            <ul class="site-footer__links">
              <li class="site-footer__link">
                <router-link :to="{ name: 'terms' }">
                  Termini e condizioni
                </router-link>
              </li>
              <li class="site-footer__link" v-if="get_config().site.urlPrivacy">
                <a :href="get_config().site.urlPrivacy" target="_blank" v-html="'Privacy Policy'" />
              </li>
              <li class="site-footer__link" v-if="get_config().site.urlCookiePolicy">
                <a :href="get_config().site.urlCookiePolicy" target="_blank" v-html="'Cookie Policy'" />
              </li>
              <li class="site-footer__link">
                <router-link :to="{ name: 'about', hash: '#contattaci' }">
                  Contatti
                </router-link>
              </li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "site-footer",
  components: {
  },
  props: {
    variant: {
      type: String,
      default: 'light',
    },
  },
  created() {
  },
  computed: {},
  watch: {},
  data() {
    return {
    }
  },
  methods: {},
  mounted() { },
};
</script>

<style lang="scss">

  .site-footer {
    background: $white;
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    margin-top:$grid-gutter-width*2;
    padding-top:$grid-gutter-width*2;
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }

    &>.container,
    &>.container-fluid {
      flex:1;
    }

    &__wrapper {
      border-radius: $border-radius;
    }

    &__logo {
      padding-bottom:$grid-gutter-width;
      max-width: 100px;
      margin: auto;

      @include media-breakpoint-up(sm) {
        padding-bottom:$grid-gutter-width*2;
        margin:0;
      }
    }

    &__title {
      display: block;
      padding-bottom: $grid-gutter-width;
    }

    &__links {
      list-style: none;
      padding:0;
      margin-bottom: $grid-gutter-width*2;
    }

    &__link {
      padding:0;
      margin:0 0 $grid-gutter-width/2 0;
      font-size: 0.8125rem;
      * {
        color:$vb-body-color;
        &:active,
        &:hover,
        &:focus,
        &:visited {
          color: $vb-body-color;
        }
      }
    }

    &.dark {
      background-color: $primary;
      color: $light;
      .site-footer__link * {
        color: $light;
        &:active,
        &:hover,
        &:focus,
        &:visited {
          color: $light;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media print {
      display: none;
    }
  }

</style>