<template>
  <b-tabs
      :class="'vb-tabs ' + (vertical && 'vb-tabs--vertical no-gutters')"
      v-model="value"
      :active-nav-item-class="active_nav_item_class"
      :active-tab-class="active_tab_class"
      :align="align"
      :content-class="'vb-tabs__content ' + content_class"
      :end="end"
      :fill="fill"
      :id="id"
      :justified="justified"
      :lazy="lazy"
      :nav-class="'vb-tabs__nav ' + nav_class"
      :nav-wrapper-class="nav_wrapper_class"
      :no-fade="no_fade"
      :no-key-nav="no_key_nav"
      :no-nav-style="no_nav_style"
      :pills="pills"
      :small="small"
      :tag="tag"
      :vertical="vertical"
      @activate-tab="$emit('activate-tab', value)"
      @changed="$emit('changed', value)"
      @input="$emit('input', value)"
  >
      <slot></slot>
      <slot name="empty"></slot>
      <slot name="tabs-start"></slot>
      <slot name="tabs-end"></slot>
  </b-tabs>
</template>

<script>

export default {
  name: 'vb-tabs',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { return {} }
    }
  },

  data() {
    return {
      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/dropdown
      active_nav_item_class: this.vb_options.active_nav_item_class !== undefined ? this.vb_options.active_nav_item_class : '',
      active_tab_class: this.vb_options.active_tab_class !== undefined ? this.vb_options.active_tab_class : '',
      align: this.vb_options.align !== undefined ? this.vb_options.align : '',
      card: this.vb_options.card !== undefined ? this.vb_options.card : false,
      content_class: this.vb_options.content_class !== undefined ? this.vb_options.content_class : '',
      end: this.vb_options.end !== undefined ? this.vb_options.end : false,
      fill: this.vb_options.fill !== undefined ? this.vb_options.fill : false,
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',
      justified: this.vb_options.justified !== undefined ? this.vb_options.justified : false,
      lazy: this.vb_options.lazy !== undefined ? this.vb_options.lazy : false,
      nav_class: this.vb_options.nav_class !== undefined ? this.vb_options.nav_class : '',
      nav_wrapper_class: this.vb_options.nav_wrapper_class !== undefined ? this.vb_options.nav_wrapper_class : '',
      no_fade: this.vb_options.no_fade !== undefined ? this.vb_options.no_fade : false,
      no_key_nav: this.vb_options.no_key_nav !== undefined ? this.vb_options.no_key_nav : false,
      no_nav_style: this.vb_options.no_nav_style !== undefined ? this.vb_options.no_nav_style : false,
      pills: this.vb_options.pills !== undefined ? this.vb_options.pills : false,
      small: this.vb_options.small !== undefined ? this.vb_options.small : false,
      tag: this.vb_options.tag !== undefined ? this.vb_options.tag : 'div',
      value: this.vb_options.value !== undefined ? this.vb_options.value : 0,
      vertical: this.vb_options.vertical !== undefined ? this.vb_options.vertical : false,
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  }
}
</script>

<style lang="scss" scoped>

  ::v-deep {

    .vb-tabs {
      $this: &;

      &__nav {

        li {
          &:first-child {
            a:before {
              display: none;
            }
          }

          .vb-tabs--vertical & {

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        a {
          transition: none;
          position: relative;

          .vb-tabs--vertical & {
            background-color: $white;
            border-radius: $border-radius-lg;
            margin-right: $grid-gutter-width/2;
            margin-bottom: $grid-gutter-width/6;
          }

          &:first-letter {
            text-transform: capitalize;
          }

          &:before {

            @include media-breakpoint-up(lg) {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              width: 1px;
              height: 30%;
              background-color: $dark;
            }

            .vb-tabs--vertical & {
              display: none;
            }
          }
        }

        .active {
          font-weight: $vb-font-weight-semibold;

          @include media-breakpoint-up(lg) {
            border-radius: 5px 5px 0 0;
            position: relative;
            z-index: 1;
            margin-right: -1px;
            background-color: $white;
            bottom: -2px;
          }

          .vb-tabs--vertical & {
            bottom: 0;
            background-color: rgba(255,255,255, .6);
          }

          // versione grigia incavata
          //box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
          //background-color: $vb-body-bg;


          &:before {
            display: none;
          }

          &:after {
            content: '.';
            display: inline-block;
            color: $info;

            .vb-tabs--vertical & {
              display: none;
            }
          }
        }
      }

      &__content {
        padding: 1.25rem !important;
        border-radius: 5px;
        background-color: $white;

        // versione grigia incavata
        //box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
      }
    }

  }

</style>