<template>
  <nav :class="'site-navigation ' + variant" v-click-outside="navigation_close">
    <div class="site-navigation__toggle">
      <vb-icon
        @click="navigation_toggle"
        :name="'hamburger'"
        :color="variant == 'dark' ? secondary : dark"
      />
    </div>
    <Transition name="bounce">
    <ul v-show="links_open">

      <li v-if="get_config().guiSettings.allowDcLogin">
        <router-link :to="{name: 'dc-sso'}">Ho un buono</router-link>
      </li>
      
      <!-- go to homepage
      <li>
        <router-link to="/">{{'home'}}</router-link>
      </li>-->
 
      <li v-if="is_authenticated()">
        <router-link @click.native="logout()" :to="{name: 'homepage'}">Logout</router-link>
      </li>      

      <!-- library
      <li v-if="is_development()">
        <router-link to="/library">library</router-link>
      </li>
      -->
      
      <!-- notifications
      <li v-if="is_development()">
        <router-link to="/test/notifications">notifications</router-link>
      </li>
      -->

      <!-- custom link
      <li v-if="is_development()">
        <custom-link
          :route_name="'test'"
          :wrap="'a'">
          <template #content>
            custom nav
          </template>
        </custom-link>
      </li>
      -->

    </ul>
    </Transition>
  </nav>
</template>

<script>
import { service_handler } from '@src/services'
import { secondary, dark } from '@running_app_src/scss/abstract/_custom.scss'

export default {
  name: "navigation",
  components: {},
  props: {
    variant: {
      type: String,
      default: 'light',
    },
  },
  created() {
    this.secondary = secondary.replace('#','')
    this.dark = dark.replace('#','')
  },
  computed: {},
  watch: {},
  data() {
    return {
      links_open: false,
    }
  },
  methods: {
    navigation_open() {
      this.links_open = true
    },
    navigation_close() {
      this.links_open = false
      document.body.classList.remove('menuOpen')
    },
    navigation_toggle() {
      this.links_open = !this.links_open
      this.links_open ? document.body.classList.add('menuOpen') : document.body.classList.remove('menuOpen')
    },
    logout(){

      service_handler(
        'logout'
      )
        .then(
          (success) => {
            this.log('[logout] - success')
            this.log(success.data)
          },
          (error) => {
            this.log('[logout] - error')
            this.log(error.response)
          }
        )

    }
  },
  mounted() { },
}
</script>

<style lang="scss">
.site-navigation {
  position: relative;

  // transitions: https://vuejs.org/guide/built-ins/transition.html#css-based-transitions
  .bounce-enter-active {
    animation: bounce-in 0.35s;
    transform-origin: center top;
  }
  .bounce-leave-active {
    animation: bounce-in 0.35s reverse;
    transform-origin: center top;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }
  // transitions  
  height:100%;
  display: flex;
  align-items: center;
  ul,li {
    margin:0;
    padding:0;
    list-style: none;
  }
  ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 45px;
    margin:auto;
    padding:$grid-gutter-width/4 0;
    width:200px;
    right: 0;
    border-radius: $border-radius;
    background-color: $white;
    overflow: hidden;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:$grid-gutter-width/4 0;
    line-height: 120%;
    cursor: pointer;
  }
  li>* {
    color: $dark;
    height:100%;
    &:active,
    &:focus,
    &:hover,
    &:visited {
      color: $vb-body-color;
      text-decoration: none;
    }
    &:hover {
      color:$info;
    }
  }

  &__toggle {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: $grid-gutter-width/2;

    path {
      transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

      @at-root .menuOpen & {
      
        &:nth-child(2) {opacity:0;}

        &:nth-child(1) {
            transform:rotate(45deg) translateY(-24px) translateX(13px);
        }
        &:nth-child(3) {
            transform:rotate(-45deg) translateY(-7px) translateX(-24px);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    ul {
      display: flex !important;
      flex-direction: row;
      position: relative;
      height:inherit;
      background-color: transparent;
      position:static;
      width: auto;
      padding: 0;
    }
    li > * {
      padding:$grid-gutter-width/4 $grid-gutter-width/2;
    }
    li {
      justify-content: flex-start;
      position: relative;

      &:after {
        content: '';
        width: 1px;
        height: 30%;
        background-color: $gray-400;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    &__toggle {
      display: none;
    }
  }

  // variante colore dark
  @include media-breakpoint-up(lg) {
    &.dark {
      background: $primary;
      li {
        display: inline-flex;
        &:after {
          display: none;
        }
        &:hover>* {
          background: rgba($primary, 0.25);
          border-radius: $border-radius-sm;
        }
      }
      li>* {
        color: $secondary;
        &:active,
        &:focus,
        &:hover,
        &:visited {
          color: $light;
          //text-decoration: none;
          background-color: rgba($light, 0.1);
        }
        display: inline-block;
        padding:$grid-gutter-width/4 $grid-gutter-width/3;        
      }
    }
  }  
}
</style>