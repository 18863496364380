<template>
  <div class="vb-toggle">
    <vb-button class="vb-toggle__btn" v-b-toggle="id" :vb_options="{
          text: btn_text,
          variant: btn_variant,
          pill: true,
          block: true,
        }"
    />
    <b-collapse
        class="vb-toggle__collapse"
        v-model="visible"
        :id="id"
        :accordion="accordion"
        :appear="appear"
        :is-nav="is_nav"
        :tag="tag"
    >

      <slot></slot>

    </b-collapse>
  </div>
</template>

<script>

export default {
  name: 'toggle',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // custom options
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',
      btn_variant: this.vb_options.btn_variant !== undefined ? this.vb_options.btn_variant : 'light',

      // b-collapse options
      accordion: this.vb_options.accordion !== undefined ? this.vb_options.accordion : '',
      appear: this.vb_options.appear !== undefined ? this.vb_options.appear : false,
      is_nav: this.vb_options.is_nav !== undefined ? this.vb_options.is_nav : false,
      tag: this.vb_options.tag !== undefined ? this.vb_options.tag : 'div',
      visible: this.vb_options.visible !== undefined ? this.vb_options.visible : false,
    }
  },

  created() {
  },

  computed: {
    btn_text() {
      return this.vb_options.btn_text !== undefined ? this.vb_options.btn_text : ''
    },
  },

  mounted() {
  }
}
</script>

<style lang="scss" scoped>

  .vb-toggle {

    &__btn {
      padding-left: 1.5em;
      padding-right: 4em;
      background-image: url('data: image/svg+xml,<?xml version="1.0" ?><svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z" /></svg>');
      background-repeat: no-repeat;
      background-position: center right 1.5em;
      background-size: 16px;
      text-align: left;

      &:hover,
      &:active,
      &.active,
      &.not-collapsed {
        box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 0 1px 0 #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
      }

      &.collapsed {
        background-image: url('data: image/svg+xml,<?xml version="1.0" ?><svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M14.7649 6.07595C14.9991 6.22231 15.0703 6.53078 14.9239 6.76495C14.4849 7.46742 13.9632 8.10644 13.3702 8.66304L14.5712 9.86405C14.7664 10.0593 14.7664 10.3759 14.5712 10.5712C14.3759 10.7664 14.0593 10.7664 13.8641 10.5712L12.6011 9.30816C11.8049 9.90282 10.9089 10.3621 9.93374 10.651L10.383 12.3276C10.4544 12.5944 10.2961 12.8685 10.0294 12.94C9.76266 13.0115 9.4885 12.8532 9.41703 12.5864L8.95916 10.8775C8.48742 10.958 8.00035 10.9999 7.5 10.9999C6.99964 10.9999 6.51257 10.958 6.04082 10.8775L5.58299 12.5864C5.51153 12.8532 5.23737 13.0115 4.97063 12.94C4.7039 12.8685 4.5456 12.5944 4.61706 12.3277L5.06624 10.651C4.09111 10.3621 3.19503 9.90281 2.3989 9.30814L1.1359 10.5711C0.940638 10.7664 0.624058 10.7664 0.428797 10.5711C0.233537 10.3759 0.233537 10.0593 0.428797 9.86404L1.62982 8.66302C1.03682 8.10643 0.515113 7.46742 0.0760677 6.76495C-0.0702867 6.53078 0.000898544 6.22231 0.235064 6.07595C0.46923 5.9296 0.777703 6.00078 0.924057 6.23495C1.40354 7.00212 1.989 7.68056 2.66233 8.2427C2.67315 8.25096 2.6837 8.25971 2.69397 8.26897C4.00897 9.35527 5.65536 9.9999 7.5 9.9999C10.3078 9.9999 12.6563 8.50629 14.0759 6.23495C14.2223 6.00078 14.5308 5.9296 14.7649 6.07595Z" fill="currentColor" fill-rule="evenodd" /></svg>');
        background-size: 15px;
        border-color: #e3e3e3;
      }
    }

    &__collapse {
      $this: &;

      background-color: $white;
      padding: 1.25rem;
      border-radius: .313rem;
      margin-top: 20px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: opacity 1ms ease-in-out;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid $white;
      }

      &.show {
        &:before {
          opacity: 1;
        }
      }
    }
  }

</style>