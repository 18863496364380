export default '<svg width="97" height="83" viewBox="0 0 97 83" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M83.6561 32.8532C84.0923 32.7599 84.5258 32.6533 84.95 32.5268L96.5629 14.3189C96.5002 13.8753 96.4108 13.441 96.3121 13.0081L83.6561 32.8532Z" fill="url(#paint0_linear_0_3)"/>\n' +
'<path d="M80.7708 33.1928C81.1483 33.1755 81.5285 33.1649 81.8993 33.1276L95.8253 11.2935C95.6999 10.9352 95.5598 10.5848 95.4131 10.2372L80.7708 33.1928Z" fill="url(#paint1_linear_0_3)"/>\n' +
'<path d="M79.2004 33.1371L94.7324 8.78526C91.9365 3.62449 86.4849 0.114262 80.1955 0.114262C71.0503 0.114262 63.6378 7.51838 63.6378 16.6516C63.6378 25.4492 70.5207 32.6202 79.2004 33.1371Z" fill="#F8A432"/>\n' +
'<path d="M79.2008 30.5126C70.9732 30.0223 64.3691 23.548 63.7035 15.3819C63.6688 15.8149 63.6381 16.2479 63.6381 16.6888C63.6381 25.4877 70.5211 32.66 79.2008 33.1756L93.5376 10.6941L79.2008 30.5126Z" fill="url(#paint2_linear_0_3)"/>\n' +
'<path d="M1.97842 0.116524C0.739225 0.116524 -0.0104029 1.48197 0.653881 2.52638L51.3849 82.16C52.0025 83.1271 53.4164 83.1271 54.034 82.1613L69.9648 57.2074C70.293 56.6945 70.293 56.0364 69.9662 55.5222L40.3789 9.07926C36.8213 3.4962 30.6547 0.116524 24.0292 0.116524H1.97842Z" fill="url(#paint3_linear_0_3)"/>\n' +
'<path d="M80.2519 9.10592C80.0145 9.17652 79.8464 9.34969 79.7917 9.58015C79.7357 9.81994 79.8144 10.081 79.9931 10.2476C80.1585 10.4034 80.3813 10.4541 80.6121 10.3861C81.2457 10.2063 81.9406 10.2928 82.5142 10.6232C83.0464 10.9309 83.4173 11.4172 83.5587 11.9847C83.6294 12.2937 83.8881 12.5016 84.2056 12.5016C84.5217 12.5016 84.7805 12.2937 84.8485 11.99C84.9913 11.4145 85.3647 10.9296 85.8996 10.6206C86.4719 10.2902 87.1602 10.2049 87.7804 10.3861C88.1486 10.4914 88.4968 10.2995 88.6048 9.93717C88.7129 9.57083 88.5221 9.21381 88.1539 9.10458C86.6866 8.69294 85.1073 9.2178 84.1989 10.3608C83.5093 9.48024 82.4168 8.96469 81.275 8.96469C80.9349 8.96469 80.5907 9.01 80.2519 9.10592Z" fill="url(#paint4_linear_0_3)"/>\n' +
'<path d="M67.98 15.3018C66.7501 13.566 64.4665 12.7574 62.3269 13.3702C61.916 13.4967 61.7026 13.9057 61.8294 14.324C61.9547 14.7396 62.3416 14.9514 62.7577 14.8302C63.7275 14.5505 64.7759 14.6823 65.639 15.1819C66.4527 15.6534 67.0196 16.4021 67.2343 17.292C67.3184 17.6344 67.6131 17.8635 67.972 17.8635C68.3294 17.8635 68.6256 17.6344 68.7083 17.2934C68.9244 16.4048 69.4913 15.6548 70.3076 15.1819C71.1787 14.6796 72.2258 14.5531 73.1835 14.8289C73.585 14.9488 73.9905 14.733 74.1146 14.324C74.24 13.9057 74.0266 13.4967 73.6104 13.3688C73.1302 13.2316 72.6433 13.165 72.1618 13.165C70.5011 13.165 68.919 13.9537 67.98 15.3018Z" fill="url(#paint5_linear_0_3)"/>\n' +
'<defs>\n' +
'<linearGradient id="paint0_linear_0_3" x1="83.6557" y1="22.931" x2="96.5634" y2="22.931" gradientUnits="userSpaceOnUse">\n' +
'<stop stop-color="#F8A432"/>\n' +
'<stop offset="0.557292" stop-color="#E3952D"/>\n' +
'<stop offset="1" stop-color="#E3952D"/>\n' +
'</linearGradient>\n' +
'<linearGradient id="paint1_linear_0_3" x1="80.7716" y1="21.7146" x2="95.825" y2="21.7146" gradientUnits="userSpaceOnUse">\n' +
'<stop stop-color="#F8A432"/>\n' +
'<stop offset="0.557292" stop-color="#E3952D"/>\n' +
'<stop offset="1" stop-color="#E3952D"/>\n' +
'</linearGradient>\n' +
'<linearGradient id="paint2_linear_0_3" x1="63.6371" y1="21.9354" x2="93.5391" y2="21.9354" gradientUnits="userSpaceOnUse">\n' +
'<stop stop-color="#F8A432"/>\n' +
'<stop offset="0.557292" stop-color="#E3952D"/>\n' +
'<stop offset="1" stop-color="#E3952D"/>\n' +
'</linearGradient>\n' +
'<linearGradient id="paint3_linear_0_3" x1="-2.48161" y1="4.55193" x2="68.8813" y2="64.5111" gradientUnits="userSpaceOnUse">\n' +
'<stop stop-color="#3572C2"/>\n' +
'<stop offset="0.992642" stop-color="#27538D"/>\n' +
'<stop offset="1" stop-color="#27538D"/>\n' +
'</linearGradient>\n' +
'<linearGradient id="paint4_linear_0_3" x1="79.7739" y1="10.7333" x2="88.6354" y2="10.7333" gradientUnits="userSpaceOnUse">\n' +
'<stop stop-color="#3572C2"/>\n' +
'<stop offset="0.992642" stop-color="#27538D"/>\n' +
'<stop offset="1" stop-color="#27538D"/>\n' +
'</linearGradient>\n' +
'<linearGradient id="paint5_linear_0_3" x1="61.7935" y1="15.5141" x2="74.1497" y2="15.5141" gradientUnits="userSpaceOnUse">\n' +
'<stop stop-color="#3572C2"/>\n' +
'<stop offset="0.992642" stop-color="#27538D"/>\n' +
'<stop offset="1" stop-color="#27538D"/>\n' +
'</linearGradient>\n' +
'</defs>\n' +
'</svg>\n'
