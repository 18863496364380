//import { parse } from 'tinyduration'
import { number, sum, round, smaller } from "mathjs"
import Store from '@/vuex'
import Router from '@/router/router'
import Vue from 'vue'
import VueWebStorage from 'vue-web-storage'

Vue.use(VueWebStorage, {
  prefix: '',// default `app_`
  drivers: ['session', 'local'], // default 'local'
})

export function log(...value) {
  if (process.env.VUE_APP_MODE !== 'development') return
  window.console.log(...value)
}

export function is_development() {
  if (process.env.VUE_APP_MODE == 'development') return true
  return false
}

export function is_authenticated() {
  let token = Store.state.token

  // se c'è un token in get ha la precedenza (quello nello state potrebbe essere scaduto)
  if (Router.history.current.query.token) token = Router.history.current.query.token

  // non ho trovato alcun token
  if (!token) return false

  // ho un token ma NON un'integrazione

  // implementazione cancellara -> integration nello state
  // if(!Store.state.integration || !Store.state.integration.integration) return false

  // implementazione livetravel -> integration solo nel config
  if (!get_config() || !get_config().integration || !get_config().integration.integration) return false

  return true
}

export function get_config(path, fallback) {

  path = path || false
  fallback = fallback || false

  if (!path) return Vue.prototype.$config

  const keys = path.split('.');
  let value = Vue.prototype.$config;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (value === undefined || value === null) {
      return fallback;
    }
    value = value[key];
  }

  return value;

}

export function get_availability(price) {
  price = price || 0

  let config = get_config(),
    discount_codes = Store.state.discount_codes,
    availability = {
      total: 0,
      welfare: false,
      is_known: false,
      details: {
        integration: 0,
        discount_codes: 0,
      },
    },
    is_jointly = config.integration.integration == 'jointly'

  if (config == undefined) return availability
  if (config.integration == undefined) return availability

  /**
   * cia: config.integration.availability può arrivare -1 ad indicare che il credito non è conosciuto
   * availability.is_known: per tracciare se la disponibilità è conosciuta oppure no
   */
  let cia = round(number(config.integration.availability), 2)
  let real_availability = (cia > 0) ? cia : 0

  availability.total = real_availability
  availability.details.integration = real_availability
  availability.welfare = config.integration.welfare ? true : false
  availability.is_known = (cia > -1) ? true : false

  if (discount_codes.length) {
    discount_codes.forEach(dc => {
      let value_resodual = number(dc.valueResidual)
      availability.details.discount_codes = sum(availability.details.discount_codes + value_resodual)
      availability.total = sum(availability.total + value_resodual)
    })
  }

  /**
   * affordable:
   * non viene più ritornato, va calcolato internamente per determinare availability.can_go_to_checkout (vedi sotto)
   */
  let affordable = true
  if (price > 0 && availability.is_known && !is_jointly) {
    if (smaller(availability.total, price)) affordable = false
  }

  /*
  * can_go_to_checkout (ex "can_checkout"):
  * - credito (totale) non sufficiente + welfare:false => POSSO andare al checkout ed integrare con carta di credito
  * - credito (totale) non sufficiente + welfare:true => NON POSSO andare al checkout
  */
  availability.can_go_to_checkout = (is_jointly || !availability.welfare || affordable)

  /*
  * show_credit_alert (ex "affordable"):
  * - sostituisce il vecchio availability.affordable, che ora NON viene ritornato
  * - pilota copy "Credito non sufficiente" a front
  * - RIAGGANCIATO a logiche di prezzo, cioè a .can_go_to_checkout (per coerenza con tasto "Prenota" abilitato o meno)
  */
  availability.show_credit_alert = !availability.can_go_to_checkout

  return availability
}

export function parse_date(date) {
  let split = date.split('T'),
    day_number = split[0].split('-')[2],
    month_number = split[0].split('-')[1],
    year = split[0].split('-')[0],
    new_date = new Date(year, parseInt(month_number) - 1, day_number),
    month_string = new_date.toLocaleString('default', { month: 'long' })

  if (split[1] && split[1].length > 5) split[1] = split[1].slice(0, -3) // rimuove i secondi (:00)

  // yyy-mm-dd to dd-mm-yyyy
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(split[0] + ' ' + split[1])

  let readable_datetime = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-') + ' ' + split[1]


  return {
    day: split[0],
    hour: split[1],
    day_number: split[0].split('-')[2],
    month_number: split[0].split('-')[1],
    year: split[0].split('-')[0],
    month_string: month_string,
    readable_datetime: readable_datetime,
    friendly_date: day_number + ' ' + month_string + ' ' + split[0].split('-')[0],
    friendly_date_short: day_number + ' ' + month_string.slice(0, 3).toUpperCase(),
  }
}

export function reverse_date(date) {
  // yyyy-mm-dd to dd-mm-yyyy and viceversa

  //return date.split('-').reverse().join('-');

  if (date == '') return ''

  function pad(s) { return (s < 10) ? '0' + s : s; }

  let dateToUse = date

  if (date.indexOf('T') == -1) dateToUse = date + 'T00:00:00'; // se non c'è l'orario viene applicato il fuso dell'utente

  var d = new Date(dateToUse)

  let reversed = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')

  return reversed
}

export function custom_event(action, label, value, category) {
  label = label || ''
  value = value || 0
  category = category || ''

  if (Vue.prototype.$gtm) {
    let gtmPayload = {
      event: 'customEvent',
      category: (category) ? category : 'ce',
      action: action,
      label: label,
      value: value,
    }
    Vue.prototype.$gtm.trackEvent(gtmPayload);
  }
  if (window.clarity) window.clarity("set", action, label)
  if (window.LogRocket) window.LogRocket.track(action + '_' + label)
}

export function encode_parameters(params) {
  return btoa(encodeURIComponent(JSON.stringify(params)))
}

export function decode_parameters(params) {
  return JSON.parse(decodeURIComponent(atob(params)))
}

export function deep_clone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function is_mobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export function format_date_YYYY_MM_DD(date = new Date(), separator = '') {
  // nested arrow function
  let pad_to_2_digits = (num) => {
    return num.toString().padStart(2, '0');
  }
  return [
    date.getFullYear(),
    pad_to_2_digits(date.getMonth() + 1),
    pad_to_2_digits(date.getDate()),
  ].join(separator);
}

export function get_traveler_birth_range_dates(departure, pax_age) {

  // formato di ritorno richiesto dal picker: 2024-03-20

  let min = new Date(departure),
    max = new Date(departure)

  if (pax_age >= 30) pax_age = 12 // alpitour adults default 30
  // facendo il range in accordo con il tipo pax
  // si crea un conflitto con i CHD considerati ADT (che ritornatno ADT ed age 30)
  // rendendo impossibile indicare la data di nascita
  // come fix, nel caso ADT, considero 12 anni (che sono sempre CHD):
  //   a noi interessa rendere facile per l'utente mettere le date di nascita di chd e inf, che resta invariato
  //   nel caso di ADT, potrebbe essere possibile inserire date nascita "da minorenni"... pazienza

  min = new Date(min.setFullYear(1900))
  max = new Date(max.setFullYear(max.getFullYear() - pax_age))

  min = format_date_YYYY_MM_DD(min, '-')
  max = format_date_YYYY_MM_DD(max, '-')

  return {
    min: min,
    max: max,
  }
}

export function format_price(price) {

  if (price.length == 1) {
    return price.replace(/\D+/g, '') ? price.replace(/\D+/g, '') : 0
  }

  let currency = '€',
    separator = '',
    price_split = []

  if (price.includes(',')) {
    price = price.replace(/[^\d,]/g, '')
    separator = ','
    price_split = price.split(separator)
  }
  else if (price.includes('.')) {
    price = price.replace(/[^\d.]/g, '')
    separator = '.'
    price_split = price.split(separator)
  }
  else {
    price = price.replace(/\D+/g, '')
  }

  if (price_split.length > 1) {
    price = price_split[0] + separator + price_split[1].replace(/\D+/g, '').slice(0, 2)
  }

  if (price.length) return currency + ' ' + price

  return ''
}

export function get_type_icon(type = '') {
  switch (type) {
    case 'hotel':
      return 'doublebed'

    case 'city':
      return 'pin'

    case 'district':
    case 'subdistrict':
      return 'hotel'

    case 'country':
      return 'flag'

    default:
      return 'pin'
  }
}

export function get_site_name() {
  return this.get_config().site.brand + ' ' + this.get_config().site.payoff
}