<template>
  <div
    class="logo"
    v-html="logo[type.replace('-','_')]"
  />
</template>

<script>
import * as Logos from '@running_app_src/components/svg/logos'

export default {
  name: 'logo',
  props: {
    type: String,
  },
  data() {
    return {
      logo: null
    }
  },
  created() {
    this.logo = {
      ...Logos
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
</style>