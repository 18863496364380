<template>
  <b-dropdown class="vb-dropdown"
              :block="block"
              :boundary="boundary"
              :disabled="disabled"
              :dropleft="dropleft"
              :dropright="dropright"
              :dropup="dropup"
              :html="html"
              :id="id"
              :lazy="lazy"
              :menu-class="menu_class"
              :no-caret="no_caret"
              :no-flip="no_flip"
              :offset="offset"
              :popper-opts="popper_opts"
              :right="right"
              :role="role"
              :size="size"
              :split-button-type="split_button_type"
              :split-class="split_class"
              :split-href="split_href"
              :split-to="split_to"
              :split-variant="split_variant"
              :text="text"
              :toggle-attrs="toggle_attrs"
              :toggle-class="toggle_class"
              :toggle-tag="toggle_tag"
              :toggle-text="toggle_text"
              :variant="variant"
  >
    <slot></slot>
  </b-dropdown>
</template>

<script>

export default {
  name: 'breadcrumbs',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/dropdown
      block: this.vb_options.block !== undefined ? this.vb_options.block : false,
      boundary: this.vb_options.boundary !== undefined ? this.vb_options.boundary : 'scrollParent',
      dropleft: this.vb_options.dropleft !== undefined ? this.vb_options.dropleft : false,
      dropright: this.vb_options.dropright !== undefined ? this.vb_options.dropright : false,
      dropup: this.vb_options.dropup !== undefined ? this.vb_options.dropup : false,
      html: this.vb_options.html !== undefined ? this.vb_options.html : '',
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',
      lazy: this.vb_options.lazy !== undefined ? this.vb_options.lazy : false,
      menu_class: this.vb_options.menu_class !== undefined ? this.vb_options.menu_class : '',
      no_caret: this.vb_options.no_caret !== undefined ? this.vb_options.no_caret : true,
      no_flip: this.vb_options.no_flip !== undefined ? this.vb_options.no_flip : false,
      offset: this.vb_options.offset !== undefined ? this.vb_options.offset : '',
      popper_opts: this.vb_options.popper_opts !== undefined ? this.vb_options.popper_opts : {},
      right: this.vb_options.right !== undefined ? this.vb_options.right : false,
      role: this.vb_options.role !== undefined ? this.vb_options.role : 'menu',
      size: this.vb_options.size !== undefined ? this.vb_options.size : '',
      split: this.vb_options.split !== undefined ? this.vb_options.split : false,
      split_button_type: this.vb_options.split_button_type !== undefined ? this.vb_options.split_button_type : 'button',
      split_class: this.vb_options.split_class !== undefined ? this.vb_options.split_class : '',
      split_href: this.vb_options.split_href !== undefined ? this.vb_options.split_href : '',
      split_to: this.vb_options.split_to !== undefined ? this.vb_options.split_to : '',
      split_variant: this.vb_options.split_to !== undefined ? this.vb_options.split_variant : '',
      toggle_attrs: this.vb_options.toggle_attrs !== undefined ? this.vb_options.toggle_attrs : {},
      toggle_class: this.vb_options.toggle_class !== undefined ? this.vb_options.toggle_class : '',
      toggle_tag: this.vb_options.toggle_tag !== undefined ? this.vb_options.toggle_tag : 'button',
      toggle_text: this.vb_options.toggle_text !== undefined ? this.vb_options.toggle_text : 'Toggle dropdown',
    }
  },

  created() {
  },

  computed: {
    text() {
      return this.vb_options.text !== undefined ? this.vb_options.text : ''
    },
    disabled() {
      return this.vb_options.disabled !== undefined ? this.vb_options.disabled : false
    },
    variant() {
      return this.vb_options.variant !== undefined ? this.vb_options.variant : 'info'
    }
  },

  mounted() {
  }
}
</script>

<style lang="scss">

  .vb-dropdown {
    $this: &;

    // gestione pressed e hover del button
    @at-root {
      #{$this}.show .dropdown-toggle,
      #{$this} .dropdown-toggle:hover {
        border-color: $gray-300 !important;
        background-color: $light !important;
        color: $dark!important;
        box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
      }

      *[class*="btn-outline-"],
      #{$this}.show .dropdown-toggle,
      #{$this} .dropdown-toggle:hover {
        &:after {
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' id='Outline' viewBox='0 0 24 24' width='512' height='512'%3E%3Cpath fill='%23000' d='M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z'/%3E%3C/svg%3E%0A") !important;
        }
      }

      #{$this}.show .dropdown-toggle {
        &:after {
          background-position: center 0;
          transform: rotate(180deg);
        }
      }
    }

    // button
    .dropdown {

      &-toggle {
        display: flex;
        align-items: center;

        // freccia
        &::after {
          width: 20px;
          height: 20px;
          border: none;
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' id='Outline' viewBox='0 0 24 24' width='512' height='512'%3E%3Cpath fill='%23fff' d='M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center 1px;
          background-size: cover;
          margin-left: 5px;
        }
      }

      &-menu {
        outline: 0;
      }
    }
  }

</style>