<template>
  <div class="vb-loader" :class="size !== '' ? 'vb-loader--' + size : ''">

    <svg class="vb-loader__icon" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>

    <template v-if="show_timing_slot">
      <slot name="timing">
        <span v-html="loading"></span>
      </slot>
    </template>

    <slot name="default"></slot>
  </div>
</template>

<script>

export default {
  name: 'loader',
  components: {

  },
  props: {
    text_loading: {
      type: Array,
      default: () => {
        return [
          "<strong>Caricamento in corso...</strong><br>Grazie di non fare altre azioni sulla pagina!",
          "<strong>Abbiamo quasi fatto...</strong><br>Grazie di non fare altre azioni sulla pagina!",
          "<strong>Ci stiamo impiegando più tempo del previsto...</strong><br>Grazie di non fare altre azioni sulla pagina!",
          "<strong>Ancora un attimo di pazienza...</strong><br>Grazie di non fare altre azioni sulla pagina!",
        ]
      }
    },
    show_timing_slot: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentIndex: 0,
      interval: -1
    }
  },

  created() {
  },

  computed: {
    loading() {
      return this.text_loading[this.currentIndex];
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.text_loading.length;
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
  }

}
</script>

<style lang="scss" scoped>

.vb-loader {
  $this: &;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;

  &--small {
    font-size: $font-size-sm;
  }

  &--large {
    font-size: $font-size-lg;
  }

  &__icon {
    animation: rotate 2s linear infinite;

    & + * {
      margin-left: 1em;
    }

    // size default
    $size: 1.563rem;
    width: $size;
    height: $size;

    // size small
    @at-root #{$this}--small & {
      $size: 1.25rem;
      width: $size;
      height: $size;
    }

    // size large
    @at-root #{$this}--large & {
      $size: 1.875rem;
      width: $size;
      height: $size;
    }


    & .path {
      stroke: $info;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>