// wrappers
import Vue from 'vue'
import Button from './wrappers/Button'
import Checkbox from './wrappers/Checkbox'
import Input from './wrappers/Input'
import Radio from './wrappers/Radio'
import Select from './wrappers/Select'
import Modal from './wrappers/Modal'
import Breadcrumbs from "./wrappers/Breadcrumbs";
import Dropdown from "./wrappers/Dropdown";
import Toggle from "./wrappers/Toggle";
import Tabs from "./wrappers/Tabs";

// blocks
import Card from './blocks/card'

const vb_library = () => {
  // wrappers
  Vue.component('vb-button', Button)
  Vue.component('vb-checkbox', Checkbox)
  Vue.component('vb-input', Input)
  Vue.component('vb-radio', Radio)
  Vue.component('vb-select', Select)
  Vue.component('vb-modal', Modal)
  Vue.component('vb-breadcrumbs', Breadcrumbs)
  Vue.component('vb-dropdown', Dropdown)
  Vue.component('vb-toggle', Toggle)
  Vue.component('vb-tabs', Tabs)

  // blocks
  Vue.component('vb-card', Card)
}

export {
  vb_library
}