import { is_development } from '@utilities/commons'

export const first_name = {
  name: "Nome",
  rules: { required: true, alpha_num_spaces: true, min: 2 },
  type: 'input',
  value: is_development() ? 'Roberto' : '',
}

export const last_name = {
  name: "Cognome",
  rules: { required: true, alpha_num_spaces: true, min: 2 },
  type: 'input',
  value: is_development() ? 'Baggio' : '',
}

export const address = {
  name: "Indirizzo",
  rules: { required: true, min: 3 },
  type: 'input',
  value: is_development() ? 'via clementina 18' : '',
}

export const city = {
  name: "Città",
  rules: { required: true, alpha_spaces: true, min: 3 },
  type: 'input',
  value: is_development() ? 'Jesi' : '',
}

export const postal_code = {
  name: "CAP",
  rules: { required: true, numeric: true, min: 5, max: 6 },
  type: 'input',
  value: is_development() ? '60035' : '',
}

export const province = {
  name: "Provincia",
  rules: { required: true, alpha: true, min: 2, max: 2 },
  type: 'input',
  value: is_development() ? 'AN' : '',
}

export const fiscal_code = {
  name: "Codice fiscale",
  rules: { required: true, alpha_num: true, length: 16 },
  type: 'input',
  value: is_development() ? 'BGGRRT67L11A703M' : '',
}

export const bill_to_pax_fiscal_code = {
  name: "Codice fiscale",
  rules: { required: true, alpha_num: true, length: 16 },
  type: 'input',
  value: is_development() ? 'BGGRRT67L11A703M' : '',
}

export const phone = {
  name: "Telefono",
  rules: { required: true, numeric: true, min: 6 },
  type: 'input',
  value: is_development() ? '3931234567' : '',
}

export const email = {
  name: "Email",
  rules: { required: true, email: true },
  type: 'input',
  value: is_development() ? 'dev@salabam.com' : '',
}

export const email_conf = {
  name: "Conferma email",
  rules: 'confirm_email:@email',//rules: 'required|email|confirm_email:@email',
  type: 'input',
  value: is_development() ? 'dev@salabam.com' : '',
}

// privacy = termini e condizioni, gdpr = privacy policy
export const privacy = {
  name: "Termini e condizioni",
  rules: { is: [true] },
  type: 'checkbox',
  value: is_development(),
  text: 'Ho preso visione delle <a href="/termini-e-condizioni" target="_blank">condizioni d\'uso</a> e le accetto',
}

export const gdpr = {
  name: "Privacy policy",
  rules: { is: [true] },
  type: 'checkbox',
  value: is_development(),
  text: 'Ho preso visione delle <a href="/privacy-policy">condizioni di trattamento dei dati</a> e le accetto',
}