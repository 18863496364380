<template>

  <b-button
    class="vb-btn"
    :class="disabled ? 'border-0' : ''"
    :active="active"
    :active-class="active_class"
    :append="append"
    :block="block"
    :disabled="disabled"
    :exact="exact"
    :exact-active-class="exact_active_class"
    :exact-path="exact_path"
    :exact-path-active-class="exact_path_active_class"
    :href="href"
    :no-prefetch="no_prefetch"
    :pill="pill"
    :prefetch="prefetch"
    :pressed="pressed"
    :rel="rel"
    :replace="replace"
    :router-component-name="router_component_name"
    :size="size"
    :squared="squared"
    :tag="tag"
    :target="target"
    :to="to"
    :type="type"
    :variant="variant"
    @click="(value) => $emit('click', value)"
  >
    <div v-if="icon && !disabled" class="custom-icon">
      <vb-icon
        :name="icon"
        :color="'ffffff'"
      />
    </div>
    <div v-if="icon_disabled && disabled" class="disabled_icon">
      <vb-icon
        :name="icon_disabled"
        :size=16
        :color="'000'"
      />
    </div>
    <div
      v-html="text"
    />
    <slot></slot>
  </b-button>

</template>

<script>
import { lock } from '@/components/svg/design-icons'

export default {
  name: 'vb-btn',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // custom options
      /*text: this.vb_options.text !== undefined ? this.vb_options.text : '',*/
      icon: this.vb_options.icon !== undefined ? this.vb_options.icon : '',
      icon_disabled: this.vb_options.icon_disabled !== undefined ? this.vb_options.icon_disabled : '',


      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/button/#button
      active: this.vb_options.active !== undefined ? this.vb_options.active : false,                                                      //When set to `true`, places the component in the active state with active styling
      active_class: this.vb_options.active_class !== undefined ? this.vb_options.active_class : '',                                       //<router-link> prop: Configure the active CSS class applied when the link is active. Typically you will want to set this to class name 'active'
      append: this.vb_options.append !== undefined ? this.vb_options.append : false,                                                      //<router-link> prop: Setting append prop always appends the relative path to the current path
      block: this.vb_options.block !== undefined ? this.vb_options.block : false,                                                         //Renders a 100% width button (expands to the width of its parent container)
      //disabled: this.vb_options.disabled !== undefined ? this.vb_options.disabled : false,                                              //When set to `true`, disables the component's functionality and places it in a disabled state
      exact: this.vb_options.exact !== undefined ? this.vb_options.exact : false,                                                         //ID of the form that the form control belongs to. Sets the `form` attribute on the control
      exact_active_class: this.vb_options.exact_active_class !== undefined ? this.vb_options.exact_active_class : '',                     //<router-link> prop: Configure the active CSS class applied when the link is active with exact match. Typically you will want to set this to class name 'active'
      exact_path: this.vb_options.exact_path !== undefined ? this.vb_options.exact_path : false,                                          //<router-link> prop: Allows matching only using the path section of the url, effectively ignoring the query and the hash sections
      exact_path_active_class: this.vb_options.exact_path_active_class !== undefined ? this.vb_options.exact_path_active_class : '',      //<router-link> prop: Configure the active CSS class applied when the link is active with exact path match. Typically you will want to set this to class name 'active'
      href: this.vb_options.href !== undefined ? this.vb_options.href : '',                                                               //<b-link> prop: Denotes the target URL of the link for standard a links
      no_prefetch: this.vb_options.no_prefetch !== undefined ? this.vb_options.no_prefetch : false,                                       //<nuxt-link> prop: To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport, Nuxt.js will automatically prefetch the code splitted page. Setting `no-prefetch` will disabled this feature for the specific link
      pill: this.vb_options.pill !== undefined ? this.vb_options.pill : false,                                                            //Renders the button with the pill style appearance when set to 'true'
      prefetch: this.vb_options.prefetch !== undefined ? this.vb_options.prefetch : null,                                                 //<nuxt-link> prop: To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport, Nuxt.js will automatically prefetch the code splitted page. Setting `prefetch` to `true` or `false` will overwrite the default value of `router.prefetchLinks`
      pressed: this.vb_options.pressed !== undefined ? this.vb_options.pressed : null,                                                    //When set to 'true', gives the button the appearance of being pressed and adds attribute 'aria-pressed="true"'. When set to `false` adds attribute 'aria-pressed="false"'. Tri-state prop. Syncable with the .sync modifier
      rel: this.vb_options.rel !== undefined ? this.vb_options.rel : null,                                                                //For number-like inputs, disables the mouse wheel from incrementing or decrementing the value
      replace: this.vb_options.replace !== undefined ? this.vb_options.replace : false,                                                   //<router-link> prop: Setting the replace prop will call `router.replace()` instead of `router.push()` when clicked, so the navigation will not leave a history record
      router_component_name: this.vb_options.router_component_name !== undefined ? this.vb_options.router_component_name : '',            //<b-link> prop: BootstrapVue auto detects between `<router-link>` and `<nuxt-link>`. In cases where you want to use a 3rd party link component based on `<router-link>`, set this prop to the component name. e.g. set it to 'g-link' if you are using Gridsome (note only `<router-link>` specific props are passed to the component)    
      size: this.vb_options.size !== undefined ? this.vb_options.size : '',                                                               //Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
      squared: this.vb_options.squared !== undefined ? this.vb_options.squared : false,                                                   //Renders the button with non-rounded corners when set to 'true'
      tag: this.vb_options.tag !== undefined ? this.vb_options.tag : 'button',                                                            //Specify the HTML tag to render instead of the default tag
      target: this.vb_options.target !== undefined ? this.vb_options.target : '_self',                                                    //<b-link> prop: Sets the `target` attribute on the rendered link
      to: this.vb_options.to !== undefined ? this.vb_options.to : null,                                                                   //<router-link> prop: Denotes the target route of the link. When clicked, the value of the to prop will be passed to `router.push()` internally, so the value can be either a string or a Location descriptor object
      type: this.vb_options.type !== undefined ? this.vb_options.type : 'button',                                                         //The value to set the button's 'type' attribute to. Can be one of 'button', 'submit', or 'reset'
      //variant: this.vb_options.variant !== undefined ? this.vb_options.variant : 'info',                                                //Applies one of the Bootstrap theme color variants to the component
      value: this.vb_options.value !== undefined ? this.vb_options.value : false,                                                         //Value returned when this checkbox is checked
    }
  },

  created() {
    this.lock = lock
  },

  computed: {
    text() {
      return this.vb_options.text !== undefined ? this.vb_options.text : ''
    },
    disabled() {
      return this.vb_options.disabled !== undefined ? this.vb_options.disabled : false
    },
    variant() {
      return this.vb_options.variant !== undefined ? this.vb_options.variant : 'info'
    }
  },

  mounted() {
  }
}
</script>

<style lang="scss">

  .vb-btn {
    $this: &;

    @at-root .disabled > &, // se il parent è disabled (es. tab calendar booking engine)
    &.disabled,
    &:disabled {
      border: 0 !important;
      background-color: $light !important;
      color: $vb-disabled !important;
      box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
    }

    @at-root .active > &, // se il parent è active (es. tab calendar booking engine)
    &:hover,
    &:active,
    &.active {
      box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
      border-color: transparent !important;
      background-color: $light !important;

      @each $color,
      $value in $theme-colors {
        @at-root #{$this}.btn-#{$color}:hover:not(.btn-light):not(.disabled),
        #{$this}.btn-outline-#{$color}:hover:not(.btn-light):not(.disabled) {
          color: $value
        }

        @at-root #{$this}.btn-#{$color}:active:not(.btn-light):not(.disabled),
        #{$this}.btn-outline-#{$color}:active:not(.btn-light):not(.disabled) {
          color: $value
        }
      }

      // fix per il .btn-dark in stato '.active'
      &.btn-dark {
        color: $dark;
        * {
          color: $dark;
        }
        svg {
          --color: #{$dark};
        }
      }
    }

    &:focus {

      @each $color,
      $value in $theme-colors {
        @at-root #{$this}.btn-#{$color}:hover:not(.btn-light):not(.disabled) {
          color: $value
        }

        @at-root #{$this}.btn-#{$color}:focus {
          background-color: $value;
          border-color: $value;
        }
      }
    }


    & > div {
      display: inline-block;
      vertical-align: middle;
    }

    .custom-icon {
      margin-right: 8px;

      svg {
        max-height: 22px;
        max-width: 22px;
        width: auto;
      }
    }

    .disabled_icon {
      margin-right: 8px;
    }

    &:hover {
      .custom-icon svg {
        --color: #{$primary} !important;
      }
    }

    &__icon {
      $size: 1.2em;
      width: $size;
      margin-right: .5em;
    }
  }

</style>