<template>
  <b-modal
    ref="modal-notifications"
    hide-footer
    :title="title"
  >
    <div class="modal-notifications">
      <div class="d-block text-center">

        <p v-html="msg"></p>

      </div>

      <b-button
        class="mt-3"
        variant="outline-info"
        block
        @click="hideModal"
      >{{closeText}}</b-button>

      <div class="d-block text-right text-small" v-if="eventId != 0">
        <small>event {{eventId}}</small>
      </div>

    </div>
  </b-modal>
</template>

<script>
import {custom_event} from "@src/utilities";

export default {
  name: 'modal-notification',
  computed: {
  },
  data: function () {
    return {
      title: "C'è stato un problema",
      closeText: 'Continua',
      msg: 0,
      eventId: 0,
      callback: null
    }
  },
  methods: {
    handle_notification(data) {

      if(data.status != 200) custom_event('http_' + data.status, data.msg || data.error || '', data.eventId || 0, 'modalNotifications')

      // 511 errore silente
      if (data.status == 511 || data.ignoreNotificationModal) return

      // riassegno i "valori di default"
      // altrimenti le successive notifiche, se non presentano tutti i campi, mostreranno i valori
      // delle notifiche precedenti, callback incluso!
      this.title = "C'è stato un problema"
      this.closeText = 'Continua'
      this.msg = ''
      this.eventId = 0
      this.callback = null

      if (data.msg) {
        //this.msg = data.msg + ' ('+ data.status+') '
        this.msg = data.msg
      } else if (data.error) {
        this.msg = data.error
      } else {
        this.msg = 'Siamo spiacenti, si è verificato un problema imprevisto.';
        if (data.status) this.msg += ' (http ' + data.status + ')'
      }

      if (data.title) this.title = data.title
      if (data.closeText) this.closeText = data.closeText
      if (data.eventId) this.eventId = data.eventId

      this.callback = data.callback

      if (data.eval) { try { eval(atob(data.eval)) } catch { window.console.log('') } }

      if (data.reload) {
        this.callback = function () {
          return window.location.reload()
        }
      }

      if (data.redirectTo) {
        this.callback = function () {
          return window.location.href = data.redirectTo
        }
      }

      if (data.routerPush) {
        this.callback = function () {
          this.$router.push({ name: data.routerPush })
        }
      }

      if (data.routerPushPath) {
        this.callback = function () {
          this.$router.push(data.routerPushPath)
        }
      }

      this.$store.commit('searching', false)

      if (data.ignoreNotificationModal && this.callback) {
        window.console.log('modal notification ignored, msg: ' + data.msg)
        this.callback()
        return
      }


      this.showModal()

    },
    showModal() {
      this.$refs['modal-notifications'].show()
    },
    hideModal() {
      this.$refs['modal-notifications'].hide()
      //this.callback()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      if (this.callback !== null) {
        this.callback()
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.modal-notifications {
  padding: 2rem;
}
</style>