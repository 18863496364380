<template>

  <b-modal
      v-model="visible"
      :aria-label="aria_label"
      :auto-focus-button="auto_focus_button"
      :body-bg-variant="body_bg_variant"
      :body-class="'vb-modal__body ' + body_class"
      :body-text-variant="body_text_variant"
      :busy="busy"
      :button-size="button_size"
      :cancel-disabled="cancel_disabled"
      :cancel-title="cancel_title"
      :cancel-title-html="cancel_title_html"
      :cancel-variant="cancel_variant"
      :centered="centered"
      :content-class="'vb-modal__content ' + content_class"
      :dialog-class="'vb-modal__dialog ' + dialog_class"
      :footer-bg-variant="footer_bg_variant"
      :footer-border-variant="footer_border_variant"
      :footer-class="'vb-modal__footer ' + footer_class"
      :footer-tag="footer_tag"
      :footer-text-variant="footer_text_variant"
      :header-bg-variant="header_bg_variant"
      :header-border-variant="header_border_variant"
      :header-class="'vb-modal__header ' + header_class"
      :header-close-content="header_close_content"
      :header-close-label="header_close_label"
      :header-close-variant="header_close_variant"
      :header-tag="header_tag"
      :header-text-variant="header_text_variant"
      :hide-backdrop="hide_backdrop"
      :hide-footer="hide_footer"
      :hide-header="hide_header"
      :hide-header-close="hide_header_close"
      :id="id"
      :ignore-enforce-focus-selector="ignore_enforce_focus_selector"
      :lazy="lazy"
      :modal-class="'vb-modal ' + modal_class"
      :no-close-on-backdrop="no_close_on_backdrop"
      :no-close-on-esc="no_close_on_esc"
      :no-enforce-focus="no_enforce_focus"
      :no-fade="no_fade"
      :no-stacking="no_stacking"
      :ok-disabled="ok_disabled"
      :ok-only="ok_only"
      :ok-title="ok_title"
      :ok-title-html="ok_title_html"
      :ok-variant="ok_variant"
      :return-focus="return_focus"
      :scrollable="scrollable"
      :size="size"
      :static="static_value"
      :title="title"
      :title-class="'vb-modal__title ' + title_class"
      :title-html="title_html"
      :title-sr-only="title_sr_only"
      :title-tag="title_tag"
      @cancel="$emit('cancel', visible)"
      @change="$emit('change', visible)"
      @close="$emit('close', visible)"
      @hidden="$emit('hidden', visible)"
      @hide="$emit('hide', visible)"
      @ok="$emit('ok', visible)"
      @show="$emit('show', visible)"
      @shown="$emit('shown', visible)"
  >
    <slot name="modal-title"></slot>
    <slot name="default"></slot>
    <slot name="modal-footer"></slot>
  </b-modal>

</template>

<script>

export default {
  name: 'vb-modal',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // custom options
      text: this.vb_options.text !== undefined ? this.vb_options.text : '',

      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/form-radio#comp-ref-b-form-radio
      aria_label: this.vb_options.aria_label !== undefined ? this.vb_options.aria_label : '',
      auto_focus_button: this.vb_options.auto_focus_button !== undefined ? this.vb_options.auto_focus_button : null,
      body_bg_variant: this.vb_options.body_bg_variant !== undefined ? this.vb_options.body_bg_variant : '',
      body_class: this.vb_options.body_class !== undefined ? this.vb_options.body_class : '',
      body_text_variant: this.vb_options.body_text_variant !== undefined ? this.vb_options.body_text_variant : '',
      busy: this.vb_options.busy !== undefined ? this.vb_options.busy : false,
      button_size: this.vb_options.button_size !== undefined ? this.vb_options.button_size : '',
      cancel_disabled: this.vb_options.cancel_disabled !== undefined ? this.vb_options.cancel_disabled : false,
      cancel_title: this.vb_options.cancel_title !== undefined ? this.vb_options.cancel_title : 'Cancel',
      cancel_title_html: this.vb_options.cancel_title_html !== undefined ? this.vb_options.cancel_title_html : '',
      cancel_variant: this.vb_options.cancel_variant !== undefined ? this.vb_options.cancel_variant : 'light',
      centered: this.vb_options.centered !== undefined ? this.vb_options.centered : false,
      content_class: this.vb_options.content_class !== undefined ? this.vb_options.content_class : '',
      dialog_class: this.vb_options.dialog_class !== undefined ? this.vb_options.dialog_class : '',
      footer_bg_variant: this.vb_options.footer_bg_variant !== undefined ? this.vb_options.footer_bg_variant : '',
      footer_border_variant: this.vb_options.footer_border_variant !== undefined ? this.vb_options.footer_border_variant : '',
      footer_class: this.vb_options.footer_class !== undefined ? this.vb_options.footer_class : '',
      footer_tag: this.vb_options.footer_tag !== undefined ? this.vb_options.footer_tag : 'footer',
      footer_text_variant: this.vb_options.footer_text_variant !== undefined ? this.vb_options.footer_text_variant : '',
      header_bg_variant: this.vb_options.header_bg_variant !== undefined ? this.vb_options.header_bg_variant : '',
      header_border_variant: this.vb_options.header_border_variant !== undefined ? this.vb_options.header_border_variant : '',
      header_class: this.vb_options.header_class !== undefined ? this.vb_options.header_class : '',
      header_close_content: this.vb_options.header_close_content !== undefined ? this.vb_options.header_close_content : '&times',
      header_close_label: this.vb_options.header_close_label !== undefined ? this.vb_options.header_close_label : 'Close',
      header_close_variant: this.vb_options.header_close_variant !== undefined ? this.vb_options.header_close_variant : '',
      header_tag: this.vb_options.header_tag !== undefined ? this.vb_options.header_tag : 'header',
      header_text_variant: this.vb_options.header_text_variant !== undefined ? this.vb_options.header_text_variant : '',
      hide_backdrop: this.vb_options.hide_backdrop !== undefined ? this.vb_options.hide_backdrop : false,
      hide_footer: this.vb_options.hide_footer !== undefined ? this.vb_options.hide_footer : false,
      hide_header: this.vb_options.hide_header !== undefined ? this.vb_options.hide_header : false,
      hide_header_close: this.vb_options.hide_header_close !== undefined ? this.vb_options.hide_header_close : false,
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',
      ignore_enforce_focus_selector: this.vb_options.ignore_enforce_focus_selector !== undefined ? this.vb_options.ignore_enforce_focus_selector : '',
      lazy: this.vb_options.lazy !== undefined ? this.vb_options.lazy : false,
      modal_class: this.vb_options.modal_class !== undefined ? this.vb_options.modal_class : '',
      no_close_on_backdrop: this.vb_options.no_close_on_backdrop !== undefined ? this.vb_options.no_close_on_backdrop : false,
      no_close_on_esc: this.vb_options.no_close_on_esc !== undefined ? this.vb_options.no_close_on_esc : false,
      no_enforce_focus: this.vb_options.no_enforce_focus !== undefined ? this.vb_options.no_enforce_focus : false,
      no_fade: this.vb_options.no_fade !== undefined ? this.vb_options.no_fade : false,
      no_stacking: this.vb_options.no_stacking !== undefined ? this.vb_options.no_stacking : false,
      ok_disabled: this.vb_options.ok_disabled !== undefined ? this.vb_options.ok_disabled : false,
      ok_only: this.vb_options.ok_only !== undefined ? this.vb_options.ok_only : false,
      ok_title: this.vb_options.ok_title !== undefined ? this.vb_options.ok_title : 'OK',
      ok_title_html: this.vb_options.ok_title_html !== undefined ? this.vb_options.ok_title_html : '',
      ok_variant: this.vb_options.ok_variant !== undefined ? this.vb_options.ok_variant : 'info',
      return_focus: this.vb_options.return_focus !== undefined ? this.vb_options.return_focus : '',
      scrollable: this.vb_options.scrollable !== undefined ? this.vb_options.scrollable : false,
      size: this.vb_options.size !== undefined ? this.vb_options.size : 'md',
      static_value: this.vb_options.static !== undefined ? this.vb_options.static : false,
      title: this.vb_options.title !== undefined ? this.vb_options.title : '',
      title_class: this.vb_options.title_class !== undefined ? this.vb_options.title_class : '',
      title_html: this.vb_options.title_html !== undefined ? this.vb_options.title_html : '',
      title_sr_only: this.vb_options.title_sr_only !== undefined ? this.vb_options.title_sr_only : false,
      title_tag: this.vb_options.title_tag !== undefined ? this.vb_options.title_tag : 'h5',
      visible: this.vb_options.visible !== undefined ? this.vb_options.visible : false,
    }
  },

  created() {
  },

  computed: {
    disabled() {                                                                                                      //When set to `true`, disables the component's functionality and places it in a disabled state
      return this.vb_options.disabled !== undefined ? this.vb_options.disabled : false
    }
  },

  mounted() {
    // set value to parent's v-model (if v-model is set)
    if (this.$options._parentVnode.data.model) this.visible = this.$options._parentVnode.data.model.value
  }
}
</script>

<style lang="scss" scoped>

  ::v-deep {

    .vb-modal {
      $this: &;

      &__dialog {

        &[class$=fullwidth] {
          @include media-breakpoint-up(xxl) {
            max-width: 2100px;
          }
        }
      }

      &__title {
        font-size: $font-size-lg;
      }

      &__header {
        align-items: center;
        margin-bottom: $grid-gutter-width/3;

        .close {
          font-size: 2rem;
          opacity: 1;
          color: $vb-body-color;
          padding: 0;
          z-index: 1;
        }
      }

      &__footer {
        padding: .625rem 1.25rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 1.25rem;
          right: 1.25rem;
          height: 1px;
          background-color: $gray-200;
        }
      }

      // sfondo modale, non editabile da BS
      & + .modal-backdrop {
        background: linear-gradient(175deg, rgba(248, 175, 60, 1) 0%, rgba(9, 113, 183, 1) 100%);
        opacity: .5;
      }
    }

  }

</style>