<template>
  <div class="custom-link" @click.prevent="navigate($event)">
    <component
      v-bind:is="wrap"
      :href="router_path"
      :class="css_class">
      <slot name="content" />
    </component>
  </div>
</template>

<script>
import Vue from 'vue'
import { new_session_key_query } from '@/resources'
//import { encode_parameters } from '@/utilities'
export const CustomLink = {
  name: "custom-link",
  props: {
    before_navigation: {
      type: Function,
      default: () => { return }
    },
    route_name: String,
    /*
    route_params: {
      type: Object,
      default: () => { return {} },
    },
    */
    wrap: {
      type: String,
      default: 'a',
    },
    css_class: {
      type: String,
      default: '',
    },
    as_default: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    router_path() {
      /*
      let encoded_state = encode_parameters(this.$store.state),
          encoded_params = encode_parameters(this.route_params)
      return this.route_name + '?params=' + encoded_params + '&state=' + encoded_state
      */
      return this.route_name + '?' + new_session_key_query + '=' + Vue.prototype.$localstorage_session_key
    }
  },
  mounted() {
  },
  methods: {
    navigate(event) {
      this.before_navigation()
      if (event.ctrlKey || event.metaKey || this.as_default) window.open(this.router_path)
      else {
        if (this.$route.name == this.route_name) return
        this.$router.push({
          name: this.route_name,
          //params: this.route_params,
        })
      }
    }
  }
}
export default CustomLink
</script>

<style lang="scss" scoped>
.custom-link {
  cursor: pointer;
  & > a {
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
    font-size: inherit;
    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: inherit;
      cursor: inherit;
      font-size: inherit;
    }
  }
}
</style>