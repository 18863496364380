import { number, largerEq, smaller, sum, multiply, round, ceil } from 'mathjs'
import Vue from "vue";
const decimals = 2

export function markup(price) {

  let mu = JSON.parse(atob(Vue.prototype.$config.mu)),
    markups = mu['DEFAULT'] || [],
    pricerange_markup = 0,
    customer_markup = number(mu.ADD.p || 0.1),
    mu_percent = customer_markup,
    mu_display_percent = (mu.DISPLAY)? mu.DISPLAY.p : 0

  markups.forEach(m => {
    if( largerEq(number(price), number(m.min)) ) {
      pricerange_markup = number(m.p)
    } else {
      return
    }
  })

  if(Vue.prototype.$config.forced_mu) {
    // caso CONFIRM -> markup forzato da confirm()
    mu_percent = JSON.parse(atob(Vue.prototype.$config.forced_mu))
  } else {
    mu_percent = round(sum(pricerange_markup, customer_markup), 3) // percentuale markup 3 decimali
  }


  let markup = round( multiply(price, mu_percent), decimals)
  let live_price = round( sum( price, markup ), decimals)

  let display_markup = round( multiply(price, mu_display_percent), decimals)
  let display_price = round( sum( price, display_markup ), decimals)

  if(smaller(live_price, price)) live_price = price

  /*window.console.table({
    price: price,
    mu_percent: mu_percent,
    markup: markup,
    live_price: live_price,
    display_price: display_price,
  })*/


  return {
    markup: markup, // ammontare del markup
    live_price: round_up(live_price), // markup su prezzo netto
    display_price: round_up(display_price), // markup "finto" su prezzo commissionabile
  }

}

export function round_up(price, decimals){

  decimals = decimals || 0

  return ceil(price * Math.pow(10, decimals)) / Math.pow(10, decimals)

}