var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"otp"},[_c('h5',{staticClass:"vb-heading"},[_vm._v("Richiedi una password temporanea")]),_c('p',[_vm._v(" Per essere sicuri che sei tu, ti invieremo "),(_vm.by == 'MAIL')?_c('span',[_vm._v("all'indirizzo "),_c('strong',[_vm._v(_vm._s(_vm.sent_to))])]):_c('span',[_vm._v("al numero di telefono "),_c('strong',[_vm._v(_vm._s(_vm.sent_to))])]),_vm._v(" un codice temporaneo (OTP) da usare per confermare la prenotazione ")]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-otp'),expression:"'modal-otp'"}],ref:"trigger_otp",on:{"click":() => { if(this.status == 'REQUIRED') _vm.$emit('otp-event', 'REQUIRED_CTA_CLICK')}}},[_c('vb-button',{attrs:{"vb_options":{
        text: _vm.copy_button,
        disabled: _vm.disabled,
        icon_disabled: 'lock'
      }}})],1),_c('b-modal',{attrs:{"id":'modal-otp',"title":_vm.display_name + ' vorremmo essere sicuri che sei tu',"size":"md"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"mt-4 mb-3 mx-3 otp--modal-requestcode d-flex w-100",class:_vm.status == 'OTP_SENT' || _vm.status == 'RATE_LIMITED' || _vm.status == 'NOT_VALID' ? 'justify-content-end' : 'justify-content-center'},[_c('vb-button',{attrs:{"vb_options":{
            text: (_vm.status == 'OTP_SENT' || _vm.status == 'RATE_LIMITED' || _vm.status == 'NOT_VALID' ? 'Richiedi un\'altra password' : 'Richiedi password'),
            disabled: _vm.countdown > 0,
            icon_disabled: 'lock'
          }},on:{"click":function($event){return _vm.request_otp()}}})],1)]},proxy:true}])},[_c('span',{staticClass:"otp--modal--description"},[_vm._v(" Per essere sicuri che sei tu, ti invieremo "),(_vm.by == 'MAIL')?_c('span',[_vm._v("all'indirizzo "),_c('strong',[_vm._v(_vm._s(_vm.sent_to))])]):_c('span',[_vm._v("al numero di telefono "),_c('strong',[_vm._v(_vm._s(_vm.sent_to))])]),_vm._v(" un codice temporaneo (OTP) da usare per confermare la prenotazione "),_c('br'),_c('br'),_vm._v(" Richiedila e potrai inserirla in questa finestra per completare la prenotazione. ")]),(_vm.loading)?_c('div',{staticClass:"text-center mt-3"},[_c('loader')],1):_c('div',{staticClass:"otp--modal-wrapper"},[(_vm.status == 'OTP_SENT' || _vm.status == 'RATE_LIMITED' || _vm.status == 'NOT_VALID')?_c('div',{staticClass:"otp--modal-add-code text-center mt-3"},[_c('span',{staticClass:"otp--modal-notify-mail"},[_vm._v("Ti abbiamo appena inviato la password temporanea."),_c('br'),_vm._v(" Controlla la tua email.")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('validation-provider',{attrs:{"rules":"required|min:6|max:6","name":"OTP"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"otp--modal-code-wrapper d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"otp--modal-prefix"},[_vm._v(_vm._s(_vm.prefix)+"- ")]),_c('b-form-input',{staticClass:"w-75 otp--moda-input",attrs:{"placeholder":"Inserisci la password","type":"text"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"code"}})],1),_c('vb-button',{staticClass:"mt-2",attrs:{"vb_options":{
                text: _vm.copy_button,
                disabled: invalid || _vm.loading,
                icon_disabled: 'lock'
              }},on:{"click":function($event){return _vm.validate_otp()}}}),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}],null,false,1860248321)}),(_vm.msg != '')?_c('div',{staticClass:"otp--modal-text mb-4 mt-2 font-weight-bold"},[_vm._v(_vm._s(_vm.msg))]):_vm._e(),_c('div',{staticClass:"otp--info text-left mt-4"},[(_vm.countdown > 0)?_c('div',{staticClass:"font-italic"},[_vm._v("Potrai richiedere una nuova password tra "+_vm._s(_vm.countdown)+" secondi")]):_vm._e(),(_vm.by == 'MAIL')?_c('strong',[_vm._v("Non trovi la nostra email? Controlla anche la cartella spam.")]):_vm._e()])],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }