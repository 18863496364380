<template>
  <div class="pinned-message" v-if="this.$config.guiSettings.pinnedMessage">
    <b-alert class="pinned-message__alert" :variant="variant" show v-b-popover.hover.top="tooltip">
      <vb-icon class="pinned-message__icon" :name="'info'" :color="'474747'" :size="14" />
      <span class="pinned-message__title" v-html="title" />
    </b-alert>
  </div>
</template>


<script>
import Vue from "vue";
export default {
  name: 'pinned-message',
  components: {
  },
  data(){
    return{
      title: (Vue.prototype.$config.guiSettings.pinnedMessage)? Vue.prototype.$config.guiSettings.pinnedMessage.title : '',
      tooltip: (Vue.prototype.$config.guiSettings.pinnedMessage)? Vue.prototype.$config.guiSettings.pinnedMessage.tooltip : '',
      variant: (Vue.prototype.$config.guiSettings.pinnedMessage)? Vue.prototype.$config.guiSettings.pinnedMessage.variant : '',
    }
  },
  created(){
  }
}
</script>

<style lang="scss" scoped>

.pinned-message {
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom:0;
  text-align:center;
  width:100%;

  .pinned-message__alert {
    flex:1;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0;
  }

  .pinned-message__icon {
    margin-right: 5px;
  }
}

</style>