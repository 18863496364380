<template>
  <div class="stripe">
    <p class="text-center">
      {{ copy_intro }}
    </p>
    <!-- NON posso togliere dal DOM l'elemento #card, o perdo tutti i listener che stripe ha iniettato => v-show al posto di v-if -->
    <div v-show="stripe_payment_intent_id == ''">
      <div v-if="loading" class="text-center mt-2">
        <loader />
      </div>
      <div v-show="!loading">
        <input
          class="form-control"
          ref="ccname"
          v-model="name"
          placeholder="Mario Rossi"
        />
        <div ref="card" id="card" class="form-control"></div>
        <vb-button
          @click="handle_get_intent_client_secret()"
          :vb_options="{
            text: copy_button,
            disabled: !stripe_form_complete,
            icon_disabled: 'lock'
          }"
        />
      </div>
    </div>
    <div class="mt-2">
      <b-alert :show="stripe_payment_intent_id !== ''" variant="success" class="text-center">
        {{ copy_success }}
      </b-alert>
      <b-alert :show="stripe_error !== ''" variant="danger" class="text-center">
        {{ stripe_error !== '' ? stripe_error : copy_error }}
      </b-alert>
    </div>
  </div>
</template>

<script>

import { service_handler } from '@/services'
export default {
  /*
    carta di credito test ok:       4242 4242 4242 4242 (data futura/CVC arbitrario)
    carta di credito test errore:   4000 0000 0000 0002 (data futura/CVC arbitrario)
  */
  name: 'stripe',
  emits: [
    'stripe-init',    // quando viene avviato il tutto
    'stripe-change',  // quando vengono inseriti i dati (emette lo status del form di inserimento)
    'stripe-confirm', // quando viene confermato o meno un pagamento
  ],
  props: {
    credit_card_amount: Number,
    copy_intro: {
      type: String,
      default: 'Inserisci la carta per completare il pagamento',
    },
    copy_button: {
      type: String,
      default: 'Conferma carta',
    },
    copy_success: {
      type: String,
      default: 'Puoi procedere con il checkout',
    },
    copy_error: {
      type: String,
      default: 'C\'è stato un problema',
    }
  },
  computed: {
  },
  watch: {
    credit_card_amount(new_value, old_value) {
      if(new_value !== old_value) this.stripe_setup()
    }
  },
  data() {
    return {
      loading: false,
      stripe_setup_public_key: this.$config.stripePublicKey,
      stripe: '',
      stripe_error: '',
      stripe_elements: '',
      stripe_card_element: '',
      stripe_card_selector: '#card',
      stripe_intent_client_secret: '',
      stripe_payment_intent_id: '',
      stripe_form_complete: false,
      name: process.env.VUE_APP_MODE == 'development' ? 'Mario Rossi' : '',
    }
  },
  mounted() {
    this.stripe_setup()
  },
  methods: {
    stripe_setup() {
      this.stripe_error = ''
      this.stripe_elements = ''
      this.stripe_card_element = ''
      this.stripe_card_selector = '#card'
      this.stripe_intent_client_secret = ''
      this.stripe_payment_intent_id = ''
      this.stripe_form_complete = false
      this.stripe = window.Stripe(this.stripe_setup_public_key)
      this.stripe_elements = this.stripe.elements({
        // c'è un warning: sono diventati obsoleti
        //family: "Montserrat",
        //src: "url(https://fonts.googleapis.com/css?family=Montserrat:300)",
        //weight: "300",
      })
      this.stripe_card_element = this.stripe_elements.create("card", {
        hidePostalCode: true,
        style: {
          base: {
            backgroundColor: "transparent",
            color: "#000000",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "18px",
            fontSmoothing: "antialiased",
            //fontStyle,
            //fontVariant,
            fontWeight: 300,
            //iconColor,
            //lineHeight,
            //letterSpacing,
            //textAlign,
            //padding: '16px',
            //textDecoration,
            //textShadow,
            //textTransform,
          },
          complete: {
            color: "green",
          },
          invalid: {
            color: "red",
          },
        },
      })
      this.stripe_card_element.mount(this.stripe_card_selector)
      this.stripe_card_element.on('change', (event) => {
        this.$emit('change', {name:'stripe-change', payload: event.complete})
        this.stripe_form_complete = event.complete
      })
      this.$emit('change',{name:'stripe-init'})
    },
    handle_get_intent_client_secret() {
      this.loading = true
      service_handler('stripeCreateIntentClientSecret',{
        token: this.$config.token,
        amount: this.credit_card_amount,
      }).then((response) => {
        this.stripe_intent_client_secret = response.data.intentClientSecret
        this.handle_confirm_card_payment()
      })
    },
    handle_confirm_card_payment() {
      this.stripe
        .confirmCardPayment(this.stripe_intent_client_secret, {
          payment_method: {
            card: this.stripe_card_element,
            billing_details: {
              name: this.name,
            },
          },
        })
        .then((result) => {
          if(result.error) {
            result.error.status = 'stripe_error'
            this.stripe_error = result.error.message
            this.$emit('change', {
                name:'stripe-confirm', 
                payload: {
                  error: true,
                  result: result.error,
                }
              })
            this.loading = false
            return
          }
          this.stripe_error = ''
          this.stripe_payment_intent_id = result.paymentIntent.id
          this.$emit( 'change', {
              name: 'stripe-confirm',
              payload: {
                error: false,
                paymentIntentId: this.stripe_payment_intent_id,
                name: this.name,
              }
            })
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.stripe {
  .btn {
    width: 100%;
  }
  #card {
    @extend .form-control !optional;
    margin:$grid-gutter-width/2 0;
  }
}
</style>