
const dev = [
    {
      path: '/faq',
      name: 'faq',
      component: () => import(process.env.VUE_APP_SITE_URL + '/components/views/static-pages/faq').then(function (Faq) {
        return Faq
      }),
    }
]

export {
    dev
}
