<template>
  <div class="otp">
    <h5 class="vb-heading">Richiedi una password temporanea</h5>

    <p>
      Per essere sicuri che sei tu, ti invieremo 
      <span v-if="by == 'MAIL'">all'indirizzo <strong>{{sent_to}}</strong></span>
      <span v-else>al numero di telefono <strong>{{sent_to}}</strong></span>
      un codice temporaneo (OTP) da usare per confermare la prenotazione
    </p>

    <div
      @click="() => { if(this.status == 'REQUIRED') $emit('otp-event', 'REQUIRED_CTA_CLICK')}"
      v-b-modal="'modal-otp'"
      ref="trigger_otp">
      <vb-button
        :vb_options="{
          text: copy_button,
          disabled: disabled,
          icon_disabled: 'lock'
        }"
      />
    </div>
    
    <b-modal
      :id="'modal-otp'"
      :title="display_name + ' vorremmo essere sicuri che sei tu'"
      size="md">

      <span class="otp--modal--description">
        Per essere sicuri che sei tu, ti invieremo 
        <span v-if="by == 'MAIL'">all'indirizzo <strong>{{sent_to}}</strong></span>
        <span v-else>al numero di telefono <strong>{{sent_to}}</strong></span>
        un codice temporaneo (OTP) da usare per confermare la prenotazione
        <br /><br />
        Richiedila e potrai inserirla in questa finestra per completare la prenotazione.
      </span>

      <div
        v-if="loading"
        class="text-center mt-3"
      >
        <loader />
      </div>

      <div
        v-else
        class="otp--modal-wrapper"
      >

        <div
          class="otp--modal-add-code text-center mt-3"
          v-if="status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID'"
        >

          <span class="otp--modal-notify-mail">Ti abbiamo appena inviato la password temporanea.<br /> Controlla la tua email.</span>

          <validation-observer v-slot="{ invalid }">
            <validation-provider
              rules="required|min:6|max:6"
              name="OTP"
              v-slot="{ errors }"
            >

              <div class="otp--modal-code-wrapper d-flex justify-content-center align-items-center">
                <span class="otp--modal-prefix">{{prefix}}- </span>
                <b-form-input
                  v-model.trim="code"
                  placeholder="Inserisci la password"
                  type="text"
                  class="w-75 otp--moda-input"
                ></b-form-input>
              </div>
              
             <!--<b-button
                :size="button_size"
                :variant="button_variant"
                :disabled="invalid || loading"
                @click="validate_otp()"
                class="mt-2"
              >
                Prenota
              </b-button>-->
              <vb-button
                @click="validate_otp()"
                class="mt-2"
                :vb_options="{
                  text: copy_button,
                  disabled: invalid || loading,
                  icon_disabled: 'lock'
                }"
              />              
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
          <div
            v-if="msg != ''"
            class="otp--modal-text mb-4 mt-2 font-weight-bold"
          >{{msg}}</div>

          <div class="otp--info text-left mt-4">
            <div
              class="font-italic"
              v-if="countdown > 0"
            >Potrai richiedere una nuova password tra {{countdown}} secondi</div>
            <strong v-if="by == 'MAIL'">Non trovi la nostra email? Controlla anche la cartella spam.</strong>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div
          class="mt-4 mb-3 mx-3 otp--modal-requestcode d-flex w-100"
          :class="status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID' ? 'justify-content-end' : 'justify-content-center'"
        >
          <!--<b-button
            :size="button_size"
            :variant="button_variant"
            :disabled="countdown > 0"
            @click="request_otp()"
          >
            <span v-html="status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID' ? 'Richiedi un\'altra password' : 'Richiedi password'"></span>
          </b-button>-->
          <vb-button
            @click="request_otp()"
            :vb_options="{
              text: (status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID' ? 'Richiedi un\'altra password' : 'Richiedi password'),
              disabled: countdown > 0,
              icon_disabled: 'lock'
            }"
          />                  
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { service_handler } from '@/services'
export default {
  name: "otp",
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    button_variant: {
      type: String,
      default: 'primary',
    },
    button_size: {
      type: String,
      default: '',
    },
    copy_button: {
      type: String,
      default: 'Richiedi'
    }
  },
  data() {
    return {
      code: '',
      loading: false,
      status: '',
      msg: '',
      display_name: '',
      sent_to: '',
      countdown: 0,
      by: '',
      prefix: '',
      /*
      - **NOT_REQUIRED**: otp non necessario, nulla da fare
      - **REQUIRED**: otp deve essere richiesto (pulsante invia + input per codice)
      - **OTP_SENT**: otp inviato
      - **RATE_LIMITED**: troppe chiamate "new"
      - **VALID**: inserito otp valido, sessione autenticata
      - **ALREADY_VALIDATED**: già autenticato
      */
    }
  },

  created() {
  },

  mounted() {
    if (this.required == false) return this.$emit('change', { name: 'otp-check', payload: false})

    service_handler('otp', {})
      .then(
        (response) => {
          this.status = response.data.status
          this.display_name = response.data.displayName
          this.sent_to = response.data.sentTo
          this.by = response.data.by

          if (this.status == 'VALID') {
            this.$emit('change', { name: 'otp-check', payload: true})
          }

          if (this.status == 'ALREADY_VALIDATED') {
            this.$emit('change', { name: 'otp-check', payload: true})
            this.$emit('change', { name: 'otp-purchase', payload: true})
          }

          if (this.status == 'NOT_REQUIRED') {
            this.$emit('change', { name: 'otp-check', payload: true})
          }

          this.$emit('change', { name: 'otp-event', payload: this.status})

          this.loading = false
        },
        (error) => {
          this.log('request otp error')
          this.log(error)
          this.$emit('change', { name: 'otp-check', payload: true})
          this.$emit('change', { name: 'otp-event', payload: 'INIT_ERROR'})

          this.status = 'ERROR'
          this.loading = false
        }
      )
  },

  methods: {
    trigger() {
      this.$nextTick(() => {
        this.$refs.trigger_otp.click()
      })
    },

    countdown_timer() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1
          this.countdown_timer()
        }, 1000)
      }
    },

    request_otp() {
      this.log('[OTP] request_otp')
      this.loading = true
      this.code = ''
      this.msg = ''
      this.$emit('change', { name: 'otp-event', payload: 'REQUEST'})
      service_handler('otp', {
        new: true,
      })
        .then(
          (response) => {
            this.status = response.data.status
            this.loading = false
            this.prefix = response.data.prefix
            this.countdown = response.data.wait ? response.data.wait : 60
            this.countdown_timer()
            this.log(response)
            this.$emit('change', { name: 'otp-event', payload: this.status})
          },
          (error) => {
            this.log('request otp error')
            this.log(error)
            this.$emit('change', { name: 'otp-check', payload: true})
            this.$emit('change', { name: 'otp-event', payload: 'REQUEST_ERROR'})
            this.status = 'ERROR'
            this.loading = false
          }
        )
    },

    validate_otp() {
      this.log('[OTP] validate_otp')
      this.loading = true
      this.$emit('change', { name: 'otp-event', payload: 'VALIDATING'})
      service_handler('otp', {
        otp: this.code,
      })
        .then(
          (response) => {
            this.status = response.data.status
            this.msg = response.data.msg
            if (this.status == 'VALID') {
              this.$emit('change', { name: 'otp-check', payload: true})
              this.$emit('change', { name: 'otp-purchase', payload: true})
            }
            this.$emit('change', { name: 'otp-event', payload: this.status})
            this.loading = false
          },
          (error) => {
            this.log('validate otp error')
            this.log(error)
            this.$emit('change', { name: 'otp-check', payload: true})
            this.$emit('change', { name: 'otp-event', payload: 'VALIDATING_ERROR'})
            this.status = 'ERROR'
            this.loading = false
          }
        )
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-title {
  font-size: 16px;
}
.vb-btn {
  width:100%;
}

.otp {
  &--modal-requestcode {
  }

  &--modal {
    &-notify-mail {
      font-size: 13px;
      display: block;
      margin-bottom: 20px;
    }

    &-prefix {
      margin-right: 10px;
      margin-left: -35px;
      font-style: italic;
      font-size: 18px;
      color: $gray-500;
    }

    &-text {
      color: $red;
    }

    &-add-code {
      .vb-btn {
        display: inline-block;
        width:auto;
        margin-top:1rem;
      }
    }
  }
}

[otp-wrap="otp--ok"] {
  pointer-events: none !important;
  filter: blur(2px) !important;
  opacity: 0.6 !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
</style>