<template>
  <div :class="'user ' + variant" v-click-outside="user_close">
    <div class="user__toggle" @click="user_toggle">
      <div class="user__name" v-html="name" />
      <div class="user__avatar">
        <img :src="avatar" />
      </div>
    </div>
    <Transition name="bounce">
    <div class="user__info" v-if="links_open">
      <div class="user__integration">
        <div class="user__integration__item" v-if="is_mobile()" >
          <div class="user__name" v-html="name" />
        </div>
        <div class="user__integration__item" v-if="availability.total >= 0">
          <div class="recap">
            <div>Credito</div>
            <div class="ml-4">€ {{ availability.total }}</div>
          </div>
          <div v-if="availability.total !== availability.details.integration">
            <div>credito welfare</div>
            <div class="bold ml-4">€ {{ availability.details.integration }}</div>
          </div>
          <div v-if="availability.details.discount_codes">
            <div>codici sconto</div>
            <div class="bold ml-4">€ {{ availability.details.discount_codes }}</div>
          </div>
        </div>
      </div>
    </div>
    </Transition>
  </div>
</template>

<script>
  import Vue from 'vue'
  //import { sum, number } from 'mathjs'
  export default {
    name: 'user',
      components: {
      },
      props: {
        variant: {
          type: String,
          default: 'light',
        },
      },
      computed: {
        availability() {
          return this.get_availability()
        },
      },
      created() {
        window.addEventListener('scroll', () => {
          this.links_open = false
        })
      },
      data() {
        return {
          config: Vue.prototype.$config,
          avatar: '',
          name: '',
          links_open: false,
        }
      },
      mounted() {
        if(this.config.integration) {
          this.avatar = (this.config.integration ? this.config.integration.avatarSrc : '')
          this.name = (this.config.integration ? this.config.integration.displayName : '')
        }
      },
      methods: {
        user_open() {
          this.links_open = true
        },
        user_close() {
          this.links_open = false
        },
        user_toggle() {
          this.links_open = !this.links_open
        },
      },
    }
</script>

<style lang="scss">
.user {
  // transitions: https://vuejs.org/guide/built-ins/transition.html#css-based-transitions
  .bounce-enter-active {
    animation: bounce-in 0.35s;
    transform-origin: center top;
  }
  .bounce-leave-active {
    animation: bounce-in 0.35s reverse;
    transform-origin: center top;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }
  // transitions
  position:relative;
  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    &>* {
      min-height: 100%;
      display: flex;
      align-items: center;
      &:last-child {
        margin-right:0;
      }
    }
    .user__name {
      display: none;
    }
  }
  &__avatar {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    img {
      max-width: 100%;
    }
  }
  &__icon {
  }
  &__name {
    color: $info;
    font-weight: $font-weight-bold;
  }
  &__info {
    position:absolute;
    z-index: 10;
    background:$white;
    top:calc(100% + 10px);
    right: -5px;
    padding:$grid-gutter-width/2;
    border-radius: $border-radius;
    max-width: 280px;
    //border:1px solid #dedede;
    &:after {
      content: "";
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $white;
      position:absolute;
      bottom:100%;
      right: 17px;
    }
    .user__name {
      display: block;
      font-size: $font-size-sm;
      margin-bottom: $grid-gutter-width/5;
    }
  }
  &__integration {
    font-size: $font-size-sm;
    line-height: 1.2;
    .recap {
      font-size: $font-size-base;
      color: $secondary;
      font-weight: $font-weight-bold;

      & + * {
        margin-top: $grid-gutter-width/3;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      * {
        white-space: nowrap;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__toggle {
      .user__name {
        display: block;
        margin-right: $grid-gutter-width/3;
      }
    }
    &__info {
      .user__name {
        display: none;
      }
      top:100%;
      right:calc(50px + #{$grid-gutter-width/2});
      //max-width: 220px;
    }
  }

  // variante colore dark
  &.dark {
    .user__name {
      color: $secondary;
    }
    .user__integration {
      .recap {
        color: $info;
        //font-weight: $font-weight-normal;
      }
    }
  }  
}
</style>