const genders = [
  {
    text: 'MR',
    value: 'MALE',
  },
  {
    text: 'MS',
    value: 'FEMALE',
  },
]

export default genders