<template>
  <div
    @click="$event => {
      if (this.$listeners.click) this.$listeners.click($event)
    }"
    class="vb-icon"
    :class="dotted ? 'vb-icon--dotted' : ''"
    :style="
      '--size:'+size+'px;'+
      '--color:#'+color.replace('#', '')
    "
    v-html="Icons[name.replace('-','_')]"
  />
</template>

<script>
import * as DesignIcons from './design-icons'
import * as CreditCards from './credit-cards'
import * as CustomIcons from './custom-icons'

export default {
  name: 'vb-icon',
  props: {
    name: String,
    size: {
      type: Number,
      default: 32,
    },
    color: {
      type: String,
      default: '000000',
    },
    dotted: Boolean,
  },
  created() {
    this.Icons = {
      ...DesignIcons,
      ...CreditCards,
      ...CustomIcons,
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.vb-icon {
  svg {
    width: var(--size);
    height: var(--size);
  }

  &--dotted {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: $secondary;
      border-radius: 100%;
    }
  }
}
</style>