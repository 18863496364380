/**
 * NB: le variabili dello schema colori sono esportate a livello di vue.config.js tramite lo pseudoselettore :export
 * per eventuali altre variabili site-specific, aggiungere un altro :export dentro '@running_app_src/scss/abstract/_custom.scss'
 */
import * as exported_sass_variables from '@running_app_src/scss/abstract/_custom.scss'

export default {
  methods: {
    /**
     * senza parametri ritorna un oggetto con tutte le variabili esportate
     */
    sass: (key = false) => {
      if (key == false) return exported_sass_variables
      let value = exported_sass_variables[key]
      return value == undefined ? '' : value
    }
  }
}