/*
PROXY EXPORT FOR:
- session settings and logics (./session)
- static data for transcode purposes (./)
- directives (./directives)
- single-file components (./components)
*/

import countries from './countries'
import genders from './genders'
import CustomLink from './components/custom-link'
import ClickOutside from './directives/click-outside'
import capitalize from './filters/capitalize'
import uppercase from './filters/uppercase'
import lowercase from './filters/lowercase'
import {
  session_handler,
  load_state_from_local_storage,
  localstorage_store_key,
  localstorage_sessions_key,
  existing_session_key_query,
  new_session_key_query
} from './session'

// app resources
export {
  countries,
  genders,
  CustomLink,
  ClickOutside,
  capitalize,
  uppercase,
  lowercase,
}

// session
export {
  session_handler,
  load_state_from_local_storage,
  localstorage_store_key,
  localstorage_sessions_key,
  existing_session_key_query,
  new_session_key_query,
}